<template>
  <ContinueQuote productType="ride-protect" />
</template>

<script>
import ContinueQuote from "../../components/ContinueQuote.vue";

export default {
  components: {
    ContinueQuote
  }
};
</script>
