var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'ni ni ni-chart-bar-32 text-red',
          path: '/dashboard'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Virtual Insurance Broking Services',
          icon: 'ni ni ni ni-building text-red',
          collapsed: true 
        }}},_vm._l((_vm.yiProducts.filter(function (f){ return f.Product_name != 'Property'; })),function(product,index){return (_vm.isShowMenu(product))?_c('sidebar-item',{key:index,attrs:{"link":{
            name: product.Product_name,
            // icon: 'ni ni ni ni-building text-red',
            // collapsed: true 
          }}},[(!_vm.isCurrentPageCreateQuote(("products/" + (product.Url_slug))))?_c('sidebar-item',{attrs:{"link":{
              name: 'Create Quote',
              icon: 'ni ni ni-single-copy-04 text-red',
              path: ("/products/" + (product.Url_slug) + "/createQuote")
            }}}):_vm._e(),(_vm.isCurrentPageCreateQuote(("products/" + (product.Url_slug))))?_c('a',{staticClass:"nav-link router-link-exact-active router-link-active",attrs:{"href":("/products/" + (product.Url_slug) + "/createQuote"),"aria-current":"page"}},[_c('span',{staticClass:"nav-link-text"},[_vm._v("Create Quote")])]):_vm._e(),_c('sidebar-item',{attrs:{"link":{
              name: 'Quotes',
              icon: 'ni ni ni-collection text-red',
              path: ("/products/" + (product.Url_slug) + "/quotes")
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Policies',
              icon: 'ni ni ni-collection text-red',
              path: ("/products/" + (product.Url_slug) + "/policies")
            }}})],1):_vm._e()}),1),_vm._l((_vm.yiProducts.filter(function (f){ return f.Product_name == 'Property'; })),function(product,index){return (_vm.isShowMenu(product))?_c('sidebar-item',{key:index,attrs:{"link":{
            name: product.Product_name,
            icon: 'ni ni ni-shop text-red',
            // collapsed: true 
          }}},[(!_vm.isCurrentPageCreateQuote(("products/" + (product.Url_slug))))?_c('sidebar-item',{attrs:{"link":{
              name: 'Create Quote',
              icon: 'ni ni ni-single-copy-04 text-red',
              path: ("/products/" + (product.Url_slug) + "/createQuote")
            }}}):_vm._e(),(_vm.isCurrentPageCreateQuote(("products/" + (product.Url_slug))))?_c('a',{staticClass:"nav-link router-link-exact-active router-link-active",attrs:{"href":("/products/" + (product.Url_slug) + "/createQuote"),"aria-current":"page"}},[_c('span',{staticClass:"nav-link-text"},[_vm._v("Create Quote")])]):_vm._e(),_c('sidebar-item',{attrs:{"link":{
              name: 'Quotes',
              icon: 'ni ni ni-collection text-red',
              path: ("/products/" + (product.Url_slug) + "/quotes")
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Policies',
              icon: 'ni ni ni-collection text-red',
              path: ("/products/" + (product.Url_slug) + "/policies")
            }}})],1):_vm._e()}),(_vm.show_lc)?_c('sidebar-item',{attrs:{"link":{
          name: 'Light Commercial',
          icon: 'ni ni ni ni-delivery-fast text-red',
          collapsed: true
        }}},[(!_vm.isCurrentPageCreateQuote('lightCommercial'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Create Quote',
            path: '/lightCommercial/createQuote'
          }}}):_vm._e(),(_vm.isCurrentPageCreateQuote('lightCommercial'))?_c('a',{staticClass:"nav-link router-link-exact-active router-link-active",attrs:{"href":"/lightCommercial/createQuote","aria-current":"page"}},[_c('span',{staticClass:"nav-link-text"},[_vm._v("Create Quote")])]):_vm._e(),_c('sidebar-item',{attrs:{"link":{
            name: 'Quotes',
            icon: 'ni ni ni-collection text-red',
            path: '/lightCommercial/quotes'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Policies',
            icon: 'ni ni ni-collection text-red',
            path: '/lightCommercial/policies'
          }}})],1):_vm._e(),(_vm.show_rs)?_c('sidebar-item',{attrs:{"link":{
          name: 'Rideshare',
          icon: 'fas fa-car text-red',
          collapsed: true
        }}},[_c('div',{staticStyle:{"padding-left":"54px","font-size":"10pt","padding-right":"20px","font-style":"italic"}},[_vm._v("As of 1 August 2024, Mercurien is no longer supporting Rideshare. Please contact "),_c('a',{attrs:{"href":"mailto:support@mercurien.com"}},[_vm._v("support@mercurien.com")]),_vm._v(" for further information.")]),(!_vm.isCurrentPageCreateQuote('rideShare') && _vm.canSeeRideShare()==true)?_c('sidebar-item',{attrs:{"link":{
            name: 'Create Quote',
            path: '/rideShare/createQuote'
          }}}):_vm._e(),(_vm.isCurrentPageCreateQuote('rideShare') && _vm.canSeeRideShare()==true)?_c('a',{staticClass:"nav-link router-link-exact-active router-link-active",attrs:{"href":"/rideShare/createQuote","aria-current":"page"}},[_c('span',{staticClass:"nav-link-text"},[_vm._v("Create Quote")])]):_vm._e(),(_vm.canSeeRideShare()==true)?_c('sidebar-item',{attrs:{"link":{
            name: 'Quotes',
            path: '/rideShare/quotes'
          }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
            name: 'Policies',
            path: '/rideShare/policies'
          }}})],1):_vm._e(),(_vm.can_see_fleet)?_c('sidebar-item',{attrs:{"link":{
          name: 'Fleet',
          icon: 'fas fa-handshake text-red',
          collapsed: true
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Light Commercial',
            path: '/lightCommercial/fleet'
          }}})],1):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Reports',
          icon: 'ni ni ni ni-chart-bar-32 text-red',
          path: '/reports/yourInsuranceReports'
        }}}),(_vm.brokerObj.Broker_super_admin)?_c('sidebar-item',{attrs:{"link":{
          name: 'Admin',
          icon: 'ni ni ni-folder-17 text-red',
          collapsed: true
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Brokers', path: '/admin/brokers' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Broker Organisations', path: '/admin/brokerOrgs' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Insurance Brokers',
            path: '/admin/insuranceBrokers'
          }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Customers', path: '/admin/customers' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Email Templates', path: '/admin/emailTemplates' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Content Templates',
            path: '/admin/contentTemplates'
          }}})],1):_vm._e()],2),_c('template',{slot:"links-after"},[_c('hr',{staticClass:"my-3"})])],2),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"body-container"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }