import Vue from "vue";
import axios from "axios";

export const appFunctions = new Vue({
  methods: {
    async crud_customerLCPolicy(method, data) {
      var resp = await this.postData("/crud/customer-lc-policy", {
        method,
        data
      });
      return resp.data;
    },

    async crud_customerRSPolicy(method, data) {
      var resp = await this.postData("/crud/customer-rs-policy", {
        method,
        data
      });
      return resp.data;
    },

    async crud_customerFleetPolicy(method, data) {
      var resp = await this.postData("/crud/customer-fleet-policy", {
        method,
        data
      });
      return resp.data;
    },

    async crud_customerPolicy(method, data) {
      var resp = await this.postData("/crud/customer-policy", { method, data });
      return resp.data;
    },

    async crud_emailTemplate(method, data) {
      var resp = await this.postData("/crud/email-template", { method, data });
      return resp.data;
    },

    async crud_contentTemplate(method, data) {
      var resp = await this.postData("/crud/content-template", {
        method,
        data
      });
      return resp.data;
    },

    async crud_broker(method, data) {
      var resp = await this.postData("/crud/broker", { method, data });
      return resp.data;
    },

    async crud_insuranceBroker(method, data) {
      var resp = await this.postData("/crud/insurance-broker", {
        method,
        data
      });
      return resp.data;
    },

    async crud_brokerOrg(method, data) {
      var resp = await this.postData("/crud/broker-org", { method, data });
      return resp.data;
    },

    async crud_customer(method, data) {
      var resp = await this.postData("/crud/customer", { method, data });
      return resp.data;
    },

    async getConfig() {
      var resp = await this.postData("/auth/getConfig");
      return resp.data;
    },

    async resetPassword(token, tokenId, password) {
      var resp = await this.postData("/lookup/resetPassword", {
        token,
        tokenId,
        password
      });
      return resp.data;
    },

    async requestPasswordReset(token, tokenId, email) {
      var resp = await this.postData("/lookup/requestPasswordReset", {
        token,
        tokenId,
        email
      });
      return resp.data;
    },

    async ubindAuthorise(requestBody) {
      
      var resp = await this.postData("/ubind/authorise", requestBody);
      return resp.data;
    },

    async ubindCreateCustomer(requestBody, authToken) {
      var resp = await this.postData("/ubind/create-customer", {
        requestBody,
        authToken
      });
      return resp.data;
    },

    async ubindCreateQuote(requestBody, authToken) {
      var resp = await this.postData("/ubind/create-quote", {
        requestBody,
        authToken
      });
      return resp.data;
    },

    async ubindCreateUser(requestBody, authToken) {
      var resp = await this.postData("/ubind/create-user", {
        requestBody,
        authToken
      });
      return resp.data;
    },

    async ubindCancelPolicy(policyId, authToken) {
      var resp = await this.postData("/ubind/cancel-policy", {
        policyId,
        authToken
      });
      return resp.data;
    },

    async ubindDiscardQuote(quoteId, authToken) {
      var resp = await this.postData("/ubind/quote-discard", {
        quoteId,
        authToken
      });
      return resp.data;
    },

    async ubindAdjustPolicy(policyId, authToken) {
      var resp = await this.postData("/ubind/adjust-policy", {
        policyId,
        authToken
      });
      return resp.data;
    },

    async ubindRenewPolicy(policyId, authToken) {
      var resp = await this.postData("/ubind/renew-policy", {
        policyId,
        authToken
      });
      return resp.data;
    },

    async ubindCheckDocument(brokerRealmId, policyId, CustomerPolicyId, policyTransactionId, isRS) {
      var resp = await this.postData("/ubind/get-document", {
        brokerRealmId, policyId, CustomerPolicyId, policyTransactionId, isRS
      });
      return resp.data;
    },

    async addFileAttachment(data) {
      var resp = await this.postData("/attachment/file", data);
      return resp.data;
    },

    async addClaimAttachment(data) {
      var resp = await this.postData("/attachment/claim", data);
      return resp.data;
    },

    async addTemplateAttachment(data) {
      var resp = await this.postData("/attachment/template", data);
      return resp.data;
    },

    async lookupLCQuoteByGuid(data) {
      console.log(data);
      var resp = await this.postData("/lookup/getLCQuoteByGuid", {
        policy_guid: data
      });
      return resp.data;
    },
    async lookupQuoteFromUbindGuid(data) {
      console.log(data);
      var resp = await this.postData("/lookup/getLCQuoteByUbindGuid", {
        policy_guid: data
      });
      return resp.data;
    },
    async lookupRSQuoteFromUbindGuid(data) {
      console.log(data);
      var resp = await this.postData("/lookup/getRSQuoteByUbindGuid", {
        policy_guid: data
      });
      return resp.data;
    },

    async lookupLCQuoteByGuid(data) {
      console.log(data);
      var resp = await this.postData("/lookup/getLCQuoteByGuid", {
        policy_guid: data
      });
      return resp.data;
    },

    async lookupRSQuoteByGuid(data) {
      var resp = await this.postData("/lookup/getRSQuoteByGuid", {
        policy_guid: data
      });
      return resp.data;
    },

    async crud_customerLCClaim(method, data) {
      var resp = await this.postData("/crud/customer-lc-claim", {
        method,
        data
      });
      return resp.data;
    },

    async crud_customerRSClaim(method, data) {
      var resp = await this.postData("/crud/customer-rs-claim", {
        method,
        data
      });
      return resp.data;
    },

    async crud_mercurienFleetManagementInquiries(method, data) {
      var resp = await this.postData(
        "/crud/mercurien-fleet-management-enquiries",
        {
          method,
          data
        }
      );
      return resp.data;
    },

    async lookupBrokerByRealmId(data) {
      var resp = await this.postData("/lookup/getBrokerByRealmId", data);
      return resp.data;
    },

    async getNextCustomerFleetPolicyID(method, data) {
      var resp = await this.postData("/crud/customer-fleet-policy", {method, data});
      return resp.data;
    },

    async lookupGetConfig() {
      var resp = await this.postData("/lookup/getConfig");
      return resp.data;
    },

    postData(url, data) {
      let config = {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token")
        }
      };

      return axios.post(process.env.VUE_APP_APIENDPOINT + url, data, config);
    },

    checkAccessPermission(realm_user_id, permission) {
      return this.postData("/auth/checkAccessPermission", {
        realm_user_id,
        permission
      });
    },
    async getFileAttachment(method, data) {
      var resp = await this.postData("/attachment/getFileAttachment", {
        method,
        data
      });
      return resp.data;
    },
    async registerUser(username, password, fname, lname) {
      var resp = await this.postData("/auth//createAuth0User", {
        username,
        password,
        fname,
        lname
      });
      return resp.data;
    },
    async crud_customerRSFleetPolicy(method, data) {
      var resp = await this.postData("/crud/customer-rs-fleet-policy", {
        method,
        data
      });
      return resp.data;
    },

    async getNextCustomerRSFleetPolicyID(method, data) {
      var resp = await this.postData("/crud/customer-rs-fleet-policy", {method, data});
      return resp.data;
    },

    async uploadImage(data) {
      var resp = await this.postData("/s3/upload-image", {data});
      return resp.data;
    },

    async uploadDocuments(data) {
      var resp = await this.postData("/s3/upload-documents", {data});
      return resp.data;
    },

    async crud_yiProduct(method, data) {
      var resp = await this.postData("/crud/yi-product", {
        method,
        data
      });
      return resp.data;
    },

    async lookupCustomerPolicyByQuoteId(data) {
      var resp = await this.postData("/lookup/getQuoteByJotformQuoteId", {
        data
      });
      return resp.data;
    },

    async sendMailGunEmail(
      emailAddresses,
      ccAddresses,
      bccAddresses,
      subjectLine,
      templateHtml,
      emailFrom,
      attachments,
      replyTo
    ) {
      var resp = await this.postData("/email/send-mail-gun-email", {
        emailAddresses,
        ccAddresses,
        bccAddresses,
        subjectLine,
        templateHtml,
        emailFrom,
        attachments,
        replyTo
      });
      return resp.data;
      x;
    }
  }
});
