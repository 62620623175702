<template>
  <div>
    <ul>
      <li
        v-for="(file, index) in fileList"
        :key="uploaderKey + '_file_' + index"
      >
        <div style="display: inline-block">{{ file.name }}</div>
        <div
          v-if="!file.success"
          style="display: inline-block; font-weight: bold; padding-left: 10px"
        >
          Please wait...file processing
        </div>
        <div
          v-if="file.success"
          style="display: inline-block; font-weight: bold; padding-left: 10px"
        >
          Upload successful
        </div>
      </li>
    </ul>
    <file-upload
      class="btn btn-primary btn-uploader"
      :custom-action="customAction"
      extensions="gif,jpg,jpeg,png,webp,doc,docx,pdf,xls,xlsx,csv"
      accept="image/png,image/gif,image/jpeg,image/webp,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      :multiple="multiple"
      v-model="fileList"
      @input-filter="inputFilter"
      @input-file="inputFile"
      :input-id="uploaderKey"
      :ref="uploaderKey"
      active="true"
    >
      <i class="fa fa-plus"></i>
      Select files
    </file-upload>
  </div>
</template>

<script>
import VueUploadComponent from "vue-upload-component";

export default {
  props: {
    /** Array that will be populated with the uploaded files. */
    files: {
      type: Array,
      required: true,
    },
    /** Id of the uploader. */
    uploaderKey: "",
    /** Allows the submission of multiple files. */
    multiple: {
      type: Boolean,
      default: false,
    },
    resetFileListAfterUpload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileList: [],
    };
  },
  components: {
    FileUpload: VueUploadComponent,
  },
  mounted() {
    console.log(this.uploaderKey);
  },
  methods: {
    async customAction(file) {
      const blob = await fetch(file.blob).then((res) => res.blob());

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        var base64data = reader.result;
        if (!this.multiple) {
          sessionStorage.setItem(this.uploaderKey, base64data);
          sessionStorage.setItem(this.uploaderKey + "_filename", file.name);
          this.$emit("uploadComplete", this.uploaderKey + "_filename");
          if (this.resetFileListAfterUpload == true) {
            this.fileList = [];
          }
        } else {
          if (!sessionStorage.getItem("attached_file_count")) {
            sessionStorage.setItem("attached_file_count", 0);
          }

          let fileCount = parseInt(
            sessionStorage.getItem("attached_file_count")
          );

          fileCount++;

          sessionStorage.setItem("attached_file_count", fileCount);

          sessionStorage.setItem(
            this.uploaderKey + "_" + fileCount.toString(),
            base64data
          );
          sessionStorage.setItem(
            this.uploaderKey + "_" + fileCount.toString() + "_filename",
            file.name
          );
          sessionStorage.setItem(
            this.uploaderKey + "_" + fileCount.toString() + "_type",
            file.type
          );
        }
        return null;
      };
    },
    /**
     * Has changed
     * @param  Object newFile Read only
     * @param  Object oldFile Read only
     */
    inputFile: function (newFile, oldFile) {

      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // Get response data
        console.log("response", newFile.response);
        if (newFile.xhr) {
          //  Get the response status code
          console.log("status", newFile.xhr.status);
        }
      }

      setTimeout(() => {
        if (
          Boolean(newFile) !== Boolean(oldFile) ||
          oldFile.error !== newFile.error
        ) {
          this.$refs[this.uploaderKey].active = true;
        }
      }, 2000);
    },
    /**
     * Pretreatment
     * @param  Object newFile Read and write
     * @param  Object oldFil  Read only
     * @param  Function prevent Prevent changing
     */
    inputFilter: function (newFile, oldFile, prevent) {
      if (!newFile.success) {
        this.$emit("disableNextStep", true)
      } else {
        this.$emit("disableNextStep", false)
      }

      if (newFile && !oldFile) {
        // Filter non-image file
        if (
          !/\.(jpeg|jpe|jpg|gif|png|webp|doc|docx|pdf|xls|xlsx|csv)$/i.test(
            newFile.name
          )
        ) {
          return prevent();
        }
      }

      // Create a blob field
      newFile.blob = "";
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file);
      }
    },
  },
};
</script>

<style lang="scss">
.btn-uploader {
  background-color: var(--uBind) !important;
  border-color: var(--uBind) !important;
}
</style>
