<template>
  <div>
    <!-- Header -->
    <div class="header py-7 py-lg-6 pt-lg-9">
      <div class="separator separator-bottom separator-skew zindex-100">
        <!-- <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg> -->
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="7" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <img class="login-logo" src="../assets/logo_full.png" />
            <b-card-body >


              <iframe  id="JotFormIFrame-241757895673071" title="Broker Feedback Survey"
                onload="window.parent.scrollTo(0,0)" allowtransparency="true"
                allow="geolocation; microphone; camera; fullscreen" src="https://form.jotform.com/241757895673071"
                frameborder="0" style="width: 800px;height:539px;border:none;" scrolling="no">
              </iframe>
              <!-- <script src='https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js'></script>
              <script>
                window.jotformEmbedHandler("iframe[id='JotFormIFrame-241757895673071']", "https://form.jotform.com/")
              </script> -->

            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <TextFooter />
  </div>
</template>

<script>
import TextFooter from "../components/TextFooter.vue";
import { GlobalStore } from "../globalStore";
import { toasts } from "../helpers/toasts";
import { appFunctions } from "../helpers/appFunctions";

export default {
  data() {
    return {
      password1: "",
      password2: "",
      token: "",
      tokenId: "",
      year: new Date().getFullYear()
    };
  },
  methods: {
    onSubmit() {
      // var Realm = window.Realm;
      // const config = {
      //   id: GlobalStore.realm_app_id
      // };
      // const app = new Realm.App(config);

      // app.emailPasswordAuth
      //   .resetPassword(this.token, this.tokenId, this.password1)
      //   .then(resp => {
      //     console.log(resp);
      //     toasts.success("Thank you your password has been reset.");
      //   });

      // this.$router.push("/login");

      // appFunctions
      //   .resetPassword(this.token, this.tokenId, this.password1)
      //   .then(resp => {
      //     if (!resp.error) {
      //       console.log(resp);
      //       toasts.success("Thank you your password has been reset.");
      //       this.$router.push("/login");
      //     } else {
      //       toasts.error(resp.error);
      //     }
      //   });
    }
  },
  mounted() {

  },
  components: {
    TextFooter
  }
};
</script>

<style scoped>
p {
  font-size: 100%;
  font-weight: 400;
  color: black;
}

.card-body {
  padding-top: 0;
}

a {
  color: #ff4438 !important;
}
</style>
