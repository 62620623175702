<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">{{ $route.name }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <div class="container-fluid mt--6">
      <b-card no-body class="mb-4">
        <!-- Card body -->
        <b-card-body>
          <b-card no-body>
            <!-- <table v-if="viewMode == 'list'">
      <tr style="background-color: lightGray">
        <td>Content Template Id</td>
        <td>Content Template Name</td>
        <td>&nbsp;</td>
      </tr>
      <tr></tr>
      <tr v-for="item in contentTemplates" :key="item.content_template_id">
        <td>{{ item.content_template_id }}</td>
        <td>{{ item.content_template_name }}</td>
        <td>
          <button @click="editItem(item.content_template_id)">Edit</button>
        </td>
      </tr>
      <tr></tr>
    </table> -->

            <div v-if="viewMode == 'list'">
              <el-table
                class="table-responsive table-flush"
                header-row-class-name="thead-light"
                row-key="content_template_id"
                :data="contentTemplates"
              >
                <el-table-column
                  type="selection"
                  align="left"
                  min-width="120px"
                >
                </el-table-column>

                <el-table-column
                  label="Template ID"
                  min-width="220px"
                  prop="content_template_id"
                  sortable
                >
                  <template v-slot="{ row }">
                    <b>{{ row.content_template_id }}</b>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="180px"
                  align="right"
                  label="Actions"
                >
                  <div slot-scope="{ $index, row }" class="d-flex">
                    <base-button
                      @click.native="handleEdit($index, row)"
                      class="edit"
                      type="success"
                      size="sm"
                      icon
                    >
                      <i class="text-white ni ni-ruler-pencil"></i>
                    </base-button>
                  </div>
                </el-table-column>
              </el-table>
            </div>

            <div class="editorContainer" v-if="viewMode == 'edit'">
              <div class="editorFrame">
                <!-- <div class="field-layout">
                  <div class="field-layout__col1">Content Template Id:</div>
                  <div class="field-layout__col2">
                    {{ currentItem.content_template_id }}
                  </div>
                </div>
                <div class="field-layout">
                  <div class="field-layout__col1">Content Template Name:</div>
                  <div class="field-layout__col2">
                    <input
                      v-model="currentItem.content_template_name"
                      type="text"
                    />
                  </div>
                </div> -->

                <b-card-body>
                  <b-row>
                    <b-col md="12">
                      <base-input
                        name="Content Template Id"
                        label="Content Template Id"
                        v-model="currentItem.content_template_id"
                        disabled
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <base-input
                        name="Content Template Name"
                        label="Content Template Name"
                        v-model="currentItem.content_template_name"
                      />
                    </b-col>
                  </b-row>
                </b-card-body>
                <div style="margin-left: 40px;margin-right: 40px;">
                  <quasar-tiptap
                    ref="editor1"
                    v-bind="options"
                    @update="onUpdate"
                  />

                  <div style="text-align:center;">
                    <base-button @click="saveItem()" type="primary" class="mb-4"
                      >Save Template</base-button
                    >

                    <base-button
                      @click="cancel()"
                      type="secondary"
                      class="mb-4 btn-outline-primary"
                      >Cancel</base-button
                    >
                  </div>
                </div>

                <!-- <input @click="saveItem()" type="button" value="Save" /> &nbsp;
                <input @click="cancel()" type="button" value="Cancel" /> -->
              </div>
            </div>
          </b-card>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { GlobalStore } from "../../globalStore";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip
} from "element-ui";
import {
  QuasarTiptap,
  // RecommendedExtensions,
  QuasarTiptapPlugin
} from "quasar-tiptap";
import "quasar-tiptap/lib/index.css";

import { Placeholder } from "tiptap-extensions";
import { appFunctions } from "../../helpers/appFunctions";

Vue.use(QuasarTiptapPlugin, {
  language: "en-us",
  spellcheck: true
});

export const TipTapExtensions = [
  "Bold",
  "Italic",
  "Strike",
  "Underline",
  "Code",
  "CodeBlock",
  "CodeBlockHighlight",
  "BulletList",
  "OrderedList",
  "ListItem",
  "TodoList",
  "HorizontalRule",
  "Table",
  "Link",
  "Image"
];

export const QuasarTipTapExtensions = [
  // "OTitle",
  // "ODoc",
  "OParagraph",
  "OBlockquote",
  "OTodoItem",
  "OHeading",
  "OAlignment",
  "OLineHeight",
  "OForeColor",
  "OBackColor",
  "OFontFamily",
  "OIframe",
  "ODiagram",
  "OKatexBlock",
  "OKatexInline",
  "OFormatClear",
  new Placeholder({
    showOnlyCurrent: false,
    emptyNodeText: node => {
      return "Start writing here.";
    }
  })
];

export const RecommendedExtensions1 = [
  ...TipTapExtensions,
  ...QuasarTipTapExtensions
];

export default {
  data() {
    return {
      realmApp: null,
      contentTemplates: [],
      viewMode: "list",
      options: {
        height: "300px",
        content: "<p>hello</p><p>hello</p>",
        editable: true,
        extensions: [
          ...RecommendedExtensions1
          // other extenstions
          // name string, or custom extension
        ],
        toolbar: [
          "separator",
          "bold",
          "italic",
          "underline",
          "strike",
          "code",
          "separator",
          "heading",
          "font-family",
          "fore-color",
          "back-color",
          "format_clear",
          "separator",
          "align-dropdown",
          "indent",
          "outdent",
          "line-height",
          "separator",
          "horizontal",
          "bullet_list",
          "ordered_list",
          "todo_list",
          "separator",
          "blockquote",
          "code_block",
          "photo",
          "table",
          "separator",
          "undo",
          "redo"
          // other toolbar buttons
          // name string
        ]
      },
      json: "",
      html: ""
    };
  },
  name: "ContentTemplates",
  methods: {
    cancel() {
      this.viewMode = "list";
    },
    saveItem() {
      this.currentItem.content_template_html = this.html;
      this.contentTemplates = appFunctions
        .crud_contentTemplate("update", this.currentItem)
        .then(resp => {
          console.log(resp);
          this.getContentTemplates();
          this.viewMode = "list";
          // this.emailTemplates = resp.data;
        });
    },
    editItem(id) {
      this.viewMode = "edit";
      this.currentItem = this.contentTemplates.find(
        f => f.content_template_id === id
      );
      this.options.content = this.currentItem.content_template_html;
      this.html = this.currentItem.content_template_html;
      console.log(this.currentItem);
    },
    getContentTemplates() {
      this.contentTemplates = appFunctions
        .crud_contentTemplate("list")
        .then(resp => {
          // console.log(resp);
          this.contentTemplates = resp.data;
        });
    },
    onUpdate({ getJSON, getHTML }) {
      this.json = getJSON();
      this.html = getHTML();
      // console.log("html", this.html);
    },
    handleEdit(index, row) {
      console.log(index, row);

      this.editItem(row.content_template_id);
      // this.$router.push("quotes/" + row.Customer_policy_id);
      // swal({
      //   title: `You want to edit ${row.name}`,
      //   buttonsStyling: false,
      //   confirmButtonClass: 'btn btn-info btn-fill'
      // });
    }
  },
  async mounted() {
    this.realmApp = GlobalStore.realmApp;
    // console.log(this.realmApp);

    this.getContentTemplates();
    // console.log("loaded");
    // var app = await authMethods.initAppInstance();
    // console.log(app);
  },
  components: {
    QuasarTiptap,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu
  }
};
</script>

<style lang="scss">
.contentTemplateList {
  table {
    margin: auto;
    width: 800px;
  }
  td {
    padding: 10px;
    text-align: left;
  }

  .editor__content {
    height: 400px;
    border: 1px solid lightgray;
    overflow-y: auto;
    text-align: left;
  }

  .editorFrame {
    width: 930px;
    margin: auto;
  }

  .field-layout {
    text-align: left;
    padding: 10px;
    position: relative;
    &__col1 {
      width: 200px;
      display: inline-block;
    }
    &__col2 {
      display: inline-block;
      input {
        width: 400px;
      }
    }
  }
}
</style>
