<template>
  <QuoteEmailed
    header="Quote Emailed"
    title="Commercial Vehicle Insurance"
    bodyTitle="Quote Emailed"
    bodyText="A quote has been emailed to your Customer with a link to
                complete the quotation steps."
    :newQuoteAction="newQuote"
    :viewQuotesAction="viewQuotes"
  />
</template>

<script>
import QuoteEmailed from "../../components/QuoteEmailed.vue";

export default {
  methods: {
    newQuote() {
      this.$router.push("/lightCommercial/createQuote");
    },
    viewQuotes() {
      this.$router.push("/lightCommercial/quotes");
    }
  },
  components: {
    QuoteEmailed
  }
};
</script>
