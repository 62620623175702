import Vue from "vue";
import { GlobalStore } from "../globalStore";
import { appFunctions } from "../helpers/appFunctions";

export const accessMethods = new Vue({
  methods: {
    async checkAccessPermission(permission) {
      var res = await appFunctions.checkAccessPermission(
        GlobalStore.brokerObj.Realm_user_id,
        permission
      );
      return res;
    }
  }
});
