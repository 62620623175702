<template>
  <!-- Left div because b-navbar does not trigger on mouseenter / mouseleave events -->
  <div
    class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
    @mouseenter="$sidebar.onMouseEnter()"
    @mouseleave="$sidebar.onMouseLeave()"
    :data="backgroundColor"
  >
    <div class="scrollbar-inner" ref="sidebarScrollArea">
      <div class="sidenav-header d-flex align-items-center">
        <b-navbar-brand href="#">
          <img :src="getLogo" class="navbar-brand-img" alt="Sidebar logo" />
        </b-navbar-brand>
        <div class="ml-auto">
          <!-- Sidenav toggler -->
          <div
            class="sidenav-toggler d-none d-xl-block"
            :class="{ active: !$sidebar.isMinimized }"
            @click="minimizeSidebar"
          >
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </div>
      </div>
      <slot></slot>
      <div class="navbar-inner">
        <b-navbar-nav>
          <slot name="links">
            <sidebar-item
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
            >
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink"
              >
              </sidebar-item>
            </sidebar-item>
          </slot>
        </b-navbar-nav>
        <slot name="links-after"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import { GlobalStore } from "../../globalStore";

export default {
  name: "sidebar",
  props: {
    title: {
      type: String,
      default: "Sidebar",
      description: "Sidebar title"
    },
    shortTitle: {
      type: String,
      default: "CT",
      description: "Sidebar short title"
    },
    logo: {
      type: String,
      default: require(`../../assets/logo_full.png`),
      description: "Sidebar app logo"
    },
    backgroundColor: {
      type: String,
      default: "vue",
      validator: value => {
        let acceptedValues = [
          "",
          "vue",
          "blue",
          "green",
          "orange",
          "red",
          "primary"
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        "Sidebar background color (vue|blue|green|orange|red|primary)"
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these."
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item"
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },

    /**
     * Fixing template sidebar issue.
     * @depreciated
     */
    watchWidth() {
      if (window.innerWidth < this.$sidebar.breakpoint) {
        this.$sidebar.isMinimized =
          window.innerWidth < this.$sidebar.breakpoint;

        let docClasses = document.body.classList;
        docClasses.add("g-sidenav-hidden");
        docClasses.remove("g-sidenav-pinned");
        docClasses.remove("g-sidenav-show");
      }
    }
  },
  computed: {
    getLogo() {
      try {
        return GlobalStore.brokerObj.parentOrg.parentInsuranceBroker
          .insurance_broker_logo;
      } catch (error) {
        console.error(error.message);
        return "src/assets/placeholder.jpeg";
      }
    }
  },
  mounted() {
    this.$sidebar.isMinimized = this.$sidebar.breakpoint < window.innerWidth;
    this.minimizeSidebar();
    window.addEventListener("resize", this.watchWidth);
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }

    window.removeEventListener("resize", this.watchWidth);
  }
};
</script>
