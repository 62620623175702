var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('base-header',{staticClass:"pb-6"},[_c('b-row',{staticClass:"py-4",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"7","lg":"6"}},[_c('h6',{staticClass:"h1 text-white d-inline-block mb-0 header fw-500"},[_vm._v(" "+_vm._s(_vm.productName)+" Policies ")]),_c('nav',{staticClass:"d-none d-md-inline-block ml-md-4",attrs:{"aria-label":"breadcrumb"}})])],1)],1),_c('div',{staticClass:"mt--6 px-2 px-sm-4"},[_c('div',[_c('div',{staticClass:"bg-white rounded-bottom change-color"},[_c('div',[_c('b-col',{staticClass:"pt-3 d-flex flex-wrap bg-white rounded-top",attrs:{"cols":"12"}},[_c('el-select',{staticClass:"select-primary pagination-select mb-3 mr-3",attrs:{"placeholder":"Per page"},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1),_c('div',{staticClass:"mr-3"},[_c('base-input',{attrs:{"prepend-icon":"fas fa-search","placeholder":"Search..."},on:{"input":function($event){return _vm.resetPagination()}},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('div',[_c('base-button',{staticClass:"btn-refresh",attrs:{"type":"danger"},on:{"click":function($event){return _vm.refreshData()}}},[_c('b-icon',{attrs:{"icon":"arrow-repeat"}})],1)],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.queriedData,"row-key":"_id","default-sort":{
              prop: 'Jotform_quote_created_date',
              order: 'descending'
            },"header-row-class-name":"thead-light"},on:{"sort-change":_vm.sortChange,"selection-change":_vm.selectionChange},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.loading ? "" : "No Data"))])]},proxy:true}])},[_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-row',{staticClass:"px-5 expandedPolicy"},[_c('b-col',{attrs:{"cols":"6","md":"8"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('p',[_vm._v("Created Date:")]),_c('p',[_vm._v("Insured Contact Name:")]),_c('p',[_vm._v("Insured Email:")]),_c('p',[_vm._v("Number Of Vehicles:")]),_c('p',[_vm._v("Business Description:")])]),_c('b-col',{attrs:{"cols":"7"}},[_c('p',[_vm._v(" "+_vm._s(_vm.formatDateTime(props.row.Jotform_quote_created_date))+" ")]),_c('p',[_vm._v(" "+_vm._s(props.row.Customer_contact_first_name)+" "+_vm._s(props.row.Customer_contact_last_name)+" ")]),_c('p',[_vm._v(_vm._s(props.row.Customer_contact_email))]),_c('p',[_vm._v(" "+_vm._s(_vm.getNumberOfVehicles( function () { return props.row.Jotform_data_obj.insuredUnits; } ))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return props.row.Jotform_data_obj.businessOccupation.join(', '); }))+" ")])])],1)],1),_c('b-col',{attrs:{"md":"1"}}),_c('b-col',{attrs:{"cols":"6","md":"3"}},_vm._l((props.row.documents),function(document){return _c('b-row',{staticClass:"pl-2",attrs:{"align-h":"center"}},[_c('b-col',{staticClass:"mb-4",attrs:{"cols":"auto","md":"12"}},[_c('base-button',{staticClass:"edit",attrs:{"type":"danger","size":"sm"}},[_c('a',{staticClass:"text-white",attrs:{"href":document.filePath,"target":"_blank"}},[_c('i',{staticClass:"fas fa-file-download btn-download"},[_c('span',[_vm._v(" "+_vm._s(document.fileName))])])])])],1)],1)}),1)],1)]}}])}),(_vm.hasUploadPermission)?_c('el-table-column',{attrs:{"label":"Upload","min-width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"tabindex":"0"}},[_c('base-button',{staticClass:"edit",attrs:{"type":"danger","size":"sm","icon":""},nativeOn:{"click":function($event){return _vm.showUploadDialog(row)}}},[_c('b-icon',{attrs:{"font-scale":"2","icon":"upload"}})],1)],1)}}],null,false,630345267)}):_vm._e(),_c('el-table-column',{attrs:{"label":"Status","min-width":"100px","prop":"Status","filters":[
                { text: 'Accepted', value: 'Accepted' }
              ],"filter-method":_vm.filterStatus}}),_c('el-table-column',{attrs:{"label":"Business Name","prop":"Customer_insured_name","min-width":"150px","sortable":""}}),_c('el-table-column',{attrs:{"label":"Total Payable","prop":"finalTotalPayable","min-width":"150px","formatter":function (row) { return _vm.formatPremium(row); },"sortable":""}}),_c('el-table-column',{attrs:{"label":"Quote Ref","min-width":"130px","prop":"Quote_Ref_No","sortable":""}}),_c('el-table-column',{attrs:{"label":"Last Updated","prop":"Jotform_quote_last_updated_date","min-width":"180px","sortable":"","formatter":function (row) { return _vm.formatDateTime(row.Jotform_quote_last_updated_date); }}}),_c('el-table-column',{attrs:{"label":"Broker Name","min-width":"180px","prop":"Broker_Name","sortable":""}}),_c('el-table-column',{attrs:{"label":"Broker Organization","min-width":"180px","prop":"Broker_Org_Name","sortable":""}})],1)],1),_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap py-3 px-5",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v(" Showing "+_vm._s(_vm.from + 1)+" to "+_vm._s(_vm.to)+" of "+_vm._s(_vm.total)+" entries "),(_vm.selectedRows.length)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedRows.length)+" rows selected ")]):_vm._e()])]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"value":_vm.pagination.currentPage,"per-page":_vm.pagination.perPage,"total":_vm.total},on:{"change":function($event){return _vm.paginationChanged($event)}}})],1)])]),_c('dialog-upload-document',{attrs:{"acceptedQuote":_vm.acceptedQuote,"titleDialog":_vm.titleDialog,"quoteScheduleModal":_vm.quoteScheduleModal},on:{"modelPopupCancel":_vm.modelPopupCancel,"handleCallAPI":_vm.handleCallAPI}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }