<template>
  <EditQuote
    title="Rideshare - Edit Quote"
    productType="ride-protect"
  />
</template>

<script>
import EditQuote from "../../components/EditQuote.vue";

export default {
  name: "Step2",
  components: {
    EditQuote
  }
};
</script>
