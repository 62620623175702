<template>
  <Layout title="Rideshare">
    <b-card no-body class="mb-4 claim-form-container" ref="topSection">
      <b-card-header>
        <h1 class="mb-0">Submit Claim</h1>
      </b-card-header>
      <validation-observer ref="formValidator" tag="form">
        <form class="needs-validation">
          <b-card-body class="pb-0">
            <b-card class="uBind">
              <b-row>
                <h2 class="secondary-card-header">
                  Policyholder Details
                </h2>
              </b-row>

              <b-row>
                <b-col md="6">
                  <base-input
                    name="Business / Insured Name"
                    label="Business / Insured Name"
                    inputGroupClasses="uBind-input"
                    :rules="formRules.insuredName"
                    :disabled="fieldsDisabled.insuredName"
                    required
                  >
                    <el-select
                      id="insuredNameDD"
                      @change="selectCustomer($event)"
                      v-model="model.insuredName"
                      :rules="formRules.insuredName"
                      filterable
                      v-cancel-read-only
                      placeholder="Search customers"
                    >
                      <el-option
                        class="style-dropdown"
                        v-for="(option, index) in customerList"
                        :key="option.label + '_' + index.toString()"
                        :label="option.label"
                        :value="option.value"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </b-col>
                <b-col md="6">
                  <base-input
                    name="Policy Number"
                    label="What is your policy number?"
                    inputGroupClasses="uBind-input"
                    :rules="formRules.policyNumber"
                    :disabled="
                      fieldsDisabled.policyNumber || selectedCustomerId == null
                    "
                    required
                  >
                    <el-select
                      id="policyNumberDD"
                      @blur="dropdownBlurred($event)"
                      @focus="populateDropdown($event)"
                      @keyup.native="populateDropdown($event)"
                      v-model="model.policyNumber"
                      :loading="isLoading"
                      :rules="formRules.policyNumber"
                      :disabled="
                        fieldsDisabled.policyNumber ||
                          selectedCustomerId == null
                      "
                      filterable
                      placeholder="Add or search policy numbers"
                    >
                      <el-option
                        v-for="(option, index) in policyList"
                        :key="option.label + '_' + index.toString()"
                        :label="option.label"
                        :value="option.value"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <base-input
                    label="Contact Name"
                    name="Contact Name"
                    placeholder="Jerry"
                    v-model="model.contactName"
                    inputGroupClasses="uBind-input"
                    :disabled="fieldsDisabled.contactName"
                  />
                </b-col>
                <b-col md="6">
                  <base-input
                    label="Contact Mobile Number"
                    name="Contact Mobile Number"
                    v-model="model.contactPhone"
                    inputGroupClasses="uBind-input"
                    prependIcon="fas fa-phone"
                    :disabled="fieldsDisabled.contactPhone"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <base-input
                    name="Contact Email"
                    label="Contact Email"
                    v-model="model.contactEmail"
                    placeholder="jerry@example.com"
                    inputGroupClasses="uBind-input"
                    prependIcon="fas fa-envelope"
                    :disabled="fieldsDisabled.contactEmail"
                  />
                </b-col>
              </b-row>
            </b-card>

            <b-card class="uBind">
              <b-row class="flex align-items-center">
                <h2 class="secondary-card-header">
                  GST
                </h2>
              </b-row>
              <b-row>
                <b-col md="3">
                  <base-input
                    autocomplete="off"
                    name="Insured ITC"
                    label="Insured ITC"
                    type="percentage"
                    v-model="model.insuredITC"
                    inputGroupClasses="uBind-input"
                    appendIcon="fas fa-percent"
                  />
                </b-col>
                <b-col md="3">
                  <base-input
                    type="text"
                    name="Insured ABN"
                    label="Insured ABN"
                    pattern="[0-9]{11}"
                    v-model="model.abn"
                    :rules="formRules.abn"
                    :disabled="fieldsDisabled.abn"
                    inputGroupClasses="uBind-input"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <base-input
                    name="Registered for 'GST'"
                    label="Registered for 'GST'"
                  >
                    <base-switch
                      on-text="Yes"
                      off-text="No"
                      v-model="model.registeredForGST"
                    >
                    </base-switch>
                  </base-input>
                </b-col>
              </b-row>
            </b-card>

            <b-card class="uBind">
              <b-row>
                <h2 class="secondary-card-header">
                  Policyholder Address
                </h2>
              </b-row>
              <b-row>
                <b-col class="mb-4 uBind-input">
                  <label for="policyholder-address" class="form-control-label">
                    Address Search
                    <span style="color:red">*</span>
                  </label>
                  <input
                    autocomplete="new-password"
                    style="cursor: text"
                    @blur="clearAddressFields($event)"
                    class="form-control"
                    id="policyholder-address"
                    v-model="model.policyholderStreet"
                    :rules="formRules.policyholderStreet"
                    :disabled="fieldsDisabled.policyholderStreet"
                    required
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <base-input
                    name="City"
                    label="City"
                    v-model="model.policyholderCity"
                    inputGroupClasses="uBind-input"
                    :disabled="fieldsDisabled.policyholderCity"
                    :rules="formRules.policyholderCity"
                    required
                  />
                </b-col>
                <b-col md="4">
                  <!-- <base-input label="State" v-model="model.insuredState" /> -->
                  <base-input
                    name="State"
                    label="State"
                    inputGroupClasses="uBind-input"
                    :disabled="fieldsDisabled.policyholderState"
                    :rules="formRules.policyholderState"
                    required
                  >
                    <el-select
                      v-model="model.policyholderState"
                      :disabled="fieldsDisabled.policyholderState"
                    >
                      <el-option
                        v-for="option in states"
                        :key="option.label"
                        :label="option.label"
                        :value="option.value"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    autocomplete="off"
                    name="Postcode"
                    label="Postcode"
                    type="number"
                    v-model="model.policyholderPostcode"
                    inputGroupClasses="uBind-input"
                    :disabled="fieldsDisabled.policyholderPostcode"
                    :rules="formRules.policyholderPostcode"
                    required
                  />
                </b-col>
              </b-row>
            </b-card>

            <b-card class="uBind">
              <b-row class="flex align-items-center">
                <h2 class="secondary-card-header">
                  Reported By
                </h2>
              </b-row>
              <b-row>
                <b-col>
                  <base-input
                    type="text"
                    name="Brokerage Name"
                    label="Brokerage Name"
                    v-model="model.brokerageName"
                    :rules="formRules.brokerageName"
                    :disabled="fieldsDisabled.brokerageName"
                    inputGroupClasses="uBind-input"
                  />
                  <!-- <base-input
                    name="Brokerage Name"
                    label="Brokerage Name"
                    inputGroupClasses="uBind-input"
                  >
                    <el-select
                      id="brokerageNameDD"
                      @blur="dropdownBlurred($event)"
                      @keyup.native="populateDropdown($event)"
                      @change="selectCustomer($event)"
                      v-model="model.brokerageName"
                      :disabled="fieldsDisabled.brokerageName"
                      filterable
                      placeholder="Add or search brokerages."
                    >
                      <el-option
                        v-for="(option, index) in brokerageList"
                        :key="option.label + '_' + index.toString()"
                        :label="option.label"
                        :value="option.value"
                      >
                      </el-option>
                    </el-select>
                  </base-input> -->
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <base-input
                    label="First Name"
                    name="First Name"
                    placeholder="Jerry"
                    v-model="model.reporterFName"
                    :disabled="fieldsDisabled.reporterFName"
                    :rules="formRules.reporterFName"
                    inputGroupClasses="uBind-input"
                    required
                  />
                </b-col>
                <b-col md="6">
                  <base-input
                    label="Last Name"
                    name="Last Name"
                    placeholder="Smith"
                    v-model="model.reporterLName"
                    :disabled="fieldsDisabled.reporterLName"
                    :rules="formRules.reporterLName"
                    inputGroupClasses="uBind-input"
                    required
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <base-input
                    name="Phone Number"
                    label="Phone Number"
                    type="tel"
                    v-model="model.reporterPhone"
                    :disabled="fieldsDisabled.reporterPhone"
                    inputGroupClasses="uBind-input"
                  />
                </b-col>
                <b-col md="6">
                  <base-input
                    name="Email"
                    label="Email"
                    type="email"
                    v-model="model.reporterEmail"
                    :disabled="fieldsDisabled.reporterEmail"
                    inputGroupClasses="uBind-input"
                    placeholder="jerry@example.com"
                  />
                </b-col>
              </b-row>
            </b-card>

            <b-card class="uBind">
              <b-row class="flex align-items-center">
                <h2 class="secondary-card-header">
                  Policy Details
                </h2>
              </b-row>
              <b-row>
                <b-col md="6">
                  <base-input
                    autocomplete="off"
                    name="date of damage or loss"
                    label="When did the damage or loss occur?"
                    type="date"
                    v-model="model.dateOfLoss"
                    inputGroupClasses="uBind-input"
                    :disabled="fieldsDisabled.dateOfLoss"
                    :rules="formRules.dateOfLoss"
                    required
                  />
                </b-col>
                <b-col md="6">
                  <base-input
                    autocomplete="off"
                    name="Policy Number"
                    label="Policy Number"
                    type="text"
                    v-model="model.policyNumber"
                    inputGroupClasses="uBind-input"
                    :disabled="fieldsDisabled.detailsPolicyNumber"
                  />
                </b-col>
              </b-row>
            </b-card>

            <b-card class="uBind">
              <b-row class="flex align-items-center">
                <h2 class="secondary-card-header">
                  Incident Details
                </h2>
              </b-row>
              <b-row>
                <b-col md="4">
                  <base-input
                    autocomplete="off"
                    name="Date of loss"
                    label="Date of loss"
                    type="date"
                    v-model="model.dateOfLoss"
                    inputGroupClasses="uBind-input"
                    :disabled="fieldsDisabled.incidentDateOfLoss"
                    :rules="formRules.dateOfLoss"
                    required
                  />
                </b-col>
                <b-col md="4">
                  <base-input
                    autocomplete="off"
                    name="Time of loss"
                    label="Time of loss"
                    type="time"
                    :disabled="fieldsDisabled.timeOfLoss"
                    :rules="formRules.timeOfLoss"
                    v-model="model.timeOfLoss"
                    inputGroupClasses="uBind-input"
                    required
                  />
                </b-col>
                <b-col md="4">
                  <base-input
                    autocomplete="off"
                    name="Estimate of loss"
                    label="Estimate of loss"
                    type="text"
                    :disabled="fieldsDisabled.incidentDetail"
                    v-model="model.lossEstimate"
                    inputGroupClasses="uBind-input"
                    prependIcon="fas fa-dollar-sign"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <base-input
                    name="Incident Detail"
                    label="Tell us what happened in detail"
                    :rules="formRules.incidentDetail"
                    :disabled="fieldsDisabled.incidentDetail"
                    inputGroupClasses="uBind-input"
                    required
                  >
                    <textarea
                      v-model="model.incidentDetail"
                      class="form-control"
                      rows="3"
                    ></textarea>
                  </base-input>
                </b-col>
              </b-row>
            </b-card>

            <b-card class="uBind">
              <b-row class="flex align-items-center">
                <h2 class="secondary-card-header">
                  Incident Location
                </h2>
              </b-row>

              <div class="ml--3 mb-2">
                <b-form-checkbox
                  v-model="usePolicyholderAddress"
                  @change="fillIncidentLocation"
                >
                  Use Policyholder address?
                </b-form-checkbox>
              </div>

              <b-row>
                <b-col class="mb-4 uBind-input">
                  <label for="incident-address" class="form-control-label">
                    Incident location
                    <span style="color:red">*</span>
                  </label>
                  <input
                    autocomplete="new-password"
                    style="cursor: text"
                    @blur="clearAddressFields($event)"
                    class="form-control"
                    id="incident-address"
                    v-model="model.incidentStreet"
                    :rules="formRules.incidentStreet"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <base-input
                    name="City"
                    label="City"
                    v-model="model.incidentCity"
                    inputGroupClasses="uBind-input"
                    :disabled="fieldsDisabled.incidentCity"
                    :rules="formRules.incidentCity"
                    required
                  />
                </b-col>
                <b-col md="4">
                  <!-- <base-input label="State" v-model="model.insuredState" /> -->
                  <base-input
                    name="State"
                    label="State"
                    :rules="formRules.incidentState"
                    :disabled="fieldsDisabled.incidentState"
                    inputGroupClasses="uBind-input"
                    required
                  >
                    <el-select
                      v-model="model.incidentState"
                      :rules="formRules.incidentState"
                      :disabled="fieldsDisabled.incidentState"
                    >
                      <el-option
                        v-for="option in states"
                        :key="option.label"
                        :label="option.label"
                        :value="option.value"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    autocomplete="off"
                    name="Postcode"
                    label="Postcode"
                    type="number"
                    v-model="model.incidentPostcode"
                    :disabled="fieldsDisabled.incidentPostcode"
                    :rules="formRules.incidentPostcode"
                    inputGroupClasses="uBind-input"
                    required
                  />
                </b-col>
              </b-row>
            </b-card>

            <b-card class="uBind">
              <b-row class="flex align-items-center">
                <h2 class="secondary-card-header">
                  Police Details
                </h2>
              </b-row>
              <b-row>
                <b-col>
                  <base-input
                    name="Reported to Police?"
                    label="Reported to Police?"
                  >
                    <base-switch
                      on-text="Yes"
                      off-text="No"
                      v-model="model.reportedToPolice"
                    >
                    </base-switch>
                  </base-input>
                </b-col>
              </b-row>
              <div v-show="model.reportedToPolice">
                <b-row>
                  <b-col md="3">
                    <base-input
                      autocomplete="off"
                      name="Reference Number"
                      label="Reference Number"
                      type="number"
                      v-model="model.policeReportRef"
                      :rules="
                        isReportedToPolice ? formRules.policeReportRef : ''
                      "
                      inputGroupClasses="uBind-input"
                      :required="isReportedToPolice"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input
                      name="Detail"
                      label="Details"
                      :rules="
                        isReportedToPolice ? formRules.policeReportDetails : ''
                      "
                      inputGroupClasses="uBind-input"
                      :required="isReportedToPolice"
                    >
                      <textarea
                        v-model="model.policeReportDetails"
                        class="form-control"
                        rows="3"
                      ></textarea>
                    </base-input>
                  </b-col>
                </b-row>
              </div>
            </b-card>

            <b-card class="uBind">
              <b-row class="flex align-items-center direction-col">
                <h2 class="secondary-card-header">
                  Contact
                </h2>
              </b-row>

              <b-row>
                <b-col>
                  <base-input
                    name="Additional contact"
                    label="Would you like to add an additional contact to this claim?"
                  >
                    <base-switch
                      on-text="Yes"
                      off-text="No"
                      v-model="model.additionalContact"
                    >
                    </base-switch>
                  </base-input>
                </b-col>
              </b-row>

              <div v-show="model.additionalContact">
                <b-row>
                  <b-col md="3">
                    <base-input
                      name="Contact Type"
                      label="Contact Type"
                      inputGroupClasses="uBind-input"
                      :rules="
                        hasAdditionalContact
                          ? formRules.additionalContactType
                          : ''
                      "
                      :required="hasAdditionalContact"
                    >
                      <el-select
                        v-model="model.additionalContactType"
                        :rules="
                          hasAdditionalContact
                            ? formRules.additionalContactType
                            : ''
                        "
                        :required="hasAdditionalContact"
                        placeholder="Please select a contact type"
                      >
                        <el-option
                          v-for="option in contactTypes"
                          :key="option"
                          :label="option"
                          :value="option"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <base-input
                      label="First Name"
                      name="First name"
                      placeholder="James"
                      v-model="model.additionalContactFName"
                      :rules="
                        hasAdditionalContact
                          ? formRules.additionalContactFName
                          : ''
                      "
                      :required="hasAdditionalContact"
                      inputGroupClasses="uBind-input"
                    />
                  </b-col>
                  <b-col md="6">
                    <base-input
                      label="Last Name"
                      name="Last Name"
                      placeholder="Kirk"
                      v-model="model.additionalContactLName"
                      :rules="
                        hasAdditionalContact
                          ? formRules.additionalContactLName
                          : ''
                      "
                      :required="hasAdditionalContact"
                      inputGroupClasses="uBind-input"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <base-input
                      name="Telephone Number"
                      label="Telephone Number"
                      type="tel"
                      v-model="model.additionalContactTelephone"
                      inputGroupClasses="uBind-input"
                    />
                  </b-col>
                  <b-col md="6">
                    <base-input
                      name="Mobile Number"
                      label="Mobile Number"
                      type="tel"
                      v-model="model.additionalContactMobile"
                      inputGroupClasses="uBind-input"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <base-input
                      name="Email"
                      label="Email"
                      type="email"
                      v-model="model.additionalContactEmail"
                      inputGroupClasses="uBind-input"
                    />
                  </b-col>
                  <b-col md="6">
                    <base-input
                      name="Preferred contact method"
                      label="Preferred contact method"
                      inputGroupClasses="uBind-input"
                    >
                      <el-select
                        v-model="model.additionalContactPreferredContact"
                        placeholder="Please select a preferred contact type"
                      >
                        <el-option
                          v-for="option in preferredContactTypes"
                          :key="option"
                          :label="option"
                          :value="option"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                </b-row>
              </div>
            </b-card>

            <b-card class="uBind">
              <b-row class="flex align-items-center direction-col">
                <h2 class="secondary-card-header">
                  Bank Account Details
                </h2>
              </b-row>
              <b-row class="mb-4">
                <span class="form-control-label">
                  Should the claim result in a payment being made to the
                  insured, please provide the insureds bank/direct deposit
                  details.
                </span>
              </b-row>
              <b-row>
                <b-col md="3">
                  <base-input
                    autocomplete="off"
                    name="Account name"
                    label="Account name"
                    v-model="model.bankAccountName"
                    inputGroupClasses="uBind-input"
                  />
                </b-col>
                <b-col md="3">
                  <base-input
                    autocomplete="off"
                    name="BSB Number"
                    label="BSB Number"
                    v-model="model.bankBSB"
                    inputGroupClasses="uBind-input"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <base-input
                    autocomplete="off"
                    name="Account number"
                    label="Account number"
                    v-model="model.bankAccountNumber"
                    inputGroupClasses="uBind-input"
                  />
                </b-col>
                <b-col md="3">
                  <base-input
                    autocomplete="off"
                    name="Bank name"
                    label="Bank name"
                    v-model="model.bankName"
                    inputGroupClasses="uBind-input"
                  />
                </b-col>
              </b-row>
            </b-card>

            <b-card class="uBind">
              <b-row>
                <h2 class="secondary-card-header mb-0">
                  Attachments
                </h2>
              </b-row>
              <b-row>
                <span class="form-control-label mb-2">
                  Please upload supporting documentation for each service
                  related to this claim.
                </span>
              </b-row>
              <b-row>
                <uploader
                  uploaderKey="claimAttachments"
                  :files="model.attachments"
                  :multiple="true"
                />
              </b-row>
            </b-card>
          </b-card-body>
          <b-row>
            <b-col xs="9">
              <base-button
                :loading="claimSubmitting"
                @click="attemptSubmission"
                type="uBind"
                class="submit-button"
                >Submit Claim</base-button
              >
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-card>
  </Layout>
</template>

<script>
import Layout from "./components/Layout.vue";
import { claimFormMethods } from "../../helpers/claimForm";
import { dropDowns } from "../../assets/data/dropdown_values";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import { Select, Option } from "element-ui";
import { GlobalStore } from "../../globalStore";
import Uploader from "../components/fleetCommon/Uploader.vue";
import { GlobalMethods } from "../../globalMethods";
import { toasts } from "../../helpers/toasts";
import { formattingMethods } from "../../helpers/formatting";
import ButtonRadioGroup from "../../components/ButtonRadioGroup.vue";
import { emailMethods } from "../../helpers/email";
import { appFunctions } from "../../helpers/appFunctions";

export default {
  name: "SubmitClaim",
  data() {
    return {
      model: {
        insuredName: "",
        policyNumber: "",
        contactName: "",
        contactPhone: "",
        contactEmail: "",
        policyholderStreet: "",
        policyholderCity: "",
        policyholderState: "",
        policyholderPostcode: "",
        dateOfLoss: "",
        registeredForGST: false,
        insuredITC: "",
        abn: "",
        brokerageName: "",
        reporterFName: "",
        reporterLName: "",
        reporterPhone: "",
        reporterEmail: "",
        dateOfLoss: "",
        timeOfLoss: "",
        incidentDetail: "",
        lossEstimate: "",
        incidentStreet: "",
        incidentCity: "",
        incidentState: "",
        incidentPostcode: "",
        reportedToPolice: false,
        policeReportRef: "",
        policeReportDetails: "",
        bankAccountName: "",
        bankBSB: "",
        bankAccountNumber: "",
        bankName: "",
        additionalContact: false,
        additionalContactType: "",
        additionalContactFName: "",
        additionalContactLName: "",
        additionalContactTelephone: "",
        additionalContactMobile: "",
        additionalContactEmail: "",
        additionalContactPreferredContact: "",
        attachments: []
      },
      contactTypes: [
        "Broker",
        "Insured",
        "Main Contact",
        "Real Estate / Managing Agent",
        "Representative",
        "Strata Manager",
        "Supplier",
        "Tenant",
        "Third Party",
        "Witness",
        "Other"
      ],
      preferredContactTypes: ["Email", "Mobile", "Phone"],
      realmApp: null,
      selectedCustomerId: null,
      formMode: "new",
      states: dropDowns.states,
      validated: false,
      usePolicyholderAddress: false,
      enteredInsuredName: "",
      customerList: [],
      enteredPolicy: "",
      policyList: [],
      enteredBrokerage: "",
      brokerageList: [],
      claimSubmitting: false,
      isLoading: true
    };
  },
  watch: {
    "model.lossEstimate": function(val, oldVal) {
      this.model.lossEstimate = formattingMethods.formatCurrency(val);
    }
  },
  computed: {
    hasAdditionalContact() {
      return this.model.additionalContact;
    },
    isReportedToPolice() {
      return this.model.reportedToPolice;
    },
    fieldsDisabled() {
      return claimFormMethods.getDisabledFields();
    },
    formRules() {
      return claimFormMethods.getFormRules(this.formMode);
    },
    brokerObj() {
      return GlobalStore.brokerObj;
    },
    validator() {
      console.log(this.$refs.formValidator);
      return this.$refs.formValidator;
    }
  },

  methods: {
    scrollToTop() {
      this.$smoothScroll({
        scrollTo: this.$refs.topSection,
        hash: "#topSection",
        offset: -200
      });
    },
    getClaimForm() {
      return emailMethods.generateClaimEmail(this.model);
    },

    /**
     * Runs validator. On success will call submission method.
     */
    attemptSubmission() {
      this.$refs.formValidator.validate().then(success => {
        if (success) {
          this.submitClaim();
        } else {
          toasts.error("Please fill in all required fields");
        }
      });
    },

    async submitClaim() {
      this.claimSubmitting = true;

      this.model.Broker_org_id = GlobalStore.brokerObj.parentOrg.Broker_org_id;
      this.model.Insurance_broker_id =
        GlobalStore.brokerObj.parentOrg.parentInsuranceBroker.insurance_broker_id;
      this.model.Customer_policy_created_by =
        GlobalStore.brokerObj.Realm_user_id;
      this.model.Customer_id = this.selectedCustomerId;
      this.model.createdDate = this.$moment(new Date()).format(
        "YYYY-MM-DD h:mm:ss a"
      );

      appFunctions
        .crud_customerRSClaim("create", this.model)
        .then(async resp => {
          console.log(resp.data);
          //this.customerList = resp.data;

          const attachmentCount = sessionStorage.getItem("attached_file_count");

          var attachments = [];

          for (let a = 1; a <= attachmentCount; a++) {
            const fileblob = sessionStorage.getItem(
              "claimAttachments_" + a.toString()
            );
            const filename = sessionStorage.getItem(
              "claimAttachments_" + a.toString() + "_filename"
            );
            const type = sessionStorage.getItem(
              "claimAttachments_" + a.toString() + "_type"
            );

            // console.log("******");
            // console.log(fileblob);
            // console.log(filename);
            // console.log(type);
            // console.log("******");

            await appFunctions.addClaimAttachment({
              Customer_claim_id: resp.data,
              fileContent: fileblob,
              filename: filename,
              guid: GlobalMethods.uuidv4(),
              type: type,
              isRideShareAttachment: true
            });

            attachments.push(
              emailMethods.prepareAttachment(filename, fileblob)
            );
          }

          emailMethods.sendClaimEmail(
            GlobalStore.realmApp,
            this.getClaimForm(),
            resp.data,
            attachments,
            "RS"
          );

          this.$router.push("/rideShare/claimSent");
        })
        .finally(() => {
          this.claimSubmitting = false;
        });
    },

    fillIncidentLocation() {
      if (!this.usePolicyholderAddress) return;

      this.model.incidentStreet = this.model.policyholderStreet;
      this.model.incidentCity = this.model.policyholderCity;
      this.model.incidentState = this.model.policyholderState;
      this.model.incidentPostcode = this.model.policyholderPostcode;
    },

    /**
     * Retrieves a broker from the broker collection.
     * @param {string} broker_id The id of the broker
     */
    getBroker(broker_id) {
      return appFunctions.crud_broker("get", {
        Broker_id: broker_id
      });
    },

    /**
     * To be called when the user makes their customer selection.
     * Applies customer data to relevant fields and disables them.
     * @param {event} e
     */
    selectCustomer(e) {
      if (e == null) return;

      this.selectedCustomerId = e;

      var findCustomer = this.customerList.find(f => f.value == e);
      this.enteredInsuredName = findCustomer.label.replace(
        / *\([^)]*\) */g,
        ""
      );
      this.model.insuredName = findCustomer.label.replace(/ *\([^)]*\) */g, "");

      appFunctions
        .crud_customer("get", {
          Customer_id: parseInt(e)
        })
        .then(resp => {
          console.log("selectCustomer prefill", resp);
          var d = resp.data;
          this.model.businessActivity = d.Customer_business_description;
          this.model.contactName = `${d.Customer_contact_first_name} ${d.Customer_contact_last_name}`;
          this.model.contactEmail = d.Customer_contact_email;
          this.model.contactPhone = d.Customer_contact_number;
          this.model.policyholderStreet = d.Customer_contact_street;
          this.model.policyholderCity = d.Customer_contact_city;
          this.model.policyholderState = d.Customer_state;
          this.model.policyholderPostcode = d.Customer_postcode;
          this.model.insuredName = d.Customer_insured_name;
          this.model.abn = d.Customer_abn;

          this.fieldsDisabled.contactName = true;
          this.fieldsDisabled.contactEmail = true;
          this.fieldsDisabled.contactPhone = true;
          // this.fieldsDisabled.policyholderStreet = true;
          // this.fieldsDisabled.policyholderCity = true;
          // this.fieldsDisabled.policyholderState = true;
          // this.fieldsDisabled.policyholderPostcode = true;

          this.model.policyNumber = "";
        });
    },

    /**
     * To be called when the user leaves the dropdown. Sets the customer name.
     * @param {event} e
     */
    dropdownBlurred(e) {
      switch (e.target.id) {
        case "insuredNameDD":
          this.model.insuredName = this.enteredInsuredName;
          break;
        case "brokerageNameDD":
          this.model.brokerageName = this.enteredBrokerage;
          break;
        case "policyNumberDD":
          this.model;
          break;
        default:
      }
    },

    /**
     * Retrieves collection of users to populate a dropdown on every
     * keystroke.
     * @param {event} e
     */
    populateDropdown(e) {
      switch (e.target.id) {
        case "insuredNameDD":
          this.enteredInsuredName = e.target.value;
          break;

        case "policyNumberDD":
          if (!this.selectedCustomerId) {
            toasts.error("Please select a Business/Insurer.");
            return;
          }
          this.isLoading = true
          appFunctions
            .crud_customerRSPolicy("listByCustomerId", {
              Customer_id: this.selectedCustomerId
            })
            .then(resp => {
              this.isLoading = false
              this.policyList = resp.data.map(r => {
                return {
                  label: r,
                  value: r
                };
              });
            });
          this.enteredPolicy = e.target.value;
          break;
        case "brokerageNameDD":
          break;
        default:
      }
    },

    /**
     * Fills the 'reported by' section with information of currently logged
     * in broker.
     */
    populateReportedByFields() {
      const d = GlobalStore.brokerObj;
      if (!d) {
        toasts.error("Could not get current broker data.");
        return;
      }

      this.model.brokerageName = d.parentOrg.Broker_Org_Name;
      this.model.reporterFName = d.Broker_first_name;
      this.model.reporterLName = d.Broker_last_name;
      this.model.reporterPhone = d.Broker_phone;
      this.model.reporterEmail = d.Broker_email;
    },

    /**
     * Clears the address city, state and postcode fields when the street
     * field is cleared.
     * @param {event} e
     */
    clearAddressFields(e) {
      switch (e.target.id) {
        case "policyholder-address":
          if (this.model.policyholderStreet == "") {
            this.model.policyholderAddress = "";
            this.model.policyholderCity = "";
            this.model.policyholderState = "";
            this.model.policyholderPostcode = "";
          }
          break;
        case "incident-address":
          if (this.model.incidentStreet == "") {
            this.model.incidentAddress = "";
            this.model.incidentCity = "";
            this.model.incidentState = "";
            this.model.incidentPostcode = "";
          }
          break;
        default:
          console.error("Incorrect argument given to 'clearAddressFields'");
          break;
      }
    },

    /**
     * Initializes the 'Policy Holder' address search.
     */
    initPolicyholderAddressSearch() {
      let script = document.createElement("script");
      script.setAttribute(
        "src",
        "https://api.addressfinder.io/assets/v3/widget.js"
      );
      script.setAttribute("async", true);
      script.onload = () => {
        this.widget = new window.AddressFinder.Widget(
          document.getElementById("policyholder-address"),
          "NMY9RTVQ3UHADL76FJ8G",
          "AU",
          {
            address_params: {
              gnaf: 1
            }
          }
        );
        this.widget.on("result:select", (fullAddress, metaData) => {
          this.model.policyholderAddress = metaData.address_line_1;
          this.model.policyholderStreet = metaData.address_line_1;
          this.model.policyholderCity = metaData.locality_name;
          this.model.policyholderState = metaData.state_territory;
          this.model.policyholderPostcode = metaData.postcode;
        });
      };
      document.head.appendChild(script);
    },

    /**
     * Initializes the 'Incident Location' address search.
     */
    initIncidentAddressSearch() {
      let script = document.createElement("script");
      script.setAttribute(
        "src",
        "https://api.addressfinder.io/assets/v3/widget.js"
      );
      script.setAttribute("async", true);
      script.onload = () => {
        this.widget = new window.AddressFinder.Widget(
          document.getElementById("incident-address"),
          "NMY9RTVQ3UHADL76FJ8G",
          "AU",
          {
            address_params: {
              gnaf: 1
            }
          }
        );
        this.widget.on("result:select", (fullAddress, metaData) => {
          this.model.incidentAddress = metaData.address_line_1;
          this.model.incidentStreet = metaData.address_line_1;
          this.model.incidentCity = metaData.locality_name;
          this.model.incidentState = metaData.state_territory;
          this.model.incidentPostcode = metaData.postcode;
        });
      };
      document.head.appendChild(script);
    }
  },
  mounted() {
    this.initPolicyholderAddressSearch();
    this.initIncidentAddressSearch();
    this.populateReportedByFields();
    this.scrollToTop();

    appFunctions
      .crud_customerRSPolicy("searchCombinedByRealmId", {
        Realm_user_id: GlobalStore.brokerObj.Realm_user_id,
        searchText: ''
      })
      .then(resp => {
        this.customerList = resp.data;
      });

    const tempArray = [];

    for (let i = 0; i < sessionStorage.length; i++) {
      if (sessionStorage.key(i).startsWith("claimAttachments")) {
        tempArray.push(sessionStorage.key(i));
      }
    }

    tempArray.forEach(ta => {
      sessionStorage.removeItem(ta);
    });

    sessionStorage.removeItem("attached_file_count");
  },
  components: {
    ButtonRadioGroup,
    BaseInput,
    Uploader,
    Layout,
    [Select.name]: Select,
    [Option.name]: Option
  },
  directives: {
    /**
     * Used ensure the keyboard is shown on mobile devices when clicking on an el-select.
     * This is because element-ui was not designed for mobile:
     * https://github.com/ElemeFE/element/issues/12742#issuecomment-420866658
     */
    cancelReadOnly(el) {
      const input = el.querySelector(".el-input__inner");
      input.removeAttribute("readonly");
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 45px;
  color: black;
}
button {
  text-transform: uppercase;
  padding: 7px 16px;
}
.btn-group {
  border: 1px solid var(--uBind);
  border-radius: 5px;
}

.form-group legend {
  color: #525f7f;
  font-size: 0.875rem;
  font-weight: 600;
}

.icon-icon-garage:before {
  font-family: "park-icon";
  font-size: 25px;
  font-weight: 200;
  content: "\e803";
}

.claim-form-container {
  max-width: 1800px;
  margin: 0px 30px 0px 0px;
  padding-bottom: 0px;
}

.submit-button {
  margin: 0px 0px 30px 27px;
}
@media only screen and (min-width: 414px) and (max-width: 600px){
  .style-dropdown {
    max-width: 300px !important;
  }
}

@media only screen and (min-width: 360px) and (max-width: 413px) {
  .style-dropdown {
    max-width: 260px !important;
  }
}
</style>
