<template>
  <div>
    <base-header class="pb-6 bg-primary">
      <b-row class="align-items-center py-4">
        <b-col>
          <base-button @click="goBack()" type="uBind" class="mt-2 mt-sm-0 mb-4"
            ><i class="fas fa-angle-left pr-3"></i>Back</base-button
          >
        </b-col>
      </b-row>
    </base-header>
    <div class="container-fluid mt--6">
      <b-card no-body class="mb-4" style="max-width: 1800px !important;">
        <div id="ubind-iframe"></div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { GlobalStore } from "../globalStore";

export default {
  name: "CancelPolicies",
  props: {
    productType: {
      type: String,
      required: true,
      validator: value =>
        ["ride-protect", "mercurien-light-commercial"].includes(value)
    },
    quoteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      ubindQuoteId: null
    };
  },
  computed: {
    getProduct() {
      return this.productType === "ride-protect"
        ? GlobalStore.ubindClientInfo.rideShareProduct
        : GlobalStore.ubindClientInfo.product;
    },
    getTenant() {
      return GlobalStore.ubindClientInfo.tenantId;
    },
    getEnvironment() {
      return GlobalStore.ubindClientInfo.environment;
    }
  },
  methods: {
    goBack() {
      this.$emit("goStepCancel");
    }
  },
  mounted() {
    let embedOptions = {
      tenant: this.getTenant,
      product: this.getProduct,
      environment: this.getEnvironment,
      quoteId: this.quoteId
    };

    let embedElement = document.getElementById("ubind-iframe");
    _uBindInjector.loadWebFormElement(embedElement, embedOptions);
  }
};
</script>

<style lang="scss" scoped>
.top-padding {
  padding-top: 0px !important;
}

.ubind-product {
  margin: 0px 15px 0px 15px;
}

.container-fluid {
  padding-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
</style>
<style>
  body {
    padding-right: 0px !important;
  }
</style>
