<template>
  <div>
    <base-header class="pb-6 bg-primary">
      <b-row class="align-items-center pt-4">
        <b-col style="max-width: 1800px !important;">
          <base-button
            @click="sendEmailAction"
            type="uBind"
            style="float:right;"
            class="mt-2 mt-sm-0 mb-4"
            ><i class="fas fa-envelope pr-3"></i>Send to Customer</base-button
          >
          <h6
            class="h1 text-white header d-inline-block mb-0 fw-500"
            ref="topSection"
          >
            {{ pageTitle }}
          </h6>
        </b-col>
      </b-row>
    </base-header>
    <div class="container-fluid mt--6">
      <b-card no-body class="mb-4" style="max-width: 1800px !important;">
        <div id="ubind-iframe"></div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { GlobalStore } from "../globalStore";

export default {
  name: "ubind-iframe",
  props: {
    pageTitle: {
      type: String,
      required: true
    },
    productType: {
      type: String,
      required: true,
      validator: value =>
        ["ride-protect", "mercurien-light-commercial"].includes(value)
    },
    sendEmailAction: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      ubindQuoteId: null,
      htmlString: ""
    };
  },
  computed: {
    getProduct() {
      return this.productType === "ride-protect"
        ? GlobalStore.ubindClientInfo.rideShareProduct
        : GlobalStore.ubindClientInfo.product;
    },
    getTenant() {
      return GlobalStore.ubindClientInfo.tenantId;
    },
    getEnvironment() {
      return GlobalStore.ubindClientInfo.environment;
    }
  },
  methods: {
    scrollUp() {
      //location.hash = "#calcSection";
      const myEl = this.$refs.topSection;

      this.$smoothScroll({
        scrollTo: myEl,
        hash: "#topSection" // required if updateHistory is true
      });
    }
  },
  mounted() {
    this.ubindQuoteId = sessionStorage.getItem("ubindQuoteID");
    // this.htmlString = `
    //   <div
    //     class="ubind-product"
    //     data-tenant="${this.getTenant}"
    //     data-product-id="${this.getProduct}"
    //     data-environment="${this.getEnvironment}"
    //     data-quoteid="${this.ubindQuoteId}"
    //   ></div>`;

    // setTimeout(() => {
    //   window._uBindInjector.createWebFormInjections();
    // }, 1000);

    let embedOptions = {
      tenant: this.getTenant,
      //organisation: appconfig.ubindInfo.organisationId,
      product: this.getProduct,
      environment: this.getEnvironment,
      quoteId: this.ubindQuoteId
    };

    let embedElement = document.getElementById("ubind-iframe");

    _uBindInjector.loadWebFormElement(embedElement, embedOptions);

    this.scrollUp();
  }
};
</script>

<style lang="scss" scoped>
.top-padding {
  padding-top: 0px !important;
}

.ubind-product {
  margin: 0px 15px 0px 15px;
}
.container-fluid {
  padding-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
</style>
