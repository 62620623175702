<template>
  <div>
    <!-- Header -->
    <div class="header py-7 py-lg-4 pt-lg-9"></div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <div></div>
    </b-container>
  </div>
</template>

<script>
// import { dropDowns } from "../assets/data/dropdown_values";
//import { quoteFormMethods } from "../helpers/quoteForm";
// import BaseInput from "../components/Inputs/BaseInput.vue";
// import { Select, Option } from "element-ui";
// import { emailMethods } from "../helpers/email";
//import { GlobalStore } from "../globalStore";

import { fileAttachments } from "../helpers/fileAttachments";

export default {
  data() {
    return {
      ubindQuoteId: "",
      isReady: false,
      htmlString: "",
      brokerObj: null
    };
  },
  methods: {},
  computed: {
    // getLogo() {
    //   return GlobalStore.brokerObj.parentOrg.parentInsuranceBroker
    //     .insurance_broker_logo;
    // },
    // getQuoteId() {
    //   return this.ubindQuoteId;
    // },
    // getProduct() {
    //   return GlobalStore.ubindClientInfo.product;
    // },
    // getTenant() {
    //   return GlobalStore.ubindClientInfo.tenantId;
    // },
    // getEnvironment() {
    //   return GlobalStore.ubindClientInfo.environment;
    // }
  },
  async mounted() {
    var fileContent = await fileAttachments.getClaimFileAttachment(
      this.$route.params.id
    );

    console.log(fileContent);

    var content = fileContent.fileContent;
    var filename = fileContent.filename;

    console.log(content, filename);

    var a = document.createElement("a"); //Create <a>
    a.href = content; //Image Base64 Goes here
    a.download = filename; //File name Here
    a.click(); //Downloaded file

    //console.log(fileContent);
    //console.log("blah");
  }
  // async mounted_old() {
  //   // GlobalStore.ubindClientInfo.organisationId =
  //   //   "7eaad2bd-0ef1-47bd-80c8-9c2963fa7b9f";

  //   // GlobalStore.ubindClientInfo.environment = "staging";

  //   // GlobalStore.ubindClientInfo.tenantId = "mercurien";

  //   // GlobalStore.ubindClientInfo.product = "gensure-light-commercial";

  //   GlobalStore.ubindClientInfo.organisationId =
  //     appconfig.ubindInfo.organisationId;

  //   GlobalStore.ubindClientInfo.environment = appconfig.ubindInfo.environment;

  //   GlobalStore.ubindClientInfo.tenantId = appconfig.ubindInfo.tenantId;

  //   GlobalStore.ubindClientInfo.product = appconfig.ubindInfo.product;

  //   // put this in to handle link from ubind generated save email
  //   // there is also some logic in the main.js to handle this.
  //   if (this.$route.params.id == "ubind") {
  //     const ubindId = this.$route.query["id"];

  //     quoteFormMethods.getLCQuoteFromUbindGuid(ubindId).then(resp => {
  //       document.location.href =
  //         "/lightCommercial/continueQuote/" +
  //         resp.quoteData.Customer_policy_guid;
  //     });
  //   } else {
  //     quoteFormMethods.getLCQuoteFromGuid(this.$route.params.id).then(resp => {
  //       this.ubindQuoteId = resp.quoteData.Ubind_quote_id;

  //       GlobalStore.brokerObj = resp.brokerData;
  //       this.brokerObj = resp.brokerData;

  //       this.htmlString = `
  //   <div
  //             class="ubind-product"
  //             data-tenant="${this.getTenant}"
  //             data-product-id="${this.getProduct}"
  //             data-environment="${this.getEnvironment}"
  //             data-quoteid="${this.ubindQuoteId}"
  //             style="margin: 0px 15px 0px 15px;"
  //           ></div>`;

  //       setTimeout(() => {
  //         window._uBindInjector.createWebFormInjections();
  //       }, 1000);
  //     });
  //   }

  //   //console.log(resp);

  //   // sessionStorage.setItem(
  //   //   "ubindQuoteID",
  //   //   "833c3103-2d4f-4ffb-b3af-5828c3eccd47"
  //   // );

  //   //respObj.quoteData.Ubind_quote_id

  //   //this.ubindQuoteId = respObj.quoteData.Ubind_quote_id;

  //   //this.ubindQuoteId = "833c3103-2d4f-4ffb-b3af-5828c3eccd47";

  //   //respObj.quoteData.Ubind_quote_id;

  //   //console.log(this.ubindQuoteId);
  // }
  //async mounted() {
  //this.isReady = false;

  //console.log(GlobalStore.ubindClientInfo.organisationId);

  //console.log(window._uBindInjector);

  // if (appconfig.ubindInfo.tenantId == "") {
  //this.ubindQuoteId = "833c3103-2d4f-4ffb-b3af-5828c3eccd47";

  // sessionStorage.setItem(
  //   "ubindQuoteID",
  //   "833c3103-2d4f-4ffb-b3af-5828c3eccd47"
  // );

  // this.ubindQuoteId = sessionStorage.getItem("ubindQuoteID");

  // var Realm = window.Realm;
  // const config = {
  //   id: GlobalStore.realm_app_id
  // };

  // const app = new Realm.App(config);

  // const credentials = Realm.Credentials.emailPassword(
  //   "donotdelete@user.com",
  //   "Password1!"
  // );
  // const user = await app.logIn(credentials);

  // console.log(user);

  // const appconfig = await appFunctions.getConfig();

  // GlobalStore.ubindClientInfo.organisationId =
  //   appconfig.ubindInfo.organisationId;

  // GlobalStore.ubindClientInfo.environment = appconfig.ubindInfo.environment;

  // GlobalStore.ubindClientInfo.tenantId = appconfig.ubindInfo.tenantId;

  // GlobalStore.ubindClientInfo.product = appconfig.ubindInfo.product;

  // console.log(GlobalStore.ubindClientInfo);

  // "staging"
  // "7eaad2bd-0ef1-47bd-80c8-9c2963fa7b9f"
  // "gensure-light-commercial"
  // "mercurien"

  // const brokerObj = await appFunctions.crud_broker("getByRealmId", {
  //   Realm_user_id: user._id
  // });
  // GlobalStore.brokerObj = brokerObj.data;

  //console.log(GlobalStore.ubindClientInfo.environment);
  // this.$router.go();
  //}

  //this.isReady = true;
  //}
  //async mounted() {
  //document.body.style.backgroundColor = "#98B8CF";
  //try {

  // if (respObj.rawModel.Customer_policy_status != "offer_accepted") {
  //   this.showForm = "valid";
  //   this.model = respObj.respModel;
  //   this.rawModel = respObj.rawModel;
  //   this.model.Payment_option = "";
  //   this.paymentChooser();
  //   this.validateFinancier();
  //   this.readFinancier(this.model.financier);
  // } else {
  //   console.log('else');
  //   this.showForm = "invalid";
  //   this.quoteAccepted = true;
  // }
  // } catch (err) {
  //   this.showForm = "invalid";
  // }
  //}
  // components: {
  //   BaseInput,
  //   [Select.name]: Select,
  //   [Option.name]: Option
  // }
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
li,
p {
  color: black !important;
}
img {
  width: 350px;
  height: auto;
}

.sideNotes {
  font-size: 0.9rem;
  margin-top: 28px;
}

.financierMessage {
  color: red;
  font-style: italic;
}

@media (max-width: 767px) {
  .bordered-table-left {
    border-style: solid;
    border-width: 1px 1px 0px 1px;
    padding: 5px;
  }

  .bordered-table-right {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    padding: 5px;
  }

  .bordered-table-right .row {
    border-style: solid !important;
    border-width: 0px 0px 1px 0px !important;
    margin: 0px -5px 0px -5px;
    padding: 0px;
  }

  .bordered-table-right .row:last-child {
    border-style: none !important;
  }
}

@media (min-width: 767px) {
  .bordered-table-left {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    padding: 5px;
  }

  .bordered-table-right {
    border-style: solid;
    border-width: 1px 1px 1px 0px;
    padding: 5px;
  }

  .bordered-table-right .row {
    border-style: solid !important;
    border-width: 0px 0px 1px 0px !important;
    margin: 0px -5px 0px -5px;
    padding: 0px;
  }

  .bordered-table-right .row:last-child {
    border-style: none !important;
  }

  .my-radio > ::after {
    border: 1px solid var(--primary);
    border-radius: 100%;
  }

  .my-checkbox > ::after {
    border: 1px solid var(--primary);
    border-radius: 25%;
  }

  .my-invalid-feedback {
    color: #fb6340;
    width: 100%;
    font-size: 80%;
    text-align: center;
  }
}
@media (max-width: 480px) {
  .logo_image {
    width: 300px;
    image-rendering: -webkit-optimize-contrast;
  }
}

.ubind-product {
  margin: 0px 15px 0px 15px;
}
.container {
  padding-left: 0px;
  padding-right: 0px;
}

.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

/* .multiselect__option--highlight:after{
  display: none
}
.multiselect__option--selected:after{
  display: none
}
.multiselect__option--selected.multiselect__option--highlight:after{
  display: none
} */
</style>
