<template>
  <div>
    <base-header class="pb-6 bg-primary">
      <b-row class="align-items-center pt-4">
        <b-col style="max-width: 1800px !important;">
          <!-- <base-button
            @click="sendEmailAction"
            type="uBind"
            style="float:right;"
            class="mt-2 mt-sm-0 mb-4"
            ><i class="fas fa-envelope pr-3"></i>Send to Customer</base-button
          > -->
          <h6
            class="h1 text-white header d-inline-block mb-0 fw-500"
            ref="topSection"
          >
            {{ pageTitle }}
          </h6>
        </b-col>
      </b-row>
    </base-header>
    <div class="container-fluid mt--6">
      <b-card no-body class="mb-4" style="max-width: 1800px !important;">
        <iframe
          :id="getJotFormId"
          title="[Staging] P&E Protect Proposal Form"
          onload="window.parent.scrollTo(0,0)"
          allowtransparency="true"
          allowfullscreen="true"
          allow="geolocation; microphone; camera"
          :src="jotFormLink"
          frameborder="0"
          style="min-width:100%;max-width:100%;min-height:80vh;border:none;"
          scrolling="no"
        >
    </iframe>
      </b-card>
    </div>
  </div>
</template>

<script>
import { GlobalStore } from "../globalStore";

export default {
  name: "jotFrom-iframe",
  props: {
    pageTitle: {
      type: String,
      required: true
    },
    quoteObj: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ubindQuoteId: null,
      htmlString: ""
    };
  },
  computed: {
    getJotFormId() {
      const jotformIds = {
        plantEquipment: `JotFormIFrame-${process.env.VUE_APP_PE_PROPOSAL_ID}`,
        motor: `JotFormIFrame-${process.env.VUE_APP_MOTOR_PROPOSAL_ID}`,
        otherAssets: `JotFormIFrame-${process.env.VUE_APP_OTHER_ASSETS_PROPOSAL_ID}`,
        property: `JotFormIFrame-${process.env.VUE_APP_PROPERTY_PROPOSAL_ID}`
      }

      console.log(process.env);
      return jotformIds[this.$route.params.productName]
    },
    jotFormLink() {





      const quoteObj = this.quoteObj
      
      console.log(quoteObj);

      var currentDate = new Date();

      var day = currentDate.getDate()
      
      const params = `businessName=${quoteObj.insuredName}&companyName=${quoteObj.insuredName}&quoteId=${quoteObj.Jotform_quote_id}&quoteRef=${quoteObj.quoteRefNo}&firstName=${quoteObj.contactFName}&lastName=${quoteObj.contactLName}
      &customerEmail=${quoteObj.contactEmail}&customerPhoneNumber=${quoteObj.contactPhone}&businessABN=${quoteObj.insuredABN}&customerAddress[addr_line1]=${quoteObj.insuredStreet}&customerAddress[city]=${quoteObj.insuredCity}&customerAddress[state]=${quoteObj.insuredState}&customerAddress[postal]=${quoteObj.insuredPostcode}&coverEndDate[day]=${currentDate.getDate()}&coverEndDate[month]=${currentDate.getMonth()+1}&coverEndDate[year]=${currentDate.getFullYear()+1}`
      const jotformLinks = {
        plantEquipment: `${process.env.VUE_APP_PE_PROPOSAL_ID}?`,
        motor: `${process.env.VUE_APP_MOTOR_PROPOSAL_ID}?`,
        otherAssets: `${process.env.VUE_APP_OTHER_ASSETS_PROPOSAL_ID}?`,
        property: `${process.env.VUE_APP_PROPERTY_PROPOSAL_ID}?`
      }
      return "https://submit.jotform.com/" + jotformLinks[this.$route.params.productName] + params
    },
    getEnvironment() {
      return GlobalStore.ubindClientInfo.environment;
    }
  },
  methods: {
    sendEmailAction() {
      console.log("send email to customer")
    },
    scrollUp() {
      //location.hash = "#calcSection";
      const myEl = this.$refs.topSection;

      this.$smoothScroll({
        scrollTo: myEl,
        hash: "#topSection" // required if updateHistory is true
      });
    }
  },
  mounted() {
    this.scrollUp();
  }
};
</script>

<style lang="scss" scoped>
.top-padding {
  padding-top: 0px !important;
}

.ubind-product {
  margin: 0px 15px 0px 15px;
}
.container-fluid {
  padding-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
</style>
