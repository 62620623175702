<template>
  <div>
    <div v-if="activityStatus == 'new' || activityStatus == 'edit'">
      <base-header class="pb-6 bg-primary">
        <b-row class="align-items-center py-4">
          <b-col lg="6" cols="7">
            <h6 class="h2 text-white d-inline-block mb-0">
              Broker Organisations
            </h6>
          </b-col>
        </b-row>
      </base-header>
      <div class="container-fluid mt--6">
        <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(firstFormSubmit)"
          >
            <b-card no-body class="mb-4">
              <b-card-header>
                <h3 class="mb-0">
                  {{
                    activityStatus == "add"
                      ? "Create new broker organisation"
                      : "Editing: " + model.orgName
                  }}
                </h3>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col md="6">
                    <base-input
                      name="Organisation Name"
                      label="Organisation Name"
                      v-model="model.orgName"
                      placeholder="Acme PTY Ltd"
                      rules="required"
                    />
                  </b-col>
                  <b-col md="6">
                    <base-input
                      name="ABN"
                      label="ABN"
                      v-model="model.orgABN"
                      rules="required"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <base-input
                      name="Insurance Issuer"
                      label="Insurance Issuer"
                      rules="required"
                    >
                      <el-select
                        v-model="model.insuranceBrokerId"
                        filterable
                        placeholder="Please select an Insurance Issuer"
                      >
                        <el-option
                          v-for="item in insuranceBrokerList"
                          :key="item.insurance_broker_id"
                          :label="item.insurance_broker_name"
                          :value="item.insurance_broker_id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>

                  <b-col md="6">
                    <base-input name="Introducer" label="Introducer">
                      <el-select
                        v-model="model.introducer"
                        filterable
                        clearable
                        placeholder="Have you been referred by another organization?"
                      >
                        <el-option
                          v-for="item in getBrokerOrgList"
                          :key="item.Broker_org_id"
                          :label="item.Broker_Org_Name"
                          :value="item.Broker_org_id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="12">
                    <h3>Access Permissions</h3>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input name="InsuranceBroker" label="Insurance Broker">
                      <base-switch
                        style="margin-top:9px;"
                        on-text="Yes"
                        off-text="No"
                        v-model="model.isInsuranceBroker"
                      >
                      </base-switch>
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="12">
                    <h3>Features</h3>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="4">
                    <b-row>
                      <b-col>
                        <base-input
                          label="Can see VIBS products (Default)"
                        >
                          <button-radio-group
                            id="can-see-vibs"
                            :value="model.defaultShowPe"
                            v-model="model.defaultShowPe"
                            :options="twoOptions"
                            name="can-see-vibs"
                            buttons
                            style="margin-top: 0px;"
                          ></button-radio-group>
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input label="Can see Light Commercial  (Default)">
                          <button-radio-group
                            id="can-see-light-commercial"
                            :value="model.defaultShowLc"
                            v-model="model.defaultShowLc"
                            :options="twoOptions"
                            name="can-see-light-commercial"
                            buttons
                            style="margin-top: 0px;"
                          ></button-radio-group>
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input label="Can see Fleet Management (Default)">
                          <button-radio-group
                            id="can-see-fleet-management"
                            :value="model.can_see_fleet_management"
                            v-model="model.can_see_fleet_management"
                            :options="twoOptions"
                            name="can-see-fleet-management"
                            buttons
                            style="margin-top: 0px;"
                          ></button-radio-group>
                        </base-input>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col md="4">
                    <b-row>
                      <b-col>
                        <base-input label="Can see Rideshare (Default)">
                          <button-radio-group
                            id="can-see-ride-share"
                            :value="model.defaultShowRs"
                            v-model="model.defaultShowRs"
                            :options="twoOptions"
                            name="can-see-ride-share"
                            buttons
                            style="margin-top: 0px;"
                          ></button-radio-group>
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input label="Can Adjust Policy">
                          <button-radio-group
                            id="can-adjust-policy"
                            :value="model.can_adjust_policy"
                            v-model="model.can_adjust_policy"
                            :options="twoOptions"
                            name="can-adjust-policy"
                            buttons
                            style="margin-top: 0px;"
                          ></button-radio-group>
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input label="Can see Fleet">
                          <button-radio-group
                            id="can-see-fleet"
                            :value="model.can_see_fleet"
                            v-model="model.can_see_fleet"
                            :options="threeOptions"
                            name="can-see-fleet"
                            buttons
                            style="margin-top: 0px;"
                          ></button-radio-group>
                        </base-input>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col md="4">
                    <b-row>
                      <b-col>
                        <base-input label="Can see Claims">
                          <button-radio-group
                            id="can-see-claims"
                            :value="model.can_see_claims"
                            v-model="model.can_see_claims"
                            :options="threeOptions"
                            name="can-see-claims"
                            buttons
                            style="margin-top: 0px;"
                          ></button-radio-group>
                        </base-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <base-input label="Can Cancel Policy">
                          <button-radio-group
                            id="can-cancel-policy"
                            :value="model.can_cancel_policy"
                            v-model="model.can_cancel_policy"
                            :options="threeOptions"
                            name="can-cancel-policy"
                            buttons
                            style="margin-top: 0px;"
                          ></button-radio-group>
                        </base-input>
                      </b-col>
                    </b-row>

                    <!-- <b-row>
                      <b-col>
                        <base-input label="Can Receive Invoice">
                          <button-radio-group
                            id="can-receive-invoice"
                            :value="model.can_receive_invoice"
                            v-model="model.can_receive_invoice"
                            :options="threeOptions"
                            name="can-receive-invoice"
                            buttons
                            style="margin-top: 0px;"
                          ></button-radio-group>
                        </base-input>
                      </b-col>
                    </b-row> -->
                  </b-col>
                </b-row>

                <b-row class="d-flex justify-content-center">
                  <base-button
                    @click="resetBrokerOrgForm"
                    type="danger"
                    class="mr-4"
                    >Cancel</base-button
                  >
                  <base-button @click="saveChanges" type="danger"
                    >Save</base-button
                  >
                </b-row>
              </b-card-body>
            </b-card>
          </form>
        </validation-observer>
      </div>
    </div>
    <div class="container-fluid" v-if="!(activityStatus == 'new' || activityStatus == 'edit')">
      <card body-classes="px-0 pb-1" footer-classes="pb-2">
        <div>
          <b-col
            cols="12"
            class="d-flex justify-content-center justify-content-sm-between flex-wrap change-color"
          >
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>

            <div>
              <base-input
                v-model="searchQuery"
                prepend-icon="fas fa-search"
                placeholder="Search..."
                v-on:input="resetPagination()"
              >
              </base-input>
            </div>
            <div>
              <base-button @click="addBroker()" type="danger"
                >Add new Broker Org</base-button
              >
            </div>
          </b-col>
          <el-table
            :data="queriedData"
            row-key="id"
            header-row-class-name="thead-light"
            @sort-change="sortChange"
            @selection-change="selectionChange"
            v-loading="loading"
          >
            <el-table-column min-width="100px" align="left" label="Edit">
              <div slot-scope="{ row }">
                <base-button
                  @click.native="populateForm(row)"
                  class="edit"
                  type="danger"
                  size="sm"
                  icon
                  v-b-tooltip.hover
                  title="View / Edit"
                >
                  <i class="text-white ni ni-ruler-pencil"></i>
                </base-button>
              </div>
            </el-table-column>

            <el-table-column
              label="Name"
              prop="Broker_Org_Name"
              min-width="190px"
              sortable
            >
            </el-table-column>

            <el-table-column
              label="Insurance Issuer"
              prop="insurance_issuer"
              min-width="190px"
              sortable
            >
            </el-table-column>
            <template #empty>
              <p>{{ loading ? "" : "No broker org found" }}</p>
            </template>
          </el-table>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div>
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

              <span v-if="selectedRows.length">
                &nbsp; &nbsp; {{ selectedRows.length }} rows selected
              </span>
            </p>
          </div>
          <base-pagination
            class="pagination-no-border"
            :value="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
            @change="paginationChanged($event)"
          >
          </base-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { GlobalStore } from "../../globalStore";
import { toasts } from "../../helpers/toasts";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "@/components";
import clientPaginationMixin from "../../components/PaginatedTables/clientPaginationMixin";
import ButtonRadioGroup from "../../components/ButtonRadioGroup.vue";
import { appFunctions } from "../../helpers/appFunctions";

export default {
  data() {
    return {
      loading: false,
      activityStatus: "add",
      currentBrokerOrgId: "",
      tableData: [],
      selectedRows: [],
      insuranceBrokerList: [],
      model: {
        orgName: "",
        orgABN: "",
        insuranceIssuer: "",
        insuranceIssuerEmail: "",
        insuranceIssuerAccountsPayable: "",
        insuranceBrokerId: "",
        introducer: "",
        isInsuranceBroker: false,
        defaultShowLc: false,
        defaultShowPe: false,
        defaultShowRs: false,
        can_see_fleet_management: false,
        can_adjust_policy: false
      },
      threeOptions: [
        {
          label: 'Inherit',
          value: null
        },
        {
          label: 'Yes',
          value: true
        },
        {
          label: 'No',
          value: false
        }
      ],
      twoOptions: [
        {
          label: 'Yes',
          value: true
        },
        {
          label: 'No',
          value: false
        }
      ]
    };
  },
  name: "BrokerOrg",
  methods: {
    // TABLE METHODS
    addBroker() {
      this.resetBrokerOrgForm();
      this.activityStatus = "new";
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    async populateForm(row) {
      var rowResp = await appFunctions.crud_brokerOrg("get", {
        Broker_org_id: row.Broker_org_id
      });

      row = rowResp.data;

      this.activityStatus = "edit";
      this.currentBrokerOrgId = row.Broker_org_id;

      (this.model.orgName = row.Broker_Org_Name),
        (this.model.orgABN = row.Broker_org_abn),
        (this.model.insuranceIssuer = row.insurance_issuer),
        (this.model.insuranceIssuerEmail = row.insurance_issuer_email),
        (this.model.insuranceIssuerAccountsPayable =
          row.insurance_issuer_accounts_payable),
        (this.model.insuranceBrokerId = row.insurance_broker_id);
      this.model.isInsuranceBroker = row.is_insurance_broker;
      this.model.defaultShowLc = row.default_show_lc;
      this.model.defaultShowPe = row.default_show_pe;
      this.model.defaultShowRs = row.default_show_rs || false;
      this.model.can_see_fleet_management =
        row.can_see_fleet_management || false;
      this.model.introducer = row.introducer || "";
      this.model.can_cancel_policy =
        row.can_cancel_policy != undefined ? row.can_cancel_policy : null;
      this.model.can_receive_invoice =
        row.can_receive_invoice != undefined ? row.can_receive_invoice : null;
      this.model.can_see_claims =
        row.can_see_claims != undefined ? row.can_see_claims : null;
      this.model.can_see_fleet =
        row.can_see_fleet != undefined ? row.can_see_fleet : null;
      this.model.can_adjust_policy = typeof row.can_adjust_policy === 'undefined' ? row.is_insurance_broker : row.can_adjust_policy
    },
    resetBrokerOrgForm() {
      // Reset flags
      this.activityStatus = "add";
      this.currentBrokerOrgId = "";

      // Reset Modal
      this.model.orgName = "";
      this.model.orgABN = "";
      this.model.insuranceIssuer = "";
      this.model.insuranceIssuerAccountsPayable = "";
      this.model.insuranceBrokerId = "";

      this.model.isInsuranceBroker = false;
      this.model.defaultShowLc = true;
      this.model.defaultShowPe = false;
      this.model.defaultShowRs = true;
      this.model.can_see_fleet_management = false;
      this.model.can_adjust_policy = false;
      this.model.introducer = "";
    },


    /**
     * Initial load and refresh broker orgs. Initializes Fuse.
     */
    async getBrokerOrgs() {
      this.tableData = []
      this.loading = true
      const show = await this.$root.$refs.DashboardLayout.disableOrEnabledMenu()
      await appFunctions.crud_brokerOrg("list").then(res => {
        this.tableData = res.data;
        this.initFuse(["Broker_Org_Name", "insurance_issuer"]);
      }).finally(() => {
        this.loading = false
      });
    },
    // CRUD METHODS
    async saveChanges() {
      const isValid = await this.$refs.formValidator.validate();
      if (!isValid) {
        toasts.error("Please complete the required fields before proceeding.");
        return;
      }

      const insIssuerName = this.insuranceBrokerList.find(
        f => f.insurance_broker_id == this.model.insuranceBrokerId
      ).insurance_broker_name;

      var tmpData = {
        Broker_Org_Name: this.model.orgName,
        Broker_org_abn: this.model.orgABN,
        insurance_issuer: insIssuerName,
        insurance_issuer_accounts_payable: this.model
          .insuranceIssuerAccountsPayable,
        insurance_broker_id: this.model.insuranceBrokerId,
        is_insurance_broker: this.model.isInsuranceBroker,
        default_show_lc: this.model.defaultShowLc,
        default_show_pe: this.model.defaultShowPe,
        default_show_rs: this.model.defaultShowRs,
        introducer: this.model.introducer,
        can_see_fleet_management: this.model.can_see_fleet_management,
        can_see_claims: this.model.can_see_claims,
        can_see_fleet: this.model.can_see_fleet,
        can_receive_invoice: this.model.can_receive_invoice,
        can_cancel_policy: this.model.can_cancel_policy,
        can_adjust_policy: this.model.can_adjust_policy
      };

      if (this.currentBrokerOrgId) {
        // Existing broker has been edited
        tmpData.Broker_org_id = this.currentBrokerOrgId;

        appFunctions
          .crud_brokerOrg("update", tmpData)
          .then(res => {
            if (res.data == "OK") {
              this.getBrokerOrgs();
              this.resetBrokerOrgForm();
              toasts.success("Broker Org updated successfully!");
            }
          })
          .catch(err => {
            console.error(err);
            toasts.error(`Failed to update organisation: ${err}`);
          });
      } else {
        // New broker will be added
        var newBrokerOrgId = await appFunctions.crud_brokerOrg(
          "getNextBrokerOrgId"
        );
        tmpData.Broker_org_id = newBrokerOrgId.data;

        appFunctions
          .crud_brokerOrg("create", tmpData)
          .then(res => {
            if (res.data == "OK") {
              this.getBrokerOrgs();
              this.resetBrokerOrgForm();
              toasts.success("Broker Org created successfully!");
            }
          })
          .catch(err => {
            toasts.error(`Failed to add organisation: ${err}`);
          });
      }
    }
  },
  computed: {
    /**
     * Returns list of broker orgs. If editing an org, will return all orgs except current.
     */
    getBrokerOrgList() {
      return this.tableData.filter(
        org => org.Broker_org_id !== this.currentBrokerOrgId
      );
    }
  },

  async mounted() {
    this.loading = true;
    this.realmApp = GlobalStore.realmApp;
    await this.getBrokerOrgs();

    appFunctions.crud_insuranceBroker("list").then(resp => {
      this.insuranceBrokerList = resp.data;
    });
  },
  mixins: [clientPaginationMixin],
  components: {
    BaseInput,
    [Select.name]: Select,
    [Option.name]: Option,
    BasePagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    ButtonRadioGroup
  }
};
</script>

<style lang="scss"></style>
