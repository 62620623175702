var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('base-header',{staticClass:"pb-6"},[_c('b-row',{staticClass:"py-4",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"7","lg":"6"}},[_c('h6',{staticClass:"h2 text-white d-inline-block mb-0"},[_vm._v("Quotes and Policies")]),_c('nav',{staticClass:"d-none d-md-inline-block ml-md-4",attrs:{"aria-label":"breadcrumb"}})]),_c('b-col',{staticClass:"text-right",attrs:{"lg":"6","cols":"5"}},[_c('base-button',{attrs:{"size":"sm","type":"neutral"},on:{"click":function($event){return _vm.newQuote()}}},[_vm._v("New")])],1)],1)],1),_c('div',{staticClass:"mt--6 px-2 px-sm-4"},[_c('div',[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('div',[_c('b-col',{staticClass:"d-flex justify-content-center justify-content-sm-between flex-wrap pb-2",attrs:{"cols":"12"}},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":"Per page"},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.queriedData,"row-key":"id","default-sort":{
              prop: 'Customer_policy_quote_generated_date',
              order: 'descending'
            },"header-row-class-name":"thead-light"},on:{"sort-change":_vm.sortChange,"selection-change":_vm.selectionChange},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.loading ? "" : "No Data"))])]},proxy:true}])},[_c('el-table-column',{attrs:{"min-width":"100px","align":"left","label":"view"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var $index = ref.$index;
            var row = ref.row;
return _c('div',{staticClass:"d-flex"},[_c('base-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"edit",attrs:{"type":"danger","size":"sm","icon":"","title":"View Quote"},nativeOn:{"click":function($event){return _vm.handleEdit($index, row)}}},[_c('i',{staticClass:"text-white ni ni-ruler-pencil"})])],1)}}])}),_c('el-table-column',{attrs:{"label":"Status","min-width":"170px","prop":"Customer_policy_status","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('badge',{staticClass:"badge-dot mr-4",attrs:{"type":""}},[_c('i',{class:("bg-" + (row.statusClass))}),_c('span',{staticClass:"status"},[_vm._v(_vm._s(row.statusText))])])]}}])}),_c('el-table-column',{attrs:{"label":"Insured Name","prop":"Customer_insured_name","min-width":"190px","sortable":""}}),_c('el-table-column',{attrs:{"label":"Equipment Value","prop":"Customer_policy_equipment_value","min-width":"190px","sortable":""}}),_c('el-table-column',{attrs:{"label":"Total Premium","prop":"Customer_policy_insurance_total_premium","min-width":"180px","sortable":""}}),_c('el-table-column',{attrs:{"label":"Created Date","prop":"Customer_policy_quote_generated_date","min-width":"180px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm.formatDate(scope.row.Customer_policy_quote_generated_date)))])]}}])}),_c('el-table-column',{attrs:{"label":"Created By","prop":"createdByName","min-width":"180px","sortable":""}})],1)],1),_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v(" Showing "+_vm._s(_vm.from + 1)+" to "+_vm._s(_vm.to)+" of "+_vm._s(_vm.total)+" entries "),(_vm.selectedRows.length)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedRows.length)+" rows selected ")]):_vm._e()])]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"current":_vm.pagination.currentPage,"per-page":_vm.pagination.perPage,"total":_vm.total},on:{"change":function($event){return _vm.paginationChanged($event)}}})],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }