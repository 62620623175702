<template>
  <b-card class="mb-4 p-3">
    <b-card-text class="text-black">
      <p class="py-1">
        For Rideshare quotes of <b>1 vehicle</b>, please go to
        <b>Create Quote</b> under the <b>Rideshare</b> link
      </p>

      <div class="py-1">
        For Rideshare fleet quotes  of more than one vehicle, please go to
        <b>Create Quote</b> under<b> Light Commercial</b> link.
      </div>

      <div class="py-2">
        Select <b>2-10 vehicles</b> or the <b>11+ button</b> depending on the
        size of your fleet on Page 2 of the quote
      </div>

      <b-card-img
        src="/img/lc-fleet-information.jpg"
        alt="fleet information"
        bottom
        class="p-4 fleet-information-image"
        style="width: 100%;max-width: 900px !important;margin: auto;"
      />

      <div class="py-2">
        Under Primary Purpose of Vehicle(s), please click Rideshare
      </div>
      <b-card-img
        src="/img/rs-fleet-information2.jpg"
        alt="fleet information"
        bottom
        class="py-4 fleet-information-image"
      />
      <div class="py-2">
        At the vehicle details page, upload a spreadsheet with details of the
        Vehicles of the fleet to be covered. Please include the Make, Year,
        Model, Sum Insured, State and Registration (Vehicle values would also be
        ideal)
      </div>
      <div class="py-2">
        Finally, please make sure you have the following if necessary:
        <ul>
          <li>
            Updated claims experience on insurer letterhead for <b>3-5 years</b>
          </li>
          <li>Rental Agreement (if applicable).</li>
        </ul>
      </div>
    </b-card-text>

    <base-button
      @click="continueToCreateQuote()"
      type="uBind"
      style="display: block; margin: auto;"
      class="mt-2 mt-sm-0 mb-4"
      ><i class="fas fa-plus pr-3"></i>
      Continute to Create Fleet Quote
    </base-button>
  </b-card>
</template>

<script>
export default {
  name: "FleetInformationRS",

  methods: {
    continueToCreateQuote() {
      this.$router.push("/lightCommercial/createQuote");
    }
  }
};
</script>

<style scoped>
.fleet-information-image {
  display: block;
  width: 250px;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (min-width: 480px) {
  .fleet-information-image {
    display: block;
    width: 600px;
    margin-right: auto;
    margin-left: auto;
  }
}
</style>
