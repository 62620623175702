import Vue from "vue";
import { formattingMethods } from "../helpers/formatting";
import { GlobalStore } from "../globalStore";
import { GlobalMethods } from "../globalMethods";
import { appFunctions } from "../helpers/appFunctions";

export const quoteFormMethods = new Vue({
  methods: {
    getFormRules(status) {
      // *** Customer_policy_status

      // in_progress
      // offer_sent_awaiting_information
      // accepted_broker_finance_payment
      // accepted_awaiting_payment_confirmation
      // payment_confirmed_awaiting_information
      // policy_bound
      // customer_rejected_offer
      // offer_expired

      switch (status) {
        case "in_progress":
          return {
            policyStart: "date30DaysFromNow",
            insuredName: "required",
            insuredState: "required",
            contactName: "required",
            contactFName: "required",
            contactLName: "required",
            contactPhone: "required",
            contactEmail: "required|email",
            equipmentDescription: "required",
            equipmentValue: "required",
            insuredStreet: "required",
            insuredCity: "required",
            insuredABN: "required|abn"
          };
        default:
          return {
            policyStart: "",
            insuredName: "",
            insuredState: "required",
            contactFName: null,
            contactLName: "",
            contactEmail: "",
            equipmentDescription: "",
            equipmentValue: "required"
          };
      }
    },
    getDisabledFields(mode) {
      var retObj = {};
      if (mode == "edit" || mode == "create") {
        retObj = {
          policyStart: false,
          insuredName: false,
          insuredABN: true,
          businessDesc: true,
          businessActivity: false,
          contactFName: true,
          contactLName: true,
          contactEmail: true,
          contactPhone: true,
          insuredAddress: true,
          insuredStreet: true,
          insuredCity: true,
          insuredState: true,
          insuredPostcode: true,
          businessAddress: false,
          businessStreet: false,
          businessCity: true,
          businessState: true,
          businessPostcode: true,
          associatedEntities: false,
          stampDutyStatus: false,
          tradingTime: false,
          otherTradingNames: false,

          equipmentDescription: false,
          equipmentValue: false,
          equipmentCondition: false,
          operatingArea: false,
          usedOnPublicRoad: false,
          //insurancePeriod: "12 Months",
          financier: false
        };
      } else {
        retObj = {
          policyStart: true,
          insuredName: true,
          insuredABN: true,
          businessActivity: true,
          contactFName: true,
          contactLName: true,
          contactEmail: true,
          contactPhone: true,
          insuredAddress: true,
          insuredStreet: true,
          insuredCity: true,
          insuredState: true,
          insuredPostcode: true,
          businessAddress: true,
          businessStreet: true,
          businessCity: true,
          businessState: true,
          businessPostcode: true,
          associatedEntities: true,
          stampDutyStatus: true,
          tradingTime: true,
          otherTradingNames: true,

          equipmentDescription: true,
          equipmentValue: true,
          equipmentCondition: true,
          operatingArea: true,
          usedOnPublicRoad: true,
          //insurancePeriod: "12 Months",
          financier: true
        };
      }
      return retObj;
    },
    getCalculation(formModel) {
      var settings = {
        gst: 0.1,
        stampDuty: [
          { state: "nsw", value: 0.09 },
          { state: "qld", value: 0.09 },
          { state: "vic", value: 0.1 },
          { state: "wa", value: 0.1 },
          { state: "sa", value: 0.11 },
          { state: "nt", value: 0.1 },
          { state: "tas", value: 0.01 },
          { state: "act", value: 0.0 }
        ],
        Depreciation: 1.15,
        default_policy_fee: 132,
        insured_value_premium: [
          { from: 0, to: 100000, rate: 0.01 },
          { from: 100001, to: 250000, rate: 0.009 },
          { from: 250001, to: 500000, rate: 0.008 }
        ]
      };

      var stampDutyRate = settings.stampDuty.find(
        f => f.state.toLowerCase() == formModel.insuredState.toLowerCase()
      ).value;

      var insuredValueBand = settings.insured_value_premium.find(
        f =>
          formModel.equipmentValue >= f.from && formModel.equipmentValue <= f.to
      )
        ? settings.insured_value_premium.find(
            f =>
              formModel.equipmentValue >= f.from &&
              formModel.equipmentValue <= f.to
          ).rate
        : 0.008; // to handle anything higher than 500000

      var _premium = formModel.equipmentValue * insuredValueBand;
      var _stampDuty = stampDutyRate * _premium;
      var _gst = settings.gst * (_premium + _stampDuty);
      var _policyFee = settings.default_policy_fee;
      var _insurance_total_premium = _premium + _stampDuty + _gst + _policyFee;

      var retObj = {
        Customer_policy_insurance_total_premium: formattingMethods.round(
          _insurance_total_premium,
          2
        ),
        Customer_policy_insurance_premium: formattingMethods.round(_premium, 2),
        Customer_policy_insurance_premium_gst: formattingMethods.round(_gst, 2),
        Customer_policy_insurance_premium_stamp_duty: formattingMethods.round(
          _stampDuty,
          2
        ),
        Customer_policy_fee: formattingMethods.round(_policyFee, 2)
      };

      return retObj;
    },
    async changeStatus(policy_id, status) {
      appFunctions
        .crud_customerPolicy("getCombined", {
          policy_id: parseInt(policy_id)
        })
        .then(async resp => {
          //console.log(resp.data);
          resp.data.Customer_policy_status = status;

          await appFunctions.crud_customerPolicy("updateCombined", resp.data);
        });
    },
    async createQuote(quoteFormModel, selectedCustomerId) {
      console.log(GlobalStore.brokerObj);

      if (selectedCustomerId == 0) {
        var newCustomer = {
          //Customer_id: "",
          //Customer_Broker_referred_by: "",
          Customer_insured_name: quoteFormModel.insuredName,
          Customer_abn: formattingMethods.formatABN(quoteFormModel.insuredABN),
          Customer_contact_first_name: quoteFormModel.contactFName,
          Customer_contact_last_name: quoteFormModel.contactLName,
          Customer_contact_email: quoteFormModel.contactEmail.trim(),
          Customer_contact_number: quoteFormModel.contactPhone,
          Customer_contact_street: quoteFormModel.insuredStreet,
          Customer_contact_city: quoteFormModel.insuredCity,
          Customer_state: quoteFormModel.insuredState,
          Customer_postcode: quoteFormModel.insuredPostcode,
          Customer_country: "",
          Customer_business_description: quoteFormModel.businessDesc
        };

        var newCustomerPolicy = {
          //Customer_policy_id: "",
          Customer_policy_number: "",
          //Customer_id: "",
          Customer_policy_status: "in_progress",
          Customer_policy_start_date: quoteFormModel.policyStart,
          Customer_policy_guid: GlobalMethods.uuidv4(),

          Customer_policy_equipment_description:
            quoteFormModel.equipmentDescription,
          Customer_policy_equipment_serial: "",
          Customer_policy_equipment_value: quoteFormModel.equipmentValue,
          Customer_policy_equipment_condition:
            quoteFormModel.equipmentCondition,
          Customer_policy_operation_location: quoteFormModel.operatingArea,
          Customer_policy_public_road_usage: quoteFormModel.usedOnPublicRoad,
          Customer_policy_insurance_period: quoteFormModel.insurancePeriod,
          Customer_policy_insurance_total_premium:
            quoteFormModel.Customer_policy_insurance_total_premium,
          Customer_policy_insurance_premium:
            quoteFormModel.Customer_policy_insurance_premium,
          Customer_policy_insurance_premium_gst:
            quoteFormModel.Customer_policy_insurance_premium_gst,
          Customer_policy_insurance_premium_stamp_duty:
            quoteFormModel.Customer_policy_insurance_premium_stamp_duty,
          Customer_policy_fee: quoteFormModel.Customer_policy_fee,
          Customer_policy_quote_generated_date: this.$moment(new Date()).format(
            "YYYY-MM-DD h:mm:ss a"
          ),
          Customer_policy_quote_signed_date: null,
          Customer_policy_schedule_pdf: "",
          Customer_policy_Broker_finance_financier: quoteFormModel.financier,
          Customer_policy_Broker_finance_financier_other:
            quoteFormModel.financierOther,
          customer_policy_broker_referred_by: "",
          Customer_payment_type: "",

          Customer_policy_created_by: GlobalStore.realmApp.currentUser.id
        };

        var combined = {
          ...newCustomer,
          ...newCustomerPolicy
        };

        var resp = await appFunctions.crud_customerPolicy(
          "createCombined",
          combined
        );
      } else {
        var newCustomerPolicy = {
          //Customer_policy_id: "",
          Customer_policy_number: "",
          Customer_id: selectedCustomerId,
          Customer_policy_status: "in_progress",
          Customer_policy_start_date: quoteFormModel.policyStart,
          Customer_policy_guid: GlobalMethods.uuidv4(),

          Customer_policy_equipment_description:
            quoteFormModel.equipmentDescription,
          Customer_policy_equipment_serial: "",
          Customer_policy_equipment_value: quoteFormModel.equipmentValue,
          Customer_policy_equipment_condition:
            quoteFormModel.equipmentCondition,
          Customer_policy_operation_location: quoteFormModel.operatingArea,
          Customer_policy_public_road_usage: quoteFormModel.usedOnPublicRoad,
          Customer_policy_insurance_period: quoteFormModel.insurancePeriod,
          Customer_policy_insurance_total_premium:
            quoteFormModel.Customer_policy_insurance_total_premium,
          Customer_policy_insurance_premium:
            quoteFormModel.Customer_policy_insurance_premium,
          Customer_policy_insurance_premium_gst:
            quoteFormModel.Customer_policy_insurance_premium_gst,
          Customer_policy_insurance_premium_stamp_duty:
            quoteFormModel.Customer_policy_insurance_premium_stamp_duty,
          Customer_policy_fee: quoteFormModel.Customer_policy_fee,
          Customer_policy_quote_generated_date: this.$moment(new Date()).format(
            "YYYY-MM-DD h:mm:ss a"
          ),
          Customer_policy_quote_signed_date: null,
          Customer_policy_schedule_pdf: "",
          Customer_policy_Broker_finance_financier: quoteFormModel.financier,
          Customer_policy_Broker_finance_financier_other:
            quoteFormModel.financierOther,
          customer_policy_broker_referred_by: "",
          Customer_payment_type: "",

          Customer_policy_created_by: GlobalStore.realmApp.currentUser.id
        };

        var resp = await appFunctions.crud_customerPolicy(
          "create",
          newCustomerPolicy
        );
      }
      // .then(resp => {
      //   console.log(resp);
      //   //this.brokers = resp.data;
      // });

      console.log(resp);
      //console.log(newCustomerPolicy);
      return resp.data;
    },
    async updateQuote(app, rawModel, quoteFormModel) {
      const brokerObj = await appFunctions.crud_broker("getByRealmId", {
        Realm_user_id: rawModel.Customer_policy_created_by
      });

      let brokerId = rawModel.Broker_id;
      let brokerOrgId = rawModel.Broker_org_id;
      let insuranceBrokerId = rawModel.Insurance_broker_id;

      if (!rawModel.Broker_id) {
        brokerId = brokerObj.data.Broker_id;
        brokerOrgId = brokerObj.data.parentOrg.Broker_org_id;
        insuranceBrokerId =
          brokerObj.data.parentOrg.parentInsuranceBroker.insurance_broker_id;
      }

      // var populatedTemplate = await templateMethods.populateContentTemplate(
      //   realmApp,
      //   mergeFields,
      //   brokerObj.data.parentOrg.parentInsuranceBroker.content_template_id
      // );

      var existingCustomer = {
        //Customer_id: "",
        //Customer_Broker_referred_by: "",
        Customer_insured_name: quoteFormModel.insuredName,
        Customer_abn: quoteFormModel.insuredABN,
        Customer_contact_first_name: quoteFormModel.contactFName,
        Customer_contact_last_name: quoteFormModel.contactLName,
        Customer_contact_email: quoteFormModel.contactEmail,
        Customer_contact_number: quoteFormModel.contactPhone,
        Customer_contact_street: quoteFormModel.insuredStreet,
        Customer_contact_city: quoteFormModel.insuredCity,
        Customer_state: quoteFormModel.insuredState,
        Customer_postcode: quoteFormModel.insuredPostcode,
        //Customer_country: "",
        Customer_business_description: quoteFormModel.businessDesc,
        Broker_org_id: brokerOrgId,
        Broker_id: brokerId,
        Insurance_broker_id: insuranceBrokerId,
        Ubind_customer_id: rawModel.Ubind_customer_id
      };

      var existingCustomerPolicy = {
        //Customer_policy_id: "",
        //Customer_policy_number: "",
        //Customer_id: "",
        //Customer_policy_status: "in_progress",
        Customer_policy_start_date: quoteFormModel.policyStart,
        //Customer_policy_guid: GlobalMethods.uuidv4(),

        Customer_policy_equipment_description:
          quoteFormModel.equipmentDescription,
        Customer_policy_equipment_serial: quoteFormModel.Equipment_serial_numbers
          ? quoteFormModel.Equipment_serial_numbers
          : "",
        Customer_policy_equipment_value: quoteFormModel.equipmentValue,
        Customer_policy_equipment_condition: quoteFormModel.equipmentCondition,
        Customer_policy_operation_location: quoteFormModel.operatingArea,
        Customer_policy_public_road_usage: quoteFormModel.usedOnPublicRoad,
        Customer_policy_insurance_period: quoteFormModel.insurancePeriod,
        Customer_policy_insurance_total_premium:
          quoteFormModel.Customer_policy_insurance_total_premium,
        Customer_policy_insurance_premium:
          quoteFormModel.Customer_policy_insurance_premium,
        Customer_policy_insurance_premium_gst:
          quoteFormModel.Customer_policy_insurance_premium_gst,
        Customer_policy_insurance_premium_stamp_duty:
          quoteFormModel.Customer_policy_insurance_premium_stamp_duty,
        Customer_policy_fee: quoteFormModel.Customer_policy_fee,
        //Customer_policy_quote_generated_date: this.$moment(new Date()).format(
        //   "YYYY-MM-DD h:mm:ss a"
        // ),
        // Customer_policy_quote_signed_date: null,
        // Customer_policy_schedule_pdf: "",
        Customer_policy_Broker_finance_financier: quoteFormModel.financier,
        Customer_policy_Broker_finance_financier_other:
          quoteFormModel.financierOther,
        //customer_policy_broker_referred_by: "test broker referred",
        Customer_payment_type: quoteFormModel.Payment_option
          ? quoteFormModel.Payment_option
          : ""

        //Customer_policy_created_by: GlobalStore.realmApp.currentUser.id
      };

      var combined = {
        ...rawModel,
        ...existingCustomer,
        ...existingCustomerPolicy
      };

      console.log(combined);

      await appFunctions.crud_customerPolicy("updateCombined", combined);

      // var resp = await appFunctions.crud_customerPolicy(
      //   "createCombined",
      //   combined
      // );
      // .then(resp => {
      //   console.log(resp);
      //   //this.brokers = resp.data;
      // });

      //console.log(combined);
      //console.log(newCustomerPolicy);
      //return resp.data;
      return combined;
    },
    /*
    async getQuoteFromGuid(guid) {
      var Realm = window.Realm;
      const config = {
        id: GlobalStore.realm_app_id
      };
      const app = new Realm.App(config);

      // Authenticate the user
      const credentials = Realm.Credentials.emailPassword(
        "donotdelete@user.com",
        "Password1!"
      );

      const user = await app.logIn(credentials);

      var resp = await user.functions.getQuoteByGuid(guid);

      // Getting broker details
      try {
        var brokerObj = await appFunctions.crud_broker("getByRealmId", {
          Realm_user_id: resp.data.Customer_policy_created_by
        });
      } catch (e) {
        console.error(e);
      }

      var respModel = {};

      var d = resp.data;
      respModel.policyStart = d.Customer_policy_start_date;
      respModel.insuredName = d.Customer_insured_name;
      respModel.insuredABN = d.Customer_abn;
      respModel.businessDesc = d.Customer_business_description;
      respModel.contactFName = d.Customer_contact_first_name;
      respModel.contactLName = d.Customer_contact_last_name;
      respModel.contactEmail = d.Customer_contact_email;
      respModel.contactPhone = d.Customer_contact_number;
      respModel.insuredAddress = "";
      respModel.insuredStreet = d.Customer_contact_street;
      respModel.insuredCity = d.Customer_contact_city;
      respModel.insuredState = d.Customer_state;
      respModel.insuredPostcode = d.Customer_postcode;
      respModel.equipmentDescription = d.Customer_policy_equipment_description;
      respModel.equipmentValue = d.Customer_policy_equipment_value;
      respModel.equipmentCondition = d.Customer_policy_equipment_condition;
      respModel.operatingArea = d.Customer_policy_operation_location;
      respModel.usedOnPublicRoad = d.Customer_policy_public_road_usage;
      respModel.insurancePeriod = d.Customer_policy_insurance_period;
      (respModel.financier = d.Customer_policy_Broker_finance_financier),
        (respModel.financierOther =
          d.Customer_policy_Broker_finance_financier_other),
        (respModel.Customer_policy_insurance_total_premium =
          d.Customer_policy_insurance_total_premium);
      respModel.Customer_policy_insurance_premium =
        d.Customer_policy_insurance_premium;
      respModel.Customer_policy_insurance_premium_gst =
        d.Customer_policy_insurance_premium_gst;
      respModel.Customer_policy_insurance_premium_stamp_duty =
        d.Customer_policy_insurance_premium_stamp_duty;
      respModel.Customer_policy_fee = d.Customer_policy_fee;
      (respModel.Customer_payment_type = d.Customer_payment_type),
        (respModel.Equipment_serial_numbers =
          d.Customer_policy_equipment_serial);
      respModel.Customer_policy_status = d.Customer_policy_status;
      respModel.insurance_broker_logo =
        brokerObj.data.parentOrg.parentInsuranceBroker.insurance_broker_logo;

      //this.brokers = resp.data;
      //});

      //console.log(respModel);

      return { respModel, rawModel: d };
    },
    
    async getLCQuoteFromUbindGuid(guid) {
      var Realm = window.Realm;
      const config = {
        id: GlobalStore.realm_app_id
      };
      //const app = new Realm.App(config);

      // Authenticate the user
      // const credentials = Realm.Credentials.emailPassword(
      //   "donotdelete@user.com",
      //   "Password1!"
      // );

      // const user = await app.logIn(credentials);

      var resp = await user.functions.getLCQuoteByUbindGuid(guid);

      console.log(resp);

      // Getting broker details
      try {
        var brokerObj = await appFunctions.crud_broker("getByRealmId", {
          Realm_user_id: resp.data.Customer_policy_created_by
        });
      } catch (e) {
        console.error(e);
      }

      console.log(brokerObj);

      // var respModel = {};

      // var d = resp.data;
      // respModel.policyStart = d.Customer_policy_start_date;
      // respModel.insuredName = d.Customer_insured_name;
      // respModel.insuredABN = d.Customer_abn;
      // respModel.businessDesc = d.Customer_business_description;
      // respModel.contactFName = d.Customer_contact_first_name;
      // respModel.contactLName = d.Customer_contact_last_name;
      // respModel.contactEmail = d.Customer_contact_email;
      // respModel.contactPhone = d.Customer_contact_number;
      // respModel.insuredAddress = "";
      // respModel.insuredStreet = d.Customer_contact_street;
      // respModel.insuredCity = d.Customer_contact_city;
      // respModel.insuredState = d.Customer_state;
      // respModel.insuredPostcode = d.Customer_postcode;
      // respModel.equipmentDescription = d.Customer_policy_equipment_description;
      // respModel.equipmentValue = d.Customer_policy_equipment_value;
      // respModel.equipmentCondition = d.Customer_policy_equipment_condition;
      // respModel.operatingArea = d.Customer_policy_operation_location;
      // respModel.usedOnPublicRoad = d.Customer_policy_public_road_usage;
      // respModel.insurancePeriod = d.Customer_policy_insurance_period;
      // (respModel.financier = d.Customer_policy_Broker_finance_financier),
      //   (respModel.financierOther =
      //     d.Customer_policy_Broker_finance_financier_other),
      //   (respModel.Customer_policy_insurance_total_premium =
      //     d.Customer_policy_insurance_total_premium);
      // respModel.Customer_policy_insurance_premium =
      //   d.Customer_policy_insurance_premium;
      // respModel.Customer_policy_insurance_premium_gst =
      //   d.Customer_policy_insurance_premium_gst;
      // respModel.Customer_policy_insurance_premium_stamp_duty =
      //   d.Customer_policy_insurance_premium_stamp_duty;
      // respModel.Customer_policy_fee = d.Customer_policy_fee;
      // (respModel.Customer_payment_type = d.Customer_payment_type),
      //   (respModel.Equipment_serial_numbers =
      //     d.Customer_policy_equipment_serial);
      // respModel.Customer_policy_status = d.Customer_policy_status;
      // respModel.insurance_broker_logo =
      //   brokerObj.data.parentOrg.parentInsuranceBroker.insurance_broker_logo;

      //this.brokers = resp.data;
      //});

      //console.log(respModel);

      //console.log()

      return { quoteData: resp.data, brokerData: brokerObj.data };
    },
    */
    async getLCQuoteFromGuid(guid) {
      var Realm = window.Realm;
      const config = {
        id: GlobalStore.realm_app_id
      };
      //const app = new Realm.App(config);

      // Authenticate the user
      // const credentials = Realm.Credentials.emailPassword(
      //   "donotdelete@user.com",
      //   "Password1!"
      // );

      // const user = await app.logIn(credentials);

      var resp = await appFunctions.lookupLCQuoteByGuid(guid);

      console.log(resp);

      // Getting broker details
      try {
        var brokerObj = await appFunctions.lookupBrokerByRealmId({
          Realm_user_id: resp.data.Customer_policy_created_by
        });
      } catch (e) {
        console.error(e);
      }

      console.log(brokerObj);

      // var respModel = {};

      // var d = resp.data;
      // respModel.policyStart = d.Customer_policy_start_date;
      // respModel.insuredName = d.Customer_insured_name;
      // respModel.insuredABN = d.Customer_abn;
      // respModel.businessDesc = d.Customer_business_description;
      // respModel.contactFName = d.Customer_contact_first_name;
      // respModel.contactLName = d.Customer_contact_last_name;
      // respModel.contactEmail = d.Customer_contact_email;
      // respModel.contactPhone = d.Customer_contact_number;
      // respModel.insuredAddress = "";
      // respModel.insuredStreet = d.Customer_contact_street;
      // respModel.insuredCity = d.Customer_contact_city;
      // respModel.insuredState = d.Customer_state;
      // respModel.insuredPostcode = d.Customer_postcode;
      // respModel.equipmentDescription = d.Customer_policy_equipment_description;
      // respModel.equipmentValue = d.Customer_policy_equipment_value;
      // respModel.equipmentCondition = d.Customer_policy_equipment_condition;
      // respModel.operatingArea = d.Customer_policy_operation_location;
      // respModel.usedOnPublicRoad = d.Customer_policy_public_road_usage;
      // respModel.insurancePeriod = d.Customer_policy_insurance_period;
      // (respModel.financier = d.Customer_policy_Broker_finance_financier),
      //   (respModel.financierOther =
      //     d.Customer_policy_Broker_finance_financier_other),
      //   (respModel.Customer_policy_insurance_total_premium =
      //     d.Customer_policy_insurance_total_premium);
      // respModel.Customer_policy_insurance_premium =
      //   d.Customer_policy_insurance_premium;
      // respModel.Customer_policy_insurance_premium_gst =
      //   d.Customer_policy_insurance_premium_gst;
      // respModel.Customer_policy_insurance_premium_stamp_duty =
      //   d.Customer_policy_insurance_premium_stamp_duty;
      // respModel.Customer_policy_fee = d.Customer_policy_fee;
      // (respModel.Customer_payment_type = d.Customer_payment_type),
      //   (respModel.Equipment_serial_numbers =
      //     d.Customer_policy_equipment_serial);
      // respModel.Customer_policy_status = d.Customer_policy_status;
      // respModel.insurance_broker_logo =
      //   brokerObj.data.parentOrg.parentInsuranceBroker.insurance_broker_logo;

      //this.brokers = resp.data;
      //});

      //console.log(respModel);

      //console.log()

      return { quoteData: resp.data, brokerData: brokerObj };
    },

    async getLCQuoteFromUbindGuid(guid) {
      // var Realm = window.Realm;
      // const config = {
      //   id: GlobalStore.realm_app_id
      // };
      //const app = new Realm.App(config);

      // Authenticate the user
      // const credentials = Realm.Credentials.emailPassword(
      //   "donotdelete@user.com",
      //   "Password1!"
      // );

      // const user = await app.logIn(credentials);

      var resp = await appFunctions.lookupQuoteFromUbindGuid(guid);

      console.log(resp);

      // Getting broker details
      try {
        var brokerObj = await appFunctions.lookupBrokerByRealmId(
          resp.data.Customer_policy_created_by
        );
      } catch (e) {
        console.error(e);
      }

      console.log(brokerObj);

      return { quoteData: resp.data, brokerData: brokerObj };
    },

    async getRSQuoteFromUbindGuid(guid) {
      // var Realm = window.Realm;
      // const config = {
      //   id: GlobalStore.realm_app_id
      // };
      //const app = new Realm.App(config);

      // Authenticate the user
      // const credentials = Realm.Credentials.emailPassword(
      //   "donotdelete@user.com",
      //   "Password1!"
      // );

      // const user = await app.logIn(credentials);

      var resp = await appFunctions.lookupRSQuoteFromUbindGuid(guid);

      console.log(resp);

      // Getting broker details
      try {
        var brokerObj = await appFunctions.lookupBrokerByRealmId(
          resp.data.Customer_policy_created_by
        );
      } catch (e) {
        console.error(e);
      }

      console.log(brokerObj);

      return { quoteData: resp.data, brokerData: brokerObj };
    },

    async getRSQuoteFromGuid(guid) {
      var resp = await appFunctions.lookupRSQuoteByGuid(guid);

      // Getting broker details
      try {
        var brokerObj = await appFunctions.lookupBrokerByRealmId({
          Realm_user_id: resp.data.Customer_policy_created_by
        });
      } catch (e) {
        console.error(e);
      }
      return { quoteData: resp.data, brokerData: brokerObj };
    },

    async getQuoteFromQuoteId(quoteId) {
      try {
        var quote = await appFunctions.lookupCustomerPolicyByQuoteId({
          Jotform_quote_id: quoteId
        });
      } catch (e) {
        console.error(e);
      }
      return { quoteData: quote.quoteData, brokerData: quote.brokerObj, customerData: quote.customerDetail };
    }
  }
});
