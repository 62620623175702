<template>
<b-modal hide-footer id="customer-modal" title="Customer Details">

    <b-row v-if="selectedRow">
      <b-col cols="5">
        <p>Business Name:</p>
        <p>ABN:</p>
        <p>Main Business Activites:</p>
        <p>Contact Name:</p>
        <p>Contact Phone:</p>
        <p>Contact Email:</p>
        <p>Address:</p>
        <p>State:</p>
        <p>Postcode:</p>
      </b-col>
      <b-col cols="7">
        <p>
          {{ selectedRow.Customer_insured_name }}
        </p>
        <p>
          {{ selectedRow.Customer_abn }}
        </p>
        <p>
          {{ selectedRow.Customer_business_description || "None provided" }}
        </p>
        <p>
          {{ selectedRow.Customer_contact_first_name }}
          {{ selectedRow.Customer_contact_last_name }}
        </p>
        <p>{{ selectedRow.Customer_contact_number }} </p>
        <p>{{ selectedRow.Customer_contact_email }} </p>
        <p>{{ selectedRow.Customer_contact_street }} </p>
        <p>{{ selectedRow.Customer_contact_city }} </p>
        <p>{{ selectedRow.Customer_postcode }} </p>

      </b-col>
    </b-row>
  </b-modal>

</template>

<script>

export default {
    name: "ViewCustomerModal",
    props: {
        selectedRow: null,
    },
    mounted() {
        console.log(this.selectedRow);
    }
  };
  </script>
  
