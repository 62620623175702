<template>
  <jotFormIFrame 
    :pageTitle="productName"
    :quoteObj="quoteObj"
  />
</template>

<script>
import jotFormIFrame from "../../components/jotFormIframe.vue";
import { GlobalStore } from "../../globalStore";
import { emailMethods } from "../../helpers/email";
import { toasts } from "../../helpers/toasts";
import { appFunctions } from "../../helpers/appFunctions";
import { formattingMethods } from "../../helpers/formatting";

export default {
  name: "Step2",
  props: {
    quoteObj: {
      type: Object,
      required: true
    }
  },
  computed: {
    productName() {
      return formattingMethods.formatProductNameVIBS(this.$route.params.productName)
    },
  },
  methods: {
    async sendEmail() {

    }
  },
  components: {
    jotFormIFrame
  }
};
</script>

<style lang="scss" scoped>
.top-padding {
  padding-top: 0px !important;
}

.ubind-product {
  margin: 0px 15px 0px 15px;
}
.container-fluid {
  padding-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
</style>
