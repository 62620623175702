<template>
  <div>
    <base-header class="pb-6 bg-primary">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">{{ $route.name }}</h6>
        </b-col>
      </b-row>
    </base-header>
    <div class="container-fluid mt--6">
      <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
        <form
          id="customerForm"
          class="needs-validation"
          @submit.prevent="handleSubmit(firstFormSubmit)"
        >
          <b-card no-body class="mb-4">
            <b-card-header>
              <h3 class="mb-0">
                {{
                  !currentCustomerId
                    ? "No customer is being edited"
                    : "Editing: " + model.insuredName
                }}
              </h3>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col md="6">
                  <base-input
                    name="Insured Name"
                    label="Insured Name"
                    v-model="model.insuredName"
                    placeholder="Acme PTY Ltd"
                    :disabled="!currentCustomerId"
                    rules="required"
                  />
                </b-col>
                <b-col md="6">
                  <base-input
                    type="number"
                    name="Insured ABN"
                    label="Insured ABN"
                    pattern="[0-9]"
                    v-model="model.insuredABN"
                    :disabled="!currentCustomerId"
                    rules="required"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <base-input
                    label="Business Description"
                    name="Business Description"
                    placeholder="E.g. Excavation services"
                    v-model="model.businessDescription"
                    :disabled="!currentCustomerId"
                    rules="required"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <base-input
                    name="Contact First Name"
                    label="Contact First Name"
                    v-model="model.contactFirstName"
                    placeholder="John"
                    :disabled="!currentCustomerId"
                    rules="required"
                  />
                </b-col>
                <b-col md="6">
                  <base-input
                    name="Contact Last Name"
                    label="Contact Last Name"
                    v-model="model.contactLastName"
                    placeholder="Smith"
                    :disabled="!currentCustomerId"
                    rules="required"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <base-input
                    label="Contact Phone Number"
                    name="Contact Phone Number"
                    v-model="model.contactPhone"
                    :disabled="!currentCustomerId"
                    rules="required"
                  />
                </b-col>
                <b-col md="6">
                  <base-input
                    name="Contact Email"
                    label="Contact Email"
                    placeholder="john@example.com"
                    v-model="model.contactEmail"
                    :disabled="!currentCustomerId"
                    rules="required"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="mb-4">
                  <label for="addrs_1" class="form-control-label">
                    Search Insured Address
                  </label>
                  <input
                    style="cursor: text"
                    @blur="clearAddressFields"
                    class="form-control"
                    id="addrs_1"
                    v-model="model.contactStreet"
                    :disabled="!currentCustomerId"
                    rules="required"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <base-input
                    label="City"
                    v-model="model.contactCity"
                    :disabled="!currentCustomerId"
                  />
                </b-col>
                <b-col md="4">
                  <base-input name="State" label="State">
                    <el-select
                      v-model="model.contactState"
                      :disabled="!currentCustomerId"
                    >
                      <el-option
                        v-for="option in states"
                        :key="option.label"
                        :label="option.label"
                        :value="option.value"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    label="Postcode"
                    type="number"
                    v-model="model.contactPostcode"
                    :disabled="!currentCustomerId"
                  />
                </b-col>
              </b-row>

              <b-row
                class="d-flex justify-content-center"
                v-if="currentCustomerId"
              >
                <base-button
                  @click="resetCustomerForm"
                  type="danger"
                  class="mr-4"
                  >Cancel</base-button
                >
                <base-button @click="saveChanges" type="danger"
                  >Save</base-button
                >
              </b-row>
            </b-card-body>
          </b-card>
        </form>
      </validation-observer>
    </div>

    <div class="container-fluid" v-if="viewMode == 'list'">
      <card body-classes="px-0 pb-1" footer-classes="pb-2">
        <div>
          <b-col
            cols="12"
            class="d-flex justify-content-center justify-content-sm-between flex-wrap change-color"
          >
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>

            <div>
              <base-input
                v-model="searchQuery"
                prepend-icon="fas fa-search"
                placeholder="Search..."
              >
              </base-input>
            </div>
          </b-col>
          <el-table
            :data="queriedData"
            row-key="id"
            :default-sort="{
              prop: 'Customer_insured_name',
              order: 'ascending'
            }"
            header-row-class-name="thead-light"
            @sort-change="sortChange"
            @selection-change="selectionChange"
          >
            <el-table-column min-width="100px" align="left" label="Edit">
              <div slot-scope="{ row }">
                <base-button
                  @click.native="populateForm(row)"
                  class="edit"
                  type="danger"
                  size="sm"
                  icon
                  title="View / Edit"
                >
                  <i class="text-white ni ni-ruler-pencil"></i>
                </base-button>
              </div>
            </el-table-column>

            <el-table-column
              label="Insured Name"
              prop="Customer_insured_name"
              min-width="190px"
              sortable
            >
            </el-table-column>

            <el-table-column
              label="Name"
              prop="Customer_contact_name"
              min-width="190px"
              sortable
            >
            </el-table-column>

            <el-table-column
              label="ABN"
              prop="Customer_abn"
              min-width="180px"
              sortable
            >
            </el-table-column>

            <el-table-column
              label="Email"
              prop="Customer_contact_email"
              min-width="180px"
              sortable
            >
            </el-table-column>

            <el-table-column
              label="Phone"
              prop="Customer_contact_number"
              min-width="180px"
              sortable
            >
            </el-table-column>

            <el-table-column
              label="Business Description"
              prop="Customer_business_description"
              min-width="180px"
              sortable
            >
            </el-table-column>

            <el-table-column min-width="100px" align="left" label="Delete">
              <div slot-scope="{ row }">
                <base-button
                  @click="deleteCustomer(row)"
                  class="delete"
                  type="danger"
                  size="sm"
                  icon
                  title="Delete"
                >
                  <i class="el-icon-delete"></i>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div>
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

              <span v-if="selectedRows.length">
                &nbsp; &nbsp; {{ selectedRows.length }} rows selected
              </span>
            </p>
          </div>
          <base-pagination
            class="pagination-no-border"
            :current="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
            @change="paginationChanged($event)"
          >
          </base-pagination>
        </div>
      </card>
    </div>
    <b-modal 
      id="modal-del-customer" 
      @cancel="handleCancel"
      @ok="handleOk"
      >
      <div class="d-block text-center">
        <h2>Delete customer!</h2>
        <p>Are you sure to delete customer {{ customer_name }}?</p>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from "vue";
import { GlobalStore } from "../../globalStore";
import { emailMethods } from "../../helpers/email";
import { brokerPageMethods } from "../../helpers/brokers";
import { toasts } from "../../helpers/toasts";
import { Table, TableColumn, Select, Option, Notification } from "element-ui";
import { BasePagination } from "@/components";
import { dropDowns } from "../../assets/data/dropdown_values";
import clientPaginationMixin from "../../components/PaginatedTables/clientPaginationMixin";
import { appFunctions } from "../../helpers/appFunctions";

export default {
  data() {
    return {
      realmApp: null,
      currentCustomerId: null,
      currentItem: {},
      viewMode: "list",
      customers: [],
      tableData: [],
      selectedRows: [],
      states: dropDowns.states,
      model: {
        insuredName: "",
        insuredABN: "",
        contactFirstName: "",
        contactLastName: "",
        contactEmail: "",
        contactPhone: "",
        contactStreet: "",
        contactCity: "",
        contactState: "",
        contactPostcode: "",
        contactCountry: "",
        businessDescription: ""
      },
      customer_name: "",
      customer_id: ""
    };
  },
  name: "Brokers",
  methods: {
    // TABLE METHODS
    paginationChanged(page) {
      this.pagination.currentPage = page;
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    // UPDATE/NEW BROKER MEHTODS
    populateForm(row) {
      this.currentCustomerId = row.Customer_id;

      this.model.insuredName = row.Customer_insured_name;
      this.model.insuredABN = row.Customer_abn;
      this.model.contactFirstName = row.Customer_contact_first_name;
      this.model.contactLastName = row.Customer_contact_last_name;
      this.model.contactEmail = row.Customer_contact_email;
      this.model.contactPhone = row.Customer_contact_number;
      this.model.contactStreet = row.Customer_contact_street;
      this.model.contactCity = row.Customer_contact_city;
      this.model.contactState = row.Customer_state;
      this.model.contactPostcode = row.Customer_postcode;
      this.model.contactCountry = row.Customer_country;
      this.model.businessDescription = row.Customer_business_description;
      this.model.Broker_org_id = row.Broker_org_id;
      this.model.Insurance_broker_id = row.Insurance_broker_id;
      this.model.Broker_id = row.Broker_id;
      this.model.Ubind_customer_id = row.Ubind_customer_id;
    },

    async saveChanges() {
      const isValid = await this.$refs.formValidator.validate();
      if (!isValid) {
        toasts.success(
          "Please complete the required fields before proceeding."
        );
        return;
      }

      var tmpData = {
        Customer_id: this.currentCustomerId,
        Customer_insured_name: this.model.insuredName,
        Customer_abn: this.model.insuredABN,
        Customer_contact_first_name: this.model.contactFirstName,
        Customer_contact_last_name: this.model.contactLastName,
        Customer_contact_email: this.model.contactEmail,
        Customer_contact_number: this.model.contactPhone,
        Customer_contact_street: this.model.contactStreet,
        Customer_contact_city: this.model.contactCity,
        Customer_state: this.model.contactState,
        Customer_postcode: this.model.contactPostcode,
        Customer_country: this.model.contactCountry,
        Customer_business_description: this.model.businessDescription,
        Broker_org_id: this.model.Broker_org_id,
        Insurance_broker_id: this.model.Insurance_broker_id,
        Broker_id: this.model.Broker_id,
        Ubind_customer_id: this.model.Ubind_customer_id
      };
      appFunctions
        .crud_customer("update", tmpData)
        .then(res => {
          if (res.data == "OK") {
            toasts.success("Customer updated successfully!");
            this.refreshCustomerList();
            this.resetCustomerForm();
          }
        })
        .catch(err => {
          toasts.error(`Failed to update customer: ${err}`);
        });
    },
    refreshCustomerList() {
      // Get list of customers and add params.
      appFunctions.crud_customer("list").then(res => {
        var tmpData = res.data;
        tmpData.forEach(customer => {
          customer.Customer_contact_name =
            customer.Customer_contact_first_name +
            " " +
            customer.Customer_contact_last_name;
        });
        this.tableData = tmpData;
        this.initFuse(["Customer_contact_name", "Customer_insured_name", "Customer_contact_email"]);
      });
    },
    resetCustomerForm() {
      // Reset flags
      this.currentCustomerId = "";

      // Reset Modal
      this.model.insuredName = "";
      this.model.insuredABN = "";
      this.model.contactFirstName = "";
      this.model.contactLastName = "";
      this.model.contactEmail = "";
      this.model.contactPhone = "";
      this.model.contactStreet = "";
      this.model.contactCity = "";
      this.model.contactState = "";
      this.model.contactPostcode = "";
      this.model.contactCountry = "";
      this.model.businessDescription = "";

      // Remove 'Invalid' prompts
      this.$refs.formValidator.reset();
    },

    /**
     * @desc Clears the address city, state and postcode fields when the street
     * field is cleared.
     */
    clearAddressFields() {
      if (this.model.contactStreet == "") {
        this.model.contactCity = "";
        this.model.contactState = "";
        this.model.contactPostcode = "";
      }
    },
    deleteCustomer(e) {
      this.customer_id = e.Customer_id
      this.customer_name = e.Customer_contact_first_name + ' ' + e.Customer_contact_last_name
      this.$bvModal.show('modal-del-customer')
    },
    async handleOk() {
      this.$bvModal.hide('modal-del-customer')
      await appFunctions.crud_customer("delete", {
          customer_id: this.customer_id
        }).then(res => {
          if (res.data == 'OK') {
            Notification.success({
              title: 'Success',
              message: 'Customer has been deleted!'
            });
          } else {
            Notification.error({
              title: 'Error',
              message: 'Error deleting the customer!'
            });
          }
      });
      this.refreshCustomerList()
    },
    handleCancel() {
      this.$bvModal.hide('modal-del-customer')
    }
  },

  computed: {},

  async mounted() {
    this.realmApp = GlobalStore.realmApp;

    this.refreshCustomerList();

    let script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://api.addressfinder.io/assets/v3/widget.js"
    );
    script.setAttribute("async", true);
    script.onload = () => {
      this.widget = new window.AddressFinder.Widget(
        document.getElementById("addrs_1"),
        "NMY9RTVQ3UHADL76FJ8G",
        "AU",
        {
          address_params: {
            gnaf: 1
          }
        }
      );
      this.widget.on("result:select", (fullAddress, metaData) => {
        this.model.contactStreet = metaData.address_line_1;
        this.model.contactCity = metaData.locality_name;
        this.model.contactState = metaData.state_territory;
        this.model.contactPostcode = metaData.postcode;
      });
    };
    document.head.appendChild(script);
  },
  mixins: [clientPaginationMixin],
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Notification
  }
};
</script>

<style lang="scss">
.brokerList {
  table {
    margin: auto;
    width: 800px;
  }
  td {
    padding: 10px;
    text-align: left;
  }

  .editor__content {
    height: 400px;
    border: 1px solid lightgray;
    overflow-y: auto;
    text-align: left;
  }

  .editorFrame {
    width: 930px;
    margin: auto;
  }

  .field-layout {
    text-align: left;
    padding: 10px;
    position: relative;
    &__col1 {
      width: 200px;
      display: inline-block;
    }
    &__col2 {
      display: inline-block;
      input {
        width: 400px;
      }
    }
  }
}
</style>
