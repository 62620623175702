<template>
  <UBindIFrame
    pageTitle="Light Commercial Vehicles"
    productType="mercurien-light-commercial"
    :sendEmailAction="sendEmail"
  />
</template>

<script>
import UBindIFrame from "../../components/UBindIFrame.vue";
import { GlobalStore } from "../../globalStore";
import { emailMethods } from "../../helpers/email";
import { toasts } from "../../helpers/toasts";
import { appFunctions } from "../../helpers/appFunctions";

export default {
  name: "Step2",
  methods: {
    async sendEmail() {
      const mostRecentLCPolicyId = sessionStorage.getItem(
        "mostRecentLCPolicyId"
      );

      console.log(mostRecentLCPolicyId);

      const customerDetails = await appFunctions.crud_customerLCPolicy(
        "getCombined",
        {
          policy_id: parseInt(mostRecentLCPolicyId)
        }
      );

      console.log(customerDetails);

      await emailMethods.sendContinueQuoteEmail(
        GlobalStore.realmApp,
        customerDetails.data
      );

      toasts.success(
        `Email has been sent to ${customerDetails.data.Customer_contact_email}`
      );

      this.$router.push("/lightCommercial/quoteEmailed");
    }
  },
  components: {
    UBindIFrame
  }
};
</script>

<style lang="scss" scoped>
.top-padding {
  padding-top: 0px !important;
}

.ubind-product {
  margin: 0px 15px 0px 15px;
}
.container-fluid {
  padding-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
</style>
