<template>
  <div>
    <!-- Header -->
    <div class="header py-7 py-lg-4 pt-lg-9"></div>
    <!-- Page content -->
    <b-container v-if="showForm == 'valid'" class="mt--8 pb-5">
      <b-row class="justify-content-center" v-if="!quoteAccepted">
        <b-col lg="12" md="12">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5 bg-white">
              <div class="text-muted mb-4">
                <div style="text-align: center;">
                  <img
                    class="logo_image pb-2"
                    :src="
                      model.insurance_broker_logo || '../assets/logo_full.png'
                    "
                  />
                </div>
                <h1 class="text-center">EQUIPMENT INSURANCE</h1>
                <h4>
                  DID YOU KNOW? - Equipment Leases and other finance
                  arrangements require the equipment owner to insure the leased
                  equipment for its full insurance value against accidental loss
                  or damage and theft – and note the financier’s interest on the
                  policy.
                </h4>

                <p>
                  We are pleased to offer you a specially designed Equipment
                  Insurance Product that provides comprehensive insurance cover
                  at a competitive price.
                </p>

                <p>
                  The Equipment Insurance Policy incorporates the following
                  important features*:
                </p>

                <p>
                  <b>Satisfies Insurance Requirements -</b> Covering accidental
                  loss, damage or destruction and theft, the policy is specially
                  designed to meet the typical insurance requirements of
                  equipment leases and other finance arrangements.
                </p>

                <p>
                  <b>Convenient Payment Solutions –</b> Premium fund the
                  insurance over the life of the finance arrangement or if the
                  lender permits, include it in your lease payments. You can
                  also pay upfront if you prefer.
                </p>

                <p>
                  <b>Premium Fixed, Regardless of Claims –</b> Your insurance
                  cost is fixed, regardless of claims that may be incurred along
                  the way. (Unless of course, there’s a total loss, in which
                  case, you’ll need to take out a new policy.)
                </p>

                <p>
                  <b>Contribution to Finance Payout –</b> An additional 30% of
                  the Sum Insured is available, in the event of a total loss, to
                  contribute toward the cost of paying out any finance
                  arrangement in place.
                </p>

                <p>
                  <b>Reputable Global Insurer –</b> Insured by ABC Underwriters
                  one of the highest rated insurers in the world with a
                  financial strength rating of A++ from AM Best and AA+ from
                  Standard & Poor’s (www.abcunderwriters.com).
                </p>

                <h4>
                  * Subject to the terms, conditions and exclusions outlined in
                  the Policy Wording.
                </h4>

                <p>
                  Full details of the insurance cover can be found at
                  <a
                    target="_blank"
                    href="https://yourinsurancehq.com.au/wp-content/uploads/2021/07/Plant-and-Equipment-Policy-Wording-30-7-2021.pdf"
                    >Equipment Insurance.</a
                  >
                  (Do check it against your finance requirements before
                  accepting the offer.)
                </p>

                <p>
                  This offer is made by Your Insurance Equipment Insurance, who
                  ABC Underwriters has authorised to arrange the Equipment
                  Insurance on their behalf.. Your finance broker distributes
                  the insurance for Your Insurance Equipment Insurance. Your
                  Insurance Equipment Insurance is paid a commission by the
                  Insurer to arrange this Insurance and shares a part of that
                  commission with the finance broker.
                </p>

                <h4 class="text-center">
                  If you have any questions at all about the Equipment
                  Insurance, contact Customer Support by email:
                  <a href="mailto:support@yourinsurancehq.com.au">
                    support@yourinsurancehq.com.au
                  </a>
                </h4>

                <h4 class="text-center">
                  YOU DO NOT HAVE TO BUY THIS INSURANCE. You can arrange
                  insurance with another insurer.
                </h4>
              </div>

              <h1 class="text-center">INSURANCE OFFER</h1>
              <validation-observer
                v-slot="{ handleSubmit }"
                ref="formValidator"
              >
                <form
                  class="needs-validation"
                  @submit.prevent="handleSubmit(firstFormSubmit)"
                >
                  <b-card-body>
                    <b-row>
                      <b-col md="6">
                        <base-input
                          name="Insured Name"
                          label="Insured Name"
                          v-model="model.insuredName"
                          placeholder="Acme PTY Ltd"
                          rules="required"
                        />
                      </b-col>
                      <b-col md="6">
                        <base-input
                          type="number"
                          name="Insured ABN"
                          label="Insured ABN"
                          pattern="[0-9]"
                          rules="required"
                          placeholder="XXXXXXXXXXX"
                          v-model="model.insuredABN"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <base-input
                          label="Business Description"
                          name="Business Description"
                          placeholder="E.g. IT Company"
                          rules="required"
                          v-model="model.businessDesc"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="6">
                        <base-input
                          name="Policy Start Date"
                          label="Policy Start Date"
                          rules="required"
                          type="date"
                          v-model="model.policyStart"
                        />
                      </b-col>
                      <b-col md="6">
                        <base-input
                          name="Insurance Period"
                          label="Insurance Period"
                          v-model="model.insurancePeriod"
                          readonly
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <base-input
                          name="Insured Item(s)"
                          label="Insured Item(s)"
                          v-model="model.equipmentDescription"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="6">
                        <base-input
                          type="number"
                          name="Year 1 Insured Value"
                          label="Year 1 Insured Value"
                          v-model="model.equipmentValue"
                          readonly
                        />
                      </b-col>
                      <b-col md="6">
                        <p class="sideNotes">
                          Over time the value of the goods that you have leased
                          will depreciate. The sum insured reduces each year the
                          Finance is on foot. In year 1, the Insured Value will
                          be the Replacement Cost. After year 1, it will be an
                          Agreed Value that reduces each year the Finance is on
                          foot to reflect the value of the leased equipment.
                        </p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="6">
                        <base-input
                          name="Equipment Condition"
                          label="Equipment Condition"
                          rules="required"
                        >
                          <el-select v-model="model.equipmentCondition">
                            <el-option
                              v-for="option in equipmentConditions"
                              :key="option.label"
                              :label="option.label"
                              :value="option.value"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                      </b-col>
                      <b-col md="6">
                        <p class="sideNotes">
                          The policy does not cover existing damage at the time
                          of purchase. See the Terms, Conditions and Exclusions
                          in the Policy wording for more detail.
                        </p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="6">
                        <base-input
                          name="Equipment used on Public Road"
                          label="Equipment used on Public Road"
                        >
                          <el-select v-model="model.usedOnPublicRoad">
                            <el-option
                              v-for="option in usedOnPublicRoadOptions"
                              :key="option.label"
                              :label="option.label"
                              :value="option.value"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                      </b-col>
                      <b-col md="6">
                        <p class="sideNotes">
                          If yes, you will also need to purchase insurance
                          covering the vehicle while it is used on public roads.
                        </p>
                      </b-col>
                    </b-row>

                    <h2 class="mt-3">COST OF INSURANCE</h2>
                    <b-row>
                      <b-col md="12">
                        <base-input
                          v-model="model.Customer_policy_insurance_premium"
                          prepend-icon="fas fa-dollar-sign"
                          readonly
                          name="Insurance Premium"
                          label="Insurance Premium"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <base-input
                          v-model="model.Customer_policy_insurance_premium_gst"
                          prepend-icon="fas fa-dollar-sign"
                          readonly
                          name="Premium GST"
                          label="Premium GST"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <base-input
                          v-model="
                            model.Customer_policy_insurance_premium_stamp_duty
                          "
                          prepend-icon="fas fa-dollar-sign"
                          readonly
                          name="Stamp Duty"
                          label="Stamp Duty"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <base-input
                          v-model="model.Customer_policy_fee"
                          prepend-icon="fas fa-dollar-sign"
                          readonly
                          name="Policy Fee"
                          label="Policy Fee"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <base-input
                          prepend-icon="fas fa-dollar-sign"
                          readonly
                          v-model="
                            model.Customer_policy_insurance_total_premium
                          "
                          name="Total Insurance Premium"
                          label="Total Insurance Premium (inc all fees and charges)"
                        />
                      </b-col>
                    </b-row>

                    <h2 class="mt-3">PAYMENT OPTIONS</h2>
                    <b-row>
                      <b-col md="6">
                        <b-form-radio-group
                          @change="paymentChooser"
                          v-model="model.Payment_option"
                        >
                          <div>
                            <b-form-radio
                              class="my-radio"
                              value="Credit Card"
                              name="creditcard"
                            >
                              Credit Card / DEFT or BPay
                            </b-form-radio>
                          </div>
                          <div class="mt-2">
                            <b-form-radio
                              class="my-radio"
                              value="Finance"
                              name="finance"
                            >
                              Include in Finance
                            </b-form-radio>
                          </div>
                          <div class="mt-2">
                            <b-form-radio
                              class="my-radio"
                              value="Premium Funding"
                              name="premiumFunding"
                            >
                              Premium Funding
                            </b-form-radio>
                          </div>
                          <b-form-invalid-feedback :state="paymentOptionsValid"
                            >Please select a payment
                            option</b-form-invalid-feedback
                          >
                        </b-form-radio-group>
                      </b-col>
                      <b-col md="6">
                        <b-row>
                          <p class="sideNotes">
                            <b>Include in Finance</b> - Please include the cost
                            of the insurance in the financed amount, to be
                            repaid with my regular lease payments.
                          </p>
                        </b-row>
                      </b-col>
                    </b-row>

                    <div class="mt-2 animated fadeIn" v-show="Show_Financier">
                      <b-row class="mb-2">
                        <span class="financierMessage" style="margin-left:15px">
                          Mandatory information to complete invoice and policy
                          cover.
                        </span>
                      </b-row>

                      <b-row>
                        <b-col md="6">
                          <base-input name="Financier" label="Financier">
                            <el-select
                              placeholder="Type to search"
                              v-model="model.financier"
                              filterable
                              @change="readFinancier($event)"
                            >
                              <el-option
                                v-for="option in financiers"
                                :key="option.label"
                                :label="option.label"
                                :value="option.value"
                              >
                              </el-option>
                            </el-select>
                          </base-input>
                        </b-col>
                        <b-col md="6">
                          <base-input
                            name="Financier - Other"
                            Placeholder="Select 'Other' to activate this field"
                            v-bind:disabled="financierOtherDisabled"
                            @input="validateFinancier"
                            label="Financier - Other"
                            v-model="model.financierOther"
                          />
                        </b-col>
                        <div
                          v-show="!financierValid"
                          class="my-invalid-feedback mt--3 mb-4"
                        >
                          Please select or type a financier
                        </div>
                      </b-row>
                    </div>
                    <b-row class="animated fadeIn" v-show="Show_Financier">
                      <b-col>
                        <div>
                          <label for="textarea" class="form-label"
                            >Equipment Serial number(s) / Identification
                            Code(s)</label
                          >
                          <textarea
                            v-bind:class="
                              serialsValid
                                ? 'form-control'
                                : 'form-control is-invalid'
                            "
                            rows="2"
                            id="textarea"
                            placeholder="Please enter Equipment Serial number(s) / Identification Code(s)"
                            @input="validateTextarea"
                            name="Equipment Serial number(s) / Identification Code(s)"
                            label="Equipment Serial number(s) / Identification Code(s)"
                            v-model="model.Equipment_serial_numbers"
                          ></textarea>
                          <div class="invalid-feedback">
                            Please enter Equipment Serial number(s) /
                            Identification Code(s)
                          </div>
                        </div>

                        <!-- <base-input
                          placeholder="Please enter Equipment Serial number(s) / Identification Code(s)"
                          :rules="serialsRequired"
                          name="Equipment Serial number(s) / Identification Code(s)"
                          label="Equipment Serial number(s) / Identification Code(s)"
                          v-model="model.Equipment_serial_numbers"
                        /> -->
                      </b-col>
                    </b-row>

                    <h2 class="mt-3">THINGS YOU NEED TO KNOW</h2>
                    <div>
                      <b-row>
                        <b-col md="6" class="bordered-table-left">
                          <h4>Your duty of disclosure</h4>
                          <p>
                            Before you enter into an insurance contract, you
                            have a duty to tell the insurer anything that you
                            know, or could reasonably be expected to know, as
                            this may affect the insurer’s decision to insure you
                            and on what terms
                          </p>
                          <p>
                            You have this duty until the insurer agrees to
                            insure you. You have the same duty before you renew,
                            extend, vary or reinstate an insurance contract.
                          </p>
                          <p>
                            You do not need to tell the insurer anything that:
                            <li>reduces the risk you are insured for; or</li>
                            <li>is of common knowledge; or</li>
                            <li>
                              the insurer knows (or should know) as an insurer;
                              or
                            </li>
                            <li>insurer has waived the duty of disclosure.</li>
                          </p>
                          <h4>If you do not tell the insurer something</h4>
                          <p>
                            If you do not tell the insurer anything you are
                            required to, the insurer may cancel your insurance
                            contract or reduce the amount the insurer will pay
                            you if you make a claim, or both.
                          </p>
                          <p>
                            If your failure to tell the insurer is fraudulent,
                            the insurer may refuse to pay a claim and treat the
                            insurance contract as if it never existed.
                          </p>
                          <h4>Code of Practice and Complaints</h4>
                          <p>
                            Your Insurance Pty Ltd subscribes to the Insurance
                            Brokers Code of Practice which sets out the minimum
                            service standards that you can expect from us. If
                            you have any complaints or disputes about our
                            services, you can contact our Customer Support.
                          </p>
                          <p>
                            If we are unable to resolve your dispute or you are
                            not satisfied with the outcome, you can lodge a
                            dispute with
                            <a target="_blank" href="http://www.fos.org.au/">
                              FOS
                            </a>
                            online or by telephone at
                            <a href="tel:1300 367 287">1300 367 287</a>. The
                            Financial Ombudsman Service offers a free of charge
                            and independent external dispute resolution service.
                          </p>
                        </b-col>
                        <b-col md="6" class="bordered-table-right">
                          <b-row>
                            <b-col>
                              <h4 class="text-center">Policy Limits</h4>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="6">
                              <h4>Sum Insured</h4>
                            </b-col>
                            <b-col md="6">
                              <p>
                                The sum Insured or Agreed Value of the
                                equipment, as Identified in the Policy Schedule
                              </p>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="6">
                              <h4>Damage to Lifted Goods</h4>
                            </b-col>
                            <b-col md="6">
                              <p>$10,000 in the aggregate per year</p>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="6">
                              <h4>Expediting Costs</h4>
                            </b-col>
                            <b-col md="6">
                              <p>
                                20% of the applicable Sum Insured and $10,000,
                                whichever is lesser
                              </p>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="6">
                              <h4>Additional Costs</h4>
                            </b-col>
                            <b-col md="6">
                              <p>
                                3 months hire of equivalent equipment and
                                $25,000 per annum, whichever is lesser
                              </p>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="6">
                              <h4>Extra Costs of Reinstatement</h4>
                            </b-col>
                            <b-col md="6">
                              <p>
                                5% of the applicable Sum Insured and $10,000 per
                                annum, whichever is lesser
                              </p>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="6">
                              <h4>Recovery Costs (no damage)</h4>
                            </b-col>
                            <b-col md="6">
                              <p>$5,000 in the aggregate per annum</p>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="6">
                              <h4>Employee’s Property Damage</h4>
                            </b-col>
                            <b-col md="6">
                              <p>$1,000 per occurrence</p>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="6">
                              <h4>Emergency Services Charge</h4>
                            </b-col>
                            <b-col md="6">
                              <p>$2,500 per occurrence</p>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="6">
                              <h4>Tools of Trade</h4>
                            </b-col>
                            <b-col md="6">
                              <p>$10,000 in the aggregate per annum</p>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="6">
                              <h4>Locks and Keys</h4>
                            </b-col>
                            <b-col md="6">
                              <p>
                                $2,500 per machine up to a maximum of $10,000 in
                                the aggregate per annum
                              </p>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col md="6">
                              <h4>Excess</h4>
                            </b-col>
                            <b-col md="6">
                              <p>
                                1% of the initial purchase price of the Insured
                                Item subject to a maximum of $2,500 each and
                                every claim.
                              </p>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </form>
              </validation-observer>

              <h2>DECLARATION</h2>

              <div>
                <p>
                  I/we accept the offer of Insurance contained within this
                  document and as provided by the Life of Lease Plant &
                  Equipment Insurance Policy. We understand that the Total Cost
                  of the Insurance, incorporating the Premium, Government Stamp
                  Duty and GST will be either included within the amount funded
                  under the Lease or by separate Premium Funding throughnpmout
                  the Life of the Lease.
                </p>

                <p>
                  This policy does not extend to, and ABC Underwriters does not
                  intend to insure “consumers” as defined in the National Credit
                  Code being Schedule 1 of the National Consumer Credit
                  Protection Act, 2009 (Cth). Should it transpire at any time
                  during the currency of the policy the insured is a “consumer”
                  then, subject to law and any other provisions within this
                  policy, the cover under the Policy will be cancelled from
                  Inception and the insurer will provide a full refund of the
                  premium.
                </p>

                <p>
                  By accepting this “Offer of Insurance” you confirm that the
                  Insured is not a Natural Person or Strata Corporation and that
                  the credit contract relating to the Insured Items under this
                  Policy was not entered into by a Natural Person or Strata
                  Corporation. You further confirm that the Insured Items under
                  this Policy for which the credit is provided are not intended
                  for use wholly or predominantly for your own personal,
                  domestic or household purposes; to purchase, renovate or
                  improve your own residential property for investment purposes;
                  or to refinance credit that has been provided wholly or
                  predominantly to purchase, renovate or improve your own
                  residential property for investment purposes.
                </p>
                <h4 class="mt-4">
                  Name: {{ this.model.contactFName }}
                  {{ this.model.contactLName }}
                </h4>
              </div>
              <b-form-checkbox class="my-checkbox" v-model="acceptedCheckbox">
                I confirm my agreement to accept the Offer of Insurance and
                Confirm that I have read, understand and accept the Declaration
                above. Status of the Offer of Insurance is accepted.
              </b-form-checkbox>
              <base-button
                :disabled="!acceptedCheckbox"
                @click="AcceptOffer()"
                class="mt-7 mt-md-5 btn-danger"
                block
                type="danger"
                >Accept Offer</base-button
              >
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="justify-content-center" v-if="quoteAccepted">
        <b-col lg="12" md="12">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-muted mb-4">
                <div style="text-align: center;">
                  <img class="logo_image" :src="model.insurance_broker_logo" />
                </div>
                <h1>Thank you for accepting the Your Insurance offer.</h1>
                <div>
                  The Certificate of Currency is being sent to your email. The
                  invoice and policy schedule is being generated and will be
                  sent to you.
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-if="showForm == 'invalid'" class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="12" md="12">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5 bg-white">
              <div class="text-muted mb-4">
                <div style="text-align: center;">
                  <img
                    v-if="quoteAccepted"
                    class="logo_image"
                    :src="model.insurance_broker_logo"
                  />
                  <img
                    v-if="!quoteAccepted"
                    class="logo_image"
                    src="../assets/logo_full.png"
                  />
                </div>
                <div class="text-center" v-if="quoteAccepted">
                  <h1>
                    This Your Insurance offer has previously been accepted.
                  </h1>
                  <p>
                    Please contact us at
                    <a href="support@yourinsurancehq.com.au"
                      >support@yourinsurancehq.com.au</a
                    >
                    for more information.
                  </p>
                </div>
                <div class="text-center" v-if="!quoteAccepted">
                  <h1>Sorry, the link is invalid</h1>
                  <p>
                    This link is no longer valid. Please contact us at
                    <a href="support@yourinsurancehq.com.au"
                      >support@yourinsurancehq.com.au</a
                    >
                    for more information.
                  </p>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { dropDowns } from "../assets/data/dropdown_values";
import { quoteFormMethods } from "../helpers/quoteForm";
import BaseInput from "../components/Inputs/BaseInput.vue";
import { Select, Option } from "element-ui";
import { emailMethods } from "../helpers/email";
import { toasts } from "../helpers/toasts";
import { GlobalStore } from "../globalStore";

export default {
  data() {
    return {
      showForm: "loading",
      paymentOptionsValid: false,
      financierValid: false,
      serialsValid: false,
      acceptedCheckbox: false,
      quoteAccepted: false,
      financierOtherDisabled: null,
      Show_Financier: false,
      equipmentConditions: dropDowns.equipmentConditions,
      usedOnPublicRoadOptions: dropDowns.usedOnPublicRoadOptions,
      financiers: dropDowns.financiers,
      rawModel: null,
      model: {
        type: Object,
        default: {
          policyStart: "",
          insuredName: "",
          insuredABN: "",
          businessDesc: "",
          contactFName: "",
          contactLName: "",
          contactEmail: "",
          contactPhone: "",
          insuredAddress: "",
          insuredStreet: "",
          insuredCity: "",
          insuredState: null,
          insuredPostcode: "",
          equipmentDescription: "",
          equipmentValue: 0,
          equipmentCondition: "",
          operatingArea: "",
          usedOnPublicRoad: "",
          insurancePeriod: "12 Months",
          financier: "",
          financierOther: "",
          Customer_policy_insurance_total_premium: null,
          Customer_policy_insurance_premium: null,
          Customer_policy_insurance_premium_gst: null,
          Customer_policy_insurance_premium_stamp_duty: null,
          Customer_policy_fee: null,
          Customer_policy_status: null,
          Payment_option: "",
          Equipment_serial_numbers: "",
          insurance_broker_logo: ""
        }
      }
    };
  },
  methods: {
    validateTextarea() {
      if (this.model.Equipment_serial_numbers.trim() == "") {
        this.serialsValid = false;
      } else {
        this.serialsValid = true;
      }
    },
    validateFinancier() {
      if (this.model.financier == "Other") {
        this.financierValid = this.model.financierOther.trim() != "";
      } else {
        this.financierValid = true;
      }
    },

    paymentChooser() {
      if (!this.model.Payment_option == "") {
        this.Show_Financier = true;
      }

      if (
        this.model.Payment_option == "Finance" ||
        this.model.Payment_option == "Credit Card" ||
        this.model.Payment_option == "Premium Funding"
      ) {
        this.paymentOptionsValid = true;
      }
    },
    async AcceptOffer() {
      const isValid = await this.$refs.formValidator.validate();
      const validPaymentOptions =
        this.paymentOptionsValid && this.serialsValid && this.financierValid;

      if (!isValid || !validPaymentOptions) {
        toasts.success(
          "Please complete the required fields before proceeding."
        );
      } else {
        this.rawModel.Customer_policy_status = "offer_accepted";

        var Realm = window.Realm;
        const config = {
          id: GlobalStore.realm_app_id
        };
        // const app = new Realm.App(config);

        // const credentials = Realm.Credentials.emailPassword(
        //   "donotdelete@user.com",
        //   "Password1!"
        // );
        // const user = await app.logIn(credentials);

        console.log(app);

        var updatedQuote = await quoteFormMethods.updateQuote(
          app,
          this.rawModel,
          this.model
        );

        emailMethods.sendCustomerWelcomeEmail(app, updatedQuote);
        emailMethods.sendInsureInfoEmail(app, updatedQuote);
        if (this.model.Payment_option == "Premium Funding") {
          emailMethods.sendPremiumFundingEmail(app, updatedQuote);
        }

        this.quoteAccepted = true;
      }
    },
    readFinancier(event) {
      console.log(event);
      if (event == "Other") {
        if (this.model.financierOther.trim() == "") {
          this.financierValid = false;
        }
        this.financierOtherDisabled = false;
      } else {
        this.financierOtherDisabled = true;
        this.model.financierOther = "";
        this.financierValid = true;
      }
    }
  },
  computed: {
    formValid() {
      // acceptedCheckbox
      return this.acceptedCheckbox;
    }
  },
  async mounted() {
    document.body.style.backgroundColor = "#98B8CF";
    try {
      var respObj = await quoteFormMethods.getQuoteFromGuid(
        this.$route.params.id
      );

      // console.log(respObj);

      if (respObj.rawModel.Customer_policy_status != "offer_accepted") {
        this.showForm = "valid";
        this.model = respObj.respModel;
        this.rawModel = respObj.rawModel;
        this.model.Payment_option = "";
        this.paymentChooser();
        this.validateFinancier();
        this.readFinancier(this.model.financier);
      } else {
        console.log("else");

        this.showForm = "invalid";
        this.quoteAccepted = true;
      }
    } catch (err) {
      this.showForm = "invalid";
    }
  },
  components: {
    BaseInput,
    [Select.name]: Select,
    [Option.name]: Option
  }
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
li,
p {
  color: black !important;
}
img {
  width: 350px;
  height: auto;
}

.sideNotes {
  font-size: 0.9rem;
  margin-top: 28px;
}

.financierMessage {
  color: red;
  font-style: italic;
}

@media (max-width: 767px) {
  .bordered-table-left {
    border-style: solid;
    border-width: 1px 1px 0px 1px;
    padding: 5px;
  }

  .bordered-table-right {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    padding: 5px;
  }

  .bordered-table-right .row {
    border-style: solid !important;
    border-width: 0px 0px 1px 0px !important;
    margin: 0px -5px 0px -5px;
    padding: 0px;
  }

  .bordered-table-right .row:last-child {
    border-style: none !important;
  }
}

@media (min-width: 767px) {
  .bordered-table-left {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    padding: 5px;
  }

  .bordered-table-right {
    border-style: solid;
    border-width: 1px 1px 1px 0px;
    padding: 5px;
  }

  .bordered-table-right .row {
    border-style: solid !important;
    border-width: 0px 0px 1px 0px !important;
    margin: 0px -5px 0px -5px;
    padding: 0px;
  }

  .bordered-table-right .row:last-child {
    border-style: none !important;
  }

  .my-radio > ::after {
    border: 1px solid var(--primary);
    border-radius: 100%;
  }

  .my-checkbox > ::after {
    border: 1px solid var(--primary);
    border-radius: 25%;
  }

  .my-invalid-feedback {
    color: #fb6340;
    width: 100%;
    font-size: 80%;
    text-align: center;
  }
}
@media (max-width: 480px) {
  .logo_image {
    width: 300px;
    image-rendering: -webkit-optimize-contrast;
  }
}

/* .multiselect__option--highlight:after{
  display: none
}
.multiselect__option--selected:after{
  display: none
}
.multiselect__option--selected.multiselect__option--highlight:after{
  display: none
} */
</style>
