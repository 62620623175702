<template>
  <div>
    <!-- Header -->
    <div class="header py-7 py-lg-4 pt-lg-9"></div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <img
        v-if="brokerObj"
        :src="getLogo"
        class="navbar-brand-img"
        alt="Sidebar logo"
        style="padding-bottom: 30px; width: 230px; padding-left: 15px;"
      />
      <div>
        <base-header class="pb-6 bg-primary">
          <b-row class="align-items-center py-4">
            <b-col> </b-col>
          </b-row>
        </base-header>
        <div class="px-0 px-sm-3 mt--6">
          <b-card no-body class="mb-4 px-0 overflow-hidden">
            <div id="ubind-iframe"></div>
          </b-card>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { quoteFormMethods } from "../helpers/quoteForm";
import { GlobalStore } from "../globalStore";
import { appFunctions } from "../helpers/appFunctions";

export default {
  name: "ContinueQuote",
  props: {
    productType: {
      type: String,
      required: true,
      validator: value =>
        ["ride-protect", "mercurien-light-commercial"].includes(value)
    }
  },
  data() {
    return {
      ubindQuoteId: "",
      isReady: false,
      htmlString: "",
      brokerObj: null
    };
  },
  computed: {
    getLogo() {
      return GlobalStore.brokerObj.parentOrg.parentInsuranceBroker
        .insurance_broker_logo;
    },
    getQuoteId() {
      return this.ubindQuoteId;
    },
    getProduct() {
      return this.productType === "ride-protect"
        ? GlobalStore.ubindClientInfo.rideShareProduct
        : GlobalStore.ubindClientInfo.product;
    },
    getTenant() {
      return GlobalStore.ubindClientInfo.tenantId;
    },
    getEnvironment() {
      return GlobalStore.ubindClientInfo.environment;
    }
  },
  async mounted() {
    const appconfig = await appFunctions.lookupGetConfig();

    GlobalStore.ubindClientInfo.organisationId =
      appconfig.ubindInfo.organisationId;
    GlobalStore.ubindClientInfo.environment = appconfig.ubindInfo.environment;
    GlobalStore.ubindClientInfo.tenantId = appconfig.ubindInfo.tenantId;
    GlobalStore.ubindClientInfo.product = appconfig.ubindInfo.product;
    GlobalStore.ubindClientInfo.rideShareProduct =
      appconfig.ubindInfo.rideShareProduct;

    // put this in to handle link from ubind generated save email
    // there is also some logic in the main.js to handle this.
    if (this.$route.params.id == "ubind") {
      const ubindId = this.$route.query["id"];

      if (this.productType === "ride-protect") {
        quoteFormMethods.getRSQuoteFromUbindGuid(ubindId).then(resp => {
          document.location.href =
            "/rideShare/continueQuote/" + resp.quoteData.Customer_policy_guid;
        });
      } else {
        quoteFormMethods.getLCQuoteFromUbindGuid(ubindId).then(resp => {
          document.location.href =
            "/lightCommercial/continueQuote/" +
            resp.quoteData.Customer_policy_guid;
        });
      }
    } else {
      const quoteRes =
        this.productType === "ride-protect"
          ? await quoteFormMethods.getRSQuoteFromGuid(this.$route.params.id)
          : await quoteFormMethods.getLCQuoteFromGuid(this.$route.params.id);

      this.ubindQuoteId = quoteRes.quoteData.Ubind_quote_id;

      GlobalStore.brokerObj = quoteRes.brokerData;
      this.brokerObj = quoteRes.brokerData;

      let embedOptions = {
        tenant: GlobalStore.ubindClientInfo.tenantId,
        //organisation: appconfig.ubindInfo.organisationId,
        product:
          this.productType === "ride-protect"
            ? GlobalStore.ubindClientInfo.rideShareProduct
            : GlobalStore.ubindClientInfo.product,
        environment: appconfig.ubindInfo.environment,
        quoteId: this.ubindQuoteId
      };

      let embedElement = document.getElementById("ubind-iframe");

      _uBindInjector.loadWebFormElement(embedElement, embedOptions);
    }

    console.log(GlobalStore.ubindClientInfo);
  }
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
li,
p {
  color: black !important;
}
img {
  width: 350px;
  height: auto;
}

.sideNotes {
  font-size: 0.9rem;
  margin-top: 28px;
}

.financierMessage {
  color: red;
  font-style: italic;
}

@media (max-width: 767px) {
  .bordered-table-left {
    border-style: solid;
    border-width: 1px 1px 0px 1px;
    padding: 5px;
  }

  .bordered-table-right {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    padding: 5px;
  }

  .bordered-table-right .row {
    border-style: solid !important;
    border-width: 0px 0px 1px 0px !important;
    margin: 0px -5px 0px -5px;
    padding: 0px;
  }

  .bordered-table-right .row:last-child {
    border-style: none !important;
  }
}

@media (min-width: 767px) {
  .bordered-table-left {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    padding: 5px;
  }

  .bordered-table-right {
    border-style: solid;
    border-width: 1px 1px 1px 0px;
    padding: 5px;
  }

  .bordered-table-right .row {
    border-style: solid !important;
    border-width: 0px 0px 1px 0px !important;
    margin: 0px -5px 0px -5px;
    padding: 0px;
  }

  .bordered-table-right .row:last-child {
    border-style: none !important;
  }

  .my-radio > ::after {
    border: 1px solid var(--primary);
    border-radius: 100%;
  }

  .my-checkbox > ::after {
    border: 1px solid var(--primary);
    border-radius: 25%;
  }

  .my-invalid-feedback {
    color: #fb6340;
    width: 100%;
    font-size: 80%;
    text-align: center;
  }
}
@media (max-width: 480px) {
  .logo_image {
    width: 300px;
    image-rendering: -webkit-optimize-contrast;
  }
}

.ubind-product {
  margin: 0px 15px 0px 15px;
}
.container {
  padding-left: 0px;
  padding-right: 0px;
}

.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
