<template>
  <Layout title="Rideshare Claims">
    <template v-slot:action>
      <base-button size="md" type="uBind" @click="newClaim"
      ><i class="fas fa-plus pr-2"></i>New Claim</base-button
      ></template
    >
    <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
      <div>
        <b-col
          cols="12"
          class="d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <el-select
            class="select-primary pagination-select mb-3"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>

          <!-- Search bar -->
          <div>
            <base-input
              v-model="searchQuery"
              prepend-icon="fas fa-search"
              placeholder="Search..."
              v-on:input="resetPagination()"
            >
            </base-input>
          </div>
        </b-col>
        <el-table
          :data="queriedData"
          row-key="id"
          :default-sort="{
            prop: 'createdDate',
            order: 'descending'
          }"
          header-row-class-name="thead-light"
          @sort-change="sortChange"
          @selection-change="selectionChange"
          v-loading="loading"
        >
          <el-table-column
            label="Created Date"
            prop="createdDate"
            min-width="180px"
            :formatter="
              row => (row.createdDate ? formatDateTime(row.createdDate) : '')
            "
            sortable
          >
            <!-- <template slot-scope="scope">
                  <span class="date">{{
                    formatDateTime(scope.row.submittedDate)
                  }}</span>
                </template> -->
          </el-table-column>

          <el-table-column
            label="Policy No."
            min-width="170px"
            prop="policyNumber"
            sortable
          >
          </el-table-column>

          <el-table-column
            label="Insured Name"
            prop="insuredName"
            min-width="190px"
            sortable
          >
          </el-table-column>

          <el-table-column
            label="Submitted By"
            prop="createdByName"
            min-width="180px"
            sortable
          >
          </el-table-column>

          <el-table-column min-width="140px" align="left" label="Claim Details">
            <div slot-scope="{ row }">
              <base-button
                @click.native="downloadPDF(row)"
                class="edit"
                type="danger"
                size="sm"
                icon
                v-b-tooltip.hover
                title="Download PDF"
              >
                <i class="fas fa-file-download"></i>
              </base-button>
            </div>
          </el-table-column>

          <template #empty>
            <p>{{ loading ? "" : "No Data" }}</p>
          </template>
        </el-table>
      </div>
      <div
        slot="footer"
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <div class="">
          <p class="card-category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

            <span v-if="selectedRows.length">
              &nbsp; &nbsp; {{ selectedRows.length }} rows selected
            </span>
          </p>
        </div>
        <base-pagination
          class="pagination-no-border"
          :value="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
          @change="paginationChanged($event)"
        >
        </base-pagination>
      </div>
    </card>
  </Layout>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { BasePagination } from "@/components";
import clientPaginationMixin from "../../components/PaginatedTables/clientPaginationMixin";
import { GlobalStore } from "../../globalStore";
import { formattingMethods } from "../../helpers/formatting";
import { clientPDF } from "../../helpers/clientPdf";
import { appFunctions } from "../../helpers/appFunctions";
import Layout from "./components/Layout.vue";

export default {
  data() {
    return {
      propsToSearch: ["insuredName"],
      tableData: [],
      selectedRows: [],
      loading: false
    };
  },
  methods: {
    downloadPDF(rowData) {
      const doc = clientPDF.generateClaimPdf(rowData);
      doc.save("claim-details.pdf");
    },
    capitalizeFirstLetter(string) {
      return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
    },
    newClaim() {
      this.$router.push("submitClaim");
    },

    paginationChanged(page) {
      this.pagination.currentPage = page;
    },

    formatDate(dateVal) {
      return formattingMethods.formatDate(dateVal);
    },

    formatDateTime(dateVal) {
      return formattingMethods.formatDateTime(dateVal);
    },

    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },

    getClaimsData() {
      this.loading = true;
      appFunctions
        .crud_customerRSClaim("listByRealmId", {
          Realm_user_id: GlobalStore.brokerObj.Realm_user_id
        })
        .then(resp => {
          let { data } = resp;

          data.forEach(q => {
            // Formatting number values.
            q.createdByName = `${q.reporterFName} ${q.reporterLName}`;
            q.createdDate = new Date(q.createdDate);
          });

          this.tableData = data;

          this.initFuse([
            "policyNumber",
            "reporterFName",
            "reporterLName",
            "insuredName"
          ]);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.getClaimsData();
  },
  mixins: [clientPaginationMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Layout
  }
};
</script>
