<template>
  <section>
    <base-button
      :loading="loading"
      @click="callCustomer"
      type="uBind"
      style="float:right;"
      class="mt-2 mt-sm-0 mb-4"
      ><i class="fas fa-phone pr-3"></i>Reduce your Customer’s Costs - Request
      Call back</base-button
    >
  </section>
</template>

<script>
export default {
  name: "CallButton",
  props: {
    callCustomer: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
section {
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
