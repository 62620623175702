<template>
  <div class="bg-white rounded p-3" style="height: 30em">
    <h3 class="font-weight-bolder">Products Market Share</h3>
    <pie-chart :chartData="pieChartData" style="position: relative; height:32vh; width:25vw" />
    <hr />
    <div class="float-right float-xl-left">
      <div v-for="(product, index) in products" :key="index">
        <p class="mt--3">
          <i class="fa fa-square" aria-hidden="true" v-bind:style="{ color: product.color }"></i>
          {{ product.label }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from "../../components/Charts/PieChart.js";

export default {
  name: "product-pie-chart",
  props: {
    dataChart: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      products: [
        {
          label: "Light Commercial",
          color: "#98b8cf	"
        },
        {
          label: "Rideshare",
          color: "#475762"
        }
      ],
      pieChartData: {}
    };
  },
  watch: {
    dataChart() {
      this.initChart()
    }
  },
  methods: {
    async initChart() {
      const customeLC = _.filter(this.dataChart, function(item) {return item.product === 'LC';});
      const customeRS = _.filter(this.dataChart, function(item) {return item.product === 'RS';});
      let chartData = {
        datasets: [
          {
            backgroundColor: [
              "#98b8cf",
              "#475762"
            ],
            hoverOffset: 4,
            data: [customeLC.length, customeRS.length]
          }
        ],
        labels: ["Light Commercial", "Rideshare"]
      };
      this.pieChartData = chartData;
    }
  },
  components: {
    PieChart
  }
};
</script>

<style scoped></style>
