import Vue from "vue";

export const GlobalStore = new Vue({
  data: {
    version: "1.8.2",
    realm_app_id: "application-yihq-dev-cfqup",
    //realm_app_id: "application-plant-prod-aogcb",
    realmApp: null,
    brokerObj: null,
    PDFCreated: false,
    siteURL: "",
    ////siteURL: "https://partner.yourinsurancehq.com.au",
    passkey:
      "a6395a8b-e731-42f2-b3e0-43a9d964f32f-a56d3c79-f5bb-4212-bb8d-7e6c3cb5f3d6",
      notification:  null,
    ubindClientInfo: {
      tenantId: "",
      organisationId: "",
      environment: "",
      product: "",
      api_endpoint: ""
    }
  }
});
