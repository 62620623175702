import Vue from "vue";

export const claimFormMethods = new Vue({
  methods: {
    getFormRules(status) {
      switch (status) {
        case "edit":
          return {
            insuredName: "required",
            policyNumber: "required",
            policyholderStreet: "required",
            policyholderCity: "required",
            policyholderState: "required",
            policyholderPostcode: "required",
            dateOfLoss: "required",
            reporterFName: "required",
            reporterLName: "required",
            timeOfLoss: "required",
            incidentDetail: "required",
            incidentStreet: "required",
            incidentCity: "required",
            incidentState: "required",
            incidentPostcode: "required",
            policeReportRef: "required",
            policeReportDetails: "required",
            additionalContactType: "required",
            additionalContactFName: "required",
            additionalContactLName: "required"
          };
        default:
          return {
            insuredName: "required",
            policyNumber: "required",
            policyholderStreet: "required",
            policyholderCity: "required",
            policyholderState: "required",
            policyholderPostcode: "required",
            dateOfLoss: "required",
            reporterFName: "required",
            reporterLName: "required",
            timeOfLoss: "required",
            incidentDetail: "required",
            incidentStreet: "required",
            incidentCity: "required",
            incidentState: "required",
            incidentPostcode: "required",
            policeReportRef: "required",
            policeReportDetails: "required",
            additionalContactType: "required",
            additionalContactFName: "required",
            additionalContactLName: "required"
          };
      }
    },
    getDisabledFields(mode, currentStatus) {
      return {
        insuredName: false,
        policyNumber: false,
        contactName: true,
        contactPhone: true,
        contactEmail: true,
        policyholderStreet: true,
        policyholderCity: true,
        policyholderState: true,
        policyholderPostcode: true,
        dateOfLoss: false,
        detailsPolicyNumber: true,
        registeredForGST: false,
        insuredITC: false,
        abn: true,
        brokerageName: true,
        reporterFName: true,
        reporterLName: true,
        reporterPhone: true,
        reporterEmail: true,
        incidentDateOfLoss: true,
        timeOfLoss: false,
        incidentDetail: false,
        lossEstimate: false,
        incidentStreet: false,
        incidentCity: false,
        incidentState: false,
        incidentPostcode: false,
        policeReportRef: false,
        policeReportDetails: false,
        bankAccountName: false,
        bankBSB: false,
        bankAccountNumber: false,
        bankName: false,
        additionalContact: false,
        additionalContactType: false,
        additionalContactFName: false,
        additionalContactLName: false,
        additionalContactTelephone: false,
        additionalContactMobile: false,
        additionalContactEmail: false,
        additionalContactPreferredContact: false
      };
    }
  }
});
