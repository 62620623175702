<template>
  <b-container fluid class="mt--7">
    <b-row>
      <b-col xl="12">
        <card>
          <b-row>
            <b-col :xl="windowWidth >= 1200 && windowWidth < 1300 ? 4 : 3" lg="4" md="5" class="p-1">
              <span class="text">Settings Time: </span>
              <el-dropdown trigger="click" @command="handleCommand">
                <el-button>
                  {{ activeName }}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="Year">Year</el-dropdown-item>
                  <el-dropdown-item command="Month">Month</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </b-col>
            <b-col :xl="windowWidth >= 1200 && windowWidth < 1300 ? 5 : 4" lg="5" md="6" class="p-1">
              <span class="text">Calendar: </span>
              <el-date-picker class="mobile-calendar" v-if="activeIndex === 0" v-model="year" type="year" align="right" :clearable="false"
                :picker-options="pickerOptions" default-time="2021" placeholder="Pick a year">
              </el-date-picker>
              <el-date-picker class="mobile-calendar" v-if="activeIndex === 1" v-model="month" type="month" align="right" :clearable="false"
                :picker-options="pickerOptions" placeholder="Pick a month">
              </el-date-picker>
            </b-col>
            <b-col :xl="2" lg="2" md="12" class="layout p-1">
              <button @click="getData()" class="btn base-button btn-danger submit">Submit</button>
            </b-col>
          </b-row>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { Button, DatePicker, Dropdown, DropdownItem, DropdownMenu } from "element-ui";
import moment from 'moment';
export default {
  props: {
  },
  name: "menu-bar",
  data() {
    return {
      windowWidth: window.innerWidth,
      month: "",
      year: "",
      activeName: "Month",
      activeIndex: 1,
      pickerOptions: {
        disabledDate(time) {
          return !moment(time.getTime()).isBetween('2022-01-01', Date.now());
        },
      },
    };
  },
  mounted() {
    this.month = Date.now()
    this.year = Date.now()
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    myEventHandler(e) {
      this.windowWidth = window.innerWidth
    },
    getData() {
      let year = null
      let month = null
      if (this.activeIndex === 0) {
        year = moment(this.year).year()
        month = 1 + moment(this.year).month()
      } else {
        year = moment(this.month).year()
        month = 1 + moment(this.month).month()
      }
      this.$emit('getDataChart', year, month, this.activeIndex, this.month)
    },
    handleCommand(command) {
      this.activeName = command
      const index = command === 'Year' ? 0 : 1
      this.activeIndex = index
    }
  },
  components: {
    [DatePicker.name]: DatePicker,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Button.name]: Button
  },
};
</script>

<style scoped>
.text-danger {
  font-size: 2.5em;
}

.border-button {
  border-radius: 20px !important;
  border: 1px solid white;
  background: #f7fafc !important;
}

.active {
  border: 1px solid #ff4438;
}

.font-color {
  color: black;
}

.submit {
  border-radius: 20px;
}

.title {
  text-align: center;
  margin-right: 10px;
  font-weight: 600;
  margin-top: 2px;
}

@media only screen and (min-width: 340px) and (max-width: 1240px) {
  .layout {
    text-align: center;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1459px) {
  .layout {
    text-align: center;
  }
}

@media only screen and (min-width: 414px) and (max-width: 768px) {
  .mobile-calendar {
    margin-left: 30px;
  }
  .mobile-status {
    margin-left: 30px;
  }
  .text {
    font-size: 15px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 414px) {
  .mobile-calendar {
    margin-left: 28px;
  }
  .mobile-status {
    margin-left: 50px;
  }
  .text {
    font-size: 15px;
  }
}
</style>
