<template>
  <QuoteEmailed
    header="Plant &amp; Equipment - Quote Emailed"
    title="Plant &amp; Equipment Insurance"
    bodyTitle="Quote Emailed"
    bodyText="A quote has been emailed to your Customer with a link to
                complete the quotation steps."
    :newQuoteAction="newQuote"
    :viewQuotesAction="viewQuotes"
  />
</template>

<script>
import QuoteEmailed from "../components/QuoteEmailed.vue";

export default {
  methods: {
    newQuote() {
      this.$router.push("/createQuote");
    },
    viewQuotes() {
      this.$router.push("/quotesAndPolicies");
    },
    components: {
      QuoteEmailed
    }
  }
};
</script>
