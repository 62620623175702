<template>
  <Backdrop title="Mercurien Fleet Management Service">
    <b-card no-body class="mb-4 claim-form-container">
      <b-card-header style="padding-bottom: 0px !important;">
        <h1 class="mb-0">
          Mercurien Fleet Management Service
        </h1>
      </b-card-header>
      <b-card-body>
        <b-card class="uBind">
          <b-row>
            <h2 class="secondary-card-header">
              Call Back Requested
            </h2>
          </b-row>
          <b-row>
            <p>
              Thank you for your inquiry. A member of the Mercurien Fleet
              Management Service team will be in contact shortly.
            </p>
          </b-row>
        </b-card>
      </b-card-body>
    </b-card>
  </Backdrop>
</template>

<script>
import Backdrop from "../components/Backdrop.vue";
import CallButton from "./components/CallButton.vue";
import { toasts } from "../../helpers/toasts";

export default {
  name: "FleetManagementServices",
  components: {
    Backdrop,
    CallButton
  },
  mounted() {
    if (this.$route.query.status && this.$route.query.status === "success") {
      toasts.success("Email sent successfully. We will be in touch shortly.");
    }
  }
};
</script>

<style lang="scss" scoped>
h1,
p {
  color: black;
  font-weight: 400;
}

h1 {
  font-size: 45px;
}
</style>
