export const dropDowns = {
    states: [
        { label: "ACT", value: "ACT" },
        { label: "NSW", value: "NSW" },
        { label: "NT", value: "NT" },
        { label: "QLD", value: "QLD" },
        { label: "SA", value: "SA" },
        { label: "VIC", value: "VIC" },
        { label: "WA", value: "WA" },
        { label: "TAS", value: "TAS" }
    ],

    operatingAreas: [
        { label: "ACT", value: "ACT" },
        { label: "NSW", value: "NSW" },
        { label: "NT", value: "NT" },
        { label: "QLD", value: "QLD" },
        { label: "SA", value: "SA" },
        { label: "VIC", value: "VIC" },
        { label: "WA", value: "WA" },
        { label: "TAS", value: "TAS" }
    ],

    equipmentConditions: [
        { label: "New", value: "New" },
        { label: "Used", value: "Used" },
        { label: "Dealer Demo", value: "Dealer Demo" }
    ],

    usedOnPublicRoadOptions: [
        { label: "No", value: "No" },
        {
        label:
            "Yes, the plant / equipment will be conditionally registered for use on road",
        value:
            "Yes, the plant / equipment will be conditionally registered for use on road"
        },
        {
        label:
            "Yes, the plant / equipment will be fully registered for use on road",
        value:
            "Yes, the plant / equipment will be fully registered for use on road"
        }
    ],

    financiers: [
        { label: "Other", value: "Other" },
        { label: "Adelaide Bank", value: "Adelaide Bank" },
        { label: "Advantedge", value: "Advantedge" },
        { label: "AFG Home Loans", value: "AFG Home Loans" },
        { label: "AGC", value: "AGC" },
        { label: "Allianz", value: "Allianz" },
        { label: "Allstate Home Loans", value: "Allstate Home Loans" },
        { label: "American Express", value: "American Express" },
        { label: "AMG", value: "AMG" },
        { label: "AMIC", value: "AMIC" },
        { label: "AMIST", value: "AMIST" },
        { label: "AMP", value: "AMP" },
        { label: "AMS", value: "AMS" },
        { label: "ANZ Bank", value: "ANZ Bank" },
        { label: "ANZ Wealth", value: "ANZ Wealth" },
        { label: "API", value: "API" },
        { label: "Arab Bank of Australia", value: "Arab Bank of Australia" },
        { label: "Armidale Building Society", value: "Armidale Building Society" },
        { label: "Aussie Home Loans", value: "Aussie Home Loans" },
        { label: "Aussie Mortgage Ltd", value: "Aussie Mortgage Ltd" },
        { label: "Aust Alliance", value: "Aust Alliance" },
        { label: "Australia Central Credit Union", value: "Australia Central Credit Union" },
        { label: "Australian Catholic", value: "Australian Catholic" },
        { label: "Australian Defence Credit Union", value: "Australian Defence Credit Union" },
        { label: "Australian Eagle", value: "Australian Eagle" },
        { label: "Australian Ethical", value: "Australian Ethical" },
        { label: "Australian First Mortgage", value: "Australian First Mortgage" },
        { label: "Australian Pensioners Insurance", value: "Australian Pensioners Insurance" },
        { label: "Australian Unity Building Society", value: "Australian Unity Building Society" },
        { label: "AustSafe", value: "AustSafe" },
        { label: "Auswide Bank", value: "Auswide Bank" },
        { label: "AV Super", value: "AV Super" },
        { label: "AVCO", value: "AVCO" },
        { label: "Bank Australia", value: "Bank Australia" },
        { label: "Bank of America", value: "Bank of America" },
        { label: "Bank of China", value: "Bank of China" },
        { label: "Bank of Melbourne", value: "Bank of Melbourne" },
        { label: "Bank of Queensland", value: "Bank of Queensland" },
        { label: "Bank of Tokyo-Mitsubishi", value: "Bank of Tokyo-Mitsubishi" },
        { label: "Bank SA", value: "Bank SA" },
        { label: "Bankers Trust Australia", value: "Bankers Trust Australia" },
        { label: "Bankwest", value: "Bankwest" },
        { label: "Banque Nationale De Paris", value: "Banque Nationale De Paris" },
        { label: "Barclays", value: "Barclays" },
        { label: "Bendigo Bank", value: "Bendigo Bank" },
        { label: "Better Choice", value: "Better Choice" },
        { label: "Better Mortgage Corporation", value: "Better Mortgage Corporation" },
        { label: "Beyond Bank Australia", value: "Beyond Bank Australia" },
        { label: "Bluestone", value: "Bluestone" },
        { label: "BOQ", value: "BOQ" },
        { label: "Broken Hill Community Credit Union", value: "Broken Hill Community Credit Union" },
        { label: "Building Society MP", value: "Building Society MP" },
        { label: "Buyers Edge", value: "Buyers Edge" },
        { label: "Capital Finance", value: "Capital Finance" },
        { label: "Capricornia Credit Union", value: "Capricornia Credit Union" },
        { label: "Catholic Church", value: "Catholic Church" },
        { label: "CBA", value: "CBA" },
        { label: "CBFC", value: "CBFC" },
        { label: "Challenge Bank", value: "Challenge Bank" },
        { label: "Chase Manhattan Bank", value: "Chase Manhattan Bank" },
        { label: "Chequecash Pty Ltd", value: "Chequecash Pty Ltd" },
        { label: "Christian Super", value: "Christian Super" },
        { label: "Circle Credit Union", value: "Circle Credit Union" },
        { label: "CIT Credit Pty", value: "CIT Credit Pty" },
        { label: "Citibank", value: "Citibank" },
        { label: "City Coast Credit Union", value: "City Coast Credit Union" },
        { label: "Coastline Credit Union", value: "Coastline Credit Union" },
        { label: "Commonwealth Bank", value: "Commonwealth Bank" },
        { label: "Connective Home Loans", value: "Connective Home Loans" },
        { label: "CPS Credit Union", value: "CPS Credit Union" },
        { label: "Credit Union Australia", value: "Credit Union Australia" },
        { label: "Credit Union SA", value: "Credit Union SA" },
        { label: "CUA", value: "CUA" },
        { label: "Custom Credit", value: "Custom Credit" },
        { label: "Daimlerchrysler Finance", value: "Daimlerchrysler Finance" },
        { label: "Defence Bank", value: "Defence Bank" },
        { label: "Defence Credit", value: "Defence Credit" },
        { label: "Defence Force Credit Union Limited", value: "Defence Force Credit Union Limited" },
        { label: "Defence Services Homes", value: "Defence Services Homes" },
        { label: "Deutsche Bank", value: "Deutsche Bank" },
        { label: "EECU", value: "EECU" },
        { label: "EISS", value: "EISS" },
        { label: "ELCOM Credit Union", value: "ELCOM Credit Union" },
        { label: "Elders", value: "Elders" },
        { label: "Esanda", value: "Esanda" },
        { label: "Essential Super", value: "Essential Super" },
        { label: "Ezy Bank", value: "Ezy Bank" },
        { label: "Flexirent", value: "Flexirent" },
        { label: "Ford Credit", value: "Ford Credit" },
        { label: "GE Capital Finance", value: "GE Capital Finance" },
        { label: "GE Capital Finance (Go Mastercard)", value: "GE Capital Finance (Go Mastercard)" },
        { label: "GE Credit Line", value: "GE Credit Line" },
        { label: "Ge Finance", value: "Ge Finance" },
        { label: "GE Money Aust Pty Ltd", value: "GE Money Aust Pty Ltd" },
        { label: "GE Mortgages", value: "GE Mortgages" },
        { label: "GIO", value: "GIO" },
        { label: "GIO Building Society", value: "GIO Building Society" },
        { label: "GMAC Finance", value: "GMAC Finance" },
        { label: "Great Pacific Financial", value: "Great Pacific Financial" },
        { label: "Greater Building Society", value: "Greater Building Society" },
        { label: "Guild", value: "Guild" },
        { label: "HBF", value: "HBF" },
        { label: "Home Building Society", value: "Home Building Society" },
        { label: "Homeloans Ltd", value: "Homeloans Ltd" },
        { label: "HSBC", value: "HSBC" },
        { label: "Hume Building Society", value: "Hume Building Society" },
        { label: "IBJ Australia Bank", value: "IBJ Australia Bank" },
        { label: "Illawarra Building Society", value: "Illawarra Building Society" },
        { label: "IMB Ltd", value: "IMB Ltd" },
        { label: "Intech Credit Union", value: "Intech Credit Union" },
        { label: "International Acceptance", value: "International Acceptance" },
        { label: "International Commercial Bank of China", value: "International Commercial Bank of China" },
        { label: "IOOF Building Society", value: "IOOF Building Society" },
        { label: "Kinetic", value: "Kinetic" },
        { label: "Keystart Home Loans", value: "Keystart Home Loans" },
        { label: "LaTrobe", value: "LaTrobe" },
        { label: "Liberty", value: "Liberty" },
        { label: "Lion Finance", value: "Lion Finance" },
        { label: "Loanave", value: "Loanave" },
        { label: "Lombard Finance", value: "Lombard Finance" },
        { label: "Macarthur Credit Union", value: "Macarthur Credit Union" },
        { label: "Mackay Permanent Building Society", value: "Mackay Permanent Building Society" },
        { label: "Macquarie Bannk Pty Ltd", value: "Macquarie Bannk Pty Ltd" },
        { label: "Macquarie Leasing Pty Ltd", value: "Macquarie Leasing Pty Ltd" },
        { label: "Maitland Mutual Building Society", value: "Maitland Mutual Building Society" },
        { label: "ME Bank", value: "ME Bank" },
        { label: "Members Equity Bank", value: "Members Equity Bank" },
        { label: "MLC", value: "MLC" },
        { label: "MMI", value: "MMI" },
        { label: "Morgan Guaranty Trust Bank of New York", value: "Morgan Guaranty Trust Bank of New York" },
        { label: "Mortgage Ezy", value: "Mortgage Ezy" },
        { label: "Mortgage House", value: "Mortgage House" },
        { label: "NAB", value: "NAB" },
        { label: "National Mortgage Corporation", value: "National Mortgage Corporation" },
        { label: "Newcastle Permanent Building Society", value: "Newcastle Permanent Building Society" },
        { label: "NGS", value: "NGS" },
        { label: "Northern Building Society", value: "Northern Building Society" },
        { label: "NSW Teachers Credit Union", value: "NSW Teachers Credit Union" },
        { label: "People's Choice Credit Union", value: "People's Choice Credit Union" },
        { label: "Pepper", value: "Pepper" },
        { label: "Pioneer Permanent Building Society", value: "Pioneer Permanent Building Society" },
        { label: "Police Credit Union", value: "Police Credit Union" },
        { label: "Police Credit Union (NSW)", value: "Police Credit Union (NSW)" },
        { label: "Powerstate Credit Union", value: "Powerstate Credit Union" },
        { label: "Primary Industry Bank of Australia", value: "Primary Industry Bank of Australia" },
        { label: "Prospect Credit Union", value: "Prospect Credit Union" },
        { label: "Pulse Credit Union", value: "Pulse Credit Union" },
        { label: "QANTAS Credit Union", value: "QANTAS Credit Union" },
        { label: "Qudos Bank", value: "Qudos Bank" },
        { label: "Queensland Country Credit Union", value: "Queensland Country Credit Union" },
        { label: "Queensland Credit Union", value: "Queensland Credit Union" },
        { label: "Queensland Industry Development Corp", value: "Queensland Industry Development Corp" },
        { label: "Queensland Professional Credit Union", value: "Queensland Professional Credit Union" },
        { label: "Queensland Teachers Credit Union", value: "Queensland Teachers Credit Union" },
        { label: "Railways Credit Union", value: "Railways Credit Union" },
        { label: "RAMS", value: "RAMS" },
        { label: "Repcol", value: "Repcol" },
        { label: "Resi", value: "Resi" },
        { label: "Southern Cross Credit Union", value: "Southern Cross Credit Union" },
        { label: "Sovereign Credit", value: "Sovereign Credit" },
        { label: "St Andrew", value: "St Andrew" },
        { label: "St George Bank", value: "St George Bank" },
        { label: "Standard Chartered Bank Aust", value: "Standard Chartered Bank Aust" },
        { label: "Teachers Credit Union", value: "Teachers Credit Union" },
        { label: "Teachers Mutual", value: "Teachers Mutual" },
        { label: "Territory Mutual Building Society", value: "Territory Mutual Building Society" },
        { label: "Toyota Finance Australia", value: "Toyota Finance Australia" },
        { label: "Trust Bank of Tasmania", value: "Trust Bank of Tasmania" },
        { label: "Victoria Teachers Credit Union Ltd", value: "Victoria Teachers Credit Union Ltd" },
    ],
}
