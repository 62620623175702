<template>
  <div>
    <base-header class="pb-6 bg-primary">
      <b-row class="align-items-center py-4">
        <b-col>
          <base-button @click="goBack()" type="uBind" class="mt-2 mt-sm-0 mb-4"
            ><i class="fas fa-angle-left pr-3"></i>Back</base-button
          >

          <br />
          <h6
            class="h1 text-white header d-inline-block mb-0 fw-500"
            ref="topSection"
          >
            Light Commercial Vehicles - Manage
          </h6>
        </b-col>
      </b-row>
    </base-header>
    <div class="container-fluid mt--6">
      <b-card no-body class="mb-4" style="height: '500px' !important ">
        <div
          class="ubind-portal"
          id="ubindPortal"
          data-tenant-id="mercurien"
          data-organisation="mercurien"
          data-portal-id="yourinsurancehq"
          data-environment="production"
          data-iframeresizer-enabled="true"
        ></div>
        <!-- <div
          v-id="ubindQuoteId"
          class="ubind-product"
          :data-tenant="getTenant"
          :data-product-id="getProduct"
          :data-environment="getEnvironment"
          :data-quoteid="getQuoteId"
        ></div> -->
      </b-card>
    </div>
  </div>
</template>

<script>
import { GlobalStore } from "../../globalStore";
import { emailMethods } from "../../helpers/email";
import { toasts } from "../../helpers/toasts";

export default {
  name: "Step2",
  data() {
    return {
      ubindQuoteId: null
    };
  },
  computed: {
    goBack() {
      this.$router.push("/lightCommercial/quotes");
    },
    getQuoteId() {
      return sessionStorage.getItem("ubindQuoteID");
    },
    getProduct() {
      return GlobalStore.ubindClientInfo.product;
    },
    getTenant() {
      return GlobalStore.ubindClientInfo.tenantId;
    },
    getEnvironment() {
      return GlobalStore.ubindClientInfo.environment;
    }
  },
  methods: {
    async sendEmail() {
      const mostRecentLCPolicyId = sessionStorage.getItem(
        "mostRecentLCPolicyId"
      );

      console.log(mostRecentLCPolicyId);

      const customerDetails = await appFunctions.crud_customerLCPolicy(
        "getCombined",
        {
          policy_id: parseInt(mostRecentLCPolicyId)
        }
      );

      console.log(customerDetails);

      await emailMethods.sendContinueQuoteEmail(
        GlobalStore.realmApp,
        customerDetails.data
      );

      toasts.success(
        `Email has been sent to ${customerDetails.data.Customer_contact_email}`
      );

      this.$router.push("/lightCommercial/quoteEmailed");
    },
    scrollUp() {
      //location.hash = "#calcSection";
      const myEl = this.$refs.topSection;

      this.$smoothScroll({
        scrollTo: myEl,
        hash: "#topSection" // required if updateHistory is true
      });
    }
  },
  mounted() {
    //console.log(window._uBindInjector);
    // this.ubindQuoteId = sessionStorage.getItem("ubindQuoteID");
    //window._uBindInjector.createPortalInjections();
    //window._uBindInjector.detectAndInject();
    // this.scrollUp();
  }
};
</script>

<style lang="scss" scoped>
.top-padding {
  padding-top: 0px !important;
}

.ubind-product {
  margin: 0px 15px 0px 15px;
}
.container-fluid {
  padding-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
</style>
