<template>
  <div class="content">
    <Spinner :isLoading="isLoading" />
    <div v-if="!isCancel">
      <base-header class="pb-6">
        <b-row align-v="center" class="py-4">
          <b-col cols="7" lg="6">
            <h6 class="h1 text-white d-inline-block mb-0 header fw-500">
              Light Commercial Policies
            </h6>
            <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            </nav>
          </b-col>
          <b-col lg="6" cols="5" class="text-right">
            <base-button size="md" type="uBind">
              <a target="_blank" href="https://app.curium.app/public/claim-lodgement/25/1/1/1" class="text-white">
                <i class="fas fa-plus pr-2"></i>Submit Claim</a>
            </base-button>
          </b-col>
        </b-row>
      </base-header>
      <div class="mt--6 px-2 px-sm-4">
        <div>
          <div class="bg-white rounded-bottom change-color">
            <div>
              <b-col cols="12" class="pt-3 d-flex flex-wrap bg-white rounded-top">
                <el-select class="select-primary pagination-select mb-3 mr-3" v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                    :value="item">
                  </el-option>
                </el-select>

                <div class="mr-3">
                  <base-input v-model="searchQuery" prepend-icon="fas fa-search" placeholder="Search..."
                    v-on:input="resetPagination()">
                  </base-input>
                </div>

                <div style="position: absolute; right: 25px;">
                  <base-button type="danger" class="btn-refresh" @click="refreshData()">
                    <b-icon icon="arrow-repeat"></b-icon>
                  </base-button>
                </div>
              </b-col>


              <CustomerDetailsModal :selectedRow="selectedRow" />

              <el-table style="width: 98%" :data="queriedData" row-key="_id" :default-sort="{
                prop: 'Ubind_quote_created_date',
                order: 'descending'
              }" header-row-class-name="thead-light" @sort-change="sortChange" @selection-change="selectionChange"
                v-loading="loading" :row-key="getRowKeys" :expand-row-keys="expands" @expand-change="handleExpand">
                <el-table-column type="expand">
                  <template #default="props">
                    <b-row class="px-5 expandedPolicy">
                      <b-col cols="6" md="9">
                        <b-row>
                          <b-col cols="4">
                            <p>Contact Name:<base-button @click.native="viewCustomerDetails(props.row)" class="edit" type="danger"
                              size="sm" style="margin-left: 10px;padding:5px" v-b-tooltip.hover
                              title="View Customer Details">
                              View Customer Details
                            </base-button></p>
                            <p>Insured Email:</p>
                            <p>Number Of Vehicles (Including Trailers):</p>
                            <p>Business Description:</p>
                            <p>Vehicle Use:</p>
                            <p>Policy Purchased:</p>
                            <p v-if="props.row.Ubind_quote_details_obj.policyTransactionType === 'adjustment'">Policy
                              Adjusted:</p>
                            <p v-if="props.row.Ubind_status === 'Cancelled'">Policy Cancelled:</p>
                            <p v-if="props.row.Ubind_quote_details_obj.policyTransactionType === 'renewal'">Policy
                              Renewal:</p>
                          </b-col>
                          <b-col cols="4">
                            <p>
                              {{ props.row.Customer_contact_first_name }}
                              {{ props.row.Customer_contact_last_name }}
                              
                            </p>
                            <p>{{ props.row.Customer_contact_email }}</p>
                            <p>
                              {{
                                getNumberOfVehicles(props.row.Ubind_quote_details_obj)
                              }}
                            </p>
                            <p>
                              {{
                                checkUbindFields(props.row.Ubind_quote_details_obj, "businessDescription")
                              }}
                            </p>
                            <p>
                              {{
                                checkUbindFields(props.row.Ubind_quote_details_obj, "vehicleUsePurpose")
                              }}
                            </p>
                            <p>
                              The policy was issued effective on {{ props.row.effectiveDateTimeNewPolicy ?
                                formatDatePolicy(props.row.effectiveDateTimeNewPolicy) :
                                formatDatePolicy(props.row.Ubind_quote_last_updated_date) }}
                            </p>
                            <p v-if="props.row.Ubind_quote_details_obj.policyTransactionType === 'adjustment'">
                              The policy was adjusted effective on {{
                                formatDatePolicy(props.row.Ubind_quote_details_obj.adjustmentDateTime) }}
                            </p>
                            <p v-if="props.row.Ubind_status === 'Cancelled'">
                              The policy was issued on {{ formatDatePolicy(props.row.Ubind_quote_last_updated_date) }} {{
                                props.row.Ubind_quote_details_obj.cancellationReason ? `- Reason:
                              ${props.row.Ubind_quote_details_obj.cancellationReason}` : `` }}
                            </p>
                            <p v-if="props.row.Ubind_quote_details_obj.policyTransactionType === 'renewal'">
                              The policy was renewed effective on {{
                                formatPolicyEndDate(props.row.Ubind_quote_details_obj.formDataFormatted.policyStartDate) }}
                            </p>
                          </b-col>
                          
                        </b-row>
                      </b-col>
                      <b-col md="1"></b-col>

                      <b-col cols="6" md="2">
                        <b-row class="pl-2" align-h="center">
                          <b-col cols="auto" md="12" class="mb-4">
                            <base-button v-if="props.row.Ubind_status != 'Cancelled'"
                              @click.native="() => downloadCOC(props.row)" class="edit" type="danger" size="sm" icon
                              v-b-tooltip.hover title="Download PDF">
                              <i class="fas fa-file-download btn-download"><span> Cert of Currency</span></i>
                            </base-button>
                            <base-button v-else
                              @click.native="() => downloadFileDoc(props.row, 'lc-credit-note', 'credit note.pdf')"
                              class="edit" type="danger" size="sm" icon v-b-tooltip.hover title="Download PDF">
                              <i class="fas fa-file-download btn-download"><span> Credit Note</span></i>
                            </base-button>
                          </b-col>
                          <b-col cols="auto" md="12" class="mb-4">
                            <template v-if="props.row.Ubind_status != 'Cancelled'">
                            <base-button 
                              @click.native="() => downloadPS(props.row)" class="edit" type="danger" size="sm" icon
                              v-b-tooltip.hover title="Download PDF">
                              <i class="fas fa-file-download btn-download"><span> Policy Schedule (PDF)</span></i>
                            </base-button><br/><br/>

                            <base-button 
                              v-if="props.row.Customer_policy_id > 2859"
                              @click.native="() => downloadPSDOC(props.row)" class="edit" type="danger" size="sm" icon
                              v-b-tooltip.hover title="Download PDF">
                              <i class="fas fa-file-download btn-download"><span> Policy Schedule (DOCX)</span></i>
                            </base-button>

                          </template>

                            <base-button v-else
                              @click.native="() => downloadFileDoc(props.row, 'lc-cancellation-notice', 'cancellation notice.pdf')"
                              class="edit" type="danger" size="sm" icon v-b-tooltip.hover title="Download PDF">
                              <i class="fas fa-file-download btn-download"><span> Cancellation note</span></i>
                            </base-button>
                          </b-col>
                          <b-col cols="auto" md="12">
                            <base-button v-if="props.row.Ubind_status != 'Cancelled'"
                              @click.native="() => downloadFileDoc(props.row, 'lc-tax-invoice', 'tax invoice.pdf')"
                              class="edit" type="danger" size="sm" icon v-b-tooltip.hover title="Download PDF">
                              <i class="fas fa-file-download btn-download"><span> Tax Invoice</span></i>
                            </base-button>
                          </b-col>
                          <b-col cols="auto" md="12">
                            <base-button
                              v-if="props.row.Ubind_status != 'Cancelled' && isDowloadFleetFile(props.row.Ubind_quote_details_obj)"
                              @click.native="() => downloadFileDoc(props.row, 'lc-schedule-of-vehicles', 'schedule of vehicles.xlsx')"
                              class="edit" type="danger" size="sm" icon v-b-tooltip.hover title="Download XLSX">
                              <i class="fas fa-file-download btn-download"><span> Schedule of Vehicles</span></i>
                            </base-button>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </template>
                </el-table-column>
                <el-table-column min-width="95px" align="center" label="VIEW DOCS & DETAILS">
                  <div slot-scope="{ row }" tabindex="0" v-b-tooltip.hover.righttop title="View Docs and Details">
                    <base-button @click.native="expandRow(row)" class="edit" type="danger" size="sm" icon>
                      <b-icon font-scale="2" icon="files"></b-icon>
                    </base-button>
                  </div>
                </el-table-column>
                <el-table-column v-if="isDisplayAdjustButton" min-width="80px" align="left" label="Adjust">
                  <div slot-scope="{ row }">
                    <base-button @click.native="handlePolicyModals(row, 'adjustment')" class="edit"
                      :type="row.isDisableAdjust ? 'dark' : 'danger'" size="sm" icon :disabled="row.isDisableAdjust"
                      v-b-tooltip.hover.righttop title="Adjust Policy">
                      <b-icon font-scale="2" icon="pencil-square"></b-icon>
                    </base-button>
                  </div>
                </el-table-column>
                <el-table-column v-if="isDisplayCancelButton" min-width="80px" align="left" label="Cancel">
                  <div slot-scope="{ row }">
                    <base-button @click.native="handlePolicyModals(row, 'cancellation')" class="edit"
                      :type="row.isDisableCancel ? 'dark' : 'danger'" size="sm" icon :disabled="row.isDisableCancel"
                      v-b-tooltip.hover.righttop title="Cancel Policy">
                      <b-icon font-scale="2" icon="x"></b-icon>
                    </base-button>
                  </div>
                </el-table-column>
                <el-table-column v-if="isDisplayCancelButton" min-width="80px" align="left" label="Renew">
                  <div slot-scope="{ row }" v-b-tooltip.hover.righttop title="Enabled within 45 days before the expiry date">
                    <base-button @click.native="handlePolicyModals(row, 'renew')" class="edit"
                      :type="row.isDisableRenew ? 'dark' : 'danger'" size="sm" icon :disabled="row.isDisableRenew">
                      <b-icon font-scale="2" icon="arrow-clockwise"></b-icon>
                    </base-button>
                  </div>
                </el-table-column>

                <el-table-column label="Status" min-width="130px" prop="Ubind_status" :filters="[
                  { text: 'Cancelled', value: 'Cancelled' },
                  { text: 'Active', value: 'Active' },
                  { text: 'policyExpired', value: 'policyExpired' }
                ]" :filter-method="filterStatus">
                </el-table-column>

                <el-table-column label="Business Name" prop="Customer_insured_name" min-width="190px" sortable>
                </el-table-column>

                <el-table-column label="Current Premium" prop="Customer_policy_insurance_total_payable" min-width="150px"
                  sortable :formatter="row => formatPremium(row)">
                </el-table-column>

                <el-table-column label="Policy Number" min-width="150px" prop="policy_number" sortable>
                </el-table-column>

                <el-table-column label="Expiry Date" min-width="180px" prop="policy_end_date" sortable
                  :formatter="row => formatDateWithSlash(row.policy_end_date)">
                </el-table-column>

                <el-table-column label="Last Updated" min-width="180px" prop="last_modified" sortable
                  :formatter="row => formatDateTime(row.last_modified)">
                </el-table-column>

                <el-table-column label="Broker Name" min-width="180px" prop="Broker_Name" sortable>
                </el-table-column>

                <el-table-column label="Broker Organization" min-width="180px" prop="Broker_Org_Name" sortable>
                </el-table-column>

                <template #empty>
                  <p>{{ loading ? "" : "No completed policies found" }}</p>
                </template>
              </el-table>
            </div>
            <div slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap py-3 px-5">
              <div class="">
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                  <span v-if="selectedRows.length">
                    &nbsp; &nbsp; {{ selectedRows.length }} rows selected
                  </span>
                </p>
              </div>
              <base-pagination class="pagination-no-border" :value="pagination.currentPage" :per-page="pagination.perPage"
                :total="total" @change="paginationChanged($event)">
              </base-pagination>
            </div>
          </div>
        </div>
        <DialogConfirm v-on:modelPopupCancel="modelPopupCancel" :title="title" :titleDigLog="titleDigLog"
          v-on:setLoading="setLoading" v-on:handleCallAPI="handleCallAPI" :cancelConfirmModal="cancelConfirmModal" />

        <DialogResume v-on:modelPopupResume="modelPopupResume" :title="title" :message="message"
          v-on:setLoading="setLoading" v-on:handleCallAPI="handleCallAPI" :quoteCancel="quoteCancel"
          v-on:handleContinuePolicies="handleContinuePolicies" :centerDialogVisible="centerDialogVisible" />

        <DialogDiscard v-on:modelPopupDiscard="modelPopupDiscard" :title="title" :message="message"
          :quoteCancel="quoteCancel" v-on:setLoading="setLoading" v-on:handleCallAPI="handleCallAPI"
          :discardDialog="discardDialog" />
      </div>
    </div>
    <div v-if="isCancel">
      <CancelPolicies @goStepCancel="goStepCancel" :quoteId="quoteId" productType="mercurien-light-commercial" />
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Notification } from "element-ui";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { BasePagination } from "@/components";
import clientPaginationMixin from "../components/PaginatedTables/clientPaginationMixin";
import { GlobalStore } from "../globalStore";
import { formattingMethods } from "../helpers/formatting";
import { appFunctions } from "../helpers/appFunctions";
import { toasts } from "../helpers/toasts";
import CancelPolicies from "./CancelPolicies.vue";
import Spinner from "./dashboard/Spinner.vue";
import DialogConfirm from "./components/DialogConfirm.vue";
import DialogResume from "./components/DialogResume.vue";
import DialogDiscard from "./components/DialogDiscard.vue";
import { formatDatePolicy, getNumberOfVehicles, downloadPDFFromBase64, getDocumentUbind } from '../helpers/common';
import CustomerDetailsModal from "../components/CustomerDetailsModal.vue";

export default {
  data() {
    return {
      tableData: [],
      selectedRows: [],
      selectedRow: null,
      loading: false,
      isCancel: false,
      quoteId: "",
      centerDialogVisible: false,
      quoteCancel: null,
      isLoading: false,
      cancelConfirmModal: false,
      title: '',
      message: '',
      discardDialog: false,
      isDisplayCancelButton: false,
      isDisplayAdjustButton: false,
      titleDigLog: '',
      clickDownloadButton: false,
      expands: [],
      selectedRow: null
    };
  },
  methods: {
    viewCustomerDetails(row) {
      this.selectedRow = row;
      this.$bvModal.show("customer-modal")
    },
    filterStatus(value, row) {
      return row.Ubind_status === value;
    },
    getRowKeys(row) {
      return row._id
    },
    handleExpand(row, expanded) {

      if (this.expands.includes(row._id)) {
        this.expands=[];
      } else {
        this.expands=[row._id];
      }
    },
    formatDatePolicy(datetime) {
      return formatDatePolicy(datetime)
    },
    getNumberOfVehicles(uBindObj) {
      return getNumberOfVehicles(uBindObj)
    },
    isDowloadFleetFile(data) {
      let result = false
      if (data.formData) {
        result = data.formData.vehicle && data.formData.vehicle.toLocaleLowerCase().includes('fleet') ? true : false
      }
      if (data.formDataFormatted) {
        result = data.formDataFormatted.vehicle && data.formDataFormatted.vehicle.toLocaleLowerCase().includes('fleet') ? true : false
      }
      return result
    },
    async goStepCancel() {
      this.isCancel = false
      this.cancelConfirmModal = false
      this.centerDialogVisible = false
      this.discardDialog = false
      await this.getCustomerLCPolicies(true)
    },
    async handleCallAPI(method) {
      this.isLoading = true
      const policyId = this.selectedRow.Ubind_quote_details_obj.policyId;
      await method(policyId)
        .then(resp => {
          this.quoteId = resp.quoteId
          this.isCancel = true
          this.isLoading = false
        })
        .catch(err => {
          if (!err.response.data.data) {
            Notification.error({
              title: 'Error',
              message: 'You are missing the required permission!'
            });
          } else {
            this.quoteCancel = err.response.data.data
            this.cancelConfirmModal = false
            this.message = err.response.data.detail
            if (err.response.data.code.includes("exists.when")) {
              this.discardDialog = true
            } else {
              this.centerDialogVisible = true
            }
          }
        });
      this.isLoading = false
    },
    modelPopupCancel() {
      this.cancelConfirmModal = false
    },
    modelPopupResume() {
      this.centerDialogVisible = false
    },
    modelPopupDiscard() {
      this.discardDialog = false
    },
    handleContinuePolicies() {
      this.quoteId = this.quoteCancel.quoteId
      this.isCancel = true
    },
    expandRow(row) {
      if (this.expands.includes(row._id)) {
        this.expands=[];
      } else {
        this.expands=[row._id];
      }
    },
    setLoading(val) {
      this.isLoading = val
    },
    handlePolicyModals(row, type) {
      const titles = {
        adjustment: `Are you sure you want to make \n an adjustment to policy ${row.policy_number}`,
        cancellation: `Are you sure you want to initiate \n cancellation of policy ${row.policy_number}?`,
        renew: `Are you sure you want to renew \n policy ${row.policy_number}?`
      }
      this.title = type
      this.titleDigLog = titles[type]
      this.selectedRow = null
      this.cancelConfirmModal = true
      this.selectedRow = row;
    },
    capitalizeFirstLetter(string) {
      string = string === 'complete' ? "active" : string
      return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
    },
    newQuote() {
      this.$router.push("createQuote");
    },

    paginationChanged(page) {
      this.pagination.currentPage = page;
    },

    formatPolicyEndDate(date) {
      if (!date) {
        return "Invalid"
      }
      const convertedDate = formattingMethods.formatDateWithHyphen(date)
      return formatDatePolicy(convertedDate)
    },

    formatDateWithSlash(dateVal) {
      if (dateVal) {
        return dateVal.replaceAll('/', '-')
      } else {
        return "N/A"
      }
    },

    formatDateTime(dateVal) {
      return formattingMethods.formatDateTime(dateVal);
    },

    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },

    /**
     * Format the premium amount to include commas and dollar symbol.
     * @param {Object} row - row data.
     * @returns {String} - formatted amount.
     */
    formatPremium(row) {
      const totalPayable =
        row.Customer_policy_insurance_total_payable &&
        row.Customer_policy_insurance_total_payable.toString().replace("$", "");
      return "$" + formattingMethods.numberWithCommas(totalPayable);
    },

    async downloadCOC(row) {
      // Checking if ubind pdf exists in file db
      const transactionType = row.Ubind_quote_details_obj.policyTransactionType
      const type = transactionType === 'adjustment' ? 'lc-adjust-certificate-of-currency' : transactionType === 'renewal' ? 'lc-renewal-certificate-of-currency' : 'lc-certificate-of-currency'
      const pdf = await appFunctions.getFileAttachment("getPolicyFileAttachment", { policy_id: row.Customer_policy_id, type });
      if (!pdf) {
        if (!this.clickDownloadButton) {
          this.clickDownloadButton = true
          await getDocumentUbind(row, "certificate of currency.pdf", false)
          this.clickDownloadButton = false
        }
      } else {
        if (row.Ubind_quote_details_obj.policyTransactionType === 'adjustment' && pdf.fileContent === "") {
          toasts.error("Documents are currently being generated - try again shortly");
        } else {
          downloadPDFFromBase64(pdf.fileContent, pdf.filename);
        }
      }
    },

    async downloadFileDoc(row, type, uBindFileName) {
      const pdf = await appFunctions.getFileAttachment(
        "getPolicyFileAttachment",
        { policy_id: row.Customer_policy_id, type }
      );
      if (!pdf) {
        if (!this.clickDownloadButton) {
          this.clickDownloadButton = true
          await getDocumentUbind(row, uBindFileName, false)
          this.clickDownloadButton = false
        }
      } else {
        // download
        if (pdf.fileContent === "") {
          toasts.error("Documents are currently being generated - try again shortly");
        } else {
          downloadPDFFromBase64(pdf.fileContent, pdf.filename);
        }
      }
    },

    async downloadPS(row) {
      const transactionType = row.Ubind_quote_details_obj.policyTransactionType
      const type = transactionType === 'adjustment' ? 'lc-adjust-policy-schedule' : transactionType === 'renewal' ? 'lc-renewal-policy-schedule' : 'lc-policy-schedule'
      // Checking if ubind pdf exists in file db
      const pdf = await appFunctions.getFileAttachment("getPolicyFileAttachment", { policy_id: row.Customer_policy_id, type });
      if (!pdf) {
        if (!this.clickDownloadButton) {
          this.clickDownloadButton = true
          await getDocumentUbind(row, "policy schedule.pdf", false)
          this.clickDownloadButton = false
        }
      } else {
        if (row.Ubind_quote_details_obj.policyTransactionType === 'adjustment' && pdf.fileContent === "") {
          toasts.error("Documents are currently being generated - try again shortly");
        } else {
          downloadPDFFromBase64(pdf.fileContent, pdf.filename);
        }
      }
    },

    async downloadPSDOC(row) {
      const transactionType = row.Ubind_quote_details_obj.policyTransactionType
      const type = transactionType === 'adjustment' ? 'lc-adjust-policy-schedule' : transactionType === 'renewal' ? 'lc-renewal-policy-schedule' : 'lc-policy-schedule-doc'
      // Checking if ubind pdf exists in file db
      const pdf = await appFunctions.getFileAttachment("getPolicyFileAttachment", { policy_id: row.Customer_policy_id, type });
      if (!pdf) {
        if (!this.clickDownloadButton) {
          this.clickDownloadButton = true
          await getDocumentUbind(row, "policy schedule.docx", false)
          this.clickDownloadButton = false
        }
      } else {
        if (row.Ubind_quote_details_obj.policyTransactionType === 'adjustment' && pdf.fileContent === "") {
          toasts.error("Documents are currently being generated - try again shortly");
        } else {
          downloadPDFFromBase64(pdf.fileContent, pdf.filename);
        }
      }
    },

    async getCustomerLCPolicies(loading) {
      if (loading) {
        this.isLoading = true
      }
      await appFunctions
        .crud_customerLCPolicy("listByRealmId", {
          Realm_user_id: GlobalStore.brokerObj.Realm_user_id,
          type: "policy"
        })
        .then(resp => {
          var tdata = resp.data.data;
          this.isDisplayCancelButton = !resp.data.isDisableCancel
          this.isDisplayAdjustButton = !resp.data.isDisableAdjust

          tdata.forEach(q => {

            // Formatting number values.
            if (q.Ubind_quote_details_obj) {
              q.policy_number = q.Ubind_quote_details_obj.policyNumber || "";
              q.last_modified = q.Ubind_quote_last_updated_date;
            }

            q.Broker_Name = q.createdByName
            q.isDisableCancel = q.Ubind_status === 'complete' ? false : true
            q.isDisableAdjust = q.isDisableCancel
            q.isDisableRenew = this.haveRenewButtonDisable(q)
            q.Ubind_status = this.capitalizeFirstLetter(q.Ubind_status);
          });

          tdata.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return (
              new Date(b.Ubind_quote_created_date) -
              new Date(a.Ubind_quote_created_date)
            );
          });

          this.tableData = tdata;
          this.initFuse([
            "Customer_insured_name",
            "policy_number",
            "Broker_Name",
            "Broker_Org_Name"
          ]);
          this.isLoading = false
        })
        .finally(() => {
          this.loading = false;
        });
    },

    refreshData() {
      this.resetPagination()
      this.searchQuery = ""
      this.tableData = []
      this.loading = true;
      this.getCustomerLCPolicies(false)
    }
  },
  async mounted() {
    this.loading = true;
    await this.getCustomerLCPolicies(false)
  },
  mixins: [clientPaginationMixin],
  components: {
    BasePagination,
    CustomerDetailsModal,
    RouteBreadCrumb,
    CancelPolicies,
    Spinner,
    DialogConfirm,
    DialogResume,
    DialogDiscard,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Notification.name]: Notification
  }
};
</script>
<style scoped>
.container {
  max-width: 1800px;
  margin: 0px 30px 0px 30px;
}

.expandedPolicy p {
  font-size: 0.9rem;
  color: #000000;
}

.btn-download {
  font-size: 0.9rem;
  margin: 5px;
  min-width: 10em;
}

.btn-download span {
  font-family: sans-serif;
}

.btn-refresh {
  padding: 0.64rem 0.85rem;
  font-size: 1rem;
  color: #8898aa;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: none;
}
</style>
<style>
.btn-primary {
  background-color: var(--uBind) !important;

  border: 1px solid var(--uBind) !important;
}

.el-table td p {
  white-space: break-spaces !important;
}

.modal-body {
  padding: 0px 1.5rem 0px 1.5rem !important;
}

.change-color .focused .form-control {
  border-color: #98B8CF !important;
}

.change-color .focused .input-group-text {
  border-color: #98B8CF !important;
}

.change-color .el-select .el-input.is-focus .el-input__inner.el-input__inner {
  border: 1px solid #98B8CF !important;
  border-color: #98B8CF !important;
}

.change-color .el-select .el-input .el-input__inner.el-input__inner:focus {
  border: 1px solid #98B8CF !important;
  border-color: #98B8CF !important;
}

.el-select-dropdown.el-popper .el-select-dropdown__item.selected,
.el-select-dropdown.el-popper.is-multiple .el-select-dropdown__item.selected {
  color: #98B8CF !important;
}

.el-select .el-input .el-input__inner {
  height: calc(1.7em + 1.25rem + 2px) !important;
}
</style>
