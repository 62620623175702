<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h1 text-white d-inline-block mb-0 header fw-500">
            {{ productName }} Policies
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- <route-bread-crumb></route-bread-crumb> -->
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <div class="mt--6 px-2 px-sm-4">
      <div>
        <div class="bg-white rounded-bottom change-color">
          <div>
            <b-col cols="12" class="pt-3 d-flex flex-wrap bg-white rounded-top">
              <el-select
                class="select-primary pagination-select mb-3 mr-3"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div class="mr-3">
                <base-input
                  v-model="searchQuery"
                  prepend-icon="fas fa-search"
                  placeholder="Search..."
                  v-on:input="resetPagination()"
                >
                </base-input>
              </div>

              <div>
                <base-button
                  type="danger"
                  class="btn-refresh"
                  @click="refreshData()"
                >
                  <b-icon icon="arrow-repeat"></b-icon>
                </base-button>
              </div>
            </b-col>
            <el-table
              :data="queriedData"
              row-key="_id"
              :default-sort="{
                prop: 'Jotform_quote_created_date',
                order: 'descending'
              }"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
              @selection-change="selectionChange"
              v-loading="loading"
            >
              <el-table-column type="expand">
                <template #default="props">
                  <b-row class="px-5 expandedPolicy">
                    <b-col cols="6" md="8">
                      <b-row>
                        <b-col cols="5">
                          <p>Created Date:</p>
                          <p>Insured Contact Name:</p>
                          <p>Insured Email:</p>
                          <p>Number Of Vehicles:</p>
                          <p>Business Description:</p>
                        </b-col>
                        <b-col cols="7">
                          <p>
                            {{
                              formatDateTime(props.row.Jotform_quote_created_date)
                            }}
                          </p>
                          <p>
                            {{ props.row.Customer_contact_first_name }}
                            {{ props.row.Customer_contact_last_name }}
                          </p>
                          <p>{{ props.row.Customer_contact_email }}</p>
                          <p>
                            {{
                              getNumberOfVehicles(
                               () => props.row.Jotform_data_obj.insuredUnits
                              )
                            }}
                          </p>
                          <p>
                            {{ 
                              getSafe(() => props.row.Jotform_data_obj.businessOccupation.join(', '))
                            }}
                          </p>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="1"></b-col>

                    <b-col cols="6" md="3">
                      <b-row class="pl-2" align-h="center" v-for="document in props.row.documents">
                        <b-col cols="auto" md="12" class="mb-4">
                          <base-button class="edit" type="danger" size="sm">
                            <a :href="document.filePath" target="_blank" class="text-white">
                              <i class="fas fa-file-download btn-download"><span> {{ document.fileName }}</span></i>
                            </a>
                          </base-button>
                        </b-col>
                      </b-row>
                    </b-col>

                  </b-row>
                </template>
              </el-table-column>

              <el-table-column
                label="Upload"
                min-width="100px"
                v-if="hasUploadPermission"
              >
                <div
                  slot-scope="{ row }"
                  tabindex="0"
                  v-b-tooltip.hover
                >
                  <base-button
                    @click.native="showUploadDialog(row)"
                    class="edit"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <b-icon font-scale="2" icon="upload"></b-icon>
                  </base-button>
                </div>
              </el-table-column>

              <el-table-column
                label="Status"
                min-width="100px"
                prop="Status"
                :filters="[
                  { text: 'Accepted', value: 'Accepted' }
                ]"
                :filter-method="filterStatus"
              >
              </el-table-column>

              <el-table-column
                label="Business Name"
                prop="Customer_insured_name"
                min-width="150px"
                sortable
              >
              </el-table-column>

              <el-table-column
                label="Total Payable"
                prop="finalTotalPayable"
                min-width="150px"
                :formatter="row => formatPremium(row)"
                sortable
              >
              </el-table-column>

              <el-table-column
                label="Quote Ref"
                min-width="130px"
                prop="Quote_Ref_No"
                sortable
              >
              </el-table-column>

              <el-table-column
                label="Last Updated"
                prop="Jotform_quote_last_updated_date"
                min-width="180px"
                sortable
                :formatter="
                  row => formatDateTime(row.Jotform_quote_last_updated_date)
                "
              >
              </el-table-column>

              <el-table-column
                label="Broker Name"
                min-width="180px"
                prop="Broker_Name"
                sortable
              >
              </el-table-column>

              <el-table-column
                label="Broker Organization"
                min-width="180px"
                prop="Broker_Org_Name"
                sortable
              >
              </el-table-column>

              <template #empty>
                <p>{{ loading ? "" : "No Data" }}</p>
              </template>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap py-3 px-5"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{ selectedRows.length }} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :value="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)"
            >
            </base-pagination>
          </div>
        </div>
      </div>
      <dialog-upload-document
        v-on:modelPopupCancel="modelPopupCancel"
        v-on:handleCallAPI="handleCallAPI"
        :acceptedQuote="acceptedQuote"
        :titleDialog="titleDialog"
        :quoteScheduleModal="quoteScheduleModal"
      />
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Scrollbar } from "element-ui";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { BasePagination } from "@/components";
import clientPaginationMixin from "../../components/PaginatedTables/clientPaginationMixin";
import { GlobalStore } from "../../globalStore";
import { formattingMethods } from "../../helpers/formatting";
import { getNumberOfVehicles } from "../../helpers/common";
import { appFunctions } from "../../helpers/appFunctions";
import { toasts } from "../../helpers/toasts";
import DialogUploadDocument from "../components/DialogUploadDocument.vue"

export default {
  data() {
    return {
      tableData: [],
      selectedRows: [],
      quotesList: [],
      productCode: '',
      loading: false,
      titleDialog: '',
      quoteScheduleModal: '',
      acceptedQuote: {},
      uploadedQuoteId: '',
      hasUploadPermission: false
    };
  },
  computed: {
    productName() {
      return formattingMethods.formatProductNameVIBS(this.$route.params.productName)
    },
  },
  methods: {
    showUploadDialog(row) {
      this.title = null
      this.titleDialog = `Policy - Quote Ref ${row.Quote_Ref_No}`
      this.acceptedQuote = row.offeredQuotes.find(quote => quote.status == "Accepted")
      this.uploadedQuoteId = row.Jotform_quote_id
      this.quoteScheduleModal = true
    },

    modelPopupCancel() {
      this.quoteScheduleModal = false
    },

    async handleCallAPI(documents) {
      await appFunctions.crud_customerPolicy("updateDocuments", {
          uploadedQuoteId: this.uploadedQuoteId,
          documents: documents
        })
        .then(resp => {
          this.fetchData()
          this.quoteScheduleModal = false
        })
        .catch((error) => {
          this.quoteScheduleModal = false
          toasts.error("Update policy error!")
        })
    },

    checkProductCode() {
      const productCodes = {
        plantEquipment: "YIHQ-PE",
        motor: "YIHQ-MOTOR",
        otherAssets: "YIHQ-OTHER-ASSETS",
        property: "YIHQ-PROPERTY"
      }
      this.productCode = productCodes[this.$route.params.productName]
    },

    modelPopupCancel() {
      this.quoteScheduleModal = false
    },

    formatData(offeredQuotes) {
      offeredQuotes.basePremium = offeredQuotes.basePremium ? "$" + formattingMethods.numberWithCommas(offeredQuotes.basePremium) : ""
      offeredQuotes.ESL = offeredQuotes.ESL ? "$" + formattingMethods.numberWithCommas(offeredQuotes.ESL) : ""
      offeredQuotes.policyFee = offeredQuotes.policyFee ? "$" + formattingMethods.numberWithCommas(offeredQuotes.policyFee) : ""
      offeredQuotes.totalPayable = offeredQuotes.totalPayable ? "$" + formattingMethods.numberWithCommas(offeredQuotes.totalPayable) : ""
      return offeredQuotes
    },

    capitalizeFirstLetter(string) {
      return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
    },

    paginationChanged(page) {
      this.pagination.currentPage = page;
    },

    formatDate(dateVal) {
      return formattingMethods.formatDate(dateVal);
    },

    formatDateTime(dateVal) {
      return dateVal === "N/A"
        ? dateVal
        : formattingMethods.formatDateTime(dateVal);
    },

    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },

    /**
     * Used by the filter dropdown to filter the table by status
     * @param {string} value - the value of the filter dropdown
     * @param {object} row - the row of the table
     * @returns {boolean} - true if the row should be shown, false if not
     */
    filterStatus(value, row) {
      return row.status === value;
    },

    /**
     * Format the premium amount to include commas and dollar symbol.
     * @param {Object} row - row data.
     * @returns {String} - formatted amount.
     */
    formatPremium(row) {
      return "$" + formattingMethods.numberWithCommas(row.finalTotalPayable);
    },

    formatOfferedQuoteTitle(quote) {
      const totalPayable = "$" + formattingMethods.numberWithCommas(quote.totalPayable)
      return `Quote Schedule - Total Payable: ${totalPayable}`
    },

    fetchData() {
      this.loading = true;
      appFunctions
        .crud_customerPolicy("listByRealmId", {
          Realm_user_id: GlobalStore.brokerObj.Realm_user_id,
          type: "policy",
          productCode: this.productCode
        })
        .then(resp => {
          var tdata = resp.data.data;
          const isPartner = resp.data.isPartner
          // only special partners can upload documents
          if (isPartner) {
            this.hasUploadPermission = true
          }

          tdata.forEach(q => {
            // Formatting number values.
            q.status = false;

            if (!!!q.Status) {
              q.Status = "Not Started";
              // q.Ubind_refNo = "N/A";
            }

            if (q.Status === "Accepted") {
              q.Status = "Active";
            }

            const acceptedQuotes = q.offeredQuotes.find(quotes => quotes.status == "Accepted")

            q.finalTotalPayable = acceptedQuotes ?  acceptedQuotes.totalPayable : "N/A"
            // console.log(q.finalTotalPayable)
            q.Broker_Name = q.createdByName;
            q.Status = this.capitalizeFirstLetter(q.Status);
          });

          tdata.sort(function(a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return (
              new Date(b.Jotform_quote_created_date) -
              new Date(a.Jotform_quote_created_date)
            );
          });

          this.tableData = tdata;

          this.initFuse([
            "Customer_insured_name",
            "Quote_Ref_No",
            "Status",
            "Broker_Name",
            "Broker_Org_Name"
          ]);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    refreshData() {
      this.resetPagination();
      this.searchQuery = "";
      this.tableData = [];
      this.fetchData();
    },
  },
  watch: {
    productName() {
      this.checkProductCode()
      this.resetPagination();
      this.searchQuery = "";
      this.tableData = [];
      this.fetchData()
    }
  },
  mounted() {
    this.checkProductCode()
    this.fetchData();
  },

  mixins: [clientPaginationMixin],
  components: {
    DialogUploadDocument,
    BasePagination,
    RouteBreadCrumb,
    [Scrollbar.name]: Scrollbar,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  }
};
</script>
<style scoped>
.container {
  max-width: 1800px;
  margin: 0px 30px 0px 30px;
}
.expandedPolicy p {
  font-size: 0.9rem;
  color: #000000;
}
.btn-download {
  font-size: 0.9rem;
  margin: 5px;
  min-width: 10em;
}

.btn-download span {
  font-family: sans-serif;
}

.btn-refresh {
  padding: 0.64rem 0.85rem;
  font-size: 1rem;
  color: #8898aa;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: none;
}
</style>

<style>
.el-table .cell {
  box-sizing: border-box;
  white-space: normal;
  word-break: break-word;
  line-height: 23px;
}
</style>
