import Vue from "vue";
import axios from "axios";

export const reportFunctions = new Vue({
  methods: {
    async reportTotal(method, data) {
      var resp = await this.postData("/dashboard-report/total", {
        method,
        data
      });
      return resp.data;
    },

    async dataChart(method, data) {
      var resp = await this.postData("/dashboard-report/data-chart", {
        method,
        data
      });
      return resp.data;
    },

    async reportTotalQuotes(method, data) {
      var resp = await this.postData("/yi-report/total", {
        method,
        data
      });
      return resp.data;
    },

    postData(url, data) {
      let config = {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token")
        }
      };
      return axios.post(process.env.VUE_APP_APIENDPOINT + url, data, config);
    },
  }
});
