<template>
  <QuoteEmailed
    header="RideShare Vehicles - Fleet Quote Emailed"
    title="Commercial Vehicle Insurance"
    bodyTitle="Quote Emailed"
    bodyText="A new fleet enquiry has been sent on your behalf."
    :newQuoteAction="newQuote"
    :viewQuotesAction="viewQuotes"
  />
</template>

<script>
import QuoteEmailed from "../../../components/QuoteEmailed.vue";
export default {
  methods: {
    newQuote() {
      this.$router.push("/rideShare/fleet");
    },
    viewQuotes() {
      this.$router.push("/rideShare/quotes");
    }
  },
  components: {
    QuoteEmailed
  }
};
</script>
