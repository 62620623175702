<template>
  <Backdrop title="Fleet Management">
    <b-card no-body class="mb-4 claim-form-container">
      <b-card-header style="padding-bottom: 0px !important;">
        <h1 class="mb-0">Mercurien Fleet Management Services</h1>
      </b-card-header>
      <b-card-body>
        <b-card class="uBind">
          <b-row>
            <h2 class="secondary-card-header">
              Fleet Operations
            </h2>
          </b-row>

          <b-row>
            <p>
              Mercurien offer services that range from consultancy, right
              through to the complete outsourcing of your customer’s ﬂeet
              operations.
            </p>
          </b-row>
          <b-row>
            <p>
              Mercurien focuses on understanding how to improve the running of
              your customer’s ﬂeet. This might be through a detailed ﬂeet
              review, an operational engagement focusing on ﬂeet management and
              driver safety (FMDS) or a complete outsourced model (FaaS), where
              we own and operate the ﬂeet for your beneﬁt.
            </p>
          </b-row>
          <b-row>
            <CallButton :loading="loading" :callCustomer="sendEmail" />
          </b-row>

          <b-row>
            <article>
              <img
                src="/img/mercurien-fleet-mgmt-service.png"
                alt="Mercurien diagram"
              />
            </article>
          </b-row>

          <b-row class="mt-5">
            <b-col xs="12" md="6" xl="4"
              ><b-card>
                <b-card-header class="info-header"
                  >Fleet Consultancy</b-card-header
                >
                <b-card-body class="info-body">
                  <p>
                    Conducted in tandem with a ﬂeet performance team that
                    operates ﬂeets for several large ASX listed companies, we
                    perform a fact ﬁnd and gap analysis to deliver:
                  </p>
                  <ul>
                    <li>Key findings,</li>
                    <li>Recommendations, and</li>
                    <li>An action plan</li>
                  </ul>

                  <p>Clients are delivered an action plan that encompasses:</p>

                  <ul>
                    <li>
                      Structuring of:
                      <ul>
                        <li>Fleet Composition</li>
                        <li>Vehicle Access/Ownership</li>
                        <li>Insurance Terms</li>
                      </ul>
                    </li>
                    <li>
                      Strategies for:
                      <ul>
                        <li>Safety</li>
                        <li>Tax Optimisation</li>
                        <li>CO<sub>2</sub> Reduction</li>
                        <li>EV Transition</li>
                      </ul>
                    </li>
                  </ul>
                </b-card-body>
              </b-card></b-col
            >
            <b-col xs="12" md="6" xl="4"
              ><b-card>
                <b-card-header class="info-header"
                  >Fleet Management &amp; Driver Safety</b-card-header
                >
                <b-card-body class="info-body ">
                  <p>
                    Suitable for organisations who want to manage their own ﬂeet
                    operations and are seeking to improve their utilisation,
                    optimisation and safety practices. We provide a range of
                    tools and services to support you.
                  </p>

                  <p>Client beneﬁt via:</p>

                  <ul>
                    <li>
                      Improved Driver Safety:
                      <ul>
                        <li>Reduced Total Crash Costs</li>
                        <li>Reduced Fuel Consumption</li>
                        <li>Reduced CO<sub>2</sub> emissions</li>
                      </ul>
                    </li>
                    <li>
                      Tax Optimisation:
                      <ul>
                        <li>Claim Fuel Tax Credits rebates</li>
                        <li>Optimise your FBT position</li>
                      </ul>
                    </li>
                    <li>
                      Supports Better Decision Making:
                      <ul>
                        <li>Fleet Rationalisation</li>
                        <li>Fleet Optimisation</li>
                        <li>EV Transition planning &amp; operation</li>
                      </ul>
                    </li>
                  </ul>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col xs="12" md="6" xl="4" class="pl-sm-0 pl-xl-3 pr-sm-3 pr-xl-0"
              ><b-card>
                <b-card-header class="info-header"
                  >Fleet-as-a-Service (FaaS)</b-card-header
                >
                <b-card-body class="info-body">
                  <p>
                    FaaS is suitable for organisations who want to focus on
                    their core business and outsource the risk, reducing the
                    cost risk and effort involved in running a ﬂeet.
                  </p>

                  <p>
                    Mercurien appoints a specialist Fleet Performance Management
                    team and provides them with a comprehensive suite of
                    technology products and services that allows them to
                    proactively manage the ﬂeet on the client’s behalf.
                  </p>
                  <p>Clients beneﬁt via:</p>
                  <ul>
                    <li>Signiﬁcant operating cost reduction</li>
                    <li>Signiﬁcant hidden cost reduction</li>
                    <li>Reduced administrative burden</li>
                    <li>
                      Being able to tangibly demonstrate their ESG principles
                      and status
                    </li>
                  </ul>
                </b-card-body>
              </b-card></b-col
            >
          </b-row>

          <b-row>
            <CallButton :loading="loading" :callCustomer="sendEmail" />
          </b-row>
        </b-card>
      </b-card-body>
    </b-card>
  </Backdrop>
</template>

<script>
import Backdrop from "../components/Backdrop.vue";
import CallButton from "./components/CallButton.vue";
import { emailMethods } from "../../helpers/email";
import { GlobalStore } from "../../globalStore";
import { toasts } from "../../helpers/toasts";

export default {
  name: "FleetManagementServices",
  data() {
    return {
      realmApp: null,
      loading: false
    };
  },

  methods: {
    async sendEmail() {
      this.loading = true;
      const emailRes = await emailMethods.sendFleetCallbackEmail(this.realmApp);

      const { Broker_id, Broker_org_id } = GlobalStore.brokerObj;
      const postRes = await appFunction.crud_mercurienFleetManagementInquiries(
        "create",
        {
          Broker_id,
          Broker_org_id
        }
      );

      if (emailRes === "ok" && postRes.data === "OK") {
        this.$router.push("/fleetManagement/callBackRequested?status=success");
      } else {
        toasts.error("Oops... something went wrong. Please try again later.");
        this.loading = false;
      }
    }
  },

  mounted() {
    this.realmApp = GlobalStore.realmApp;
  },

  components: {
    Backdrop,
    CallButton
  }
};
</script>

<style lang="scss" scoped>
p,
li {
  color: #525f7f;
  font-weight: 400;
}

h1 {
  color: black;
  font-size: 45px;
  font-weight: 600;
}

article {
  width: 100%;
  display: flex;
  justify-content: center;
}

img {
  width: 100%;
  max-width: 1300px;
  height: auto;
  border-radius: 1rem;
}

.table-responsive {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

td {
  max-width: 33vw;
  overflow-wrap: break-word;
}

.info-body {
  padding: 25px !important;
}

.info-header {
  color: black;
  font-size: 1.2rem;
  text-align: center;
}

@media (max-width: 575.98px) {
  .card-body {
    padding: 0 10px !important;
  }
}
</style>
