<template>
  <Layout title="Rideshare Vehicle Fleet Quotes">
    <FleetInformationRS />
  </Layout>
</template>

<script>
import FleetInformationRS from "./fleetInformationRS.vue";
import Layout from "../../rideshare/components/Layout.vue";
export default {
  data() {
    return {
    };
  },

  components: {
    FleetInformationRS,
    Layout
  },
};
</script>