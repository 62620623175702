import Vue from "vue"

export const brokerPageMethods = new Vue ({
  methods: {
    getBrokerFormRules() {
      return {
        brokerFName: "required",
        brokerLName: "required",
        brokerEmail: "required"
      };
    }
  }
})