<template>
  <div>
    <el-dialog v-if="quoteCancel" :title="`Discard policy ${title} quote in progress`" @close="cancelModelDiscard" :visible.sync="cancelModelPopup"
      width="30%" :show-close="false" :close-on-click-modal="false" center>
      <span class="text-center">{{ message }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelModelDiscard">CANCEL</el-button>
        <el-button type="danger" @click="handleDiscardPolicies()">DISCARD</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { Dialog, Button } from "element-ui";
import { ubindMethods } from "../../helpers/ubind";
import { handleUbindPolicy } from '../../helpers/common';

export default {
  name: 'DialogDiscard',
  data() {
    return {
      cancelModelPopup: false
    }
  },
  props: ['discardDialog', 'title', 'message', 'quoteCancel'],
  methods: {
    async handleDiscardPolicies() {
      this.$emit('setLoading', true)
      await ubindMethods.ubindDiscardQuote(this.quoteCancel.quoteId)
        .then(resp => {
          this.$emit('handleCallAPI', handleUbindPolicy(this.title))
        })
        .catch(err => {
          Notification.error({
            title: 'Error',
            message: this.title + ' policies error!'
          });
        });
    },
    cancelModelDiscard() {
      this.$emit('modelPopupDiscard')
    },
  },
  watch: {
    discardDialog(val) {
      this.cancelModelPopup = val
    }
  },
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button
  }
};
</script>

<style>
  .el-dialog__body {
    text-align: center !important;
  }
  .el-dialog__title {
    font-weight: bold;
  }
  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: black;
  }
</style>
