import Fuse from "fuse.js";
import { formattingMethods } from "../../helpers/formatting";
import moment from "moment";
export default {
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      } else {
        if (this.searchQuery) {
          result = [];
        }
      }
      return result.slice(this.from, this.to);
    },
    searchedData() {
      let result = [];
      if (this.searchQuery !== "" && this.fuseSearch) {
        result = this.fuseSearch.search(this.searchQuery);
      }
      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      let downBound =
        this.pagination.perPage * (this.pagination.currentPage - 1);
      if (downBound >= this.total) {
        downBound = 0;
      }
      return downBound;
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: "",
      fuseSearch: null
    };
  },
  methods: {
    sortChange({ prop, order }) {
      if (prop) {
        if (prop == "Ubind_quote_created_date") {
          this.tableData.sort((a, b) => {
            let aVal = new Date(a[prop]);
            let bVal = new Date(b[prop]);

            if (order === "ascending") {
              return aVal - bVal;
            }
            return bVal - aVal;
          });
        } else {
          this.tableData.sort((a, b) => {
            let aVal = a[prop];
            let bVal = b[prop];

            if (order === "ascending") {
              return aVal > bVal ? 1 : -1;
            }
            return bVal > aVal ? 1 : -1;
          });
        }
      } else {
        // this.tableData.sort((a, b) => {
        //   return a.id - b.id;
        // });
      }
      //console.log("Sorted data", this.tableData);
    },

    // reset current page to be default when search to show right data
    resetPagination() {
      this.pagination.currentPage = 1;
    },
    
    /**
     * Initializes the search engine
     * @param {Array.<string|object>} keys Keys to search in the data
     */
    initFuse(keys) {
      // Fuse search initialization.
      this.fuseSearch = new Fuse(this.tableData, {
        keys: keys,
        threshold: 0.0,
        ignoreLocation: true
      });
    },

    // access properties in a fail-safe way
    getSafe(callback) {
      try {
        return callback();
      } catch (error) {
        return "N/A";
      }
    },

    getNumberOfVehicles(callback) {
      try {
        const vehicles = callback()
        const vehiclesObj = JSON.parse(vehicles)
        return vehiclesObj.length;
      } catch (error) {
        return "N/A";
      }
    },

    // check fields existed between old & new Ubind data
    checkUbindFields(uBindObj, field) {
      try {
        let result;
        if (uBindObj.formData) {
          result = uBindObj.formData[field];
        }

        if (uBindObj.formDataFormatted) {
          result = uBindObj.formDataFormatted[field];
        }
        return result || "N/A";
      } catch (error) {
        return "N/A";
      }
    },

    haveRenewButtonDisable(policy) {
      const policyEndDate = this.checkUbindFields(policy.Ubind_quote_details_obj, "policyEndDate")

      //disable Renew when policy is cancelled or expired
      if (policy.Ubind_status !== "complete") {
        return true
      }

      if (policyEndDate !== "N/A") {
        const convertedpolicyEndDate = moment(formattingMethods.formatDateWithHyphen(policyEndDate))
        // difference is negative when current is before policyEndDate
        const difference = moment().diff(convertedpolicyEndDate, "days")
        // disable renew button until 45 days before policyEndDate
        const maximumReminderDate = 45
        if (difference <= 0 && difference + maximumReminderDate >= 0) { 
          // console.log(
          // "send remind",
          // policyEndDate,
          // convertedpolicyEndDate,
          // policy.Ubind_quote_details_obj.policyNumber,
          // difference,
          // difference + maximumReminderDate
          // );
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
   
  },
  // watch: {
  //   /**
  //    * Searches through the table data by a given query.
  //    * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
  //    * @param value of the query
  //    */
  //   searchQuery(value) {
  //     let result = this.tableData;
  //     if (value !== "" && this.fuseSearch) {
  //       result = this.fuseSearch.search(this.searchQuery);
  //     }

  //     this.searchedData = result;
  //   }
  // }
};
