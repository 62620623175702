<template>
  <div>
    <!-- Header -->
    <div class="header py-7 py-lg-6 pt-lg-9">
      <div class="separator separator-bottom separator-skew zindex-100">
        <!-- <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg> -->
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <img class="login-logo" src="../assets/logo_full.png" />
            <b-card-body class="px-lg-5 pb-lg-5 pt-lg-0">
              <div class="mb-3">
                <a href="/"><i class="ni ni-bold-left" />&nbsp;Back</a>
              </div>
              <div class="text-left mb-4">
                <p>
                  Please set and confirm your new password.
                </p>
              </div>
              <validation-observer
                v-slot="{ handleSubmit }"
                ref="formValidator"
              >
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input
                    alternative
                    class="mb-3"
                    name="Enter Password"
                    :rules="{ required: true, min: 6 }"
                    prepend-icon="ni ni-lock-circle-open"
                    type="password"
                    placeholder="Password"
                    v-model="password1"
                  >
                  </base-input>

                  <base-input
                    alternative
                    class="mb-3"
                    name="Re-enter Password"
                    :rules="{ required: true, min: 6 }"
                    prepend-icon="ni ni-lock-circle-open"
                    type="password"
                    placeholder="Re-enter Password"
                    v-model="password2"
                  >
                  </base-input>

                  <base-button
                    type="primary"
                    native-type="submit"
                    class="my-4 bg-primaryred"
                    style="border: var(--primaryred)"
                    >Set Password</base-button
                  >
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <TextFooter />
  </div>
</template>

<script>
import TextFooter from "../components/TextFooter.vue";
import { GlobalStore } from "../globalStore";
import { toasts } from "../helpers/toasts";
import { appFunctions } from "../helpers/appFunctions";
export default {
  data() {
    return {
      password1: "",
      password2: "",
      token: "",
      tokenId: "",
      year: new Date().getFullYear()
    };
  },
  methods: {
    onSubmit() {
      // var Realm = window.Realm;
      // const config = {
      //   id: GlobalStore.realm_app_id
      // };
      //const app = new Realm.App(config);
      // app.emailPasswordAuth.sendResetPasswordEmail(this.emailAddress);

      appFunctions
        .resetPassword(this.token, this.tokenId, this.password1)
        .then(resp => {
          if (!resp.error) {
            console.log(resp);
            toasts.success("Thank you your password has been reset.");
            this.$router.push("/login");
          } else {
            toasts.error(resp.error);
          }
        });
    }
  },
  mounted() {
    this.token = this.$route.query.token;
    this.tokenId = this.$route.query.tokenId;

    console.log(this.token);
    console.log(this.tokenId);
  },
  components: {
    TextFooter
  }
};
</script>

<style scoped>
p {
  font-size: 100%;
  font-weight: 400;
  color: black;
}

a {
  color: #ff4438 !important;
}
.card-body {
  padding-top: 0;
}
</style>
