<template>
  <QuoteEmailed
    header="Rideshare - Quote Emailed"
    title="Rideshare"
    bodyTitle="Quote Emailed"
    bodyText="A new Rideshare quote has been sent on your behalf."
    :newQuoteAction="newQuote"
    :viewQuotesAction="viewQuotes"
  />
</template>

<script>
import QuoteEmailed from "../../components/QuoteEmailed.vue";
export default {
  methods: {
    newQuote() {
      this.$router.push("/rideShare/createQuote");
    },
    viewQuotes() {
      this.$router.push("/rideShare/quotes");
    }
  },
  components: {
    QuoteEmailed
  }
};
</script>
