<template>
  <div>
    <div class="window-sizer mx-auto">
      <div style="max-width: 950px;" class="mx-auto">
        <!-- Header -->
        <div>
          <div>
            <img class="login-logo" src="../assets/logo_full.png" />
          </div>
        </div>
        <!-- Page content -->

        <b-row style="display: flex; justify-content: center">
          <b-col class="d-none d-sm-block">
            <div class="text-center">
              <img class="digger" src="/img/homepage_image.png" />
            </div>
          </b-col>

          <b-col md="6" class="d-flex">
            <b-container class="align-self-center">
              <b-row class="justify-content-center">
                <b-col lg="11">
                  <div class="mb-4 greeting">
                    <span>G'day </span>
                    <span style="color: var(--primaryred)">Partner.</span>
                  </div>
                  <validation-observer
                    v-slot="{ handleSubmit }"
                    ref="formValidator"
                  >
                    <b-form
                      role="form"
                      @submit.prevent="handleSubmit(onSubmit)"
                    >
                      <base-input
                        alternative
                        class="mb-3"
                        name="Email"
                        :rules="{ required: true, email: true }"
                        prepend-icon="ni ni-email-83"
                        placeholder="Email"
                        v-model="username"
                      >
                      </base-input>

                      <base-input
                        alternative
                        class="mb-3"
                        name="Password"
                        :rules="{ required: true, min: 6 }"
                        prepend-icon="ni ni-lock-circle-open"
                        type="password"
                        placeholder="Password"
                        v-model="password"
                      >
                      </base-input>

                      <div class="form-buttons">
                        <b-form-checkbox
                          class="align-self-center"
                          v-model="rememberMe"
                        >
                          Remember me
                        </b-form-checkbox>
                        <base-button
                          :loading="isSubmitting"
                          type="primary"
                          native-type="submit"
                          class="bg-primaryred"
                          style="border: var(--primaryred)"
                          >Sign in
                        </base-button>
                      </div>
                    </b-form>
                  </validation-observer>
                  <b-row class="mt-3 d-felx justify-content-center">
                    <router-link to="/requestPasswordReset" class="text-light">
                      <small style="color: black">
                        Forgot password?
                      </small>
                    </router-link>
                    <!-- <b-col cols="6" class="text-right">
                      <router-link to="/register" class="text-light"
                        ><small>Create new account</small></router-link
                      >
                    </b-col> -->
                  </b-row>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </div>
    <TextFooter />
  </div>
</template>

<script>
// @ is an alias to /src
import TextFooter from "../components/TextFooter.vue";
import { baseDataMethods } from "../helpers/baseData";
import { authMethods } from "../helpers/auth";
import { GlobalStore } from "../globalStore";
import axios from "axios";
import { ubindMethods } from "../helpers/ubind";

export default {
  data() {
    return {
      username: "",
      password: "",
      rememberMe: false,
      loginResponse: "",
      emailResponse: "",
      pdfResponse: "",
      isSubmitting: false,
      year: new Date().getFullYear()
    };
  },
  name: "Login",
  methods: {
    onSubmit() {
      this.$toasted.clear();
      this.isSubmitting = true;
      authMethods
        .login(this.username, this.password)
        .then(async resp => {
          if (!resp.error) {
            var app = await authMethods.initAppInstance(true);

            if (this.rememberMe == true) {
              const encPassword = btoa(this.username + "|" + this.password);
              localStorage.setItem("rmt", encPassword);
            }
            this.$router.push("/dashboard");
            await ubindMethods.authenticateUbind().then(resp => {
              sessionStorage.setItem("ubindAccessToken", resp.accessToken);
            });
          } else {
            this.isSubmitting = false;
            localStorage.removeItem("rmt");
            this.$toasted
              .error("Incorrect username or password, please try again.", {
                position: "bottom-center"
              })
              .goAway(4000);
            console.log(resp);
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    }
    // doLogin() {
    //   authMethods.login(this.username, this.password).then(resp => {
    //     console.log(resp);
    //     if (!resp.error) {
    //       this.$router.push("/contentTemplates");
    //     }
    //   });
    // },
    // async createPDF() {
    //   var https = require("https");
    //   // var path = require("path");
    //   // var fs = require("fs");

    //   this.pdfResponse = "Waiting for PDF...";
    //   // The authentication key (API Key).
    //   // Get your own by registering at https://app.pdf.co/documentation/api
    //   const API_KEY = "alex@kora.net.au_6159b1121b1915c1";

    //   // HTML template
    //   const template = "./invoice_template.html";
    //   // Data to fill the template
    //   const templateData = "./invoice_data.json";
    //   // Destination PDF file name
    //   const DestinationFile = "./result.pdf";

    //   // Prepare request to `HTML To PDF` API endpoint
    //   var queryPath = `/v1/pdf/convert/from/html?name=test.pdf&async=True`;
    //   var reqOptions = {
    //     host: "api.pdf.co",
    //     path: encodeURI(queryPath),
    //     method: "POST",
    //     headers: {
    //       "x-api-key": API_KEY,
    //       "Content-Type": "application/json"
    //     }
    //   };
    //   var requestBody = JSON.stringify({
    //     html:
    //       "<h1>Test for username:" +
    //       this.username +
    //       "</h1><p>Some other text</p>",
    //     templateData: JSON.stringify({
    //       number: "1234567",
    //       date: "April 30, 2016",
    //       from:
    //         "Acme Inc., City, Street 3rd , +1 888 123-456, support@example.com",
    //       to: "Food Delivery Inc., New York, Some Street, 42",
    //       lines: [
    //         {
    //           title: "Setting up new web-site",
    //           quantity: 3,
    //           price: "50"
    //         },
    //         {
    //           title: "Configuring mail server and mailboxes",
    //           quantity: "5",
    //           price: "50"
    //         }
    //       ]
    //     })
    //   });
    //   // Send request
    //   var postRequest = https
    //     .request(reqOptions, response => {
    //       response.on("data", d => {
    //         // Parse JSON response
    //         var data = JSON.parse(d);
    //         if (data.error === false) {
    //           console.log(`Job #${data.jobId} has been created!`);
    //           this.checkIfJobIsCompleted(data.jobId, data.url);
    //         } else {
    //           // Service reported error
    //           console.log(data.message);
    //         }
    //       });
    //     })
    //     .on("error", e => {
    //       // Request error
    //       console.log(e);
    //     });

    //   // Write request data
    //   postRequest.write(requestBody);
    //   postRequest.end();
    // },
    // checkIfJobIsCompleted(jobId, resultFileUrl) {
    //   var https = require("https");
    //   const queryPath = `/v1/job/check`;

    //   const API_KEY = "alex@kora.net.au_6159b1121b1915c1";

    //   // JSON payload for api request
    //   const jsonPayload = JSON.stringify({
    //     jobid: jobId
    //   });

    //   const reqOptions = {
    //     host: "api.pdf.co",
    //     path: queryPath,
    //     method: "POST",
    //     headers: {
    //       "x-api-key": API_KEY,
    //       "Content-Type": "application/json",
    //       "Content-Length": Buffer.byteLength(jsonPayload, "utf8")
    //     }
    //   };

    //   // Send request
    //   var postRequest = https.request(reqOptions, response => {
    //     response.on("data", d => {
    //       response.setEncoding("utf8");

    //       // Parse JSON response
    //       const data = JSON.parse(d);
    //       console.log(
    //         `Checking Job #${jobId}, Status: ${
    //           data.status
    //         }, Time: ${new Date().toLocaleString()}`
    //       );

    //       if (data.status === "working") {
    //         // Check again after 3 seconds
    //         setTimeout(() => {
    //           this.checkIfJobIsCompleted(jobId, resultFileUrl);
    //         }, 3000);
    //       } else if (data.status === "success") {
    //         // Download PDF file
    //         // var file = fs.createWriteStream(DestinationFile);
    //         https.get(resultFileUrl, response2 => {
    //           console.log(response2.url);

    //           this.pdfResponse =
    //             "<a href='" + response2.url + "'>Download PDF</a>";

    //           // response2.pipe(file).on("close", () => {
    //           //   console.log(
    //           //     `Generated PDF file saved as "test.pdf" file.`
    //           //   );
    //           // });
    //           console.log(response2);
    //         });
    //       } else {
    //         console.log(`Operation ended with status: "${data.status}".`);
    //       }
    //     });
    //   });

    //   // Write request data
    //   postRequest.write(jsonPayload);
    //   postRequest.end();
    // },
    // async sendEmail() {
    //   var Realm = window.Realm;
    //   const config = {
    //     id: GlobalStore.realm_app_id
    //   };
    //   const app = new Realm.App(config);

    //   try {
    //     // Authenticate the user
    //     const credentials = Realm.Credentials.emailPassword(
    //       this.username,
    //       this.password
    //     );
    //     const user = await app.logIn(credentials);

    //     var emailResp = await user.functions.sendEmail(this.username);
    //     // `App.currentUser` updates to match the logged in user
    //     // assert(user.id === app.currentUser.id)
    //     // this.loginResponse = JSON.stringify(user);
    //     // this.loginResponse = "Success, your access token is:" + user._accessToken;

    //     console.log(emailResp);
    //     this.emailResponse = "Email Sent: ID: " + emailResp.MessageId;
    //     // loginResponse
    //   } catch (err) {
    //     console.log(err.message);
    //     // console.log(JSON.stringify(err));
    //     // this.loginResponse = "Login Failed: " + err.message;
    //   }
    // },
    // async createBaseDummyData() {
    //   var Realm = window.Realm;
    //   const config = {
    //     id: GlobalStore.realm_app_id
    //   };
    //   const app = new Realm.App(config);

    //   try {
    //     // Authenticate the user
    //     const credentials = Realm.Credentials.emailPassword(
    //       this.username,
    //       this.password
    //     );
    //     const user = await app.logIn(credentials);

    //     baseDataMethods.createBaseData(
    //       user,
    //       false,
    //       true,
    //       false,
    //       false,
    //       false,
    //       false
    //     );

    //     // console.log(resp);
    //   } catch (err) {
    //     console.log(err.message);
    //     // console.log(JSON.stringify(err));
    //     // this.loginResponse = "Login Failed: " + err.message;
    //   }
    // }
  },
  mounted() {
    //console.log(new URL(location.href).searchParams.get("autologout"));

    if (localStorage.getItem("rmt")) {
      const autoLogout = new URL(location.href).searchParams.get("autologout");
      const decPassword = atob(localStorage.getItem("rmt")).split("|");
      this.username = decPassword[0];
      this.password = decPassword[1];
      this.rememberMe = true;

      if (!autoLogout) {
        this.onSubmit();
      }
    }
    // var usersplit = this.CryptoJS.AES.decrypt(
    //   sessionStorage.getItem("pex"),
    //   GlobalStore.passkey
    // ).toString(this.CryptoJS.enc.Utf8).split("|");
    // console.log(usersplit);
  },
  components: {
    TextFooter
  }
};
</script>
<style scoped>
.copyright {
  color: black !important;
  margin-top: 30px;
}
.login-logo {
  width: 100%;
  max-width: 350px;
}
.digger {
  width: 100%;
  max-width: 300px;
  margin: 30px 0px 80px 0px;
}
.greeting span {
  color: black;
  font-size: 2rem;
  font-weight: bold;
}
.form-buttons {
  display: flex;
  justify-content: space-between;
}
.window-sizer {
  width: 100vw;
  max-width: 1900px;
}

a small {
  color: #ff4438 !important;
}
</style>
