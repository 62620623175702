<template>
  <div>
    <div v-if="activityStatus == 'new' || activityStatus == 'edit'">
      <base-header class="pb-6 bg-primary">
        <b-row class="align-items-center py-4">
          <b-col lg="6" cols="7">
            <h6 class="h2 text-white d-inline-block mb-0">{{ $route.name }}</h6>
          </b-col>
        </b-row>
      </base-header>
      <div class="container-fluid mt--6">
        <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
          <form
            id="brokerForm"
            class="needs-validation"
            @submit.prevent="handleSubmit(firstFormSubmit)"
            autocomplete="off"
          >
            <b-card no-body class="mb-4">
              <b-card-header>
                <h3 class="mb-0">
                  {{
                    activityStatus == "add"
                      ? "Create new broker"
                      : "Editing: " +
                        model.brokerFName +
                        " " +
                        model.brokerLName
                  }}
                </h3>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col md="4">
                    <base-input
                      name="Broker First Name"
                      label="Broker First Name"
                      v-model="model.brokerFName"
                      placeholder="John"
                      :rules="formRules.brokerFName"
                    />
                  </b-col>
                  <b-col md="4">
                    <base-input
                      name="Broker Last Name"
                      label="Broker Last Name"
                      v-model="model.brokerLName"
                      placeholder="Smith"
                      :rules="formRules.brokerLName"
                    />
                  </b-col>
                  <b-col md="4">
                    <base-input name="Member Of" label="Member Of">
                      <el-select
                        v-model="model.organisationId"
                        filterable
                        placeholder="Add or search for an organisation"
                      >
                        <el-option
                          v-for="broker in brokerOrgs"
                          :key="broker.Broker_org_id"
                          :label="broker.Broker_Org_Name"
                          :value="broker.Broker_org_id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <base-input
                      label="Broker Phone Number"
                      name="Broker Phone Number"
                      v-model="model.brokerPhone"
                      :rules="formRules.brokerPhone"
                    />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="4">
                    <base-input
                      name="Broker Email"
                      label="Broker Email (username)"
                      v-model="model.brokerEmail"
                      placeholder="john@example.com"
                      :rules="formRules.brokerEmail"
                    />
                  </b-col>
                  <b-col md="4">
                    <base-input
                      :disabled="activityStatus == 'edit'"
                      name="Broker Password"
                      label="Broker Password"
                      type="password"
                      v-model="model.brokerPassword"
                      placeholder=""
                    />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="4">
                    <base-input
                      autocomplete="off"
                      label="uBind Username"
                      name="uBind Useername"
                      v-model="model.UBindUsername"
                      :rules="formRules.UBindUsername"
                    />
                  </b-col>
                  <b-col md="4">
                    <base-input
                      autocomplete="new-password"
                      name="uBind Password"
                      label="uBind Password"
                      type="password"
                      v-model="model.UBindPassword"
                      placeholder="john@example.com"
                      :rules="formRules.UBindPassword"
                    />
                  </b-col>
                  <b-col md="4">
                    <base-button
                      :disabled="
                        model.brokerEmail == '' ||
                          (model.UBindPassword != undefined &&
                            model.UBindPassword != '' &&
                            model.UBindUsername != undefined &&
                            model.UBindUsername != '')
                      "
                      @click="createUbindUser"
                      type="primary"
                      style="width:100% !important;position:relative; margin-top:32px;"
                      >Create uBind User</base-button
                    >
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="12">
                    <h3>Access Permissions</h3>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6">
                    <b-row>
                      <b-col>
                        <base-input name="Broker Active" label="Broker Active">
                          <base-switch
                            on-text="Yes"
                            off-text="No"
                            v-model="model.brokerActive"
                          >
                          </base-switch>
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input
                          name="Broker Organisation Admin"
                          label="Broker Organisation Admin"
                        >
                          <base-switch
                            on-text="Yes"
                            off-text="No"
                            v-model="model.brokerOrgAdmin"
                          >
                          </base-switch>
                        </base-input>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="6">
                    <b-row>
                      <b-col>
                        <base-input name="Super Admin" label="Super Admin">
                          <base-switch
                            on-text="Yes"
                            off-text="No"
                            v-model="model.brokerSuperAdmin"
                          >
                          </base-switch>
                        </base-input>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="12">
                    <h3>Features</h3>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="4">
                    <b-row>
                      <!-- <b-col>
                        <base-input label="Can see Plant and Equipment">
                          <button-radio-group
                            id="can-see-plant-equipment"
                            :value="model.enablePlantEquipment"
                            v-model="model.enablePlantEquipment"
                            :options="twoOptions"
                            name="can-see-plant-equipment"
                            buttons
                            style="margin-top: 0px;"
                          ></button-radio-group>
                        </base-input>
                      </b-col> -->
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input label="Can see Light Commercial">
                          <button-radio-group
                            id="can-see-light-commercial"
                            :value="model.enableLightCommercial"
                            v-model="model.enableLightCommercial"
                            :options="threeOptions"
                            name="can-see-light-commercial"
                            buttons
                            style="margin-top: 0px;"
                          ></button-radio-group>
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input label="Can see Rideshare">
                          <button-radio-group
                            id="can-see-ride-share"
                            :value="model.enableRideShare"
                            v-model="model.enableRideShare"
                            :options="threeOptions"
                            name="can-see-ride-share"
                            buttons
                            style="margin-top: 0px;"
                          ></button-radio-group>
                        </base-input>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col md="4">
                    <!-- <b-row>
                      <b-col>
                        <base-input label="Can Receive Invoice">
                          <button-radio-group
                            id="can-receive-invoice"
                            :value="model.can_receive_invoice"
                            v-model="model.can_receive_invoice"
                            :options="threeOptions"
                            name="can-receive-invoice"
                            buttons
                            style="margin-top: 0px;"
                          ></button-radio-group>
                        </base-input>
                      </b-col>
                    </b-row> -->
                    <b-row>
                      <b-col>
                        <base-input label="Can see Fleet Management">
                          <button-radio-group
                            id="can-see-fleet-management"
                            :value="model.can_see_fleet_management"
                            v-model="model.can_see_fleet_management"
                            :options="threeOptions"
                            name="can-see-fleet-management"
                            buttons
                            style="margin-top: 0px;"
                          ></button-radio-group>
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input label="Can see Claims">
                          <button-radio-group
                            id="can-see-claims"
                            :value="model.can_see_claims"
                            v-model="model.can_see_claims"
                            :options="threeOptions"
                            name="can-see-claims"
                            buttons
                            style="margin-top: 0px;"
                          ></button-radio-group>
                        </base-input>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col md="4">
                    <b-row>
                      <b-col>
                        <base-input label="Can see Fleet">
                          <button-radio-group
                            id="can-see-fleet"
                            :value="model.can_see_fleet"
                            v-model="model.can_see_fleet"
                            :options="threeOptions"
                            name="can-see-fleet"
                            buttons
                            style="margin-top: 0px;"
                          ></button-radio-group>
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input label="Can Cancel Policy">
                          <button-radio-group
                            id="can-cancel-policy"
                            :value="model.can_cancel_policy"
                            v-model="model.can_cancel_policy"
                            :options="threeOptions"
                            name="can-cancel-policy"
                            buttons
                            style="margin-top: 0px;"
                          ></button-radio-group>
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input label="Can Adjust Policy">
                          <button-radio-group
                            id="can-adjust-policy"
                            :value="model.can_adjust_policy"
                            v-model="model.can_adjust_policy"
                            :options="threeOptions"
                            name="can-adjust-policy"
                            buttons
                            style="margin-top: 0px;"
                          ></button-radio-group>
                        </base-input>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row class="d-flex justify-content-center">
                  <base-button
                    @click="resetBrokerForm"
                    type="danger"
                    class="mr-4"
                    >Cancel</base-button
                  >
                  <base-button @click="saveChanges" type="danger"
                    >Save</base-button
                  >
                </b-row>
              </b-card-body>
            </b-card>
          </form>
        </validation-observer>
      </div>
    </div>

    <div
      class="container-fluid"
      v-if="
        viewMode == 'list' &&
          !(activityStatus == 'new' || activityStatus == 'edit')
      "
    >
      <card body-classes="px-0 pb-1" footer-classes="pb-2">
        <div>
          <b-col
            cols="12"
            class="d-flex justify-content-center justify-content-sm-between flex-wrap change-color"
          >
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>

            <div>
              <base-input
                v-model="searchQuery"
                prepend-icon="fas fa-search"
                placeholder="Search..."
                v-on:input="resetPagination()"
              >
              </base-input>
            </div>
            <div>
              <base-button @click="addBroker()" type="danger"
                >Add new Broker</base-button
              >
            </div>
          </b-col>
          <el-table
            :data="queriedData"
            row-key="id"
            header-row-class-name="thead-light"
            @sort-change="sortChange"
            @selection-change="selectionChange"
            v-loading="loading"
          >
            <el-table-column min-width="90px" align="left" label="Edit">
              <div slot-scope="{ row }">
                <base-button
                  @click.native="populateForm(row)"
                  class="edit"
                  type="danger"
                  size="sm"
                  icon
                  v-b-tooltip.hover
                  title="View / Edit"
                >
                  <i class="text-white ni ni-ruler-pencil"></i>
                </base-button>
              </div>
            </el-table-column>

            <el-table-column
              label="Last Login"
              prop="Last_login"
              min-width="190px"
              sortable
              :formatter="row => formatLastLogin(row)"
            >
            </el-table-column>

            <el-table-column
              label="Organisation"
              prop="Broker_org_name"
              min-width="190px"
              sortable
            >
            </el-table-column>

            <el-table-column
              label="Name"
              prop="Broker_name"
              min-width="190px"
              sortable
            >
            </el-table-column>

            <el-table-column
              label="Email"
              prop="Broker_email"
              min-width="180px"
            >
            </el-table-column>

            <el-table-column
              label="Phone"
              prop="Broker_phone"
              min-width="150px"
            >
            </el-table-column>

            <el-table-column
              label="Admin"
              prop="Broker_org_admin"
              min-width="120px"
              :formatter="row => (row.Broker_org_admin === true ? 'Yes' : 'No')"
              :filters="[
                { text: 'Yes', value: true },
                { text: 'No', value: false }
              ]"
              :filter-method="filterOrgAdmin"
            >
            </el-table-column>

            <el-table-column
              label="Super Admin"
              prop="Broker_super_admin"
              min-width="120px"
              :formatter="
                row => (row.Broker_super_admin === true ? 'Yes' : 'No')
              "
              :filters="[
                { text: 'Yes', value: true },
                { text: 'No', value: false }
              ]"
              :filter-method="filterSuperAdmin"
            >
            </el-table-column>

            <el-table-column label="Send Welcome Email" min-width="160px">
              <div slot-scope="{ row }">
                <base-button
                  v-b-tooltip.hover
                  type="danger"
                  @click="sendWelcomeEmail(row)"
                  >Send</base-button
                >
              </div>
            </el-table-column>

            <el-table-column label="Send Password Reset" min-width="160px">
              <div slot-scope="{ row }">
                <base-button
                  v-b-tooltip.hover
                  type="danger"
                  @click="sendResetEmail(row)"
                  >Send</base-button
                >
              </div>
            </el-table-column>
            <template #empty>
              <p>{{ loading ? "" : "No broker found" }}</p>
            </template>
          </el-table>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div>
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

              <span v-if="selectedRows.length">
                &nbsp; &nbsp; {{ selectedRows.length }} rows selected
              </span>
            </p>
          </div>
          <base-pagination
            class="pagination-no-border"
            :value="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
            @change="paginationChanged($event)"
          >
          </base-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { GlobalStore } from "../../globalStore";
import { GlobalMethods } from "../../globalMethods";
import { emailMethods } from "../../helpers/email";
import { authMethods } from "../../helpers/auth";
import { formattingMethods } from "../../helpers/formatting";
import { toasts } from "../../helpers/toasts";
import { brokerPageMethods } from "../../helpers/brokers";
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "@/components";
import clientPaginationMixin from "../../components/PaginatedTables/clientPaginationMixin";
import { ubindMethods } from "../../helpers/ubind";
import ButtonRadioGroup from "../../components/ButtonRadioGroup.vue";
import { appFunctions } from "../../helpers/appFunctions";

export default {
  data() {
    return {
      loading: false,
      realmApp: null,
      brokerObj: null,
      activityStatus: "add",
      currentBrokerId: "",
      currentPassword: "",
      currentItem: {},
      viewMode: "list",
      tableData: [],
      brokerOrgs: [],
      selectedRows: [],
      model: {
        brokerFName: "",
        brokerLName: "",
        brokerEmail: "",
        brokerPhone: "",
        organisationId: "",
        realmUserId: "",
        brokerActive: true,
        brokerSuperAdmin: false,
        brokerOrgAdmin: false,
        /**
         * Toggle for the brokers access to P&E pages
         */
        enablePlantEquipment: null,
        /**
         * Toggle for the brokers access to LC pages
         */
        enableLightCommercial: null,
        /**
         * Toggle for the brokers access to RS pages
         */
        enableRideShare: null,
        UBindPassword: "",
        UBindUSername: "",
        can_cancel_policy: null,
        can_receive_invoice: null,
        /**
         * Toggle for the brokers access to FM pages
         */
        can_see_fleet_management: null,
        can_see_claims: null,
        can_see_fleet: null,
        can_adjust_policy: null
      },
      threeOptions: [
        {
          label: 'Inherit',
          value: null
        },
        {
          label: 'Yes',
          value: true
        },
        {
          label: 'No',
          value: false
        }
      ],
      twoOptions: [
        {
          label: 'Yes',
          value: true
        },
        {
          label: 'No',
          value: false
        }
      ]
    };
  },
  name: "Brokers",
  methods: {
    addBroker() {
      this.resetBrokerForm()
      this.activityStatus = "new";
    },
    // TABLE METHODS
    paginationChanged(page) {
      this.pagination.currentPage = page;
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    formatLastLogin(data) {
      return data.Last_login
        ? formattingMethods.formatDateTime(data.Last_login)
        : "No login data";
    },
    sendResetEmail(broker) {
      // this.realmApp.emailPasswordAuth.callResetPasswordFunction(
      //   "alex@kora.net.au",
      //   "Rocket7530!",
      //   []
      // );
      // this.realmApp.emailPasswordAuth.callResetPasswordFunction(
      //   broker.Broker_email,
      //   "temp1234!",
      //   ["reset"]
      // );

      //toasts.success(`Reset password email sent to ${broker.Broker_email}`);

      // const brokerObj = await appFunctions.crud_broker("getByRealmId", {
      //         Realm_user_id: broker.Realm_user_id
      //       });

      var emailToken = GlobalMethods.uuidv4();
      var emailTokenId = GlobalMethods.uuidv4();

      // await appFunctions.crud_broker("updatePasswordResetTokens", {
      //   Broker_id: brokerObj.data.Broker_id,
      //   emailToken: emailToken,
      //   emailTokenId: emailTokenId
      // });

      appFunctions
        .requestPasswordReset(emailToken, emailTokenId, broker.Broker_email)
        .then(resp => {
          if (!resp.error) {
            toasts.success(
              `Reset password email sent to ${broker.Broker_email}`
            );
            this.emailAddress = "";
          } else {
            toasts.error(resp.error);
          }
        });
    },

    async sendWelcomeEmail(broker) {
      const brokerObj = await appFunctions.crud_broker("getByRealmId", {
        Realm_user_id: broker.Realm_user_id
      });

      var emailToken = GlobalMethods.uuidv4();
      var emailTokenId = GlobalMethods.uuidv4();

      await appFunctions.crud_broker("updatePasswordResetTokens", {
        Broker_id: brokerObj.data.Broker_id,
        emailToken: emailToken,
        emailTokenId: emailTokenId
      });

      brokerObj.data["emailToken"] = emailToken;
      brokerObj.data["emailTokenId"] = emailTokenId;

      emailMethods.sendWelcomeEmail(this.realmApp, brokerObj);

      toasts.success(`Welcome password email sent to ${broker.Broker_email}`);
    },

    formatName(fName, lName) {
      return fName + lName;
    },

    async getBrokerOrgs() {
      await appFunctions.crud_brokerOrg("list").then(resp => {
        this.brokerOrgs = resp.data;
      });
    },

    findBrokerOrg(org_id) {
      var tmp = "Could not find organisation.";
      this.brokerOrgs.forEach(org => {
        if (org.Broker_org_id == org_id) {
          tmp = org.Broker_Org_Name;
        }
      });
      return tmp;
    },
    generatePassword(passwordLength) {
      let password = "";
      var chars =
        "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";

      for (var i = 0; i <= passwordLength; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber + 1);
      }
      return password;
    },
    // UBIND METHODS
    async createUbindUser() {
      var orgResp = await appFunctions.crud_brokerOrg("get", {
        Broker_org_id: this.model.organisationId
      });

      const orgObj = orgResp.data;

      var insBrokerResp = await appFunctions.crud_insuranceBroker("get", {
        insurance_broker_id: orgObj.insurance_broker_id
      });

      const insBrokerOrg = insBrokerResp.data;

      const requestBody = {
        tenant: GlobalStore.ubindClientInfo.tenantId,
        organisation: insBrokerOrg.ubind_organisation_id,
        emailAddress: insBrokerOrg.ubind_service_username,
        plaintextPassword: insBrokerOrg.ubind_service_password
      };
      const ubindResp = await appFunctions.ubindAuthorise(requestBody);
      const newPassword = this.generatePassword(12) + "Az6*";
      let userRole = "";

      if (orgObj.is_insurance_broker == true) {
        userRole = GlobalStore.ubindClientInfo.orgadminRoleId;
      } else {
        userRole = GlobalStore.ubindClientInfo.brokerRoleId;
      }

      const userObj = {
        preferredName: "",
        fullName: `${this.model.brokerFName} ${this.model.brokerLName}`,
        namePrefix: "",
        firstName: this.model.brokerFName,
        middleNames: "",
        lastName: this.model.brokerLName,
        nameSuffix: "",
        company: GlobalStore.brokerObj.parentOrg.Broker_Org_Name,
        loginEmail: this.model.brokerEmail,
        title: "",
        email: this.model.brokerEmail,
        picture: null,
        userType: "Client",
        default: false,
        userStatus: "Active",
        blocked: false,
        tenantId: "mercurien",
        sendActivationInvitation: false,
        password: newPassword,
        ownerId: null,
        pictureId: null,
        referrerId: null,
        createdDateTime: null,
        lastModifiedDateTime: null,
        organisationId: insBrokerOrg.ubind_organisation_id,
        organisationName: null,
        emailAddresses: [
          {
            emailAddress: this.model.brokerEmail,
            label: "work",
            customLabel: "",
            sequenceNo: 0,
            default: true,
            fieldId: "00000000-0000-0000-0000-000000000000"
          }
        ],
        // phoneNumbers: [
        //   {
        //     phoneNumber: this.model.brokerPhone,
        //     label: "work",
        //     customLabel: "",
        //     sequenceNo: 0,
        //     default: false,
        //     fieldId: "00000000-0000-0000-0000-000000000000"
        //   }
        // ],
        websiteAddresses: [],
        streetAddresses: [],
        messengerIds: [],
        socialMediaIds: [],
        environment: GlobalStore.ubindClientInfo.environment,
        InitialRoles: [userRole],
        portalId: ""
      };
      await ubindMethods.ubindCreateUser(userObj, ubindResp.accessToken);

      this.model.UBindPassword = newPassword;
      this.model.UBindUsername = this.model.brokerEmail;

      toasts.success(`User created successfully in uBind Portal`);

      //console.log(userObj);
    },

    // UPDATE/NEW BROKER METHODS
    async populateForm(row) {
      var rowResp = await appFunctions.crud_broker("get", {
        Broker_id: row.Broker_id
      });

      row = rowResp.data;
      this.activityStatus = "edit";
      this.currentBrokerId = row.Broker_id;

      this.model.brokerFName = row.Broker_first_name;
      this.model.brokerLName = row.Broker_last_name;
      this.model.brokerPassword = row.Broker_password;
      this.model.brokerEmail = row.Broker_email;
      this.model.brokerPhone = row.Broker_phone;
      this.model.organisationId = row.Broker_org_id;
      this.model.brokerActive = row.Broker_active;
      this.model.brokerSuperAdmin = row.Broker_super_admin;
      this.model.brokerOrgAdmin = row.Broker_org_admin;
      this.model.realmUserId = row.Realm_user_id;
      this.model.enablePlantEquipment = row.Show_pe;
      this.model.enableLightCommercial = row.Show_lc;
      this.model.enableRideShare = row.Show_rs;
      this.model.can_see_fleet_management =
        row.can_see_fleet_management != undefined
          ? row.can_see_fleet_management
          : null;
      this.model.ub = row.Show_lc;
      this.model.UBindPassword = row.UBindPassword;
      this.model.UBindUsername = row.UBindUsername;
      this.model.can_cancel_policy =
        row.can_cancel_policy != undefined ? row.can_cancel_policy : null;
      this.model.can_receive_invoice =
        row.can_receive_invoice != undefined ? row.can_receive_invoice : null;
      this.model.can_see_claims =
        row.can_see_claims != undefined ? row.can_see_claims : null;
      this.model.can_see_fleet =
        row.can_see_fleet != undefined ? row.can_see_fleet : null;

      this.currentPassword = row.Broker_password;
      this.model.can_adjust_policy = row.can_adjust_policy != undefined ? row.can_adjust_policy : null;
    },

    async saveChanges() {
      const isValid = await this.$refs.formValidator.validate();
      if (!isValid) {
        toasts.error("Please complete the required fields before proceeding.");
        return;
      }

      var tmpData = {
        Broker_org_id: this.model.organisationId,
        Broker_first_name: this.model.brokerFName,
        Broker_last_name: this.model.brokerLName,
        Broker_email: this.model.brokerEmail,
        Broker_password: this.model.brokerPassword,
        Broker_phone: this.model.brokerPhone,
        Broker_active: this.model.brokerActive,
        Broker_org_admin: this.model.brokerOrgAdmin,
        Broker_super_admin: this.model.brokerSuperAdmin,
        Realm_user_id: this.model.realmUserId,
        Show_pe: this.model.enablePlantEquipment,
        Show_lc: this.model.enableLightCommercial,
        Show_rs: this.model.enableRideShare,
        UBindPassword: this.model.UBindPassword,
        UBindUsername: this.model.UBindUsername,
        can_see_fleet_management: this.model.can_see_fleet_management,
        can_see_claims: this.model.can_see_claims,
        can_see_fleet: this.model.can_see_fleet,
        can_receive_invoice: this.model.can_receive_invoice,
        can_cancel_policy: this.model.can_cancel_policy,
        can_adjust_policy: this.model.can_adjust_policy
      };

      if (this.currentBrokerId) {
        // Existing broker has been edited
        tmpData.Broker_id = this.currentBrokerId;

        appFunctions
          .crud_broker("update", tmpData)
          .then(async res => {
            if (res.data == "OK") {
              await this.refreshBrokerList();
              await this.resetBrokerForm();
              toasts.success("Broker updated successfully!");
            }
          })
          .catch(err => {
            toasts.error(`Failed to update broker: ${err}`);
          });
      } else {
        // New broker will be added
        var newBrokerId = await appFunctions.crud_broker("getNextBrokerId");
        tmpData.Broker_id = newBrokerId.data;

        var realmUserCreated = false;
        let user_id = "";

        try {
          var user = await appFunctions.registerUser(
            this.model.brokerEmail,
            this.model.brokerPassword,
            this.model.brokerFName,
            this.model.brokerLName
          );
          realmUserCreated = true;

          user_id = user.user_id;
        } catch (err) {
          realmUserCreated = false;
          toasts.error(`Failed to create user in Auth0: ${err}`);
        }

        if (realmUserCreated == true) {
          tmpData.Realm_user_id = user_id;
          appFunctions
            .crud_broker("create", tmpData)
            .then(res => {
              if (res.data == "OK") {
                toasts.success("Broker created successfully!");
                this.refreshBrokerList();
                this.resetBrokerForm();
              }
            })
            .catch(err => {
              toasts.error(`Failed to add broker: ${err}`);
            });
        }
      }
    },
    async refreshBrokerList() {
      this.tableData = []
      const show = await this.$root.$refs.DashboardLayout.disableOrEnabledMenu()
      this.loading = true
      await this.getBrokerOrgs().then(() => {
        appFunctions.crud_broker("list")
        .then(res => {
          var tmpData = res.data;
          tmpData.forEach(broker => {
            broker.Broker_name =
              broker.Broker_first_name + " " + broker.Broker_last_name;
            broker.Broker_org_name = this.findBrokerOrg(broker.Broker_org_id);
          });
          this.tableData = [...tmpData];

          if (!this.fuzeSearch) {
            this.initFuse([
              "Broker_name",
              "Broker_org_name",
              "Broker_email",
              "Broker_phone"
            ]);
          }
        })
        .finally(() => {
          this.loading = false;
        })
      });
    },

    resetBrokerForm() {
      // Reset flags
      this.activityStatus = "add";
      this.currentBrokerId = "";

      // Reset Modal
      this.model.brokerFName = "";
      this.model.brokerLName = "";
      this.model.brokerEmail = "";
      this.model.brokerPhone = "";
      this.model.organisationId = "";
      this.model.realmUserId = "";
      this.model.brokerActive = true;
      this.model.brokerSuperAdmin = false;
      this.model.brokerOrgAdmin = false;
      this.model.enablePlantEquipment = this.brokerObj.parentOrg.default_show_pe;
      this.model.enableLightCommercial = null;
      this.model.enableRideShare = null;
      this.model.can_see_fleet_management = this.brokerObj.parentOrg.can_see_fleet_management;
      this.model.UBindPassword = "";
      this.model.UBindUsername = "";
    },

    /**
     * Used by the filter dropdown to filter the table by org admin status
     * @param {string} value - the value of the filter dropdown
     * @param {object} row - the row of the table
     * @returns {boolean} - true if the row should be shown, false if not
     */
    filterOrgAdmin(value, row) {
      return row.Broker_org_admin === value;
    },

    /**
     * Used by the filter dropdown to filter the table by super admin status
     * @param {string} value - the value of the filter dropdown
     * @param {object} row - the row of the table
     * @returns {boolean} - true if the row should be shown, false if not
     */
    filterSuperAdmin(value, row) {
      return row.Broker_super_admin === value;
    }
  },

  computed: {
    formRules() {
      return brokerPageMethods.getBrokerFormRules("in_progress");
    }
  },

  async mounted() {
    this.loading = true;
    this.realmApp = GlobalStore.realmApp;
    this.brokerObj = GlobalStore.brokerObj;

    await this.refreshBrokerList();
  },
  mixins: [clientPaginationMixin],
  components: {
    BasePagination,
    ButtonRadioGroup,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  }
};
</script>

<style lang="scss">
.brokerList {
  table {
    margin: auto;
    width: 800px;
  }
  td {
    padding: 10px;
    text-align: left;
  }

  .editor__content {
    height: 400px;
    border: 1px solid lightgray;
    overflow-y: auto;
    text-align: left;
  }

  .editorFrame {
    width: 930px;
    margin: auto;
  }

  .field-layout {
    text-align: left;
    padding: 10px;
    position: relative;
    &__col1 {
      width: 200px;
      display: inline-block;
    }
    &__col2 {
      display: inline-block;
      input {
        width: 400px;
      }
    }
  }
}
</style>
