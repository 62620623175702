<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">Quotes and Policies</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- <route-bread-crumb></route-bread-crumb> -->
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral" @click="newQuote()"
            >New</base-button
          >
        </b-col>
      </b-row>
    </base-header>
    <div class="mt--6 px-2 px-sm-4">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <div>
            <b-col
              cols="12"
              class="d-flex justify-content-center justify-content-sm-between flex-wrap pb-2"
            >
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <!-- <div>
                <base-input
                  v-model="searchQuery"
                  prepend-icon="fas fa-search"
                  placeholder="Search..."
                >
                </base-input>
              </div> -->
            </b-col>
            <el-table
              :data="queriedData"
              row-key="id"
              :default-sort="{
                prop: 'Customer_policy_quote_generated_date',
                order: 'descending'
              }"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
              @selection-change="selectionChange"
              v-loading="loading"
            >
              <el-table-column min-width="100px" align="left" label="view">
                <div slot-scope="{ $index, row }" class="d-flex">
                  <!-- <base-button
                    @click.native="handleLike($index, row)"
                    class="like btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="text-white ni ni-like-2"></i>
                  </base-button> -->
                  <base-button
                    @click.native="handleEdit($index, row)"
                    class="edit"
                    type="danger"
                    size="sm"
                    icon
                    v-b-tooltip.hover
                    title="View Quote"
                  >
                    <i class="text-white ni ni-ruler-pencil"></i>
                  </base-button>
                  <!-- <base-button
                    @click.native="handleDelete($index, row)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="text-white ni ni-fat-remove"></i>
                  </base-button> -->
                </div>
              </el-table-column>

              <el-table-column
                label="Status"
                min-width="170px"
                prop="Customer_policy_status"
                sortable
              >
                <template v-slot="{ row }">
                  <badge class="badge-dot mr-4" type="">
                    <i :class="`bg-${row.statusClass}`"></i>
                    <span class="status">{{ row.statusText }}</span>
                  </badge>
                </template>
              </el-table-column>

              <el-table-column
                label="Insured Name"
                prop="Customer_insured_name"
                min-width="190px"
                sortable
              >
              </el-table-column>

              <el-table-column
                label="Equipment Value"
                prop="Customer_policy_equipment_value"
                min-width="190px"
                sortable
              >
              </el-table-column>

              <el-table-column
                label="Total Premium"
                prop="Customer_policy_insurance_total_premium"
                min-width="180px"
                sortable
              >
              </el-table-column>

              <el-table-column
                label="Created Date"
                prop="Customer_policy_quote_generated_date"
                min-width="180px"
                sortable
              >
                <template slot-scope="scope">
                  <span class="date">{{
                    formatDate(scope.row.Customer_policy_quote_generated_date)
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Created By"
                prop="createdByName"
                min-width="180px"
                sortable
              >
              </el-table-column>
              <template #empty>
                <p>{{ loading ? "" : "No Data" }}</p>
              </template>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{ selectedRows.length }} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { BasePagination } from "@/components";
import clientPaginationMixin from "../components/PaginatedTables/clientPaginationMixin";
import swal from "sweetalert2";
import users from "./users2";
import { GlobalStore } from "../globalStore";
import { formattingMethods } from "../helpers/formatting";
import { appFunctions } from "../helpers/appFunctions";

export default {
  data() {
    return {
      //quoteList:[],
      propsToSearch: ["Customer_policy_insured_name"],
      // tableColumns: [
      //   {
      //     type: "selection"
      //   },
      //   {
      //     prop: "Customer_policy_insured_name",
      //     label: "Insured Name",
      //     minWidth: 160,
      //     sortable: true
      //   },
      //   {
      //     prop: "Customer_policy_equipment_value",
      //     label: "Equipment Value",
      //     minWidth: 120,
      //     sortable: true
      //   },
      //   {
      //     prop: "Customer_policy_insurance_total_premium",
      //     label: "Total Premium",
      //     minWidth: 120,
      //     sortable: true
      //   },
      //   {
      //     prop: "Customer_policy_bound_date",
      //     label: "Created Date",
      //     minWidth: 100,
      //     sortable: true
      //   }
      //   // {
      //   //   prop: 'Customer_policy_status',
      //   //   label: 'Status',
      //   //   minWidth: 150,
      //   //   sortable: true
      //   // },
      //   // {
      //   //   prop: 'Customer_policy_created_by',
      //   //   label: 'Created By',
      //   //   minWidth: 120,
      //   //   sortable: true
      //   // },
      //   // {
      //   //   prop: 'Customer_policy_bound_date',
      //   //   label: 'Bound Date',
      //   //   minWidth: 120,
      //   //   sortable: true
      //   // }
      // ],
      tableData: [],
      selectedRows: [],
      loading: true
    };
  },
  methods: {
    dateFormatter(dateVal) {
      console.log(dateVal);
      return "";
    },
    newQuote() {
      this.$router.push("/createQuote");
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
    },
    // handleLike(index, row) {
    //   swal({
    //     title: `You liked ${row.name}`,
    //     buttonsStyling: false,
    //     type: 'success',
    //     confirmButtonClass: 'btn btn-success btn-fill'
    //   });
    // },
    handleEdit(index, row) {
      this.$router.push("quotes/" + row.Customer_policy_id);
      // swal({
      //   title: `You want to edit ${row.name}`,
      //   buttonsStyling: false,
      //   confirmButtonClass: 'btn btn-info btn-fill'
      // });
    },
    formatDate(dateVal) {
      return formattingMethods.formatDate(dateVal);
    },
    handleDelete(index, row) {
      // swal({
      //   title: 'Are you sure?',
      //   text: `You won't be able to revert this!`,
      //   type: 'warning',
      //   showCancelButton: true,
      //   confirmButtonClass: 'btn btn-success btn-fill',
      //   cancelButtonClass: 'btn btn-danger btn-fill',
      //   confirmButtonText: 'Yes, delete it!',
      //   buttonsStyling: false
      // }).then(result => {
      //   if (result.value) {
      //     this.deleteRow(row);
      //     swal({
      //       title: 'Deleted!',
      //       text: `You deleted ${row.name}`,
      //       type: 'success',
      //       confirmButtonClass: 'btn btn-success btn-fill',
      //       buttonsStyling: false
      //     });
      //   }
      // });
    },
    deleteRow(row) {
      // let indexToDelete = this.tableData.findIndex(
      //   tableRow => tableRow.id === row.id
      // );
      // if (indexToDelete >= 0) {
      //   this.tableData.splice(indexToDelete, 1);
      // }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    }
  },
  mounted() {
    if (GlobalStore.brokerObj.Broker_super_admin) {
      appFunctions
        .crud_customerPolicy("listCombined", {
          Realm_user_id: GlobalStore.brokerObj.Realm_user_id
        })
        .then(resp => {
          console.log(resp);
          var tdata = resp.data;

          tdata.forEach(q => {
            q.status = q.Customer_policy_status;
            q.statusType = "warning";
            q.Customer_policy_equipment_value =
              "$" +
              formattingMethods.numberWithCommas(
                q.Customer_policy_equipment_value
              );
            q.Customer_policy_insurance_total_premium =
              "$" +
              formattingMethods.numberWithCommas(
                q.Customer_policy_insurance_total_premium
              );
            q.Customer_policy_quote_generated_date = this.$moment(
              q.Customer_policy_quote_generated_date,
              "YYYY-MM-DD hh:mm:ss a"
            ).toDate();
          });

          this.tableData = tdata;
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      appFunctions
        .crud_customerPolicy("listCombinedByRealmId", {
          Realm_user_id: GlobalStore.brokerObj.Realm_user_id
        })
        .then(resp => {
          console.log(resp);
          var tdata = resp.data;

          tdata.forEach(q => {
            q.status = q.Customer_policy_status;
            q.statusType = "warning";
            q.Customer_policy_equipment_value =
              "$" +
              formattingMethods.numberWithCommas(
                q.Customer_policy_equipment_value
              );
            q.Customer_policy_insurance_total_premium =
              "$" +
              formattingMethods.numberWithCommas(
                q.Customer_policy_insurance_total_premium
              );
            //q.Customer_policy_quote_generated_date = formattingMethods.formatDate(
            //q.Customer_policy_quote_generated_date
            //);

            q.Customer_policy_quote_generated_date = this.$moment(
              q.Customer_policy_quote_generated_date,
              "YYYY-MM-DD hh:mm:ss a"
            ).toDate();
          });

          tdata.sort(function(a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return (
              new Date(b.Customer_policy_quote_generated_date) -
              new Date(a.Customer_policy_quote_generated_date)
            );
          });

          this.tableData = tdata;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mixins: [clientPaginationMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  }
};
</script>
<style scoped>
.container-fluid {
  max-width: 1800px;
  margin: 0px 30px 0px 30px;
}
</style>
