import Vue from "vue";
import { GlobalStore } from "../globalStore";
import axios from "axios";
import { appFunctions } from "../helpers/appFunctions";
import { authMethods } from "../helpers/auth"

export const ubindMethods = new Vue({
  methods: {
    authenticateUbind() {
      const requestBody = {
        tenant: GlobalStore.ubindClientInfo.tenantId,
        organisation:
          GlobalStore.brokerObj.parentOrg.parentInsuranceBroker
            .ubind_organisation_id,
        emailAddress: GlobalStore.brokerObj.UBindUsername,
        plaintextPassword: GlobalStore.brokerObj.UBindPassword
      };

      return appFunctions.ubindAuthorise(requestBody);
    },
    async ubindCreateCustomer(requestBody) {
      await this.checkExpiredUbindToken()
      return appFunctions.ubindCreateCustomer(
        requestBody,
        sessionStorage.getItem("ubindAccessToken")
      );
    },
    async ubindCreateQuote(requestBody) {
      await this.checkExpiredUbindToken()
      return appFunctions.ubindCreateQuote(
        requestBody,
        sessionStorage.getItem("ubindAccessToken")
      );
    },
    async ubindCreateUser(requestBody, accessToken) {
      await this.checkExpiredUbindToken()
      return appFunctions.ubindCreateUser(
        JSON.stringify(requestBody),
        accessToken
      );
    },
    async ubindCancelPolicy(policyId) {
      await this.checkExpiredUbindToken()
      return appFunctions.ubindCancelPolicy(
        policyId,
        sessionStorage.getItem("ubindAccessToken")
      );
    },
    ubindDiscardQuote(quoteId) {
      return appFunctions.ubindDiscardQuote(
        quoteId,
        sessionStorage.getItem("ubindAccessToken")
      );
    },
    async checkExpiredUbindToken() {
      if(authMethods.isExpiredUbindToken()) {
        await this.authenticateUbind().then(resp => {
          sessionStorage.setItem("ubindAccessToken", resp.accessToken);
        });
      }
    },
    async ubindAdjustPolicy(policyId) {
      return appFunctions.ubindAdjustPolicy(
        policyId,
        sessionStorage.getItem("ubindAccessToken")
      );
    },
    async ubindRenewPolicy(policyId) {
      return appFunctions.ubindRenewPolicy(
        policyId,
        sessionStorage.getItem("ubindAccessToken")
      );
    },
    async ubindCheckDocument(brokerRealmId, policyId, CustomerPolicyId, policyTransactionId, isRS) {
      return appFunctions.ubindCheckDocument(
        brokerRealmId, policyId, CustomerPolicyId, policyTransactionId, isRS
      );
    },
  }
});
