<template>
  <div class="btn-group-toggle" data-toggle="buttons">
    <label
      v-for="(option, index) in options"
      :key="index"
      class="btn btn-label btn-multi"
      :class="[
        { active: value === option.value },
        buttonClasses,
        { btnMultiFirst: index == 0 },
        { btnMultiLast: index == options.length - 1 }
      ]"
    >
      <input
        :value="option.value"
        v-model="model"
        type="radio"
        id="option1"
        autocomplete="off"
        checked=""
      />
      <img
        v-if="option.image"
        :src="
          value === option.value
            ? option.image + '-active' + '.png'
            : option.image + '.png'
        "
        width="30px"
        style="display:block; margin-left: calc(50% - 15px);"
      />
      {{ option.label }}
    </label>
  </div>
</template>
<script>
export default {
  name: "button-radio-group",
  props: {
    options: {
      type: Array,
      description:
        'Radio options. Should be an array of objects {value: "", label: ""}',
      default: () => []
    },
    value: {
      type: [String, Boolean],
      description: "Radio value"
    },
    buttonClasses: {
      type: [String, Object],
      description: "Inner button css classes"
    }
  },
  model: {
    prop: "value",
    event: "change"
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      }
    }
  }
};
</script>
<style lang="scss">
.btn-multi {
  margin-right: 0px !important;
  text-align: center;
  border-style: solid !important;
  border-color: var(--uBind) !important;
  border-width: 1px !important;
  border-radius: 0px !important;
  border-right-style: none !important;
}

.btnMultiFirst {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.btnMultiLast {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-right-style: solid !important;
}

.btn.active {
  background-color: var(--uBind) !important;
}
</style>
