<template>
  <div>
    <base-header class="pb-6 bg-primary">
      <b-row class="align-items-center py-4">
        <b-col>
          <base-button @click="goBack()" type="uBind" class="mt-2 mt-sm-0 mb-4"
            ><i class="fas fa-angle-left pr-3"></i>Back</base-button
          >
          <base-button
            @click="sendEmail()"
            type="uBind"
            style="float:right;"
            class="mt-2 mt-sm-0 mb-4"
            v-if="isShowSendToCustomer"
            ><i class="fas fa-envelope pr-3"></i>Send to Customer</base-button
          >
          <br />
          <h6
            class="h1 text-white header d-inline-block mb-0 fw-500"
            ref="topSection"
          >
            {{ title }}
          </h6>
        </b-col>
      </b-row>
    </base-header>
    <div class="container-fluid mt--6">
      <b-card no-body class="mb-4">
        <iframe
          :id="getJotFormId"
          title="[Staging] P&E Protect Proposal Form"
          onload="window.parent.scrollTo(0,0)"
          allowtransparency="true"
          allowfullscreen="true"
          allow="geolocation; microphone; camera"
          :src="jotFormLink"
          frameborder="0"
          style="min-width:100%;max-width:100%;min-height:80vh;border:none;"
          scrolling="no"
        >
        </iframe>
      </b-card>
    </div>
  </div>
</template>

<script>
import { GlobalStore } from "../../globalStore";
import { emailMethods } from "../../helpers/email";
import { toasts } from "../../helpers/toasts";
import { appFunctions } from "../../helpers/appFunctions";

export default {
  name: "EditQuote",
  props: {
    title: {
      type: String,
      default: "Edit Quote"
    }
    // productType: {
    //   type: String,
    //   required: true,
    //   validator: value =>
    //     ["ride-protect", "mercurien-light-commercial"].includes(value)
    // }
  },
  data() {
    return {
      ubindQuoteId: null,
      jotFormLink: "",
      isShowSendToCustomer: false
    };
  },
  computed: {
    getJotFormId() {
      const jotformIds = {
        plantEquipment: `JotFormIFrame-${process.env.VUE_APP_PE_PROPOSAL_ID}`,
        motor: `JotFormIFrame-${process.env.VUE_APP_MOTOR_PROPOSAL_ID}`,
        otherAssets: `JotFormIFrame-${process.env.VUE_APP_OTHER_ASSETS_PROPOSAL_ID}`,
        property: `JotFormIFrame-${process.env.VUE_APP_PROPERTY_PROPOSAL_ID}`,

      }
      return jotformIds[this.$route.params.productName]
    },
    getTenant() {
      return GlobalStore.ubindClientInfo.tenantId;
    },
    getEnvironment() {
      return GlobalStore.ubindClientInfo.environment;
    }
  },
  methods: {
    async getCustomerInfo(customerId) {
      const customerObj = await appFunctions.crud_customer("get", {
        Customer_id: customerId
      });
      return customerObj.data;
    },

    async getjotFormLink() {
      const hasSubmissionId = this.$route.params.id;
      const quote = sessionStorage.getItem("jotformQuoteID");
      const quoteObj = JSON.parse(quote);

      if (hasSubmissionId != "NA") {
        this.isShowSendToCustomer = true
        this.jotFormLink = `https://www.jotform.com/edit/${quoteObj.Jotform_data_obj.submissionID}`
      }

      // if not submissionId, create a new jotform and customer infor with same quoteId to update exactly
      if (hasSubmissionId == "NA") {
        const customerObj = await this.getCustomerInfo(quoteObj.Customer_id);

        const params = `quoteId=${quoteObj.Jotform_quote_id}&quoteRef=${quoteObj.Quote_Ref_No}&firstName=${customerObj.Customer_contact_first_name}&lastName=${customerObj.Customer_contact_last_name}
        &customerEmail=${customerObj.Customer_contact_email}&customerPhoneNumber=${customerObj.Customer_contact_number}&businessABN=${customerObj.Customer_abn}&businessName=${quoteObj.Customer_insured_name}`;
        const jotformLinks = {
          plantEquipment: `${process.env.VUE_APP_PE_PROPOSAL_ID}?`,
          motor: `${process.env.VUE_APP_MOTOR_PROPOSAL_ID}?`,
          otherAssets: `${process.env.VUE_APP_OTHER_ASSETS_PROPOSAL_ID}?`,
          property: `${process.env.VUE_APP_PROPERTY_PROPOSAL_ID}?`
        }
        this.jotFormLink = "https://submit.jotform.com/" + jotformLinks[this.$route.params.productName] + params
      }
    },

    async sendEmail() {
      const mostRecentJotformQuoteId = JSON.parse(sessionStorage.getItem("jotformQuoteID"));
      let customerDetails;
      
      customerDetails = await appFunctions
        .crud_customerPolicy("getCombined", {
        policy_id: parseInt(mostRecentJotformQuoteId.Customer_policy_id)
      })


      await emailMethods.sendContinueQuoteEmail(
        GlobalStore.realmApp,
        customerDetails.data,
        "VIBS",
        this.$route.params.productName
      );

      toasts.success(
        `Email has been sent to ${customerDetails.data.Customer_contact_email}`
      );

      this.$router.push(`/products/${this.$route.params.productName}/quoteEmailed`);
    },
    goBack() {
      this.$router.push(`/products/${this.$route.params.productName}/quotes`);
    },
    scrollUp() {
      //location.hash = "#calcSection";
      const myEl = this.$refs.topSection;

      this.$smoothScroll({
        scrollTo: myEl,
        hash: "#topSection" // required if updateHistory is true
      });
    }
  },
  mounted() {
    this.getjotFormLink();
    this.scrollUp();
  }
};
</script>

<style lang="scss" scoped>
.top-padding {
  padding-top: 0px !important;
}

.ubind-product {
  margin: 0px 15px 0px 15px;
}

.container-fluid {
  padding-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
</style>
