<template>
  <div>
    <el-dialog class="pre-formatted" :title="titleDialog" @close="cancelModel" :visible.sync="quoteSchedulePopup"
      width="65%" center>
      <div>
        <div class="mb-5">
          <el-upload
            ref="uploadRef"
            class="upload-document"
            action="#"
            :auto-upload="false"
            :show-file-list="true"
            :on-change="handleChangeUpload"
            :on-remove="handleRemoveUpload"
            drag
            multiple
          >
          <div class="el-upload__text mt-4">
            <b-icon font-scale="5" icon="cloud-arrow-up-fill"></b-icon>
            <p>Drop file here or <em>click to upload</em></p>
          </div>
          </el-upload>
        </div>
        
        <div>
          <div>
            <span slot="footer" class="dialog-footer">
            <el-button @click="cancelModel">Cancel</el-button>
            <el-button type="danger" @click="handleOk" :disabled="!isUploadDocuments">OK</el-button>
            </span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Dialog, Button, Checkbox, Notification, Table, TableColumn, Upload } from "element-ui";
import { toasts } from "../../helpers/toasts";
import { formattingMethods } from "../../helpers/formatting";
import { appFunctions } from '../../helpers/appFunctions';

export default {
  name: 'DialogConfirm',
  data() {
    return {
      cancelConfirmed: false,
      quoteSchedulePopup: false,
      isUploadDocuments: false,
      uploadedDocuments: [],
      documentObject: []
    }
  },
  props: ['quoteScheduleModal', 'titleDialog', 'acceptedQuote'],
  computed: {
    
  },
  methods: {
    async handleOk() {
      if (this.isUploadDocuments && this.documentObject.length > 0) {
        await this.uploadFileToS3(this.documentObject)
        this.$emit('handleCallAPI', this.uploadedDocuments)
      }
    },

    handleChangeUpload(e) {
      this.isUploadDocuments = true
      const loadendDocumentsObject = {}
      loadendDocumentsObject.s3name = this.acceptedQuote.transactionId + "-" + e.name.replaceAll(" ", "-");
      loadendDocumentsObject.id = e.uid
      loadendDocumentsObject.transactionId = this.acceptedQuote.transactionId
      loadendDocumentsObject.productCode = this.acceptedQuote.productCode.toLowerCase()
      loadendDocumentsObject.type = e.raw.type;
      loadendDocumentsObject.fileName = e.name.replaceAll(" ", "-");

      let file = e.raw
      let reader = new FileReader();
      reader.onloadend = function() {
        loadendDocumentsObject.base64 = reader.result.split(";base64,")[1]
      }
      reader.readAsDataURL(file)
      this.documentObject.push(loadendDocumentsObject)
    },

    handleRemoveUpload(e) {
      this.documentObject = this.documentObject.filter((document) => document.id != e.uid)
      if (!this.documentObject.length > 0) {
        this.isUploadDocuments = false
      }
    },

    async uploadFileToS3() {
      await appFunctions
        .uploadDocuments(this.documentObject)
        .then((response) => {
          if (response) {
            this.uploadedDocuments = response
            toasts.success("Upload documents successfully!");
          }
        })
        .catch((error) => {
          //console.log('Error', error)
          toasts.error("Upload documents failed!");
        })
    },

    cancelModel() {
      this.cancelConfirmed = false
      this.$emit('modelPopupCancel')
    },
    formatCurrency(input) {
      if (!input) return 'N/A'
      return '$' + formattingMethods.numberWithCommas(input)
    }
  },

  watch: {
    quoteScheduleModal(val) {
      this.$refs.uploadRef && this.$refs.uploadRef.clearFiles()
      this.documentObject = []
      this.isUploadDocuments = false
      this.quoteSchedulePopup = val
    }
  },
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Notification.name]: Notification,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Upload.name]: Upload
  }
};
</script>
<style>
.el-dialog__body {
  text-align: center !important;
}

.el-dialog__title {
  font-weight: bold;
}

.el-checkbox__input.is-checked+.el-checkbox__label {
  color: black;
}

.el-dialog__close {
  margin-left: 10px;
}

.pre-formatted {
  white-space: pre;
}

.upload {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
