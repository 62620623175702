import Vue from "vue";

export const toasts = new Vue({
  methods: {
    /**
     * Creates success toast.
     * @param {String} message Toast body.
     * @param {Number} timeout Duration of toast (ms).
     */
    success(message, timeout = 4000) {
      this.$toasted
        .success(message, {
          position: "bottom-center"
        })
        .goAway(timeout);
    },

    /**
     * Creates error toast.
     * @param {String} message Toast body.
     * @param {Number} timeout Duration of toast (ms).
     */
    error(message, timeout = 4000) {
      this.$toasted
        .error(message, {
          position: "bottom-center"
        })
        .goAway(timeout);
    },

    show(message) {
      this.$toasted
        .show(message, {
          position: "bottom-center"
        })
    },

    clear() {
      this.$toasted.clear()
    }
  }
});
