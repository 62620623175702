<template>
  <b-card no-body class="mb-4">
    <b-card-header>
      <h1 class="mb-0">Customer Details</h1>
    </b-card-header>
    <validation-observer v-slot="{ invalid }" ref="formValidator" tag="form">
      <b-card-body>


        <!-- <b-card class="uBind">
          <b-row>
            <h2 class="secondary-card-header">
              New or existing customer?
            </h2>
          </b-row>



          <b-row>
            <b-col md="12">
              <base-input label="Would you like to create a quote for a new or existing customer?">
                <button-radio-group @change="changeModeClicked($event)" id="btn-radios-1" v-model="formMode" :options="[
                  {
                    label: 'New',
                    value: 'new',
                  },
                  {
                    label: 'Existing',
                    value: 'existing',
                  },

                ]" name="radios-btn-default" buttons style="margin-top: 20px;"></button-radio-group>
              </base-input>
            </b-col>
          </b-row>


          <b-row v-if="formMode == 'existing'">
            <b-col md="6">
              <base-input name="Insured Name" label="Select Business" inputGroupClasses="uBind-input"
                :rules="formRules.insuredName">
                <el-select id="insuredDD" @blur="InsuredNameBlurred($event)" @visible-change="visibleChange($event)"
                  @keyup.native="setInsuredName($event)" @change="selectCustomer($event)" v-model="model.insuredName"
                  :rules="formRules.insuredName" filterable placeholder="Search previous customers" v-cancel-read-only>
                  <el-option class="style-dropdown" v-for="(option, index) in customerList" :key="index"
                    :label="option.Customer_insured_name + ' (' + option.Customer_contact_first_name + ' ' + option.Customer_contact_last_name + ')'"
                    :value="option.Customer_id">
                  </el-option>
                </el-select>
              </base-input>
            </b-col>
            <b-col md="6"> </b-col>
          </b-row>



        </b-card> -->


        <b-card class="uBind">
          <b-row>
            <h2 class="secondary-card-header">
              Your Quote
            </h2>
          </b-row>

          

          <b-row>
            <b-col md="6">
              <base-input
                name="Insured Name"
                label="Business Name"
                inputGroupClasses="uBind-input"
                :rules="formRules.insuredName"
              >
                <el-select
                  id="insuredDD"
                  @blur="InsuredNameBlurred($event)"
                  @visible-change="visibleChange($event)"
                  @keyup.native="setInsuredName($event)"
                  @change="selectCustomer($event)"
                  v-model="model.insuredName"
                  :disabled="fieldsDisabled.insuredName"
                  :rules="formRules.insuredName"
                  filterable
                  placeholder="Add or search previous customers"
                  v-cancel-read-only
                >
                  <el-option
                    class="style-dropdown"
                    v-for="(option, index) in customerList"
                    :key="index"
                    :label="option.Customer_insured_name + ' (' + option.Customer_contact_first_name + ' ' + option.Customer_contact_last_name + ')'"
                    :value="option.Customer_id"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </b-col>
            <b-col md="6"> </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <div>
                <a
                  class="abn-link"
                  target="_blank"
                  href="https://abr.business.gov.au/"
                  >Don't know the ABN?</a
                >
              </div>
              <base-input
                type="text"
                name="Insured ABN"
                label="Insured ABN"
                pattern="[0-9]{11}"
                v-model="model.insuredABN"
                :disabled="fieldsDisabled.insuredABN"
                :rules="formRules.insuredABN"
                prependIcon="fas fa-certificate"
                inputGroupClasses="uBind-input"
              />
            </b-col>
            <b-col md="6">
              <base-input
                label="Please describe the main Business activities"
                placeholder="E.g. Excavation services"
                v-model="model.businessDesc"
                :disabled="fieldsDisabled.businessDesc"
                inputGroupClasses="uBind-input"
              />
            </b-col>
          </b-row>
        </b-card>

        <b-card class="uBind">
          <b-row>
            <h2 class="secondary-card-header">
              Contact Details
            </h2>
          </b-row>
          <b-row>
            <p class="form-control-label" style="font-style: italic;">
              Note: Please ensure the Contact Details are for the primary insured.
            </p>
          </b-row>
          <b-row>
            <b-col md="6">
              <base-input
                name="Contact First Name"
                label="Contact First Name"
                :rules="formRules.contactFName"
                v-model="model.contactFName"
                :disabled="fieldsDisabled.contactFName"
                inputGroupClasses="uBind-input"
                placeholder="John"
              />
            </b-col>
            <b-col md="6">
              <base-input
                name="Contact Last Name"
                label="Contact Last Name"
                :rules="formRules.contactLName"
                v-model="model.contactLName"
                :disabled="fieldsDisabled.contactLName"
                placeholder="Smith"
                inputGroupClasses="uBind-input"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <base-input
                label="Contact Mobile Number"
                name="Contact Mobile Number"
                :rules="formRules.contactPhone"
                v-model="model.contactPhone"
                :disabled="fieldsDisabled.contactPhone"
                inputGroupClasses="uBind-input"
                prependIcon="fas fa-phone"
              />
            </b-col>
            <b-col md="6">
              <base-input
                name="Contact Email"
                label="Contact Email"
                :rules="formRules.contactEmail"
                v-model="model.contactEmail"
                :disabled="fieldsDisabled.contactEmail"
                placeholder="john@example.com"
                inputGroupClasses="uBind-input"
                prependIcon="fas fa-envelope"
              />
            </b-col>
          </b-row>
        </b-card>

        <b-card class="uBind">
          <b-row>
            <h2 class="secondary-card-header">
              Address Details
            </h2>
          </b-row>

          <b-row>
            <b-col md="12" class="mb-4 uBind-input">
              <label for="addrs_1" class="form-control-label">
                Search Insured Address
              </label>
              <input
                autocomplete="new-password"
                style="cursor: text"
                @blur="clearAddressFields"
                class="form-control"
                id="addrs_1"
                v-model="model.insuredStreet"
                :disabled="fieldsDisabled.insuredStreet"
                :rules="formRules.insuredStreet"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <base-input
                label="City"
                v-model="model.insuredCity"
                :disabled="fieldsDisabled.insuredCity"
                :rules="formRules.insuredCity"
                inputGroupClasses="uBind-input"
              />
            </b-col>
            <b-col md="4">
              <!-- <base-input label="State" v-model="model.insuredState" /> -->
              <base-input
                name="State"
                label="State"
                :rules="formRules.insuredState"
                inputGroupClasses="uBind-input"
              >
                <el-select
                  v-model="model.insuredState"
                  :disabled="fieldsDisabled.insuredState"
                >
                  <el-option
                    v-for="option in states"
                    :key="option.label"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </b-col>
            <b-col md="4">
              <base-input
                autocomplete="off"
                label="Postcode"
                type="number"
                v-model="model.insuredPostcode"
                :disabled="fieldsDisabled.insuredPostcode"
                inputGroupClasses="uBind-input"
              />
            </b-col>
          </b-row>
        </b-card>

        <b-row align="right">
          <b-col class="text-left" md="6" style="max-width:450px;">
            <base-button
              v-if="productName != 'Property'"
              :disabled="invalid || emailLinkButtonDisabled"
              :loading="emailLinkButtonLoading"
              @click="emailCustomer()"
              type="link"
              class="uBind-link pl-0"
              ><i class="fas fa-link pr-1"></i>Send to Customer</base-button
            >
            <base-button
              @click="gotoStep2()"
              :disabled="invalid || step2ButtonDisabled"
              :loading="step2ButtonLoading"
              type="uBind"
              class="mt-2 mt-sm-0"
              ><i class="fas fa-angle-right pr-3"></i>Next</base-button
            >
          </b-col>
          <b-col class="text-danger text-left pt-1 mt-2">
            <div v-if="invalid">All mandatory fields must be completed</div>
          </b-col>
        </b-row>
      </b-card-body>
    </validation-observer>
  </b-card>
</template>

<script>
import { quoteFormMethods } from "../helpers/quoteForm";
import { dropDowns } from "../assets/data/dropdown_values";
import BaseInput from "./Inputs/BaseInput.vue";
import { Select, Option } from "element-ui";
import { GlobalStore } from "../globalStore";
import { appFunctions } from "../helpers/appFunctions";

export default {
  name: "CustomerForm",
  data() {
    return {
      certContents: "",
      realmApp: null,
      formMode: "edit",
      states: dropDowns.states,
      validated: false,
      enteredInsuredName: "",
      customerList: [],
      step2ButtonDisabled: false,
      step2ButtonLoading: false,
      emailLinkButtonDisabled: false,
      emailLinkButtonLoading: false,
      isOldCustomer: false,
      selected: false
    };
  },
  props: {
    rawModel: null,
    productName:  null,
    model: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: "editable"
    }
  },
  computed: {
    // productName() {
    //   return this.productName;
    // },
    fieldsDisabled() {
      return quoteFormMethods.getDisabledFields(
        this.formMode,
      );
    },
    formRules() {
      return quoteFormMethods.getFormRules("in_progress");
    },
    brokerObj() {
      return GlobalStore.brokerObj;
    },
    validator() {
      return this.$refs.formValidator;
    }
  },
  async mounted() {

    await appFunctions.crud_customerLCPolicy("getCombinedByRealmId", {
            Realm_user_id: GlobalStore.brokerObj.Realm_user_id
          })
          .then(resp => {
            this.customerList = resp.data.filter(data => data && data.Customer_insured_name)
          });
  },
  methods: {
    emailCustomer() {
      this.step2ButtonDisabled = true;
      this.emailLinkButtonLoading = true;
      this.$emit("emailCustomer", this.model);
    },
    gotoStep2() {
      this.step2ButtonLoading = true;
      this.emailLinkButtonDisabled = true;
      this.$emit("nextStep", this.model);
    },
    selectCustomer(e) {
      this.selected = true
      const findCustomer = this.customerList.find(f => f.Customer_id == e);
      this.enteredInsuredName = findCustomer ? findCustomer.Customer_insured_name : this.enteredInsuredName
      if (findCustomer) {
        this.formData(findCustomer, true)
      } else {
        this.formData(null, false)
      }
    },
    InsuredNameBlurred(e) {
      this.model.insuredName = this.enteredInsuredName;
    },
    setInsuredName(e) {
      this.enteredInsuredName = e.target.value;
    },
    visibleChange(e) {
      if (e) {
        this.selected = false
      }
      if (!e && !this.selected) {
        this.model.insuredName = this.enteredInsuredName;
        this.enteredInsuredName && this.formData(null, false)
      }
    },
    formData(data, disabled) {
      this.model.insuredName = data ? data.Customer_insured_name : this.enteredInsuredName
      this.model.businessDesc = data ? data.Customer_business_description : ""
      this.model.contactEmail = data ? data.Customer_contact_email : ""
      this.model.contactPhone = data ? data.Customer_contact_number : ""
      this.model.insuredStreet = data ? data.Customer_contact_street : ""
      this.model.insuredCity = data ? data.Customer_contact_city : ""
      this.model.insuredState = data ? data.Customer_state : ""
      this.model.insuredPostcode = data ? data.Customer_postcode : ""
      this.model.insuredABN = data ? data.Customer_abn : ""
      this.model.contactFName = data ? data.Customer_contact_first_name : ""
      this.model.contactLName = data ? data.Customer_contact_last_name : ""
      this.model.selectedCustomerId = data ? data.Customer_id : ""
      this.model.Ubind_customer_id = data ? data.Ubind_customer_id : ""
      this.fieldsDisabled.businessDesc = disabled
      this.fieldsDisabled.contactEmail = disabled
      this.fieldsDisabled.contactPhone = disabled
      this.fieldsDisabled.insuredStreet = disabled
      this.fieldsDisabled.insuredCity = disabled
      this.fieldsDisabled.insuredState = disabled
      this.fieldsDisabled.insuredPostcode = disabled
      this.fieldsDisabled.insuredABN = disabled
      this.fieldsDisabled.contactFName = disabled
      this.fieldsDisabled.contactLName = disabled
    },
    /**
     * @desc Clears the address city, state and postcode fields when the street
     * field is cleared.
     */
    clearAddressFields() {
      if (this.model.insuredStreet == "") {
        this.model.insuredCity = "";
        this.model.insuredState = "";
        this.model.insuredPostcode = "";
      }
    }
  },
  components: {
    BaseInput,
    [Select.name]: Select,
    [Option.name]: Option
  },
  directives: {
    /**
     * Used ensure the keyboard is shown on mobile devices when clicking on an el-select.
     * This is because element-ui was not designed for mobile:
     * https://github.com/ElemeFE/element/issues/12742#issuecomment-420866658
     */
    cancelReadOnly(el) {
      const input = el.querySelector(".el-input__inner");
      input.removeAttribute("readonly");
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 45px;
  color: black;
}
button {
  text-transform: uppercase;
  padding: 7px 16px;
}
.abn-link {
  font-size: 9pt;
  color: var(--uBind) !important;
  position: absolute;
  right: 0px;
  margin-right: 10px;
}
@media only screen and (min-width: 414px) and (max-width: 600px){
  .style-dropdown {
    max-width: 300px !important;
  }
}

@media only screen and (min-width: 360px) and (max-width: 413px) {
  .style-dropdown {
    max-width: 260px !important;
  }
}

</style>
