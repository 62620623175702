<template>
  <div>
    <base-header class="pb-6 bg-primary">
      <b-row class="align-items-center pt-4" ref="topSection">
        <b-col style="max-width: 1840px !important;">
          <base-button
            @click="emailCustomer(formModel)"
            type="uBind"
            style="float:right;"
            class="mt-2 mt-sm-0 mb-4"
            ><i class="fas fa-envelope pr-3"></i>Send to Customer</base-button
          >

          <h6
            class="h1 text-white header d-inline-block mb-0 fw-500"
            ref="topSection"
          >
            Light Commercial Vehicles
          </h6>
        </b-col>
      </b-row>
    </base-header>
    <div class="container-fluid mt--6">
      <div v-if="!quoteEmailed" style="max-width: 1800px !important;">
        <customer-form
          @emailCustomer="emailCustomer"
          @nextStep="nextStep"
          :model="formModel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CustomerForm from "./uBindCustomerForm.vue";
import { GlobalStore } from "../../globalStore";
import { GlobalMethods } from "../../globalMethods";
import { ubindMethods } from "../../helpers/ubind";
import { emailMethods } from "../../helpers/email";
import { toasts } from "../../helpers/toasts";
import { appFunctions } from "../../helpers/appFunctions";

export default {
  name: "Step1",
  data() {
    return {
      quoteAccepted: true,
      quoteEmailed: false,
      formModel: {
        insuredName: "",
        insuredABN: "",
        businessDesc: "",
        contactFName: "",
        contactLName: "",
        contactEmail: "",
        contactPhone: "",
        insuredAddress: "",
        insuredStreet: "",
        insuredCity: "",
        insuredState: null,
        insuredPostcode: "",
        equipmentDescription: "",
        equipmentValue: null,
        equipmentCondition: "",
        operatingArea: "",
        usedOnPublicRoad: "",
        insurancePeriod: "12 Months",
        financier: "",
        Customer_policy_insurance_total_premium: null,
        Customer_policy_insurance_premium: null,
        Customer_policy_insurance_premium_gst: null,
        Customer_policy_insurance_premium_stamp_duty: null,
        Customer_policy_fee: null,
        Customer_policy_status: null,
        hasEditedAddress: false
      }
    };
  },

  methods: {
    scrollToTop() {
      this.$smoothScroll({
        scrollTo: this.$refs.topSection,
        hash: "#topSection"
      });
    },
    async nextStep(formModel, callback) {
      let internalCustId = formModel.selectedCustomerId;

      const ubindOrgId =
        GlobalStore.brokerObj.parentOrg.parentInsuranceBroker
          .ubind_organisation_id;

      if (!formModel.selectedCustomerId) {
        var newCustomer = {
          Customer_insured_name: formModel.insuredName,
          Customer_abn: formModel.insuredABN,
          Customer_contact_first_name: formModel.contactFName,
          Customer_contact_last_name: formModel.contactLName,
          Customer_contact_email: formModel.contactEmail,
          Customer_contact_number: formModel.contactPhone,
          Customer_contact_street: formModel.insuredStreet,
          Customer_contact_city: formModel.insuredCity,
          Customer_state: formModel.insuredState,
          Customer_postcode: formModel.insuredPostcode,
          Customer_country: "",
          Customer_business_description: formModel.businessDesc,
          Broker_org_id: GlobalStore.brokerObj.parentOrg.Broker_org_id,
          Broker_id: GlobalStore.brokerObj.Broker_id,
          Insurance_broker_id:
            GlobalStore.brokerObj.parentOrg.parentInsuranceBroker
              .insurance_broker_id
        };

        const customerResp = await appFunctions.crud_customer(
          "create",
          newCustomer,
          callback
        );

        if (!customerResp.data.Customer_id) {
          toasts.error(
            "Uh ohh... could not create " +
              formModel.insuredName +
              " please try again."
          );
          return;
        }

        internalCustId = customerResp.data.Customer_id;
      }

      if (formModel.Ubind_customer_id) {
        if ( formModel.hasEditedAddress ) {
          await appFunctions.crud_customer("updateCustomerAddress", {
            Customer_id: internalCustId,
            insuredStreet: formModel.insuredStreet,
            insuredCity: formModel.insuredCity,
            insuredState: formModel.insuredState,
            insuredPostcode: formModel.insuredPostcode,
          })
        }
        this.createQuoteInUbind(
          formModel,
          formModel.Ubind_customer_id,
          internalCustId,
          callback
        );
      } else {
        const custObj = {
          tenantId: GlobalStore.ubindClientInfo.tenantId,
          organisationId: ubindOrgId,
          environment: GlobalStore.ubindClientInfo.environment,
          fullName: formModel.contactFName + " " + formModel.contactLName,
          preferredName: formModel.contactFName,
          email: formModel.contactEmail,

          homePhone: formModel.contactPhone,
          loginEmail: formModel.contactEmail,
          firstName: formModel.contactFName,
          lastName: formModel.contactLName,
          company: formModel.insuredName
        };

        ubindMethods.ubindCreateCustomer(custObj).then(resp => {
          var custid = resp.id;

          appFunctions
            .crud_customer("updateUbindCustomerId", {
              Customer_id: internalCustId,
              Ubind_customer_id: custid
            })
            .then(resp2 => {
              this.createQuoteInUbind(
                formModel,
                custid,
                internalCustId,
                callback
              );
            });
        });
      }
    },
    emailCustomer(formModel) {

      if (formModel.contactEmail != "") {
        this.nextStep(formModel, () => {
          const mostRecentLCPolicyId = sessionStorage.getItem(
            "mostRecentLCPolicyId"
          );
          console.log(mostRecentLCPolicyId);
          appFunctions
            .crud_customerLCPolicy("getCombined", {
              policy_id: parseInt(mostRecentLCPolicyId)
            })
            .then(resp => {
              emailMethods.sendContinueQuoteEmail(
                GlobalStore.realmApp,
                resp.data
              );
            });
          toasts.success(`Email has been sent to ${formModel.contactEmail}`);

          this.$router.push("/lightCommercial/quoteEmailed");
        });
      } else {
        toasts.error(
          `The customer must have at least the email address populated. Please try again`
        );
      }
    },
    sendCustomerToUbind() {},
    createQuoteInUbind(
      formModelData,
      customerId,
      internalCustomerID,
      callBack
    ) {

      console.log(formModelData);

      const {
        tenantId,
        organisationId,
        environment,
        product
      } = GlobalStore.ubindClientInfo;
      const brokerObj = GlobalStore.brokerObj;

      const quoteObj = {
        tenantId: tenantId,
        organisationId:
          GlobalStore.brokerObj.parentOrg.parentInsuranceBroker
            .ubind_organisation_id,
        environment: environment,
        product: product,
        customerId: customerId,
        isTestData: false,
        formData: {
          formModel: {
            ratingHeading: "",
            policyStartDateInput: null,
            isCommonPolicyDueDate: null,
            commonPolicyDueDate: null,
            nextPolicyDueDate: null,
            insuredCompanyLabel: "",
            companyName: formModelData.insuredName,
            companyABN: formModelData.insuredABN,
            manualEndorsementResetTimestamp: "",
            brokerLabel: "",
            brokerName:
              brokerObj.Broker_first_name + " " + brokerObj.Broker_last_name,
            brokerABN: brokerObj.parentOrg.Broker_org_abn,
            brokerEmail: brokerObj.Broker_email,
            brokerPhone: brokerObj.Broker_phone,
            policyStartDate: null,
            policyEndDate: null,
            policyAdjustmentDate: null,
            policyCancellationDate: null,
            variableUserType: "client",
            variableUserHasAccount: true,
            variableCustomerHasAccount: false,
            variableQuoteHasCustomer: false,
            variableQuoteHadCustomerOnCreation: "false",
            variableQuoteType: "newBusiness",
            variableQuoteState: "nascent",
            variableFinancialTransactionType: "none",
            variableApplicationURL: GlobalStore.ubindClientInfo.api_endpoint,
            variableAssetsFolderURL:
              GlobalStore.ubindClientInfo.api_endpoint +
              "/assets/" +
              tenantId +
              "/" +
              product +
              "/" +
              environment,
            variableApplicationMode: "create",
            variableProductEnvironment: "staging",
            variablePreviousWorkflowStep2: "",
            variablePreviousWorkflowStep3: "",
            paymentOption: "Yearly",
            paymentMethod: "Invoice",
            contactFirstName: formModelData.contactFName,
            contactLastName: formModelData.contactLName,
            contactEmail: formModelData.contactEmail,
            contactPhone: formModelData.contactPhone,
            businessDescriptionInput: formModelData.businessDesc,
            businessAddress:
              formModelData.insuredStreet +
              " " +
              formModelData.insuredCity +
              " " +
              formModelData.insuredState +
              " " +
              formModelData.insuredPostcode,
            streetAddressEnterManually: true,
            streetAddress: formModelData.insuredStreet,
            streetSuburb: formModelData.insuredCity,
            streetState: formModelData.insuredState,
            streetPostcode: formModelData.insuredPostcode,
            contactName:
              formModelData.contactFName + " " + formModelData.contactLName
          }
        }
      };

      ubindMethods.ubindCreateQuote(quoteObj).then(async resp => {
        let LCPolicyObj = {
          Customer_policy_number: "",
          Customer_policy_guid: GlobalMethods.uuidv4(),
          Customer_id: internalCustomerID,
          Customer_policy_status: "created",
          Ubind_customer_id: customerId,
          Ubind_quote_id: resp.quoteId,
          customer_policy_broker_referred_by:
            brokerObj.Broker_first_name + " " + brokerObj.Broker_last_name,
          Customer_policy_created_by: brokerObj.Realm_user_id,
          Broker_org_id: GlobalStore.brokerObj.parentOrg.Broker_org_id,
          Insurance_broker_id:
            GlobalStore.brokerObj.parentOrg.parentInsuranceBroker
              .insurance_broker_id
        };

        const newQuoteId = await appFunctions.crud_customerLCPolicy(
          "create",
          LCPolicyObj
        );

        sessionStorage.setItem("mostRecentLCPolicyId", newQuoteId.data);

        sessionStorage.setItem("ubindQuoteID", resp.quoteId);
        if (callBack) {
          callBack();
        } else {
          this.$emit("goStep2");
        }
      });
    },
    initAddressSearch() {
      let script = document.createElement("script");
      script.setAttribute(
        "src",
        "https://api.addressfinder.io/assets/v3/widget.js"
      );
      script.setAttribute("async", true);
      script.onload = () => {
        this.widget = new window.AddressFinder.Widget(
          document.getElementById("addrs_1"),
          "NMY9RTVQ3UHADL76FJ8G",
          "AU",
          {
            address_params: {
              gnaf: 1
            }
          }
        );
        this.widget.on("result:select", (fullAddress, metaData) => {
          this.formModel.insuredAddress = metaData.address_line_1;
          this.formModel.insuredStreet = metaData.address_line_combined;
          this.formModel.insuredCity = metaData.locality_name;
          this.formModel.insuredState = metaData.state_territory;
          this.formModel.insuredPostcode = metaData.postcode;
        });
      };
      document.head.appendChild(script);
    }
  },
  components: {
    CustomerForm
  },

  mounted() {
    this.initAddressSearch();
    this.scrollToTop();
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 45px;
  color: black;
}

.quote-emailed-screen {
  .card-header {
    border: none;
    padding-bottom: 0;
  }

  .uBind .card-body {
    padding: 0;
  }
}

.top-padding {
  padding-top: 0px !important;
}
.secondary-card-header {
  color: var(--uBind);
  font-size: 30px;
}

.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
