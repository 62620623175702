<template>
  <footer class="footer px-4">
    <b-row align-v="center" class="justify-content-lg-between">
      <b-col lg="6">
        <!-- <div class="copyright text-center text-lg-left text-black">
          © {{ year }}
          <a
            href="https://www.yourinsurancehq.com.au"
            class="font-weight-bold ml-1 text-black"
            target="_blank"
            >YOUR INSURANCE PTY LTD | ABN: 85 650 930 733 | RELEASE: v{{
              appVersion
            }}</a
          >
        </div> -->
      </b-col>
      <!-- <b-col lg="6">
        <b-nav align="center" class="nav-footer justify-content-lg-end">
          <b-nav-item href="https://www.creative-tim.com"  target="_blank">
            Creative Tim
          </b-nav-item>
          <b-nav-item href="https://www.creative-tim.com/presentation" target="_blank">
           About Us
          </b-nav-item>
          <b-nav-item href="http://blog.creative-tim.com" target="_blank">
            Blog
          </b-nav-item>
          <b-nav-item href="https://www.creative-tim.com/license" target="_blank">
            License
          </b-nav-item>
        </b-nav>
      </b-col> -->
    </b-row>
  </footer>
</template>
<script>
import { GlobalStore } from "../globalStore";
export default {
  computed: {
    appVersion() {
      return GlobalStore.version;
    }
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
