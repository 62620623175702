import moment from "moment";
import { ubindMethods } from "./ubind";
import { formattingMethods } from "./formatting"
import { toasts } from "./toasts"

// function getMessageResume(title, quoteCancel) {
//   let message = null
//   if (title === 'cancellation') {
//     message = `A cancellation quote for the policy ${ quoteCancel.policyNumber } was started at ${formatTime(quoteCancel.createdDateTime) }, but never completed. 
//               Would you like to resume the existing policy cancellation or start a new one?`
//   } else {
//     message = `An adjustment quote for the policy ${ quoteCancel.policyNumber } was started at ${formatTime(quoteCancel.createdDateTime) }, but never completed. 
//               Would you like to resume the existing adjustment quote, or start a new adjustment quote?`
//   }
//   return message
// }

// function getMessageDiscard(title, quoteCancel) {
//   let message = null
//   if (title === 'cancellation') {
//     message = `An adjustment quote for the policy ${ quoteCancel.policyNumber } was started at ${formatTime(quoteCancel.createdDateTime) }, but never completed. A cancellation cannot be performed while an adjustment is still in progress. 
//               By starting a cancellation for this policy, the incomplete policy adjustment will therefore be discarded and can no longer be resumed. Would you like to proceed?`
//   } else {
//     message = `A cancellation quote for the policy ${ quoteCancel.policyNumber } was started at ${formatTime(quoteCancel.createdDateTime) }, but never completed. 
//               By adjusting this policy, the incomplete policy cancellation will therefore be discarded and can no longer be resumed. Would you like to proceed?`
//   }
//   return message
// }

function handleUbindPolicy(title) {
  if (title === "cancellation") {
    return ubindMethods.ubindCancelPolicy;
  } else if (title === "renew") {
    return ubindMethods.ubindRenewPolicy;
  } else {
    return ubindMethods.ubindAdjustPolicy;
  }
}

function formatTime(time) {
  return moment(time).format("hh:mm a")
}

function formatDatePolicy(datetime) {
  return moment(datetime).format('DD MMMM YYYY')
}

function getNumberOfVehicles(uBindObj) {
  try {
    let result;
    if (uBindObj.formData) {
      result = getDataFormatted(uBindObj.formData)
    }

    if (uBindObj.formDataFormatted) {
      result = getDataFormatted(uBindObj.formDataFormatted)
    }
    return result ? result : (uBindObj.formData.vehicle === 'Fleet' || uBindObj.formDataFormatted.vehicle === 'Fleet') ? 'Fleet' : "N/A";
  } catch (error) {
    return "N/A";
  }
}

function getDataFormatted(data) {
  let result
  if (data.vehicle === 'Fleet') {
    result = data.fleetTotalVehicles;
  } else {
    result = data.numberOfVehicles;
  }
  return result
}

function checkTotalPayableExcludingMerchantFee(policy, type) {
  let totalPayable = 0;
  if (type === 'Rideshare') {
    if (policy && policy.calculation) {
      if (policy.calculation.payment) {
        totalPayable = policy.calculation.payment.payableComponents ? 
        (policy.calculation.payment.payableComponents.totalPayable - policy.calculation.payment.payableComponents.merchantFees).toFixed(2) : 0
      }
    }
    return formattingMethods.numberWithCommas(totalPayable.toString().replace("$", ""));
  } else {
    if (policy && policy.formData) {
      totalPayable = policy.formData.totalPayableExludingMerchantFee;
      if (!policy.formData.totalPayableExludingMerchantFee && policy.premiumData) {
        totalPayable = (policy.premiumData.totalPayable - policy.premiumData.merchantFees).toFixed(2);
      }
    }

    if (policy && policy.formDataFormatted) {
      totalPayable =
        policy.formDataFormatted.totalPayableExcludingMerchantFee ||
        policy.formDataFormatted.totalPayableExludingMerchantFee;
    }
    return formattingMethods.numberWithCommas(totalPayable.toString().replace("$", ""));
  }
  
}

function formatBasePremium(policy) {
  let totalPremium = 0;
  if (policy && policy.calculation) {
    if(policy.calculation.payment) {
      totalPremium = policy.calculation.payment.payableComponents ? policy.calculation.payment.payableComponents.basePremium : 0
    }
  }

  if (policy && policy.premiumData) {
    totalPremium = policy.premiumData.basePremium
  }
  return totalPremium
}

function downloadPDFFromBase64(pdfData, fileName) {
  const linkSource = `data:application/pdf;base64,${pdfData}`;
  const downloadLink = document.createElement("a");

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

async function getDocumentUbind(row, fileName, isRS) {
  if(moment().subtract(5, 'm').isAfter(row.Ubind_quote_last_updated_date)) {
    toasts.show("Documents are currently being generated...");
    await ubindMethods
    .ubindCheckDocument(row.Customer_policy_created_by, row.Ubind_quote_details_obj.policyId, row.Customer_policy_id, row.Ubind_quote_details_obj.policyTransactionId, isRS)
    .then(resp => {
      toasts.clear()
      if (resp) {
        const file = resp.find(item => item.filename.toLocaleLowerCase() == fileName)
        if (file) {
          downloadPDFFromBase64(file.content, file.filename)
        } else {
          toasts.error("Documents are currently being generated - try again shortly");
        }
      }
    })
    .catch(err => {
      toasts.clear()
      toasts.error("Documents are currently being generated - try again shortly");
    });
  } else {
    toasts.error("Documents are currently being generated - try again shortly");
  }
}

function getEmailTemplateId(productType) {
  const emailTemplates = {
    RS: "rideshare-continue-quote-email",
    LC: "ubind-continue-quote-email",
    VIBS: "vibs-continue-quote-email"
  }
  return emailTemplates[productType]
}

function getContinueQuoteUrl(guid, productType, productName) {
  const continueUrls = {
    RS: "/rideShare/continueQuote/",
    LC: "/lightCommercial/continueQuote/",
    VIBS: `/products/${productName}/continueQuote/yiCustomer/`
  }
  return process.env.VUE_APP_SITEURL + continueUrls[productType] + guid
}

export {
  // getMessageResume,
  handleUbindPolicy,
  // getMessageDiscard,
  formatDatePolicy,
  getNumberOfVehicles,
  checkTotalPayableExcludingMerchantFee,
  formatBasePremium,
  downloadPDFFromBase64,
  getDocumentUbind,
  getEmailTemplateId,
  getContinueQuoteUrl
}
