<template>
  <ClaimEmailed
    header="Rideshare - Claim Emailed"
    title="Rideshare Insurance"
    subtitle="Claim Sent"
    body="A new rideshare claim has been sent on your behalf."
    :action="newClaim"
  />
</template>

<script>
import ClaimEmailed from "@/components/ClaimEmailed.vue";

export default {
  methods: {
    newClaim() {
      this.$router.push("/rideShare/submitClaim");
    }
  },
  components: {
    ClaimEmailed
  }
};
</script>
