<template>
  <div>
    <el-dialog class="pre-formatted" :title="titleDigLog" @close="cancelModel" :visible.sync="cancelModelPopup" width="30%"
      :show-close="false" center>
      <el-checkbox v-model="cancelConfirmed">Yes, I am sure.</el-checkbox>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelModel">Cancel</el-button>
        <el-button type="danger" :disabled="!cancelConfirmed" @click="handleOk">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { Dialog, Button, Checkbox, Notification } from "element-ui";
import { handleUbindPolicy } from '../../helpers/common';

export default {
  name: 'DialogConfirm',
  data() {
    return {
      cancelConfirmed: false,
      cancelModelPopup: false
    }
  },
  props: ['cancelConfirmModal', 'title', 'titleDigLog'],
  methods: {
    async handleOk() {
      this.$emit('handleCallAPI', handleUbindPolicy(this.title))
    },
    cancelModel() {
      this.cancelConfirmed = false
      this.$emit('modelPopupCancel')
    }
  },
  watch: {
    cancelConfirmModal(val) {
      this.cancelModelPopup = val
    }
  },
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Notification.name]: Notification
  }
};
</script>
<style>
  .el-dialog__body {
    text-align: center !important;
  }
  .el-dialog__title {
    font-weight: bold;
  }
  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: black;
  }
  .el-dialog__close {
    margin-left: 10px;
  }
  .pre-formatted {
    white-space: pre;
  }
</style>