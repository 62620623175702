<template>
  <div>
    <div v-if="activityStatus == 'new' || activityStatus == 'edit'">
      <base-header class="pb-6 bg-primary">
        <b-row class="align-items-center py-4">
          <b-col lg="6" cols="7">
            <h6 class="h2 text-white d-inline-block mb-0">
              Insurance Brokers
            </h6>
          </b-col>
        </b-row>
      </base-header>
      <div class="container-fluid mt--6">
        <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(firstFormSubmit)"
          >
            <b-card no-body class="mb-4">
              <b-card-header>
                <h3 class="mb-0">
                  {{
                    activityStatus == "new"
                      ? "Create new Insurance Brokers"
                      : "Editing: " + model.insurance_broker_name
                  }}
                </h3>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col md="6">
                    <base-input
                      name="Insurance Broker Name"
                      label="Insurance Broker Name"
                      v-model="model.insurance_broker_name"
                      placeholder="Insurance Broker Name"
                      rules="required"
                    />
                  </b-col>
                  <b-col md="6">
                    <base-input
                      name="Insurance Issuer Email"
                      label="Insurance Issuer Email"
                      v-model="model.insurance_issuer_email"
                      placeholder="Insurance Issuer Email"
                      rules="required|email"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <base-input
                      name="Insurance Broker signature"
                      label="Insurance Broker signature"
                      v-model="model.insurance_broker_signature"
                      placeholder="Insurance Broker signature"
                    />
                  </b-col>
                  <b-col md="6">
                    <base-input
                      label="uBind Organisation Id"
                      name="uBind Organisation Id"
                      v-model="model.ubind_organisation_id"
                      placeholder="uBind Organisation Id"
                      rules="required"
                    />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6">
                    <base-input
                      autocomplete="off"
                      label="uBind Service Username"
                      name="uBind Service Useername"
                      v-model="model.ubind_service_username"
                      placeholder="uBind Service Username"
                      rules="required"
                    />
                  </b-col>
                  <b-col md="6">
                    <base-input
                      autocomplete="new-password"
                      name="uBind Service Password"
                      label="uBind Service Password"
                      type="password"
                      v-model="model.ubind_service_password"
                      placeholder="uBind Service Password"
                      rules="required"
                    />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6">
                    <base-input
                      name="webhook URL"
                      label="webhook URL"
                      type="text"
                      v-model="model.webhook_url"
                      placeholder="customized webhook url"
                    />
                  </b-col>
                  <b-col md="6">
                    <base-input
                      name="Bcc emails"
                      label="Bcc emails"
                      type="text"
                      v-model="model.bcc_emails"
                      placeholder="example1@gmail.com, example2@gmail.com"
                    />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6">
                    <label style="font-weight: bold; font-size: 14px;"
                      >Insurance Broker Logo</label
                    >
                    <span style="font-size: 10px; margin-left: 5px;"
                      >(Please click image to change a new logo)</span
                    >
                    <el-upload
                      class="avatar-uploader"
                      slot="append"
                      action="#"
                      :accept="
                        '.jpeg, .jpg, .png, .gif, .jpe, .heic, .heif, .jfif'
                      "
                      :show-file-list="false"
                      :auto-upload="false"
                      :on-change="handleChangeImage"
                    >
                      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="12">
                    <h3>Features</h3>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6">
                    <base-input label="Can Cancel Policy">
                      <button-radio-group
                        id="can-cancel-policy"
                        :value="model.can_cancel_policy"
                        v-model="model.can_cancel_policy"
                        :options="[
                          {
                            label: 'Yes',
                            value: true
                          },
                          {
                            label: 'No',
                            value: false
                          }
                        ]"
                        name="can-cancel-policy"
                        buttons
                        style="margin-top: 0px;"
                      ></button-radio-group>
                    </base-input>
                  </b-col>
                  <!-- <b-col md="6">
                    <base-input label="Can Receive Invoice">
                      <button-radio-group
                        id="can-receive-invoice"
                        :value="model.can_receive_invoice"
                        v-model="model.can_receive_invoice"
                        :options="[
                          {
                            label: 'Yes',
                            value: true
                          },
                          {
                            label: 'No',
                            value: false
                          }
                        ]"
                        name="can-receive-invoice"
                        buttons
                        style="margin-top: 0px;"
                      ></button-radio-group>
                    </base-input>
                  </b-col> -->
                </b-row>

                <b-row>
                  <b-col md="6">
                    <base-input label="Can see Claims">
                      <button-radio-group
                        id="can-see-claims"
                        :value="model.can_see_claims"
                        v-model="model.can_see_claims"
                        :options="[
                          {
                            label: 'Yes',
                            value: true
                          },
                          {
                            label: 'No',
                            value: false
                          }
                        ]"
                        name="can-see-claims"
                        buttons
                        style="margin-top: 0px;"
                      ></button-radio-group>
                    </base-input>
                  </b-col>
                  <b-col md="6">
                    <base-input label="Can see Fleet">
                      <button-radio-group
                        id="can-see-fleet"
                        :value="model.can_see_fleet"
                        v-model="model.can_see_fleet"
                        :options="[
                          {
                            label: 'Yes',
                            value: true
                          },
                          {
                            label: 'No',
                            value: false
                          }
                        ]"
                        name="can-see-fleet"
                        buttons
                        style="margin-top: 0px;"
                      ></button-radio-group>
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6">
                    <base-input label="Can trigger webhook">
                      <button-radio-group
                        id="can-trigger-webhook"
                        :value="model.can_trigger_webhook"
                        v-model="model.can_trigger_webhook"
                        :options="[
                          {
                            label: 'Yes',
                            value: true
                          },
                          {
                            label: 'No',
                            value: false
                          }
                        ]"
                        name="can-trigger-webhook"
                        buttons
                        style="margin-top: 0px;"
                      ></button-radio-group>
                    </base-input>
                  </b-col>
                  <b-col md="6">
                    <base-input label="Can receive customized documents">
                      <button-radio-group
                        id="can-receive-customized-documents"
                        :value="model.can_receive_customized_documents"
                        v-model="model.can_receive_customized_documents"
                        :options="[
                          {
                            label: 'Yes',
                            value: true
                          },
                          {
                            label: 'No',
                            value: false
                          }
                        ]"
                        name="can-receive-customized-documents"
                        buttons
                        style="margin-top: 0px;"
                      ></button-radio-group>
                    </base-input>

                  </b-col>
                </b-row>

                <b-row class="d-flex justify-content-center">
                  <base-button
                    @click="resetInsuranceBrokerForm"
                    type="danger"
                    class="mr-4"
                    >Cancel</base-button
                  >
                  <base-button @click="saveChanges" type="danger"
                    >Save</base-button
                  >
                </b-row>
              </b-card-body>
            </b-card>
          </form>
        </validation-observer>
      </div>
    </div>
    <div
      class="container-fluid"
      v-if="!(activityStatus == 'new' || activityStatus == 'edit')"
    >
      <card body-classes="px-0 pb-1" footer-classes="pb-2">
        <div>
          <b-col
            cols="12"
            class="d-flex justify-content-center justify-content-sm-between flex-wrap change-color"
          >
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>

            <div>
              <base-input
                v-model="searchQuery"
                prepend-icon="fas fa-search"
                placeholder="Search..."
                v-on:input="resetPagination()"
              >
              </base-input>
            </div>
            <div>
              <base-button @click="addBroker()" type="danger"
                >Add new Insurance Brokers</base-button
              >
            </div>
          </b-col>
          <el-table
            :data="queriedData"
            row-key="id"
            header-row-class-name="thead-light"
            @sort-change="sortChange"
            @selection-change="selectionChange"
            v-loading="loading"
          >
            <el-table-column min-width="100px" align="left" label="Edit">
              <div slot-scope="{ row }">
                <base-button
                  @click.native="populateForm(row)"
                  class="edit"
                  type="danger"
                  size="sm"
                  icon
                  v-b-tooltip.hover
                  title="View / Edit"
                >
                  <i class="text-white ni ni-ruler-pencil"></i>
                </base-button>
              </div>
            </el-table-column>

            <el-table-column
              label="Name"
              prop="insurance_broker_name"
              min-width="190px"
              sortable
            >
            </el-table-column>
            <template #empty>
              <p>{{ loading ? "" : "No broker org found" }}</p>
            </template>
          </el-table>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div>
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

              <span v-if="selectedRows.length">
                &nbsp; &nbsp; {{ selectedRows.length }} rows selected
              </span>
            </p>
          </div>
          <base-pagination
            class="pagination-no-border"
            :value="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
            @change="paginationChanged($event)"
          >
          </base-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { toasts } from "../../helpers/toasts";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import { Table, TableColumn, Select, Option, Upload } from "element-ui";
import { BasePagination } from "@/components";
import clientPaginationMixin from "../../components/PaginatedTables/clientPaginationMixin";
import ButtonRadioGroup from "../../components/ButtonRadioGroup.vue";
import { appFunctions } from "../../helpers/appFunctions";

export default {
  data() {
    return {
      loading: false,
      activityStatus: "add",
      currentInsuranceBrokerId: "",
      tableData: [],
      selectedRows: [],
      imageUrl: "",
      model: {
        content_template_id: "",
        insurance_broker_name: "",
        insurance_broker_logo: "",
        insurance_broker_signature: "",
        insurance_issuer_email: "",
        ubind_service_password: "",
        ubind_service_username: "",
        ubind_organisation_id: "",
        bcc_emails: "",
        webhook_url: "",
        can_trigger_webhook: false,
        can_see_fleet: false,
        can_see_claims: false,
        can_cancel_policy: false,
        can_receive_invoice: false,
        can_receive_customized_documents: false
      },
      imageObject: {},
      isUploadImage: false
    };
  },
  name: "InsuranceBrokers",
  methods: {
    // TABLE METHODS
    addBroker() {
      this.isUploadImage = false;
      this.imageUrl = "";
      this.activityStatus = "new";
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    async populateForm(row) {
      this.isUploadImage = false;
      var rowResp = await appFunctions.crud_insuranceBroker("get", {
        insurance_broker_id: row.insurance_broker_id
      });
      row = rowResp.data;
      this.activityStatus = "edit";
      this.imageUrl = row.insurance_broker_logo;
      this.currentInsuranceBrokerId = row.insurance_broker_id;
      this.model.content_template_id = row.content_template_id;
      this.model.insurance_broker_name = row.insurance_broker_name;
      this.model.insurance_broker_logo = row.insurance_broker_logo;
      this.model.insurance_broker_signature = row.insurance_broker_signature;
      this.model.insurance_issuer_email = row.insurance_issuer_email;
      this.model.ubind_service_password = row.ubind_service_password;
      this.model.ubind_service_username = row.ubind_service_username;
      this.model.ubind_organisation_id = row.ubind_organisation_id;
      this.model.bcc_emails = row.bcc_emails;
      this.model.webhook_url = row.webhook_url;
      this.model.can_trigger_webhook = row.can_trigger_webhook;
      this.model.can_see_fleet = row.can_see_fleet;
      this.model.can_see_claims = row.can_see_claims;
      this.model.can_cancel_policy = row.can_cancel_policy;
      this.model.can_receive_invoice = row.can_receive_invoice;
      this.model.can_receive_customized_documents = row.can_receive_customized_documents;
    },
    resetInsuranceBrokerForm() {
      this.activityStatus = "add";
      this.currentInsuranceBrokerId = "";
      // Reset Modal
      this.model.content_template_id = "";
      this.model.insurance_broker_name = "";
      this.model.insurance_broker_logo = "";
      this.model.insurance_broker_signature = "";
      this.model.insurance_issuer_email = "";
      this.model.ubind_service_password = "";
      this.model.ubind_service_username = "";
      this.model.ubind_organisation_id = "";
      this.model.bcc_emails = "";
      this.model.webhook_url = "";
      this.model.can_trigger_webhook = false;
      this.model.can_see_fleet = false;
      this.model.can_see_claims = false;
      this.model.can_cancel_policy = false;
      this.model.can_receive_invoice = false;
      this.model.can_receive_customized_documents = false;
    },

    /**
     * Initial load and refresh insurance. Initializes Fuse.
     */
    async getInsuranceBrokers() {
      this.tableData = [];
      this.loading = true;
      await appFunctions
        .crud_insuranceBroker("list")
        .then(resp => {
          this.tableData = resp.data;
          this.initFuse(["insurance_broker_name"]);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // CRUD METHODS
    async saveChanges() {
      const isValid = await this.$refs.formValidator.validate();
      if (!isValid) {
        toasts.error("Please complete the required fields before proceeding.");
        return;
      }
      // if (this.currentInsuranceBrokerId && !this.isUploadImage) {
      //   toasts.error("Please upload a logo before proceeding.");
      //   return;
      // }
      if (this.isUploadImage) {
        await this.uploadFileToS3();
      }
      var tmpData = {
        content_template_id: this.model.content_template_id,
        insurance_broker_logo: this.model.insurance_broker_logo,
        insurance_broker_name: this.model.insurance_broker_name,
        insurance_broker_signature: this.model.insurance_broker_signature,
        insurance_issuer_email: this.model.insurance_issuer_email,
        ubind_service_password: this.model.ubind_service_password,
        ubind_service_username: this.model.ubind_service_username,
        ubind_organisation_id: this.model.ubind_organisation_id,
        bcc_emails: this.model.bcc_emails,
        webhook_url: this.model.webhook_url,
        can_trigger_webhook: this.model.can_trigger_webhook,
        can_see_claims: this.model.can_see_claims,
        can_see_fleet: this.model.can_see_fleet,
        can_receive_invoice: this.model.can_receive_invoice,
        can_cancel_policy: this.model.can_cancel_policy,
        can_receive_customized_documents: this.model.can_receive_customized_documents
      };

      if (this.currentInsuranceBrokerId) {
        // Existing broker has been edited
        tmpData.insurance_broker_id = this.currentInsuranceBrokerId;

        appFunctions
          .crud_insuranceBroker("update", tmpData)
          .then(async res => {
            if (res.data == "OK") {
              toasts.success("Insurance Broker updated successfully!");
              this.resetInsuranceBrokerForm();
              await this.getInsuranceBrokers();
            }
          })
          .catch(err => {
            console.error(err);
            toasts.error(`Failed to update Insurance broker: ${err}`);
          });
      } else {
        // New broker will be added
        var newInsuranceBrokerId = await appFunctions.crud_insuranceBroker(
          "getNextInsuranceBrokerId"
        );
        tmpData.insurance_broker_id = newInsuranceBrokerId.data;

        appFunctions
          .crud_insuranceBroker("create", tmpData)
          .then(async res => {
            if (res.data == "OK") {
              toasts.success("Insurance Broker created successfully!");
              this.resetInsuranceBrokerForm();
              await this.getInsuranceBrokers();
            }
          })
          .catch(err => {
            toasts.error(`Failed to add Insurance Broker: ${err}`);
          });
      }
      await this.$root.$refs.DashboardLayout.disableOrEnabledMenu();
    },
    isImageValid(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        toasts.error("Avatar picture size can not exceed 2MB!");
      }
      return isLt2M;
    },
    handleRemove(file, fileList) {
      this.isUploadImage = !this.isUploadImage;
    },
    handleChangeImage(e) {
      this.isUploadImage = true;
      if (this.isImageValid(e)) {
        this.imageObject.name = e.uid + "-" + e.name;
        this.imageObject.type = e.raw.type;
        this.imageUrl = URL.createObjectURL(e.raw);
        let self = this;
        let file = e.raw;
        let reader = new FileReader();
        reader.onloadend = function() {
          self.imageObject.base64 = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    async uploadFileToS3() {
      let self = this;
      await appFunctions
        .uploadImage(this.imageObject)
        .then(function(response) {
          if (response) {
            self.model.insurance_broker_logo = response;
            toasts.success("Upload image successfully!");
          } else {
            toasts.error("Upload image failed!");
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  },
  async mounted() {
    await this.getInsuranceBrokers();
  },
  mixins: [clientPaginationMixin],
  components: {
    BaseInput,
    [Select.name]: Select,
    [Option.name]: Option,
    BasePagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    ButtonRadioGroup,
    [Upload.name]: Upload
  }
};
</script>

<style lang="scss">
/* .hideUpload > div {
  display: none;
} */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
.avatar {
  width: 200px !important;
  height: 150px !important;
  display: block;
}
</style>
