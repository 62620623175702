<template>
  <div class="content">
    <Spinner :isLoading="isLoading" />
    <dashboard-header />
    <main>
      <menu-bar v-on:getDataChart="getDataChart" />
      <policies-charts :dataChart="dataChart" :activeIndex="activeIndex" :submitDate="submitDate"/>
      <quotes-charts :dataChart="dataChart" :activeIndex="activeIndex" :submitDate="submitDate"/>
      <b-row align-h="around" class="mx-3 mt--3">
        <b-col cols="12" xl="4" class="px-3 py-sm-3">
          <top-brokers :dataChart="dataChart" />
        </b-col>
        <b-col cols="12" xl="4" class="px-3 py-sm-3">
          <product-pie-chart :dataChart="dataChart" />
        </b-col>
        <b-col cols="12" xl="4" class="px-3 py-sm-3">
          <progress-track :dataChart="dataChart" />
        </b-col>
      </b-row>
    </main>
  </div>
</template>

<script>

import DashboardHeader from "./DashboardHeader.vue"
import PoliciesCharts from "./PoliciesCharts.vue";
import QuotesCharts from "./QuotesCharts.vue";
import TopBrokers from "./TopBrokers.vue";
import ProductPieChart from './ProductPieChart.vue'
import ProgressTrack from "./ProgressTrack.vue";
import MenuBar from "./MenuBar.vue";
import { reportFunctions } from "../../helpers/reportFunctions";
import moment from "moment";
import Spinner from "./Spinner.vue";
import { GlobalStore } from "../../globalStore";
import { appFunctions } from "../../helpers/appFunctions";

export default {
  data() {
    return {
      activeIndex: 1,
      dataChart: [],
      isLoading: false,
      submitDate: new Date()
    }
  },
  components: {
    DashboardHeader,
    PoliciesCharts,
    QuotesCharts,
    ProductPieChart,
    TopBrokers,
    ProgressTrack,
    MenuBar,
    Spinner
  },
  methods: {
    async getDataChart(year, month, index, date) {
      this.submitDate = date ? new Date(date) : new Date()
      this.dataChart = []
      this.isLoading = true
      let data = null
      this.activeIndex = index
      if (index === 0) {
        data = {
          "year": year
        }
      } else {
        data = {
          "year": year,
          "month": month
        }
      }
      await reportFunctions.dataChart("", data)
        .then(resp => {
          this.filterDataByBroker(resp)
          this.isLoading = false
        })
        .catch(error => {
          console.log("error:", error)
        })
        .finally(() => {
        });
    },
    async filterDataByBroker(data) {
      if (GlobalStore.brokerObj.Broker_super_admin) {
        this.dataChart = data
      } else {
        const orgResp = await appFunctions.crud_brokerOrg("get", {
          Broker_org_id: GlobalStore.brokerObj.Broker_org_id
        });
        if (orgResp.data.is_insurance_broker) {
          const result = _.filter(data, function(item) {return item.insurance_broker_id === orgResp.data.insurance_broker_id;});
          this.dataChart = result
        } else {
          if (GlobalStore.brokerObj.Broker_org_admin) {
            const result = _.filter(data, function(item) {return item.broker_org_id === GlobalStore.brokerObj.Broker_org_id;});
            this.dataChart = result
          } else {
            const result = _.filter(data, function(item) {return item.broker_id === GlobalStore.brokerObj.Broker_id;});
            this.dataChart = result
          }
        }
      }
    }
  },
  async mounted() {
    await this.getDataChart(moment().year(), moment().month() + 1, this.activeIndex)
  },
};
</script>

<style></style>
