import Vue from "vue";
import { GlobalStore } from "../globalStore";
import { appFunctions } from "../helpers/appFunctions";
import axios from "axios";
import moment from "moment";

function fnAtob(base64) {
  return Buffer.from(base64, "base64").toString("binary");
}

export const authMethods = new Vue({
  methods: {
    async login(username, password, dontUpdateState) {
      var Realm = window.Realm;
      const config = {
        id: GlobalStore.realm_app_id
      };
      //const app = new Realm.App(config);

      // try {
      //   // Authenticate the user
      //   const credentials = Realm.Credentials.emailPassword(
      //     username.toLowerCase(),
      //     password
      //   );

      //   const user = await app.logIn(credentials);
      //   if (!dontUpdateState) {
      //     sessionStorage.setItem(
      //       "pex",
      //       this.CryptoJS.AES.encrypt(
      //         username.toLowerCase() + "|" + password,
      //         GlobalStore.passkey
      //       ).toString()
      //     );
      //   }
      //   return user;
      // } catch (err) {
      //   console.log(err.message);
      //   return { error: err.message };
      // }

      try {
        var response = await axios.post(
          "https://dev-tf7i80w1.us.auth0.com/oauth/token",
          new URLSearchParams({
            grant_type: "password",
            username: username,
            password: password,
            audience: "https://partner-staging-api.yourinsurancehq.com.au",
            scope: "openid",
            client_id: "NLqPVOS9vJRFn37eBFoQCtnd0o8mh1l5",
            client_secret:
              "Ot_3g5z97IAGmH8C3EB_SmfiF_3d3ITWTSsvXgddgm19r8Ev9VrGzCjN_zlyG90y"
          })
        );
        //  .then(async response => {

        var userInfo = JSON.parse(fnAtob(response.data.id_token.split(".")[1]));

        sessionStorage.setItem("token", response.data.access_token);

        //var user = {};
        if (!dontUpdateState) {
          sessionStorage.setItem(
            "pex",
            this.CryptoJS.AES.encrypt(
              username.toLowerCase() + "|" + password,
              GlobalStore.passkey
            ).toString()
          );
        }
        return userInfo;
      } catch (err) {
        console.log(err.message);
        return { error: err.message };
      }
      //console.log(response);
      // var app = await authMethods.initAppInstance();

      // if (this.rememberMe == true) {
      //   const encPassword = btoa(this.username + "|" + this.password);
      //   localStorage.setItem("rmt", encPassword);
      // }

      // console.log(GlobalStore.brokerObj);
      // if (GlobalStore.brokerObj.Show_pe == true) {
      //   this.$router.push("/createQuote");
      // } else if (GlobalStore.brokerObj.Show_lc == true) {
      //   this.$router.push("/lightCommercial/createQuote");
      // }
      // });
      // .catch(err => {
      //   return { error: err.message };
      //   // this.isSubmitting = false;
      //   // localStorage.removeItem("rmt");
      //   // this.$toasted
      //   //   .error("Incorrect username or password, please try again.", {
      //   //     position: "bottom-center"
      //   //   })
      //   //   .goAway(4000);
      // });
    },
    isLoggedIn() {
      if (sessionStorage.getItem("pex") != null) {
        return true;
      } else {
        return false;
      }
    },
    async initAppInstance(login = null) {
      if (sessionStorage.getItem("pex") != null) {
        var usersplit = this.CryptoJS.AES.decrypt(
          sessionStorage.getItem("pex"),
          GlobalStore.passkey
        )
          .toString(this.CryptoJS.enc.Utf8)
          .split("|");
        if (this.isExpiredAuth0Token()) {
          var user = await authMethods.login(usersplit[0], usersplit[1]);
        } else {
          var user = this.parseJwt(sessionStorage.getItem("token"))
        }
        const config = await appFunctions.getConfig();

        GlobalStore.ubindClientInfo.organisationId =
          config.ubindInfo.organisationId;

        GlobalStore.ubindClientInfo.api_endpoint =
          config.ubindInfo.api_endpoint;

        GlobalStore.ubindClientInfo.environment = config.ubindInfo.environment;

        GlobalStore.ubindClientInfo.tenantId = config.ubindInfo.tenantId;

        GlobalStore.ubindClientInfo.product = config.ubindInfo.product;

        GlobalStore.ubindClientInfo.rideShareProduct =
          config.ubindInfo.rideShareProduct;

        GlobalStore.ubindClientInfo.rideShareProduct =
          config.ubindInfo.rideShareProduct;

        GlobalStore.ubindClientInfo.orgadminRoleId =
          config.ubindInfo.orgadminRoleId;

        GlobalStore.ubindClientInfo.brokerRoleId =
          config.ubindInfo.brokerRoleId;

        GlobalStore.ubindClientInfo.fleetEmailDest =
          config.ubindInfo.fleetEmailDest;

        GlobalStore.ubindClientInfo.claimEmailDest =
          config.ubindInfo.claimEmailDest;

        GlobalStore.ubindClientInfo.ccEmailList =
          config.ubindInfo.ccEmailList;

        const brokerObj = await appFunctions.crud_broker("getByRealmId", {
          Realm_user_id: user.sub
        });
        GlobalStore.brokerObj = brokerObj.data;

        GlobalStore.notification = config.notification;

        // Updating last login value
        if (login) {
          await appFunctions.crud_broker("loglogin", {
            Broker_id: brokerObj.data.Broker_id,
            Broker_full_name: brokerObj.data.Broker_first_name + " " + brokerObj.data.Broker_last_name,
            Last_login: brokerObj.data.Last_login,
            insurance_broker_name: brokerObj.data.parentOrg.parentInsuranceBroker.insurance_broker_name
          });
        }

        var app = {};
        return app;
      } else {
        return { error: "token expired" };
      }
    },
    parseJwt (token) {
      var base64Url = token.split('.')[1]
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
    
      return JSON.parse(jsonPayload);
    },
    isExpiredUbindToken() {
      if (!sessionStorage.getItem("ubindAccessToken")) return false
      const now = moment().format('x')
      const expired = this.parseJwt(sessionStorage.getItem("ubindAccessToken")).exp * 1000
      return expired < now
    },
    isExpiredAuth0Token() {
      if (!sessionStorage.getItem("token")) return false
      const now = moment().format('x')
      const expired = this.parseJwt(sessionStorage.getItem("token")).exp * 1000
      return expired < now
    }
  }
});
