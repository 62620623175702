import Vue from "vue";
import { templateMethods } from "../helpers/template";
import { GlobalStore } from "../globalStore";
import { GlobalMethods } from "../globalMethods";
import { appFunctions } from "./appFunctions";

export const pdfMethods = new Vue({
  methods: {
    async createPDF(htmlContent) {
      var https = require("https");
      // var path = require("path");
      // var fs = require("fs");

      this.pdfResponse = "Waiting for PDF...";
      // The authentication key (API Key).
      // Get your own by registering at https://app.pdf.co/documentation/api
      //const API_KEY = "gavan.smythe@yourinsurancehq.com.au_ff752d78259bed9f";
      const API_KEY = "alex@kora.net.au_6159b1121b1915c1";

      // HTML template
      const template = "./invoice_template.html";
      // Data to fill the template
      const templateData = "./invoice_data.json";
      // Destination PDF file name
      const DestinationFile = "./result.pdf";

      // Prepare request to `HTML To PDF` API endpoint
      var queryPath = `/v1/pdf/convert/from/html?name=test.pdf&async=True`;
      var reqOptions = {
        host: "api.pdf.co",
        path: encodeURI(queryPath),
        method: "POST",
        headers: {
          "x-api-key": API_KEY,
          "Content-Type": "application/json"
        }
      };

      const styles = `<style>
      body {
        font-family: Arial;
        font-size: 11px;
        margin: 0px 0px 0px 0px;
      }


      h1 {
        display: block;
        margin-left: auto;
        margin-right: auto;
        color: #182850;
      }

      h2 {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        width: 100%;
        color: #182850;
        font-size: 18pt;
      }

      p {
        display: block;
        margin: 5px 0px 5px 0px;
      }

      td,
      th {
        text-align: left;
        font-size: 11px;
      }
      th {
        font-weight: normal;
        font-size: 11px;
      }

      [data-text-align="center"] { 
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        width: 100%; 
      }
    </style>`;

      var requestBody = JSON.stringify({
        html: styles + htmlContent
        // templateData: JSON.stringify({
        //   number: "1234567",
        //   date: "April 30, 2016",
        //   from:
        //     "Acme Inc., City, Street 3rd , +1 888 123-456, support@example.com",
        //   to: "Food Delivery Inc., New York, Some Street, 42",
        //   lines: [
        //     {
        //       title: "Setting up new web-site",
        //       quantity: 3,
        //       price: "50"
        //     },
        //     {
        //       title: "Configuring mail server and mailboxes",
        //       quantity: "5",
        //       price: "50"
        //     }
        //   ]
        // })
      });
      // Send request
      var postRequest = https
        .request(reqOptions, response => {
          response.on("data", d => {
            // Parse JSON response
            var data = JSON.parse(d);
            if (data.error === false) {
              console.log(`Job #${data.jobId} has been created!`);
              this.checkIfJobIsCompleted(data.jobId, data.url);
            } else {
              // Service reported error
              console.log(data.message);
            }
          });
        })
        .on("error", e => {
          // Request error
          console.log(e);
        });

      // Write request data
      postRequest.write(requestBody);
      postRequest.end();
    },
    checkIfJobIsCompleted(jobId, resultFileUrl) {
      var https = require("https");
      const queryPath = `/v1/job/check`;

      const API_KEY = "gavan.smythe@yourinsurancehq.com.au_ff752d78259bed9f";

      // JSON payload for api request
      const jsonPayload = JSON.stringify({
        jobid: jobId
      });

      const reqOptions = {
        host: "api.pdf.co",
        path: queryPath,
        method: "POST",
        headers: {
          "x-api-key": API_KEY,
          "Content-Type": "application/json",
          "Content-Length": Buffer.byteLength(jsonPayload, "utf8")
        }
      };

      // Send request
      var postRequest = https.request(reqOptions, response => {
        response.on("data", d => {
          response.setEncoding("utf8");

          // Parse JSON response
          const data = JSON.parse(d);
          console.log(
            `Checking Job #${jobId}, Status: ${
              data.status
            }, Time: ${new Date().toLocaleString()}`
          );

          if (data.status === "working") {
            // Check again after 3 seconds
            setTimeout(() => {
              this.checkIfJobIsCompleted(jobId, resultFileUrl);
            }, 3000);
          } else if (data.status === "success") {
            // Download PDF file
            // var file = fs.createWriteStream(DestinationFile);
            https.get(resultFileUrl, response2 => {
              console.log(response2.url);

              this.downloadPDF(response2.url);

              //   this.pdfResponse =
              //     "<a href='" + response2.url + "'>Download PDF</a>";

              //   // response2.pipe(file).on("close", () => {
              //   //   console.log(
              //   //     `Generated PDF file saved as "test.pdf" file.`
              //   //   );
              //   // });
              //   console.log(response2);
            });
          } else {
            console.log(`Operation ended with status: "${data.status}".`);
          }
        });
      });

      // Write request data
      postRequest.write(jsonPayload);
      postRequest.end();
    },
    downloadPDF(url) {
      fetch(url)
        .then(resp => resp.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = "certification-of-currency.pdf";
          document.body.appendChild(a);
          a.click();
          GlobalStore.PDFCreated = true;
          window.URL.revokeObjectURL(url);
          //alert("your file has downloaded!"); // or you know, something with better UX...
        })
        .catch(() => console("error"));
    },
    async sendEmail() {
      var Realm = window.Realm;
      const config = {
        id: GlobalStore.realm_app_id
      };
      //const app = new Realm.App(config);

      try {
        // Authenticate the user
        // const credentials = Realm.Credentials.emailPassword(
        //   this.username,
        //   this.password
        // );
        // const user = await app.logIn(credentials);

        var emailResp = await appFunctions.sendEmail(this.username);
        // `App.currentUser` updates to match the logged in user
        // assert(user.id === app.currentUser.id)
        // this.loginResponse = JSON.stringify(user);
        // this.loginResponse = "Success, your access token is:" + user._accessToken;

        console.log(emailResp);
        this.emailResponse = "Email Sent: ID: " + emailResp.MessageId;
        // loginResponse
      } catch (err) {
        console.log(err.message);
        // console.log(JSON.stringify(err));
        // this.loginResponse = "Login Failed: " + err.message;
      }
    },
    async createBaseDummyData() {
      var Realm = window.Realm;
      const config = {
        id: GlobalStore.realm_app_id
      };
      //const app = new Realm.App(config);

      try {
        // Authenticate the user
        // const credentials = Realm.Credentials.emailPassword(
        //   this.username,
        //   this.password
        // );
        // const user = await app.logIn(credentials);

        baseDataMethods.createBaseData(
          user,
          false,
          true,
          false,
          false,
          false,
          false
        );

        // console.log(resp);
      } catch (err) {
        console.log(err.message);
        // console.log(JSON.stringify(err));
        // this.loginResponse = "Login Failed: " + err.message;
      }
    }
  }
});
