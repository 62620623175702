import Vue from "vue";
import { templateMethods } from "../helpers/template";
import { GlobalStore } from "../globalStore";
import { GlobalMethods } from "../globalMethods";
import { formattingMethods } from "../helpers/formatting";
import Mailgun from "mailgun.js";
import formData from "form-data";
import { clientPDF } from "./clientPdf";
import { accessMethods } from "../helpers/accessPermissions";
import { appFunctions } from "../helpers/appFunctions";
import { getContinueQuoteUrl, getEmailTemplateId } from "./common";

export const emailMethods = new Vue({
  methods: {
    /**
     * Clears fleet upload files from session storage.
     */
    clearFiles() {
      sessionStorage.removeItem("fiveYearsClaimHistory");
      sessionStorage.removeItem("driverInductionFile");
      sessionStorage.removeItem("vehicleCareProceduresFile");
      sessionStorage.removeItem("excelScheduleUploadFile");
      sessionStorage.removeItem("proofOfStampDutyExemptionFile");

      sessionStorage.removeItem("fleetModel");

      sessionStorage.removeItem("fiveYearsClaimHistory_filename");
      sessionStorage.removeItem("driverInductionFile_filename");
      sessionStorage.removeItem("vehicleCareProceduresFile_filename");
      sessionStorage.removeItem("excelScheduleUploadFile_filename");
      sessionStorage.removeItem("proofOfStampDutyExemptionFile_filename");
    },

    async sendWelcomeEmail(realmApp, broker) {
      const bcc_emails = GlobalMethods.checkSafeString(broker.data.parentOrg.parentInsuranceBroker.bcc_emails)
      var mergeFields = templateMethods.convertToHashFields(
        broker.data,
        "broker"
      );
      const emailTemplateId = broker.data.parentOrg.is_insurance_broker
        ? "broker-welcome-email"
        : "finance-broker-welcome-email";

      var populatedTemplate = await templateMethods.populateEmailTemplate(
        realmApp,
        mergeFields,
        emailTemplateId
      );

      var populatedTemplateContent = populatedTemplate.templateContent;

      populatedTemplateContent = GlobalMethods.replaceAll(
        populatedTemplateContent,
        "##Click here to set your password##",
        "<a href='" +
          process.env.VUE_APP_SITEURL +
          "/setPassword?token=" +
          broker.data.emailToken +
          "&tokenId=" +
          broker.data.emailTokenId +
          "'>Click here to set your password</a>"
      );

      populatedTemplateContent = GlobalMethods.replaceAll(
        populatedTemplateContent,
        "##insurer-logo##",
        "<img src='https://partner.yourinsurancehq.com.au/img/brand/logo_full_cropped.png'>"
      );

      const attachmentItems = await appFunctions.crud_emailTemplate(
        "getAttachments",
        "broker-welcome-email"
      );

      const attachments = [];

      attachmentItems.data.forEach(f => {
        const contentSplit = f.fileContent.split(",");

        const type = contentSplit[0].replace("data:", "").replace(";base64");
        const content = contentSplit[1];

        const contentBlob = this.b64toBlob(content);

        attachments.push({ filename: f.filename, data: contentBlob });
      });


      this.sendMailGunEmail(
        [broker.data.Broker_email],
        [],
        [bcc_emails],
        populatedTemplate.subjectLine,
        populatedTemplateContent,
        "Your Insurance <noreply@yourinsurancehq.com.au>",
        attachments
      );

      //console.log(populatedTemplate);
    },
    prepareAttachment(filename, fileContentb64) {
      const contentSplit = fileContentb64.split(",");

      const type = contentSplit[0].replace("data:", "").replace(";base64");
      const content = contentSplit[1];

      const contentBlob = this.b64toBlob(content, type);

      return { filename, data: contentBlob };
    },
    async generateCertificateOfCurrency(realmApp, customerPolicy) {
      var mergeFields = templateMethods.convertToHashFields(
        customerPolicy,
        "policy"
      );

      const brokerObj = await appFunctions.crud_broker("getByRealmId", {
        Realm_user_id: customerPolicy.Customer_policy_created_by
      });

      var populatedTemplate = await templateMethods.populateContentTemplate(
        realmApp,
        mergeFields,
        brokerObj.data.parentOrg.parentInsuranceBroker.content_template_id
      );

      //console.log();

      //var this.$moment(dateString).format('DD-MM-YYYY'); = ;

      var startDate = new Date(customerPolicy.Customer_policy_start_date);
      var endDate = this.$moment(startDate).add(12, "months");

      var dateRangeString =
        this.$moment(startDate).format("DD/MM/YYYY") +
        " to " +
        this.$moment(endDate).format("DD/MM/YYYY");

      //formattingMethods.

      populatedTemplate = GlobalMethods.replaceAll(
        populatedTemplate.templateContent,
        "##policy-start-date##",
        this.$moment(startDate).format("DD/MM/YYYY")
      );
      populatedTemplate = GlobalMethods.replaceAll(
        populatedTemplate,
        "##policy-start-date-plus-12-months##",
        this.$moment(endDate).format("DD/MM/YYYY")
      );

      populatedTemplate = GlobalMethods.replaceAll(
        populatedTemplate,
        "##date_today##",
        this.$moment(startDate).format("Do of MMMM, YYYY")
      );

      populatedTemplate = GlobalMethods.replaceAll(
        populatedTemplate,
        "##calculated.insurance-period##",
        dateRangeString
      );
      populatedTemplate = GlobalMethods.replaceAll(
        populatedTemplate,
        "##insurance-broker-logo##",
        "<img width='300' src='" +
          brokerObj.data.parentOrg.parentInsuranceBroker.insurance_broker_logo +
          "'/>"
      );
      populatedTemplate = GlobalMethods.replaceAll(
        populatedTemplate,
        "##insurance-broker-signature##",
        "<img width='150' src='" +
          brokerObj.data.parentOrg.parentInsuranceBroker
            .insurance_broker_signature +
          "'/>"
      );

      return populatedTemplate;
    },

    /**
     * Sends continue quote email containing link.
     * @param {*} realmApp Realm app object
     * @param {Object} customerPolicy Customer policy object
     * @param {"RS" | "LC"} quoteType Quote type either "RS" (ride share) or "LC" (light commercial)
     * @returns {Promise} Result of email send
     */
    async sendContinueQuoteEmail(realmApp, customerPolicy, quoteType = "LC", productName) {
      const isRs = quoteType === "RS";
      const emailTemplateId = getEmailTemplateId(quoteType)

      if (quoteType == "VIBS") {
        const convertedProductName = formattingMethods.formatProductNameVIBS(productName)
        customerPolicy.Product_name = convertedProductName
      }

      var mergeFields = templateMethods.convertToHashFields(
        customerPolicy,
        "policy"
      );

      var TemplateData = await templateMethods.populateEmailTemplate(
        realmApp,
        mergeFields,
        emailTemplateId
      );

      TemplateData.templateContent = GlobalMethods.replaceAll(
        TemplateData.templateContent,
        "##rideshare-logo##",
        "<img width='350' norescale alt='Rideprotect logo' src='https://partner-staging.yourinsurancehq.com.au/img/brand/rideprotect-signature-logo.png'>"
      );

      TemplateData.templateContent = GlobalMethods.replaceAll(
        TemplateData.templateContent,
        "##rideshare-footer##",
        "<p style='color: #1b254e;'>T: 1300 33 45 20 E: info@rideprotect.com.au W: www.rideprotect.com.au</p><p style='color: #afafaf; font-size: 8px;'>Ride Protect Pty Limited (CAR No, 1288778) is a Corporate Authorised Representative of Mercurien Insurance Pty Ltd ABN 68 150 845 177, AFSL No 480284, who is an agent of HDI Global, AFSL No 458776</p><p style='color: #afafaf; font-size: 8px;'>Important Notices:</p><p style='color: #afafaf; font-size: 8px;''>If you are not the intended recipient, please delete this email as its use is prohibited.Ride Protect Pty Ltd, which is an authorised representative of Mercurien Insurance does not warrant or represent that this email is free from viruses or defects.If you do not wish to receive any further commercial or insurance disclosure electronic messages from Ride Protect Pty Ltd, please email management@rideprotect.com.au. We may use your personal information in line with our Privacy Statement. For more details, visit https://www.rideprotect.com.au/privacy-policy.html or email us on info@rideprotect.com.au</p><p style='color: #afafaf; font-size: 8px;'>General Advice</p><p style='color: #afafaf; font-size: 8px;'>General Advice has been provided, whilst we may have collected risk information, your personal objectives, needs or financial situations were not taken into account when preparing this information. We recommend that you consider the suitability of this general advice, in respect of your objectives, financial situation and needs. Please consider the relevant PDS before making any decision to purchase this financial product.</p>"
      );

      let guid = quoteType === 'VIBS' ? customerPolicy.Jotform_quote_id : customerPolicy.Customer_policy_guid;
      const continueQuoteUrl = getContinueQuoteUrl(guid, quoteType, productName)

      TemplateData.templateContent = GlobalMethods.replaceAll(
        TemplateData.templateContent,
        "##ubind-continue-quote-url##",
        "<a href='" + continueQuoteUrl + "'>Continue quote here</a>"
      );

      var result = appFunctions.sendMailGunEmail(
        [customerPolicy.Customer_contact_email],
        [],
        [GlobalStore.brokerObj.Broker_email],
        TemplateData.subjectLine,
        TemplateData.templateContent,
        GlobalStore.brokerObj.Broker_first_name +
          " " +
          GlobalStore.brokerObj.Broker_last_name +
          " via Your Insurance <notifications@yourinsurancehq.com.au>",
        [],
        GlobalStore.brokerObj.Broker_email
      );

      return result;
    },

    async sendFleetCallbackEmail(realmApp) {
      const {
        Broker_email,
        Broker_first_name,
        Broker_last_name,
        Broker_phone
      } = GlobalStore.brokerObj;
      const data = {
        Broker_email,
        Broker_first_name,
        Broker_last_name,
        Broker_phone
      };

      var mergeFields = templateMethods.convertToHashFields(data, "broker");
      var TemplateData = await templateMethods.populateEmailTemplate(
        realmApp,
        mergeFields,
        "fleet-call-back-email"
      );

      TemplateData.templateContent = GlobalMethods.replaceAll(
        TemplateData.templateContent,
        "##insurer-logo##",
        "<img src='https://partner.yourinsurancehq.com.au/img/brand/logo_full_cropped.png'>"
      );

      var result = appFunctions.sendMailGunEmail(
        ["alex@kora.net.au"],
        //["mercurien.faas@yourinsurancehq.com.au"],
        [],
        [],
        TemplateData.subjectLine,
        TemplateData.templateContent,
        GlobalStore.brokerObj.Broker_first_name +
          " " +
          GlobalStore.brokerObj.Broker_last_name +
          " via Your Insurance <notifications@yourinsurancehq.com.au>",
        [],
        GlobalStore.brokerObj.Broker_email
      );

      return result;
    },

    async sendOfferEmail(realmApp, customerPolicy) {
      var mergeFields = templateMethods.convertToHashFields(
        customerPolicy,
        "policy"
      );

      var TemplateData = await templateMethods.populateEmailTemplate(
        realmApp,
        mergeFields,
        "offer"
      );

      //console.log(GlobalStore.brokerObj.Broker_email)

      //console.log(TemplateData);

      TemplateData.templateContent = GlobalMethods.replaceAll(
        TemplateData.templateContent,
        "##insurer-logo##",
        "<img src='https://partner.yourinsurancehq.com.au/img/brand/logo_full_cropped.png'>"
      );

      var result = appFunctions.sendEmail(
        [customerPolicy.Customer_contact_email],
        [GlobalStore.brokerObj.Broker_email],
        TemplateData.subjectLine,
        TemplateData.templateContent
      );

      //console.log(result);
    },
    async sendInsureInfoEmail(realmApp, customerPolicy) {
      var mergeFieldsPolicy = templateMethods.convertToHashFields(
        customerPolicy,
        "policy"
      );

      const brokerObj = await appFunctions.crud_broker("getByRealmId", {
        Realm_user_id: customerPolicy.Customer_policy_created_by
      });

      var mergeFieldsBroker = templateMethods.convertToHashFields(
        brokerObj.data,
        "broker"
      );

      var mergeFields = [...mergeFieldsPolicy, ...mergeFieldsBroker];

      var TemplateData = await templateMethods.populateEmailTemplate(
        realmApp,
        mergeFields,
        "insurer-invoice-info-email"
      );

      console.log(mergeFields);

      //GlobalStore.brokerObj = brokerObj.data;

      var parentOrg = brokerObj.data.parentOrg;

      TemplateData.templateContent = GlobalMethods.replaceAll(
        TemplateData.templateContent,
        "##brokerorg.insurance_issuer##",
        parentOrg.insurance_issuer
      );
      TemplateData.templateContent = GlobalMethods.replaceAll(
        TemplateData.templateContent,
        "##insurer-logo##",
        "<img src='https://partner.yourinsurancehq.com.au/img/brand/logo_full_cropped.png'>"
      );
      TemplateData.templateContent = GlobalMethods.replaceAll(
        TemplateData.templateContent,
        "##brokerorg.broker-org-name##",
        parentOrg.Broker_Org_Name
      );

      var result = appFunctions.sendEmail(
        [parentOrg.parentInsuranceBroker.insurance_issuer_email],
        [],
        TemplateData.subjectLine,
        TemplateData.templateContent
      );

      //console.log(result);
    },
    async sendFleetEmail(realmApp, customerFleetPolicy, attachments, productType) {
      var mergeFieldsPolicy = templateMethods.convertToHashFields(
        customerFleetPolicy,
        "policy"
      );

      const brokerObj = await appFunctions.crud_broker("getByRealmId", {
        Realm_user_id: customerFleetPolicy.Customer_policy_created_by
      });

      var mergeFieldsBroker = templateMethods.convertToHashFields(
        brokerObj.data,
        "broker"
      );

      var mergeFields = [...mergeFieldsPolicy, ...mergeFieldsBroker];

      var TemplateData = await templateMethods.populateEmailTemplate(
        realmApp,
        mergeFields,
        "fleet-quote-email"
      );

      const method = productType === 'Rideshare' ? appFunctions.crud_customerRSFleetPolicy : appFunctions.crud_customerFleetPolicy
      const replaceLinks = await method(
        "getQuoteAttachmentLinks",
        { Customer_policy_id: customerFleetPolicy.Customer_policy_id }
      );

      TemplateData.templateContent = GlobalMethods.replaceAll(
        TemplateData.templateContent,
        "##attachmentLinks##",
        replaceLinks.data
      );

      var parentOrg = brokerObj.data.parentOrg;

      TemplateData.templateContent = GlobalMethods.replaceAll(
        TemplateData.templateContent,
        "##brokerorg.insurance_issuer##",
        parentOrg.insurance_issuer
      );
      TemplateData.templateContent = GlobalMethods.replaceAll(
        TemplateData.templateContent,
        "##insurer-logo##",
        "<img src='https://partner.yourinsurancehq.com.au/img/brand/logo_full_cropped.png'>"
      );
      TemplateData.templateContent = GlobalMethods.replaceAll(
        TemplateData.templateContent,
        "##your-insurance-logo##",
        "<img src='https://partner.yourinsurancehq.com.au/img/brand/yihq_logo_full.png'>"
      );
      TemplateData.templateContent = GlobalMethods.replaceAll(
        TemplateData.templateContent,
        "##brokerorg.broker-org-name##",
        parentOrg.Broker_Org_Name
      );

      TemplateData.subjectLine = GlobalMethods.replaceAll(
        TemplateData.subjectLine,
        "##brokerorg.broker-org-name##",
        parentOrg.Broker_Org_Name
      );

      TemplateData.subjectLine = GlobalMethods.replaceAll(
        TemplateData.subjectLine,
        "New",
        `New ${productType}`
      );

      var result = emailMethods.sendMailGunEmail(
        //["alex@Kora.net.au"],
        [GlobalStore.ubindClientInfo.fleetEmailDest],
        [],
        //[],
        ["mercurien.fleet@yourinsurancehq.com.au", brokerObj.data.Broker_email],
        TemplateData.subjectLine,
        TemplateData.templateContent,
        GlobalStore.brokerObj.Broker_first_name +
          " " +
          GlobalStore.brokerObj.Broker_last_name +
          " via Your Insurance <notifications@yourinsurancehq.com.au>",
        attachments,
        GlobalStore.brokerObj.Broker_first_name +
          " " +
          GlobalStore.brokerObj.Broker_last_name +
          "<" +
          GlobalStore.brokerObj.Broker_email +
          ">"
      );

      console.log(result);
    },
    async sendClaimEmail(
      realmApp,
      emailHtmlContent,
      Customer_claim_id,
      attachments,
      quoteType = "LC"
    ) {
      const isRS = quoteType === "RS";
      // var mergeFieldsPolicy = templateMethods.convertToHashFields(
      //   customerFleetPolicy,
      //   "policy"
      // );

      // const brokerObj = await appFunctions.crud_broker("getByRealmId", {
      //   Realm_user_id: Customer_policy_created_by
      // });

      // var mergeFieldsBroker = templateMethods.convertToHashFields(
      //   brokerObj.data,
      //   "broker"
      // );

      // var mergeFields = [...mergeFieldsPolicy, ...mergeFieldsBroker];
      // var TemplateData = await templateMethods.populateEmailTemplate(
      //   realmApp,
      //   mergeFields,
      //   "fleet-quote-email"
      // );

      // const replaceLinks = isRS
      //   ? await appFunctions.crud_customerRSClaim("getClaimAttachmentLinks", {
      //       Customer_claim_id
      //     })
      //   : await appFunctions.crud_customerLCClaim("getClaimAttachmentLinks", {
      //       Customer_claim_id
      //     });

      // emailHtmlContent = GlobalMethods.replaceAll(
      //   emailHtmlContent,
      //   "##attachmentLinks##",
      //   replaceLinks.data
      // );

      // var parentOrg = GlobalStore.brokerObj.parentOrg;

      // TemplateData.templateContent = GlobalMethods.replaceAll(
      //   TemplateData.templateContent,
      //   "##brokerorg.insurance_issuer##",
      //   parentOrg.insurance_issuer
      // );
      // TemplateData.templateContent = GlobalMethods.replaceAll(
      //   TemplateData.templateContent,
      //   "##insurer-logo##",
      //   "<img src='https://partner-dev.yourinsurancehq.com.au/img/brand/logo_full_cropped.png'>"
      // );
      // TemplateData.templateContent = GlobalMethods.replaceAll(
      //   TemplateData.templateContent,
      //   "##your-insurance-logo##",
      //   "<img src='https://partner-dev.yourinsurancehq.com.au/img/brand/yihq_logo_full.png'>"
      // );
      // TemplateData.templateContent = GlobalMethods.replaceAll(
      //   TemplateData.templateContent,
      //   "##brokerorg.broker-org-name##",
      //   parentOrg.Broker_Org_Name
      // );

      // TemplateData.subjectLine = GlobalMethods.replaceAll(
      //   TemplateData.subjectLine,
      //   "##brokerorg.broker-org-name##",
      //   parentOrg.Broker_Org_Name
      // );

      // var result = appFunctions.sendEmail(
      //   [GlobalStore.ubindClientInfo.claimEmailDest],
      //   //[GlobalStore.ubindClientInfo.fleetEmailDest],
      //   //["mercurien.fleet@yourinsurancehq.com.au", brokerObj.data.Broker_email],
      //   [GlobalStore.brokerObj.Broker_email],
      //   "New Light Commercial Claim",
      //   emailHtmlContent,
      //   {
      //     email: GlobalStore.brokerObj.Broker_email,
      //     name:
      //       GlobalStore.brokerObj.Broker_first_name +
      //       " " +
      //       GlobalStore.brokerObj.Broker_last_name
      //   }
      // );

      var result = emailMethods.sendMailGunEmail(
        GlobalStore.ubindClientInfo.claimEmailDest,
        GlobalStore.ubindClientInfo.ccEmailList,
        //[],
        [GlobalStore.brokerObj.Broker_email, "uyen.nguyen@codecomplete.jp", "alex.barry@yourinsurancehq.com.au", "liz.still@yourinsurancehq.com.au", "light.commercial@yourinsurancehq.com.au"],
        isRS ? "New RideProtect Claim" : "New Light Commercial Claim",
        emailHtmlContent,
        GlobalStore.brokerObj.Broker_first_name +
          " " +
          GlobalStore.brokerObj.Broker_last_name +
          " via Your Insurance <notifications@yourinsurancehq.com.au>",
        attachments,
        GlobalStore.brokerObj.Broker_first_name +
          " " +
          GlobalStore.brokerObj.Broker_last_name +
          "<" +
          GlobalStore.brokerObj.Broker_email +
          ">"
      );

      // var result = appFunctions.sendEmail(
      //   //[parentOrg.parentInsuranceBroker.insurance_issuer_email],
      //   ["alex@kora.net.au"],
      //   [],
      //   TemplateData.subjectLine,
      //   TemplateData.templateContent,
      //   {
      //     email: GlobalStore.brokerObj.Broker_email,
      //     name:
      //       GlobalStore.brokerObj.Broker_first_name +
      //       " " +
      //       GlobalStore.brokerObj.Broker_last_name
      //   }
      // );
    },
    async sendPremiumFundingEmail(realmApp, customerPolicy) {
      var mergeFieldsPolicy = templateMethods.convertToHashFields(
        customerPolicy,
        "policy"
      );

      const brokerObj = await appFunctions.crud_broker("getByRealmId", {
        Realm_user_id: customerPolicy.Customer_policy_created_by
      });

      var mergeFieldsBroker = templateMethods.convertToHashFields(
        brokerObj.data,
        "broker"
      );

      var mergeFields = [...mergeFieldsPolicy, ...mergeFieldsBroker];

      console.log(TemplateData);

      var TemplateData = await templateMethods.populateEmailTemplate(
        realmApp,
        mergeFields,
        "premium-funding-broker-email"
      );

      var parentOrg = brokerObj.data.parentOrg;

      TemplateData.templateContent = GlobalMethods.replaceAll(
        TemplateData.templateContent,
        "##brokerorg.insurance_issuer##",
        parentOrg.insurance_issuer
      );
      TemplateData.templateContent = GlobalMethods.replaceAll(
        TemplateData.templateContent,
        "##insurer-logo##",
        "<img src='https://partner.yourinsurancehq.com.au/img/brand/logo_full_cropped.png'>"
      );

      var result = appFunctions.sendEmail(
        [brokerObj.data.Broker_email],
        [],
        TemplateData.subjectLine,
        TemplateData.templateContent
      );

      //console.log(result);
    },
    async sendCustomerWelcomeEmail(realmApp, customerPolicy) {
      var mergeFields = templateMethods.convertToHashFields(
        customerPolicy,
        "policy"
      );

      var TemplateData = await templateMethods.populateEmailTemplate(
        realmApp,
        mergeFields,
        "customer-congratulations-email"
      );

      const brokerObj = await appFunctions.crud_broker("getByRealmId", {
        Realm_user_id: customerPolicy.Customer_policy_created_by
      });

      TemplateData.templateContent = GlobalMethods.replaceAll(
        TemplateData.templateContent,
        "##insurer-logo##",
        "<img src='https://partner.yourinsurancehq.com.au/img/brand/logo_full_cropped.png'>"
      );

      TemplateData.templateContent = GlobalMethods.replaceAll(
        TemplateData.templateContent,
        "##certificate-of-currency-link##",
        "<a href='" +
          process.env.VUE_APP_SITEURL +
          "/certificateOfCurrency/" +
          customerPolicy.Customer_policy_guid +
          "'>Click here to download your Certificate of Currency</a>"
      );

      var result = appFunctions.sendEmail(
        [customerPolicy.Customer_contact_email],
        [brokerObj.data.Broker_email],
        TemplateData.subjectLine,
        TemplateData.templateContent
      );
    },
    generateClaimEmail(model) {
      const h1 = "h3";
      const h2 = "h4";
      const p = "p";

      const lossDate = this.$moment(model.dateOfLoss, "YYYY-MM-DD");

      let lossDateString = "";

      if (lossDate.isValid()) {
        lossDateString = lossDate.format("DD/MM/YYYY");
      }

      //console.log(lossDate);

      let outString = `
      <${h1}>Supporting Documentation is attached.<${h1}>

      <${h1}>Policyholder Details<${h1}>

      <${h2}>Business / Insured Name</${h2}>
      <${p}>${model.insuredName}</${p}>
      
      <${h2}>Policy Number</${h2}>
      <${p}>${model.policyNumber}</${p}>
      
      <${h2}>Contact Name</${h2}>
      <${p}>${model.contactName}</${p}>

      <${h2}>Contact Mobile Number</${h2}>
      <${p}>${model.contactPhone}</${p}>
      
      <${h2}>Contact Email</${h2}>
      <${p}>${model.contactEmail}</${p}>

      <${h1}>Policyholder Address</${h1}>
      <${p}>${model.policyholderStreet} ${model.policyholderCity}</${p}>
      <${p}>${model.policyholderState} ${model.policyholderPostcode}</${p}>

      <${h1}>PolicyDetails</${h1}>
      <${h2}>When did the damage or loss occur?</${h2}>
      <${p}>${lossDateString}</${p}>

      <${h2}>Policy Number</${h2}>
      <${p}>${model.policyNumber}</${p}>

      <${h1}>GST</${h1}>

      <${h2}>Registered for 'GST' ?</${h2}>
      <${p}>${model.registeredForGST ? "Yes" : "No"}</${p}>
      
      <${h2}>Insured ITC</${h2}>
      <${p}>${model.insuredITC}</${p}>

      <${h2}>Insured ABN</${h2}>
      <${p}>${model.abn}</${p}>


      

      <${h1}>Reported By</${h1}>

      <${h2}>Brokerage Name</${h2}>
      <${p}>${model.brokerageName}</${p}>
      
      <${h2}>First Name</${h2}>
      <${p}>${model.reporterFName}</${p}>

      <${h2}>Last Name</${h2}>
      <${p}>${model.reporterLName}</${p}>

      <${h2}>Phone Number</${h2}>
      <${p}>${model.reporterPhone}</${p}>

      <${h2}>Email</${h2}>
      <${p}>${model.reporterEmail}</${p}>

      <${h1}>Incident Details</${h1}>

      <${h2}>Date of loss</${h2}>
      <${p}>${lossDateString}</${p}>
      
      <${h2}>Time of loss</${h2}>
      <${p}>${model.timeOfLoss}</${p}>

      <${h2}>Tell us what happened in detail</${h2}>
      <${p}>${model.incidentDetail}</${p}>


      <${h2}>Estimate of loss</${h2}>
      <${p}>${model.lossEstimate}</${p}>
      
      <${h1}>Incident location</${h1}>

      <${h2}>Address</${h2}>
      <${p}>${model.incidentStreet} ${model.incidentCity}</${p}>
      <${p}>${model.incidentState} ${model.incidentPostcode}</${p}>

      <${h1}>Police Details</${h1}>
      <${h2}>Reported to Police</${h2}>
      <${p}>${model.reportedToPolice ? "Yes" : "No"}</${p}>`;

      if (model.reportedToPolice) {
        outString += `
      <${h2}>Reference Number</${h2}>
      <${p}>${model.policeReportRef}</${p}>

      <${h2}>Details</${h2}>
      <${p}>${model.policeReportDetails}</${p}>`;
      }

      outString += ` <${h1}>Bank Account Details</${h1}>

      <${h2}>Account Name</${h2}>
      <${p}>${model.bankAccountName}</${p}>

      <${h2}>BSB Number</${h2}>
      <${p}>${model.bankBSB}</${p}>

      <${h2}>Account Number</${h2}>
      <${p}>${model.bankAccountNumber}</${p}>

      <${h2}>Bank Name</${h2}>
      <${p}>${model.bankName}</${p}>

      <${h1}>Contact</${h1}>

      <${h2}>Would you like to add an additional contact to this claim?</${h2}>
      <${p}>${model.additionalContact ? "Yes" : "No"}</${p}>`;

      if (model.additionalContact) {
        outString += `<${h2}>Contact Type</${h2}>
      <${p}>${model.additionalContactType}</${p}>



      <${h2}>First Name</${h2}>
      <${p}>${model.additionalContactFName}</${p}>

      <${h2}>Last Name</${h2}>
      <${p}>${model.additionalContactLName}</${p}>

      <${h2}>Telephone Number</${h2}>
      <${p}>${model.additionalContactTelephone}</${p}>

      <${h2}>Mobile Number</${h2}>
      <${p}>${model.additionalContactMobile}</${p}>

      <${h2}>Email</${h2}>
      <${p}>${model.additionalContactEmail}</${p}>

      <${h2}>Preferred Contact</${h2}>
      <${p}>${model.additionalContactPreferredContact}</${p}>
`;
      }

      return outString;
    },
    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    async sendMailGunEmail(
      emailAddresses,
      ccAddresses,
      bccAddresses,
      subjectLine,
      templateHtml,
      emailFrom,
      attachments,
      replyTo
    ) {
      try {
        const mailgun = new Mailgun(formData);
        const mg = mailgun.client({
          username: "api",
          key: "62d1abf9793e1d3c9dd0501368535803-fe066263-e1704e0c"
        }); 
        
        var data = {
          from: emailFrom,
          to: emailAddresses,
          cc: ccAddresses,
          bcc: bccAddresses,
          subject: subjectLine,
          html: templateHtml,
          attachment: attachments
        };

        if (replyTo) {
          data["h:Reply-To"] = replyTo;
        }

        var result = await mg.messages.create("yourinsurancehq.com.au", data);
      } catch {
        return false;
      }
      return true;
    }
  }
});
