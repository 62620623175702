var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"btn-group-toggle",attrs:{"data-toggle":"buttons"}},_vm._l((_vm.options),function(option,index){return _c('label',{key:index,staticClass:"btn btn-label btn-multi",class:[
      { active: _vm.value === option.value },
      _vm.buttonClasses,
      { btnMultiFirst: index == 0 },
      { btnMultiLast: index == _vm.options.length - 1 }
    ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],attrs:{"type":"radio","id":"option1","autocomplete":"off","checked":""},domProps:{"value":option.value,"checked":_vm._q(_vm.model,option.value)},on:{"change":function($event){_vm.model=option.value}}}),(option.image)?_c('img',{staticStyle:{"display":"block","margin-left":"calc(50% - 15px)"},attrs:{"src":_vm.value === option.value
          ? option.image + '-active' + '.png'
          : option.image + '.png',"width":"30px"}}):_vm._e(),_vm._v(" "+_vm._s(option.label)+" ")])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }