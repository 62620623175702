<template>
  <div>
    <el-dialog class="pre-formatted" :title="titleDialog" @close="cancelModel" :visible.sync="quoteSchedulePopup"
      width="65%" center>
      <div v-if="isBindQuote">
        <el-table :data="quoteData" :show-header="false">
          <el-table-column property="premium" label="Premium"/>
          <el-table-column property="value" label="Value($)" />
        </el-table>
        <br/>
        <!-- hide function broker send email to customer with final quote chosen by broker, refs#task-3158462 -->
        <!-- <p v-if="!this.offeredQuote.status"> Would you like to choose this quote to send to your customer email ?</p>
        <el-checkbox v-if="!this.offeredQuote.status" v-model="cancelConfirmed">Yes, I am sure.</el-checkbox> -->
        <!-- <div class="mb-5">
          <el-upload
            ref="uploadRef"
            class="upload-document"
            action="#"
            :auto-upload="false"
            :show-file-list="true"
            :on-change="handleChangeUpload"
            :on-remove="handleRemoveUpload"
          >
            <el-button class="btn btn-outline-danger">Click to upload policy documents</el-button>
          </el-upload>
        </div> -->
        
        <div>
          <p> Would you like to bind this quote ?</p>
          <el-checkbox v-model="cancelConfirmed">Yes, I am sure.</el-checkbox>
          <div>
            <span slot="footer" class="dialog-footer">
            <el-button @click="cancelModel">Cancel</el-button>
            <!-- hide function broker send email to customer with final quote chosen by broker, refs#task-3158462 -->
            <!-- <el-button v-if="!this.offeredQuote.status" type="danger" :disabled="!cancelConfirmed" @click="handleOk">OK</el-button> -->
            <el-button type="danger" :disabled="!cancelConfirmed" @click="handleOk">OK</el-button>
            </span>
          </div>
        </div>
      </div>

      <div v-else>
        <el-table :data="offeredQuoteList" header-row-class-name="thead-light">
          <!--<el-table-column prop="id" label="No" min-width="50px"/>-->
          <el-table-column prop="insurer" label="Insurer" />
          <el-table-column prop="basePremium" label="Base Premium" :formatter="row => formatCurrency(row.basePremium)"/>
          <el-table-column prop="esl" label="ESL" :formatter="row => formatCurrency(row.esl)" min-width="50px"/>
          <el-table-column prop="policyFee" label="Policy Fee" :formatter="row => formatCurrency(row.policyFee)"/>
          <el-table-column prop="totalPayable" label="Total Payable" :formatter="row => formatCurrency(row.totalPayable)"/>
          <el-table-column prop="notes" label="Notes" min-width="180px"/>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Dialog, Button, Checkbox, Notification, Table, TableColumn, Upload } from "element-ui";
import { toasts } from "../../helpers/toasts";
import { formattingMethods } from "../../helpers/formatting";
import { appFunctions } from '../../helpers/appFunctions';

export default {
  name: 'DialogConfirm',
  data() {
    return {
      cancelConfirmed: false,
      quoteSchedulePopup: false,
      isUploadDocuments: false,
      uploadedDocuments: [],
      documentObject: []
    }
  },
  props: ['quoteScheduleModal', 'titleDialog', 'offeredQuote', 'isBindQuote', 'offeredQuoteList'],
  computed: {
    quoteData() {
      const result = [
        {
          "premium": "Insurer",
          "key": "insurer",
          "value": null
        },
        {
          "premium": "Base Premium",
          "key": "basePremium",
          "value": null
        },
        {
          "premium": "ESL",
          "key": "esl",
          "value": null
        },
        {
          "premium": "Policy Fee",
          "key": "policyFee",
          "value": null
        },
        {
          "premium": "Total Payable",
          "key": "totalPayable",
          "value": null
        },
        {
          "premium": "Notes",
          "key": "notes",
          "value": null
        },
      ];
      if(!this.quoteSchedulePopup) return
      result.forEach(element => {
        const key = element.key
        element.value = formattingMethods.numberWithCommas(this.offeredQuote[key])
      });
      return result
    }
  },
  methods: {
    async handleOk() {
      // if (this.isUploadDocuments && this.documentObject.length > 0) {
      //  await this.uploadFileToS3(this.documentObject)
      // }
  
      this.$emit('handleCallAPI')
    },

    // handleChangeUpload(e) {
    //   this.isUploadDocuments = true
    //   const loadendDocumentsObject = {}
    //   loadendDocumentsObject.s3name = this.offeredQuote.transactionId + "-" + e.name.replaceAll(" ", "-");
    //   loadendDocumentsObject.id = e.uid
    //   loadendDocumentsObject.transactionId = this.offeredQuote.transactionId
    //   loadendDocumentsObject.productCode = this.offeredQuote.productCode.toLowerCase()
    //   loadendDocumentsObject.type = e.raw.type;
    //   loadendDocumentsObject.fileName = e.name.replaceAll(" ", "-");

    //   let file = e.raw
    //   let reader = new FileReader();
    //   reader.onloadend = function() {
    //     loadendDocumentsObject.base64 = reader.result.split(";base64,")[1]
    //   }
    //   reader.readAsDataURL(file)
    //   this.documentObject.push(loadendDocumentsObject)
    // },

    // handleRemoveUpload(e) {
    //   this.documentObject = this.documentObject.filter((document) => document.id != e.uid)
    // },

    // async uploadFileToS3() {
    //   await appFunctions
    //     .uploadDocuments(this.documentObject)
    //     .then((response) => {
    //       if (response) {
    //         this.uploadedDocuments = response
    //         toasts.success("Upload documents successfully!");
    //       }
    //     })
    //     .catch((error) => {
    //       //console.log('Error', error)
    //       toasts.error("Upload documents failed!");
    //     })
    // },

    cancelModel() {
      this.cancelConfirmed = false
      this.$emit('modelPopupCancel')
    },
    formatCurrency(input) {
      if (!input) return 'N/A'
      return '$' + formattingMethods.numberWithCommas(input)
    }
  },

  watch: {
    quoteScheduleModal(val) {
      //this.$refs.uploadRef && this.$refs.uploadRef.clearFiles()
      this.quoteSchedulePopup = val
    }
  },
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Notification.name]: Notification,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Upload.name]: Upload
  }
};
</script>
<style>
.el-dialog__body {
  text-align: center !important;
}

.el-dialog__title {
  font-weight: bold;
}

.el-checkbox__input.is-checked+.el-checkbox__label {
  color: black;
}

.el-dialog__close {
  margin-left: 10px;
}

.pre-formatted {
  white-space: pre;
}
</style>
