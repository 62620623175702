<template>
  <div>
    <div v-if="step === 'step1'">
      <Step1 @goStep2="goStep2" />
    </div>

    <div v-if="step === 'step2'">
      <Step2 @goStep1="goStep1" :quoteObj="quoteObj" />
    </div>
  </div>
</template>

<script>
import Step1 from "./createQuoteStep1.vue";
import Step2 from "./createQuoteStep2.vue";
import { appFunctions } from "../../helpers/appFunctions";

export default {
  data() {
    return {
      step: "step1",
      quoteObj: {}
    };
  },
  methods: {
    goStep2(quoteObj) {
      this.step = "step2";
      this.quoteObj = quoteObj
    },
    goStep1() {
      this.step = "step1";
    }
  },
  components: {
    Step1,
    Step2
  }
};
</script>

<style scoped></style>
