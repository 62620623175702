<template>
  <ContinueQoute productType="mercurien-light-commercial" />
</template>

<script>
import ContinueQoute from "../../components/ContinueQuote.vue";

export default {
  components: {
    ContinueQoute
  }
};
</script>
