<template>
  <b-container fluid class="mt--10">
    <b-row>
      <b-col cols="12" md="7" xl="8">
        <card type="secondary" header-classes="bg-transparent">
          <b-row align-v="center" slot="header">
            <b-col>
              <h5 class="h3 mb-0">Total Created Quotes Number 
                <b-badge variant="danger" class="text-lg">{{ totalBoundPolicies }}</b-badge>
              </h5>
              
            </b-col>
            <!-- <b-col>
              <b-nav class="nav-pills justify-content-end">
              <span style="margin: 6px;">Status: </span>
              <el-dropdown class="mobile-status" @command="filterChartStatus" trigger="click">
                <el-button>
                  {{ filterName }} <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(quote, index) in status" :key="index" :command="quote.key">{{ quote.name
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              </b-nav>
            </b-col> -->
          </b-row>
          <line-chart
            :height="100"
            ref="bigChart"
            :chart-data="bigLineChart.chartData"
            :extra-options="bigLineChart.extraOptions"
          >
          </line-chart>
          <hr/>
          <div class="float-right float-xl-left">
            <div v-for="(label, index) in labelsChart" :key="index">
              <p class="mt--3">
                <i class="fa fa-square" aria-hidden="true" v-bind:style="{ color: label.color }"></i>
                {{ label.title }}
              </p>
            </div>
          </div>
        </card>
      </b-col>
      <b-col cols="12" md="5" xl="4">  
        <div class="bg-white rounded p-3" style="height: 340px">
          <div class="dashboard-info bg-danger mb-5">
            <span>{{ formatPremium(totalPremiumAllProducts) }}</span>
            <div>QUOTES TOTAL PREMIUM</div>
          </div>
          <div v-for="product in totalPremiumDatas" :key="product.productName" class="mb-3">
            <h3 class="mb-3">{{ product.productName }}</h3>
            <b-progress :max="totalPremiumAllProducts" height="1.5rem">
              <b-progress-bar :value="product.totalPremium" :style="{backgroundColor: product.color}">
                <span><strong>{{ formatPremium(product.totalPremium) }}</strong></span>
              </b-progress-bar>
            </b-progress>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import * as chartConfigs from "../../components/Charts/config.js";
import LineChart from "../../components/Charts/LineChart.js";
import BarChart from "../../components/Charts/BarChart.js";
import Spinner from "./Spinner.vue";
import moment from "moment";
import { Button, Dropdown, DropdownItem, DropdownMenu } from "element-ui";
import { formattingMethods } from "../../helpers/formatting";

export default {
  name: "charts",
  props: {
    activeIndex: {
      type: Number,
      required: true
    },
    dataChart: {
      type: Array,
      required: true
    },
    submitDate: {
      type: Date,
      required: true
    }
  },
  data() {
    return {
      totalPremiumAllProducts: 0,
      totalPremiumDatas: [],
      totalBoundPolicies: 0,
      chartDataLC: [],
      chartDataRS: [],
      bigLineChart: {
        activeIndex: 0,
        chartData: {},
        extraOptions: chartConfigs.lineChartOptionsBlue,
      },
      labels: [],
      isLoading: true,
      labelsChart: [
        {
          title: "Light Commercial",
          color: "#98b8cf"
        },
        {
          title: "Rideshare",
          color: "#475762"
        }
      ],
      status: [
        {
          "key": "all",
          "name": "All",
          "active": true
        },
        {
          "key": "not_started",
          "name": "Not Started",
          "active": false
        },
        {
          "key": "nascent",
          "name": "Started",
          "active": false
        },
        {
          "key": "incomplete",
          "name": "Incomplete",
          "active": false
        },
        {
          "key": "approved",
          "name": "Approved",
          "active": false
        },
        {
          "key": "declined",
          "name": "Declined",
          "active": false
        },
        {
          "key": "expired",
          "name": "Expired",
          "active": false
        },
        {
          "key": "complete",
          "name": "Complete",
          "active": false
        },
        {
          "key": "warning",
          "name": "Warning",
          "active": false
        },
        {
          "key": "abandoned",
          "name": "Abandoned",
          "active": false
        }
      ],
      filterName: "All",
      filterStatus: 'all'
    };
  },
  methods: {
    calculateTotalPremium(customerLC, customerRS) {
      let totalPremiumLC = _.sumBy(customerLC.filter(policy => policy.total_premium), function(policy) {
        return parseFloat(policy.total_premium)
      })
      let totalPremiumRS = _.sumBy(customerRS.filter(policy => policy.total_premium), function(policy) {
        return parseFloat(policy.total_premium)
      })

      this.totalPremiumAllProducts = totalPremiumLC + totalPremiumRS
      this.totalPremiumDatas = [
        {
          productName: "Light Commercial",
          totalPremium: totalPremiumLC,
          color: "#98b8cf"
        },
        {
          productName: "Ride Share",
          totalPremium: totalPremiumRS,
          color: "#475762"
        },
      ]
    },

    formatPremium(value) {
      return formattingMethods.formatCurrencyString(value);
    },

    async initBigChart(index) {
      // console.log("run", index)
      let data = await this.dataChart
      // filter not bound policies
      data = _.filter(data, function(item) {return !["complete", "cancelled"].includes(_.lowerCase(item.status))});
      this.totalBoundPolicies = data.length

      // if (this.filterStatus != 'all') {
      //   const status = this.filterStatus === 'not_started' ? null : this.filterStatus
      //   data = _.filter(data, function(item) {return (status != null ? _.lowerCase(item.status) : item.status) === status;});
      // }
      const customerLC = _.filter(data, function(item) {return item.product === 'LC';});
      const customerRS = _.filter(data, function(item) {return item.product === 'RS';});

      this.calculateTotalPremium(customerLC, customerRS)

      //filter data by time - month/year, date/month
      let monthName = null
      if(this.activeIndex === 0) {
        monthName = item => moment(item.date_created, 'YYYY-MM-DD').format('MMM');
      } else {
        monthName = item => moment(item.date_created, 'YYYY-MM-DD').format('D');
      }
      const dataLC = this.filterData(index, _.groupBy(customerLC, monthName))
      const dataRS = this.filterData(index, _.groupBy(customerRS, monthName))
      let chartData = {
        datasets: [
          {
            label: "LC",
            borderColor: "#98b8cf",
            data: dataLC
          },
          {
            label: "RS",
            borderColor: "#475762",
            data: dataRS
          },
        ],
        labels: this.labels[index],
      };
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    },
    setLabels() {
      this.labels = []
      let labelsYear = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
      let labelsMonth = []
      for (let index = 1; index <= new Date(this.submitDate.getFullYear(), this.submitDate.getMonth() + 1, 0).getDate(); index++) {
        labelsMonth.push(index)
      }
      this.labels.push(labelsYear, labelsMonth)
    },
    filterData(index, groupByMonth) {
      const data = []
      this.labels[index].forEach(element => {
        if (groupByMonth[element]) {
          data.push(groupByMonth[element].length)
        } else {
          data.push(0)
        }
      });
      return data
    },
    filterChartStatus(key) {
      this.filterStatus = key
      this.status.forEach(element => {
        if (element.key === key) {
          this.filterName = element.name
        }
      });
      this.initBigChart(this.activeIndex)
    },
  },
  watch: {
    activeIndex() {
      this.initBigChart(this.activeIndex)
      this.setLabels()
    },
    dataChart() {
      this.initBigChart(this.activeIndex)
      this.setLabels()
    }
  },
  async mounted() {
    this.setLabels()
  },
  components: {
    LineChart,
    BarChart,
    Spinner,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Button.name]: Button
},
};
</script>

<style scoped>
.dashboard-info {
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
}
.dashboard-info span {
  font-size: 25px;
}
</style>
