<template>
  <div class="mt-auto copyright text-center text-muted h5 text-black">
    © {{ year }}
    YOUR INSURANCE PTY LTD | ABN: 85 650 930 733
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>

<style scoped>
.copyright {
  color: black !important;
  margin-top: 30px;
}
</style>