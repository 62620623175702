<template>
  <div>
    <base-header class="pb-6 bg-primary">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h1 text-white d-inline-block mb-0 header fw-500">
            {{ header }}
          </h6>
        </b-col>
      </b-row>
    </base-header>
    <div class="container-fluid mt--6">
      <b-card class="mb-4 quote-emailed-screen">
        <b-card-header>
          <h1 class="mb-0 fw-500">{{ title }}</h1>
        </b-card-header>
        <b-card-body>
          <!-- uBind text card -->
          <b-card class="uBind">
            <div class="text-muted mb-4">
              <h2 class="secondary-card-header fw-500">{{ bodyTitle }}</h2>
              <div class="text-black">
                {{ bodyText }}
              </div>
            </div>
          </b-card>

          <!-- uBind Buttons -->
          <div class="text-left">
            <base-button type="uBind" @click="newQuoteAction">
              <i class="fas fa-angle-right pr-3"></i>
              New Quote
            </base-button>
            <base-button type="uBind" @click="viewQuotesAction">
              <i class="fas fa-angle-right pr-3"></i>
              Quotes
            </base-button>
          </div>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    bodyTitle: {
      type: String,
      required: true
    },
    bodyText: {
      type: String,
      required: true
    },
    newQuoteAction: {
      type: Function,
      required: true
    },
    viewQuotesAction: {
      type: Function,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 45px;
  color: black;
}

.quote-emailed-screen {
  .card-header {
    border: none;
    padding-bottom: 0;
  }

  .uBind .card-body {
    padding: 0;
  }
}

.top-padding {
  padding-top: 0px !important;
}
.secondary-card-header {
  color: var(--uBind);
  font-size: 30px;
}
</style>
