<template>
  <div>
    <base-header class="pb-6 bg-primary">
      <b-row class="align-items-center py-4">
        <b-col>
          <base-button @click="goBack()" type="uBind" class="mt-2 mt-sm-0 mb-4"
            ><i class="fas fa-angle-left pr-3"></i>Back</base-button
          >
          <base-button
            @click="sendEmail()"
            type="uBind"
            style="float:right;"
            class="mt-2 mt-sm-0 mb-4"
            ><i class="fas fa-envelope pr-3"></i>Send to Customer</base-button
          >

          <br />
          <h6
            class="h1 text-white header d-inline-block mb-0 fw-500"
            ref="topSection"
          >
            {{ title }}
          </h6>
        </b-col>
      </b-row>
    </base-header>
    <div class="container-fluid mt--6">
      <b-card no-body class="mb-4">
        <div
          v-id="ubindQuoteId"
          class="ubind-product"
          :data-tenant="getTenant"
          :data-product-id="getProduct"
          :data-environment="getEnvironment"
          :data-quoteid="getQuoteId"
        ></div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { GlobalStore } from "../globalStore";
import { emailMethods } from "../helpers/email";
import { toasts } from "../helpers/toasts";
import { appFunctions } from "../helpers/appFunctions";

export default {
  name: "EditQuote",
  props: {
    title: {
      type: String,
      default: "Edit Quote"
    },
    productType: {
      type: String,
      required: true,
      validator: value =>
        ["ride-protect", "mercurien-light-commercial"].includes(value)
    }
  },
  data() {
    return {
      ubindQuoteId: null
    };
  },
  computed: {
    getQuoteId() {
      return sessionStorage.getItem("ubindQuoteID");
    },
    getProduct() {
      return this.productType === "ride-protect"
        ? GlobalStore.ubindClientInfo.rideShareProduct
        : GlobalStore.ubindClientInfo.product;
    },
    getTenant() {
      return GlobalStore.ubindClientInfo.tenantId;
    },
    getEnvironment() {
      return GlobalStore.ubindClientInfo.environment;
    }
  },
  methods: {
    async sendEmail() {
      const isRideShare = this.productType === "ride-protect";
      const mostRecentPolicyId = sessionStorage.getItem(
        isRideShare ? "mostRecentRSPolicyId" : "mostRecentLCPolicyId"
      );

      let customerDetails;
      if (isRideShare) {
        customerDetails = await appFunctions.crud_customerRSPolicy(
          "getCombined",
          {
            policy_id: parseInt(mostRecentPolicyId)
          }
        );
      } else {
        customerDetails = await appFunctions.crud_customerLCPolicy(
          "getCombined",
          {
            policy_id: parseInt(mostRecentPolicyId)
          }
        );
      }

      console.log(customerDetails);

      await emailMethods.sendContinueQuoteEmail(
        GlobalStore.realmApp,
        customerDetails.data,
        isRideShare ? "RS" : "LC"
      );

      toasts.success(
        `Email has been sent to ${customerDetails.data.Customer_contact_email}`
      );

      this.$router.push(
        isRideShare
          ? "/rideShare/quoteEmailed"
          : "/lightCommercial/quoteEmailed"
      );
    },
    goBack() {
      this.$router.push(
        this.productType === "ride-protect"
          ? "/rideShare/quotes"
          : "/lightCommercial/quotes"
      );
    },
    scrollUp() {
      //location.hash = "#calcSection";
      const myEl = this.$refs.topSection;

      this.$smoothScroll({
        scrollTo: myEl,
        hash: "#topSection" // required if updateHistory is true
      });
    }
  },
  mounted() {
    //console.log(window._uBindInjector);

    this.ubindQuoteId = sessionStorage.getItem("ubindQuoteID");

    window._uBindInjector.createWebFormInjections();

    this.scrollUp();
  }
};
</script>

<style lang="scss" scoped>
.top-padding {
  padding-top: 0px !important;
}

.ubind-product {
  margin: 0px 15px 0px 15px;
}
.container-fluid {
  padding-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
</style>
