import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueCryptojs from "vue-cryptojs";
import { authMethods } from "./helpers/auth";
import { GlobalStore } from "./globalStore";
import Quasar from "quasar";
import Toasted from "vue-toasted";
import DashboardPlugin from "./plugins/dashboard-plugin";
import VueSmoothScroll from "vue2-smooth-scroll";
import { appFunctions } from "./helpers/appFunctions";
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import ProgressBar from 'primevue/progressbar';
import InputNumber from 'primevue/inputnumber';
import Slider from 'primevue/slider';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import MultiSelect from 'primevue/multiselect';
import Button from 'primevue/button';

Vue.use(VueSmoothScroll);

Vue.use(Quasar); // Install Quasar Framework

Vue.config.productionTip = false;

Vue.use(require("vue-moment"));

Vue.use(VueCryptojs);

Vue.use(Toasted);

Vue.use(DashboardPlugin);

window._ = require('lodash');
Vue.use(PrimeVue);

Vue.component('DataTable', DataTable);
Vue.component('Column', Column);
Vue.component('InputText', InputText);
Vue.component('ProgressBar', ProgressBar);
Vue.component('InputNumber', InputNumber);
Vue.component('Slider', Slider);
Vue.component('Dropdown', Dropdown);
Vue.component('Calendar', Calendar);
Vue.component('MultiSelect', MultiSelect);
Vue.component('Button', Button);

import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: 'fd882e40-d69d-4ca3-b5c9-2eab264f141d',
    clientToken: 'pub6ded13b91647069c9336d2b32936e913',
    site: 'us5.datadoghq.com',
    service:'yourinsurancehq',
    env: process.env.VUE_APP_SITEURL.replace("http://","").replace("https://",""),
    // Specify a version number to identify the deployed version of your application in Datadog 
    version: GlobalStore.version, 
    sessionSampleRate:100,
    premiumSampleRate: 100,
    trackUserInteractions: true,
});
    
datadogRum.startSessionReplayRecording();

router.beforeEach(async (to, from, next) => {
  if (
    to.path.toLowerCase() === "/requestpasswordreset" ||
    to.path.toLowerCase() === "/requestpasswordreset/" ||
    to.path.toLowerCase() === "/resetpassword" ||
    to.path.toLowerCase() === "/feedbacksurvey" ||
    to.path.toLowerCase() === "/resetpassword/" ||
    to.path.toLowerCase() === "/feedbacksurvey/" ||
    to.path.toLowerCase() === "/setpassword" ||
    to.path.toLowerCase() === "/setpassword/" ||
    to.path.toLowerCase().includes("/acceptoffer") ||
    to.path.toLowerCase().includes("/lightcommercial/continuequote") ||
    to.path.toLowerCase().includes("/rideshare/continuequote") ||
    to.path.toLowerCase().includes("/certificateofcurrency") ||
    to.path.toLowerCase().includes("/downloadfile") ||
    to.path.toLowerCase().includes("/downloadclaimfile") ||
    to.path.toLowerCase().includes("/triggerclaimcompleteemail") ||
    to.path.toLowerCase().includes("/products/plantequipment/continuequote") ||
    to.path.toLowerCase().includes("/products/motor/continuequote") || 
    to.path.toLowerCase().includes("/products/otherassets/continuequote")
  ) {
    next();
  } else {
    // To handle link from email generated from ubind when user saves the quote
    if (
      to.path.toLowerCase().includes("/lightcommercial/createquote") &&
      to.query["quoteId"]
    ) {
      next("/lightCommercial/continueQuote/ubind?id=" + to.query["quoteId"]);
    } else if (
      to.path.toLowerCase().includes("/rideshare/createquote") &&
      to.query["quoteId"]
    ) {
      next("/rideshare/continueQuote/ubind?id=" + to.query["quoteId"]);
    } else {
      var app = await authMethods.initAppInstance();
      if (app.error) {
        if (
          to.path.toLowerCase() !== "/login" &&
          to.path.toLowerCase() !== "/login/"
        ) {
          next("/login");
        } else {
          next();
        }
      } else {
        GlobalStore.realmApp = app;
        next();
      }
    }
  }
});

new Vue({
  router,
  render: function(h) {
    return h(App);
  }
}).$mount("#app");
