<template>
  <QuoteEmailed
    header="Light Commercial Vehicles - Fleet Quote Emailed"
    title="Commercial Vehicle Insurance"
    bodyTitle="Quote Emailed"
    bodyText="A new fleet enquiry has been sent on your behalf."
    :newQuoteAction="newQuote"
    :viewQuotesAction="viewQuotes"
  />
</template>

<script>
import QuoteEmailed from "../../components/QuoteEmailed.vue";
export default {
  methods: {
    newQuote() {
      this.$router.push("/lightCommercial/fleet");
    },
    viewQuotes() {
      this.$router.push("/lightCommercial/quotes");
    }
  },
  components: {
    QuoteEmailed
  }
};
</script>
