<template>
  <div>
    <!-- Header -->
    <div class="header py-7 py-lg-6 pt-lg-9">
      <div class="separator separator-bottom separator-skew zindex-100">
        <!-- <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg> -->
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <img class="login-logo" src="../assets/logo_full.png" />
            <b-card-body class="px-lg-5 pb-lg-5 pt-lg-0">
              <div class="mb-3">
                <a href="/"><i class="ni ni-bold-left" />&nbsp;Back</a>
              </div>
              <h2 class="mb-3">Forgot Password</h2>
              <div class="text-left mb-4">
                <p>Please enter your email address.</p>
              </div>
              <validation-observer
                v-slot="{ handleSubmit }"
                ref="formValidator"
              >
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input
                    alternative
                    class="mb-3"
                    name="Email"
                    :rules="{ required: true, email: true }"
                    prepend-icon="ni ni-email-83"
                    placeholder="Email"
                    v-model="emailAddress"
                    inputClasses="text-black"
                  >
                  </base-input>

                  <base-button
                    type="primary"
                    native-type="submit"
                    class="my-4 bg-primaryred"
                    style="border: var(--primaryred)"
                    >Send Password Request</base-button
                  >
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <TextFooter />
  </div>
</template>

<script>
import TextFooter from "../components/TextFooter.vue";
import { GlobalStore } from "../globalStore";
import { GlobalMethods } from "../globalMethods";
import { toasts } from "../helpers/toasts";
import { appFunctions } from "../helpers/appFunctions";

export default {
  data() {
    return {
      emailAddress: "",
      year: new Date().getFullYear()
    };
  },
  methods: {
    isEmailValid(emailAddress) {
      return GlobalMethods.isEmailValid(emailAddress);
    },
    async onSubmit() {
      // var Realm = window.Realm;
      // const config = {
      //   id: GlobalStore.realm_app_id
      // };
      // const app = new Realm.App(config);
      // app.emailPasswordAuth.callResetPasswordFunction(
      //   this.emailAddress,
      //   "temp1234!",
      //   ["reset"]
      // );

      var emailToken = GlobalMethods.uuidv4();
      var emailTokenId = GlobalMethods.uuidv4();

      // await appFunctions.crud_broker("updatePasswordResetTokens", {
      //   Broker_id: brokerObj.data.Broker_id,
      //   emailToken: emailToken,
      //   emailTokenId: emailTokenId
      // });

      appFunctions
        .requestPasswordReset(emailToken, emailTokenId, this.emailAddress)
        .then(resp => {
          if (!resp.error) {
            console.log(resp);
            toasts.success(
              "Thank you, if an account matching your email is found, a password reset email has been sent."
            );

            this.$router.push("/login");

            this.emailAddress = "";
          } else {
            toasts.error(resp.error);
          }
        });
    }
  },
  components: {
    TextFooter
  }
};
</script>
<style scoped>
p {
  font-size: 100%;
  font-weight: 400;
  color: black;
}

a {
  color: #ff4438 !important;
}

.card-body {
  padding-top: 0;
}
</style>
