<template>
  <EditQuote
    title="Light Commercial Vehicles - Edit Quote"
    productType="mercurien-light-commercial"
  />
</template>

<script>
import EditQuote from "../../components/EditQuote.vue";

export default {
  name: "Step2",
  components: {
    EditQuote
  }
};
</script>
