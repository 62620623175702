import Vue from "vue";
import moment from "moment";
import { formattingMethods } from "./formatting";

export const validationMethods = new Vue({
  methods: {
    /**
     * Validates an email string.
     * @param {String} email String to be validated
     * @returns {Boolean} True if the string is a valid email and false if not.
     */
    email(email) {
      console.log("email", email);
      const VALIDATOR = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return VALIDATOR.test(email);
    },

    /**
     * Validates a given ABN
     * @param {String | Number} abn ABN to be validated
     * @returns {Boolean} True if the abn is valid and false if not.
     */
    abn(abn) {
      const WEIGHTING = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
      const ABN_LENGTH = 11;
      const CHECK_VALUE = 89;
      // Ensuring string type and removing whitespace and hyphens.
      abn = formattingMethods.formatABN(abn);

      if (abn && abn.length === ABN_LENGTH) {
        let sum = 0;
        for (let i = 0; i < abn.length; i++) {
          let value = abn[i];
          // Subtract 1 if first digit
          if (i === 0) value--;
          sum += value * WEIGHTING[i];
        }
        return sum % CHECK_VALUE === 0;
      }
      return false;
    },

    /**
     * Checks to make sure an ABN is 11 digits long.
     * @param {String | Number} abn ABN to be validated.
     * @returns {Boolean} True if the abn is 11 characters long and false if not.
     */
    simpleABN(abn) {
      abn = formattingMethods.formatABN(abn);
      return (abn.length === 11)
    },
    
    /**
     * Validates whether or not a date is 30 days from now (inclusive).
     * @param {String} date Date string of format: "YYYY-MM-DD".
     * @returns true if the given date is in the range of now to thirty days 
     * from now and false otherwise.
     */
    dateWithin30Days(date) {  
      if (moment(date).isBetween(moment().subtract(1, 'd'), moment().add(30, 'd'))) {
        return true;
      }
      return false;
    }
  }
});
