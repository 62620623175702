

<template>
  <div>
    <base-header class="pb-6 bg-primary">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">{{ $route.name }}</h6>
        </b-col>
      </b-row>
    </base-header>
    <div class="container-fluid mt--6">
      <card no-body class="mb-4 card-header">
        <div>
          <DataTable ref="dt" :value="customers" :paginator="true" class="p-datatable-customers" :rows="10" dataKey="id"
            :rowHover="true" :selection.sync="selectedCustomers" :filters.sync="filters" filterDisplay="menu"
            :loading="loading"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50]"
            :scrollable="scroll"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            :globalFilterFields="['Ubind_quote_last_updated_date', 'type', 'customer_insured_name', 'base_premium', 'total_payable', 'broker_name', 'Broker_Org_Name', 'Ubind_status']"
            showGridlines responsiveLayout="stack">
            <template #header>
              <div class="flex justify-content-between align-items-center">
                <div style="text-align: left">
                  <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                  <Button icon="pi pi-external-link" class="p-button-label mobile-select" label="Export CSV"
                    @click="exportCSV($event)" />
                  <Button type="button" icon="pi pi-filter-slash" label="Clear All" class="refresh-button p-button-outlined" outlined @click="clearFilter()"/>
                  <Button @click="refreshPage()" class="refresh-button" icon="pi pi-sync" />
                </div>
              </div>
            </template>
            <template #empty>
              No data found.
            </template>
            <template #loading>
              Loading data. Please wait.
            </template>
            <Column field="Ubind_quote_last_updated_date" header="Last Update Date" dataType="date"
              :styles="{ 'min-width': '13rem' }">
              <template #body="{ data }">
                {{ formatDateTime(data.Ubind_quote_last_updated_date) }}
              </template>
              <template #filter="{ filterModel }">
                <Calendar v-model="filterModel.value" dateFormat="yy-mm-dd" placeholder="DD-MM-YYYY" />
              </template>
            </Column>
            <!-- sortable -->
            <Column field="type" header="Product Name" :styles="{ 'min-width': '12rem' }">
              <template #body="{ data }">
                {{ data.type }}
              </template>
              <template #filter="{ filterModel }">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name" />
              </template>
            </Column>
            <Column field="customer_insured_name" header="Insured Name" filterMatchMode="contains"
              :styles="{ 'min-width': '12rem' }">
              <template #body="{ data }">
                {{ data.customer_insured_name }}
              </template>
              <template #filter="{ filterModel }">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                  placeholder="Search by country" />
              </template>
            </Column>
            <Column field="base_premium" header="Base Premium" dataType="numeric" :styles="{ 'min-width': '12rem' }">
              <template #body="{ data }">
                {{ formatCurrency(data.base_premium) }}
              </template>
              <template #filter="{ filterModel }">
                <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
              </template>
            </Column>
            <Column field="total_payable" header="Total Payable" dataType="numeric" :styles="{ 'min-width': '11rem' }">
              <template #body="{ data }">
                {{ formatCurrency(data.total_payable) }}
              </template>
              <template #filter="{ filterModel }">
                <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
              </template>
            </Column>
            <Column field="broker_name" header="Broker Name" :styles="{ 'min-width': '12rem' }">
              <template #body="{ data }">
                {{ data.broker_name }}
              </template>
              <template #filter="{ filterModel }">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name" />
              </template>
            </Column>
            <Column field="Broker_Org_Name" header="Broker Org Name" :filterMenuStyle="{ 'width': '15rem' }"
              :styles="{ 'min-width': '10rem' }">
              <template #body="{ data }">
                {{ data.Broker_Org_Name }}
              </template>
              <template #filter="{ filterModel }">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name" />
              </template>
            </Column>
            <Column field="Ubind_status" header="Status" :styles="{ 'min-width': '8rem' }">
              <template #body="{ data }">
                {{ data.Ubind_status }}
              </template>
              <template #filter="{ filterModel }">
                <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter"
                  :showClear="true">
                  <template #value="slotProps">
                    <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{ slotProps.value}}</span>
                    <span v-else>{{ slotProps.placeholder }}</span>
                  </template>
                  <template #option="slotProps">
                    <span :class="'customer-badge status-' + slotProps.option">{{ slotProps.option }}</span>
                  </template>
                </Dropdown>
              </template>
            </Column>
          </DataTable>
        </div>
      </card>
    </div>
  </div>
</template>
  
<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import 'primevue/resources/themes/lara-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import { reportFunctions } from "../../helpers/reportFunctions";
import { GlobalStore } from "../../globalStore";
import { checkTotalPayableExcludingMerchantFee, formatBasePremium } from "../../helpers/common";

export default {
  data() {
    return {
      customers: [],
      scroll: null,
      selectedCustomers: null,
      filters: {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Ubind_quote_last_updated_date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        'type': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'customer_insured_name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'base_premium': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        'total_payable': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        'broker_name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'Broker_Org_Name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'Ubind_status': { operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}] }
      },
      loading: true,
      statuses: [
        'Complete', 'Not Started', 'Started', 'Cancelled', 'Endorsement', 'Expired', 'Approved', 'Declined', 'Review', 'Abandoned', 'Incomplete'
      ]
    }
  },
  async mounted() {
    await this.fetchData()
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    myEventHandler(e) {
      if (window,innerWidth < 1000) {
        this.scroll = false
      } else {
        this.scroll = true
      }
    },
    async fetchData() {
      this.loading = true
      await reportFunctions
        .reportTotalQuotes({}, {
          Realm_user_id: GlobalStore.brokerObj.Realm_user_id
        })
        .then(resp => {
          this.customers = _.orderBy(resp, [(item) => { return item.Ubind_quote_last_updated_date || '' }], ['desc']);
          this.customers.forEach(element => {
            element.type = element.type === 'lc' ? 'Light Commercial' : 'Rideshare'
            element.broker_name = element.broker.length > 0 ? element.broker[0].Broker_first_name + ' ' + element.broker[0].Broker_last_name : 'N/A'
            element.customer_insured_name = element.customer.length > 0 ? element.customer[0].Customer_insured_name : 'N/A'
            element.Broker_Org_Name = element.brokerOrg.length > 0 ? element.brokerOrg[0].Broker_Org_Name : 'N/A'
            element.Ubind_status = this.filterUbindStatus(element.Ubind_status, element.Ubind_quote_details_obj)
            element.Ubind_quote_last_updated_date = new Date(this.formatDate(element.Ubind_quote_last_updated_date))
            element.base_premium = this.formatBasePremium(element.Ubind_quote_details_obj)
            element.total_payable = this.checkTotalPayableExcludingMerchantFee(element.Ubind_quote_details_obj, element.type)
          });
        })
        .finally(() => {
          this.loading = false;
        })
    },
    formatDateTime(date) {
      if (date != "Invalid Date") {
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let h = date.getHours();
        let m = date.getMinutes();
        let am_pm = "am"
        if (month < 10) {
          month = '0' + month;
        }
        if(h >= 12){
          am_pm = "pm";
        }
        if(h < 10){
          h = '0' + h
        }
        if(m < 10){
          m = '0' + m
        }
        if (day < 10) {
            day = '0' + day;
        }
        return day + '-' + month + '-' + date.getFullYear()+ ","+ " " + h + ":" + m + " " + am_pm;
      } else {
        return "N/A"
      }
      
    },
    formatDate(value) {
      return value ? this.$moment(value).format("YYYY-MM-DD, h:mm a") : "N/A"
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    clearFilter() {
      this.filters = {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Ubind_quote_last_updated_date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        'type': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'customer_insured_name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'base_premium': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        'total_payable': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        'broker_name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'Broker_Org_Name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'Ubind_status': { operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}] }
      }
    },
    filterUbindStatus(status, detail) {
      if (!status) return "Not Started"
      if (status === 'warning') return detail ? "Started" : "Not Started"
      if (status && status.toLowerCase() === "nascent") return "Started"
      return status.charAt(0).toUpperCase() + status.slice(1)
    },
    checkTotalPayableExcludingMerchantFee(policy, type) {
      return checkTotalPayableExcludingMerchantFee(policy, type)
    },
    async refreshPage() {
      await this.fetchData()
    },
    formatBasePremium(policy) {
      return formatBasePremium(policy)
    },
    formatCurrency(value) {
      value = typeof value != 'string' ? value : value.replace(',', '')
      return parseFloat(value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
  },
  components: {
  }
}
</script>
  
<style lang="scss" scoped>
:deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

.p-component {
  font-size: 14.4px;
  font-family: Open Sans, sans-serif;
  color: #000000;
}

.p-paginator {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  background-color: #ffffff;
}

.p-button {
  display: inline;
  color: #495057;
  background-color: white;
  border: 1px solid #98B8CF;
}

.b-table-sticky-header,
.table-responsive,
[class*=table-responsive-] {
  margin-bottom: 0px;
}

.card-header {
  padding: 0px;
}

.p-button:hover {
  color: white;
  background-color: #ff4438;
  border: 1px solid #ff4438;
}

.p-inputtext {
  border: 1px solid #98B8CF;
}

.p-inputtext:hover {
  border: 1px solid #98B8CF;
}

.p-inputtext:focus {
  border: 1px solid #98B8CF;
}

:deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

:deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

:deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
    display: flex;
  }

  .p-datatable-thead>tr>th {
    text-align: left;
  }

  .p-datatable-tbody>tr>td {
    cursor: auto;
  }
}

.mobile-select {
  margin-left: 10px;
}
.refresh-button {
  margin-left: 10px;
}

@media only screen and (min-width: 375px) and (max-width: 413px) {
  .mobile-select {
    margin-top: 10px;
    margin-left: 0px;
  }
  .refresh-button {
    margin-left: 10px;
  }
}

@media only screen and (min-width: 414px) and (max-width: 767px) {
  .refresh-button {
    margin-top: 10px;
    margin-left: 0px;
  }
}
</style>
  
<style lang="scss">
.p-button-label {
  font-weight: normal;
}

.p-datatable .p-datatable-header {
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
  background-color: #ffffff;
}

.p-datatable .p-datatable-thead>tr>th {
  background-color: #f6f9fc;
}
</style>