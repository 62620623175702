<template>
  <Layout title="Light Commercial Vehicle Fleet Quotes">
    <FleetInformationLC />
  </Layout>
</template>

<script>
import FleetInformationLC from "./fleetInformationLC.vue";
import Layout from "../../rideshare/components/Layout.vue";

export default {
  data() {
    return {

    }
  },

  components: {
    FleetInformationLC,
    Layout
  }
}
</script>

<style></style>