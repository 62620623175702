<template>
  <QuoteEmailed
    header="Quote Emailed"
    :title="quoteTitle"
    bodyTitle="Quote Emailed"
    bodyText="A quote has been emailed to your Customer with a link to
                complete the quotation steps."
    :newQuoteAction="newQuote"
    :viewQuotesAction="viewQuotes"
  />
</template>

<script>
import QuoteEmailed from "../../components/QuoteEmailed.vue";
import { formattingMethods } from "../../helpers/formatting";

export default {
  computed: {
    quoteTitle() {
      return formattingMethods.formatProductNameVIBS(this.$route.params.productName)
    }
  },
  methods: {
    newQuote() {
      this.$router.push("createQuote");
    },
    viewQuotes() {
      this.$router.push("quotes");
    }
  },
  components: {
    QuoteEmailed
  }
};
</script>
