<template>
  <div class="bg-white rounded p-3" style="height: 30em">
    <h3 class="font-weight-bolder">Top brokers</h3>
    <hr />
    <div v-for="(broker, index) in topBrokers" :key="index">
      <b-row>
        <b-col cols="2"
          ><i class="fa fa-user-circle" style="fontSize: 2.5em"></i
        ></b-col>
        <b-col cols="6">
          <h4>{{ broker.name }}</h4>
        </b-col>
        <b-col class="font-policies" cols="4">{{ broker.policies }} policies</b-col>
      </b-row>
      <hr class="margin-hr" v-if="index !== topBrokers.length - 1" />
    </div>
  </div>
</template>

<script>
export default {
  name: "top-broker",
  props: {
    dataChart: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      topBrokers: []
    };
  },
  watch: {
    dataChart() {
      this.topBrokers = []
      const data = _.filter(this.dataChart, function(item) {return item.status === 'complete';});
      const groupByBroker =_.take(_.orderBy(_.values(_.groupBy(data, "broker_id")), function(broker) { return broker.length; }, ['desc']), 4)
      if (groupByBroker.length > 0) {
        groupByBroker.forEach(element => {
          this.topBrokers.push({
            "name": element[0].broker_name,
            "policies": element.length
          })
        });
      }
    }
  },
};
</script>

<style>
  @media only screen and (min-width: 1200px) and (max-width: 1300px) {
    .margin-hr {
      margin-top: 1.5rem;
    }
    .font-policies {
      font-size: 15px;
    }
  }
</style>
