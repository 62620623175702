import Vue from "vue";
import { GlobalStore } from "../globalStore";
import axios from "axios";

export const fileAttachments = new Vue({
  methods: {
    async getFileAttachment(guid) {
      // console.log(GlobalStore.brokerObj);
      // const requestBody = {
      //   tenantId: GlobalStore.ubindClientInfo.tenantId,
      //   organisationId:
      //     GlobalStore.brokerObj.parentOrg.parentInsuranceBroker
      //       .ubind_organisation_id,
      //   emailAddress: GlobalStore.brokerObj.UBindUsername,
      //   plaintextPassword: GlobalStore.brokerObj.UBindPassword
      // };

      // return appFunctions.ubindAuthorise(requestBody);

      var Realm = window.Realm;
      const config = {
        id: GlobalStore.realm_app_id
      };

      // const app = new Realm.App(config);

      // const credentials = Realm.Credentials.emailPassword(
      //   "donotdelete@user.com",
      //   "Password1!"
      // );
      // const user = await app.logIn(credentials);

      //const appconfig = await appFunctions.getConfig();

      var response = await app.functions.getFileAttachment("getByGuid", guid);

      //console.log(response);

      return response;
    },
    async getClaimFileAttachment(guid) {
      // console.log(GlobalStore.brokerObj);
      // const requestBody = {
      //   tenantId: GlobalStore.ubindClientInfo.tenantId,
      //   organisationId:
      //     GlobalStore.brokerObj.parentOrg.parentInsuranceBroker
      //       .ubind_organisation_id,
      //   emailAddress: GlobalStore.brokerObj.UBindUsername,
      //   plaintextPassword: GlobalStore.brokerObj.UBindPassword
      // };

      // return appFunctions.ubindAuthorise(requestBody);

      var Realm = window.Realm;
      const config = {
        id: GlobalStore.realm_app_id
      };

      // const app = new Realm.App(config);

      // const credentials = Realm.Credentials.emailPassword(
      //   "donotdelete@user.com",
      //   "Password1!"
      // );
      // const user = await app.logIn(credentials);

      //const appconfig = await appFunctions.getConfig();

      var response = await appFunctions.getClaimFileAttachment(guid);

      //console.log(response);

      return response;
    }
  }
});
