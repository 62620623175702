<template>
  <div class="bg-white rounded p-3" style="height: 30em">
    <h3 class="font-weight-bolder">Quotes Progress</h3>
    <div v-for="(product, index) in dataProgress" :key="index">
      <base-progress :showLabel="true" :label="product.name" :height="15">
        <template #multipleBars>
          <b-progress-bar v-for="(quote, index) in product.quotes" :key="index" :class="`bg-${quote.color}`"
            :value="quote.rate" show-progress>
          </b-progress-bar>
        </template>
      </base-progress>
      <div class="text-right">
        <a v-if="product.name == 'Light Commercial'" href="/lightCommercial/quotes">See more >>></a>
        <a v-else href="/rideshare/quotes">See more >>></a>
      </div>
      <hr v-if="index === 0" />
    </div>

    <b-row class="mt-4">
      <div v-for="(product, index) in products" :key="index">
        <p class="mt--3" style="margin-left: 10px;">
          <i :class="['fa fa-square', `text-${product.color}`]" aria-hidden="true"></i>
          {{ product.name }}
        </p>
      </div>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "progress-track",
  props: {
    dataChart: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      products: [
        {
          name: "Not Started",
          key: "not_started",
          color: "gray"
        },
        {
          name: "Started",
          key: "nascent",
          color: "purple"
        },
        {
          name: "Incomplete",
          key: "incomplete",
          color: "info"
        },
        {
          name: "Approved",
          key: "approved",
          color: "blue"
        },
        {
          name: "Declined",
          key: "declined",
          color: "black"
        },
        {
          name: "Expired",
          key: "expired",
          color: "primary"
        },
        {
          name: "Completed",
          key: "complete",
          color: "success"
        },
        {
          name: "Warning",
          key: "warning",
          color: "yellow"
        },
        {
          name: "Abandoned",
          key: "abandoned",
          color: "danger"
        }
      ],
      dataProgress: []
    };
  },
  watch: {
    dataChart() {
      this.setProgressBar()
    }
  },
  methods: {
    setProgressBar() {
      this.dataProgress = []
      const customeLC = _.filter(this.dataChart, function(item) {return item.product === 'LC';});
      const customeRS = _.filter(this.dataChart, function(item) {return item.product === 'RS';});
      this.dataProgress.push({
        "name": "Light Commercial",
        "quotes" : this.filterData(_.groupBy(customeLC, "status"), customeLC)
      }, {
        "name": "Rideshare",
        "quotes" : this.filterData(_.groupBy(customeRS, "status"), customeRS)
      })
    },
    filterData(groupByData, customer) {
      const data = []
      this.products.forEach(element => {
        if (element.key === 'not_started') {
          data.push({
            "color" : element.color,
            "rate": groupByData[null] ? (groupByData[null].length / customer.length * 100).toFixed(2) : 0
          })
        } else {
          const item = groupByData[element.key]
          data.push({
            "color" : element.color,
            "rate": item ? (item.length / customer.length * 100).toFixed(2) : 0
          })
        }
      });
      return data
    }
  },
};
</script>

<style></style>
