<template>
  <div>
    <base-header class="pb-6 bg-primary">
      <b-row class="align-items-center py-4">
        <b-col>
          <h6 class="h1 text-white d-inline-block mb-0 header fw-500">
            {{title}}
          </h6>
        </b-col>
      </b-row>
    </base-header>
    <div class="container-fluid mt--6">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Backdrop',
  props: {
    title: String
  }
};
</script>
