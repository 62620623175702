<template>
  <div>
    <!-- Header -->
    <div class="header py-7 py-lg-4 pt-lg-9"></div>

    <!-- <div v-html="htmlContent"></div> -->

    <!-- Page content -->
    <b-container v-if="showForm == 'valid'" class="mt--8 pb-5">
      <b-row class="justify-content-center" v-if="!quoteAccepted">
        <b-col lg="12" md="12">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5 bg-white">
              <div class="text-muted mb-4">
                <div style="text-align: center;">
                  <img src="../assets/logo_full.png" />
                  <!-- <button @click="createPDF()">run pdf</button>

                  <button @click="downloadPDF()">download pdf</button> -->
                  <div style="height: 200px;" v-if="documentDownloaded">
                    <h3>
                      Document Retrieved Successfully.
                    </h3>
                  </div>
                  <div style="height: 200px;" v-if="!documentDownloaded">
                    <h3>
                      Retrieving document. It will start downloading shortly,
                      please wait..
                    </h3>

                    <div class="loader">Loading...</div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-if="showForm == 'invalid'" class="mt--8 pb-5">
      <b-row class="justify-content-center" v-if="!quoteAccepted">
        <b-col lg="12" md="12">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5 bg-white">
              <div class="text-muted mb-4">
                <div style="text-align: center;">
                  <img src="../assets/logo_full.png" />
                </div>
                <h1 class="text-center">Invalid Link</h1>
                <p>
                  This link is no longer valid. Please contact us at
                  <a href="support@yourinsurancehq.com.au"
                    >support@yourinsurancehq.com.au</a
                  >
                  for more information.
                </p>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { dropDowns } from "../assets/data/dropdown_values";
import { quoteFormMethods } from "../helpers/quoteForm";
import BaseInput from "../components/Inputs/BaseInput.vue";
import { Select, Option } from "element-ui";
import { emailMethods } from "../helpers/email";
import { GlobalStore } from "../globalStore";
import { pdfMethods } from "../helpers/pdf";
import { GlobalMethods } from "../globalMethods";

export default {
  data() {
    return {
      htmlContent: "",
      policy_id: 0,
      showForm: "valid",
      serialsRequired: "",
      paymentOptionsValid: false,
      acceptedCheckbox: false,
      quoteAccepted: false,
      financierOtherDisabled: true,
      Show_Financier: false,
      equipmentConditions: dropDowns.equipmentConditions,
      usedOnPublicRoadOptions: dropDowns.usedOnPublicRoadOptions,
      financiers: dropDowns.financiers,
      rawModel: null,
      model: {
        type: Object,
        default: {
          policyStart: "",
          insuredName: "",
          insuredABN: "",
          businessDesc: "",
          contactFName: "",
          contactLName: "",
          contactEmail: "",
          contactPhone: "",
          insuredAddress: "",
          insuredStreet: "",
          insuredCity: "",
          insuredState: null,
          insuredPostcode: "",
          equipmentDescription: "",
          equipmentValue: 0,
          equipmentCondition: "",
          operatingArea: "",
          usedOnPublicRoad: "",
          insurancePeriod: "12 Months",
          financier: "",
          financierOther: "",
          Customer_policy_insurance_total_premium: null,
          Customer_policy_insurance_premium: null,
          Customer_policy_insurance_premium_gst: null,
          Customer_policy_insurance_premium_stamp_duty: null,
          Customer_policy_fee: null,
          Customer_policy_status: null,
          Payment_option: "",
          Equipment_serial_numbers: ""
        }
      }
    };
  },
  methods: {
    async createPDF(app) {
      var certContent = await emailMethods.generateCertificateOfCurrency(
        app,
        this.rawModel
      );
      certContent = GlobalMethods.replaceAll(
        certContent,
        "data-colwidth",
        "width"
      );

      pdfMethods.createPDF(certContent);
    },
    paymentChooser() {
      if (this.model.Payment_option == "Finance") {
        this.Show_Financier = true;
        this.serialsRequired = "required";
      } else {
        this.Show_Financier = false;
        this.serialsRequired = "";
      }
      if (
        this.model.Payment_option == "Finance" ||
        this.model.Payment_option == "Credit Card"
      ) {
        this.paymentOptionsValid = true;
      }
    }
    // async AcceptOffer() {
    //   const isValid = await this.$refs.formValidator.validate();

    //   if (!isValid || !this.paymentOptionsValid) {
    //     this.$toasted
    //       .error("Please complete the required fields before proceeding.", {
    //         position: "bottom-center"
    //       })
    //       .goAway(4000);
    //   } else {
    //     this.rawModel.Customer_policy_status = "offer_accepted";

    //     var Realm = window.Realm;
    //     const config = {
    //       id: GlobalStore.realm_app_id
    //     };
    //     const app = new Realm.App(config);

    //     const credentials = Realm.Credentials.emailPassword(
    //       "donotdelete@user.com",
    //       "Password1!"
    //     );
    //     const user = await app.logIn(credentials);

    //     var updatedQuote = await quoteFormMethods.updateQuote(
    //       app,
    //       this.rawModel,
    //       this.model
    //     );

    //     //console.log(updatedQuote);

    //     emailMethods.sendCustomerWelcomeEmail(app, updatedQuote);
    //     emailMethods.sendInsureInfoEmail(app, updatedQuote);

    //     this.quoteAccepted = true;
    //   }
    // },
    // readFinancier(event) {
    //   if (event == "Other") {
    //     this.financierOtherDisabled = false;
    //     //this.model.financier = "";
    //   } else {
    //     this.financierOtherDisabled = true;
    //     this.model.financierOther = "";
    //   }
    // }
  },
  computed: {
    formValid() {
      // acceptedCheckbox
      return this.acceptedCheckbox;
    },
    documentDownloaded() {
      return GlobalStore.PDFCreated;
    }
  },
  async mounted() {
    document.body.style.backgroundColor = "#98B8CF";

    GlobalStore.PDFCreated = false;

    var respObj = await quoteFormMethods.getQuoteFromGuid(
      this.$route.params.id
    );

    if (respObj.rawModel.Customer_policy_status == "offer_accepted") {
      this.rawModel = respObj.rawModel;
      this.policy_id = respObj.rawModel.Customer_policy_id;

      var Realm = window.Realm;
      const config = {
        id: GlobalStore.realm_app_id
      };
      // const app = new Realm.App(config);

      // const credentials = Realm.Credentials.emailPassword(
      //   "donotdelete@user.com",
      //   "Password1!"
      // );
      // const user = await app.logIn(credentials);

      this.createPDF(app);
      // } else {
      //   this.showForm = "invalid";
    }
    // } catch (err) {
    //   this.showForm = "invalid";
    // }
  },
  components: {
    BaseInput,
    [Select.name]: Select,
    [Option.name]: Option
  }
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
li,
p {
  color: black !important;
}
img {
  width: 350px;
  height: auto;
}

.sideNotes {
  font-size: 0.9rem;
  margin-top: 28px;
}

@media (max-width: 767px) {
  .bordered-table-left {
    border-style: solid;
    border-width: 1px 1px 0px 1px;
    padding: 5px;
  }

  .bordered-table-right {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    padding: 5px;
  }

  .bordered-table-right .row {
    border-style: solid !important;
    border-width: 0px 0px 1px 0px !important;
    margin: 0px -5px 0px -5px;
    padding: 0px;
  }

  .bordered-table-right .row:last-child {
    border-style: none !important;
  }
}

@media (min-width: 767px) {
  .bordered-table-left {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    padding: 5px;
  }

  .bordered-table-right {
    border-style: solid;
    border-width: 1px 1px 1px 0px;
    padding: 5px;
  }

  .bordered-table-right .row {
    border-style: solid !important;
    border-width: 0px 0px 1px 0px !important;
    margin: 0px -5px 0px -5px;
    padding: 0px;
  }

  .bordered-table-right .row:last-child {
    border-style: none !important;
  }

  .my-radio > ::after {
    border: 1px solid var(--primary);
    border-radius: 100%;
  }

  .my-checkbox > ::after {
    border: 1px solid var(--primary);
    border-radius: 25%;
  }
}

.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #5e72e4;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
</style>
