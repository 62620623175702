import Vue from "vue";
export const formattingMethods = new Vue({
  methods: {
    formatProductNameVIBS(productUrlSlug) {
      const products = {
        plantEquipment: "Plant & Equipment",
        motor: "Motor",
        otherAssets: "Other Assets",
        property: "Property"
      }
      return products[productUrlSlug]
    },
    round(value, decimalPlaces) {
      return parseFloat(value).toFixed(decimalPlaces);
    },
    numberWithCommas(x) {
      return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
    },
    formatDate(dateString) {
      return this.$moment(dateString).format("DD-MM-YYYY");
    },
    formatDateTime(dateString) {
      if (dateString) {
        return this.$moment(dateString).format("DD-MM-YYYY, h:mm a");
      } else {
        return "N/A";
      }
    },
    formatDateWithHyphen(dateInput) {
      if (!dateInput) {
        return;
      }
      const dateParts = dateInput.split("/");
      const day = dateParts[0];
      const month = dateParts[1];
      const year = dateParts[2];

      return `${year}-${month}-${day}`;
    },
    /**
     * Formats given ABN. Removes whitespace and hyphens.
     * @param {String | Number} abn ABN to be formatted
     * @returns {String} Formatted ABN string
     */
    formatABN(abn) {
      return String(abn).replace(/\s|\-/g, "");
    },

    /**
     * Formats a given number into a currency value.
     * @param {string | number} value String to be formatted
     * @returns {string} Formatted value
     */
    formatCurrency(value) {
      let cleanValue = value.toString().replace(/\D/g, "");
      return cleanValue ? parseInt(cleanValue).toLocaleString() : value;
    },

    formatCurrencyString(value) {
      return "$" + this.numberWithCommas(value.toFixed(2));
    }
  }
});
