import Vue from "vue";
import { GlobalStore } from "../globalStore";
import { GlobalMethods } from "../globalMethods";
import { appFunctions } from "../helpers/appFunctions";

export const templateMethods = new Vue({
  methods: {
    async populateEmailTemplate(realmApp, dataFields, emailTemplateId) {
      var resp = await appFunctions.crud_emailTemplate("get", {
        email_template_id: emailTemplateId
      });

      var templateContent = resp.data.email_template_html;
      var subjectLine = resp.data.email_template_subject_line;

      console.log(dataFields);

      dataFields.forEach(field => {
        templateContent = GlobalMethods.replaceAll(
          templateContent,
          "##" + field.key + "##",
          field.value
        );
        subjectLine = GlobalMethods.replaceAll(
          subjectLine,
          "##" + field.key + "##",
          field.value
        );
      });

      console.log(dataFields);

      var guid = dataFields.find(k => k.key == "policy.customer-policy-guid");

      if (guid) {
        var acceptQuoteLink =
          "<a href='" +
          process.env.VUE_APP_SITEURL +
          "/acceptOffer/" +
          guid.value +
          "'>Click here to accept quote</a>";

        console.log(acceptQuoteLink);

        templateContent = templateContent
          .toString()
          .replace("##Click here to accept quote##", acceptQuoteLink);
      }

      // templateContent = GlobalMethods.replaceAll(
      //   templateContent,
      //   "##Click here to set your password##",
      //   "<a href='#'>Click here to set your password</a>"
      // );

      return { subjectLine, templateContent };
    },
    async populateContentTemplate(realmApp, dataFields, contentTemplateId) {
      var resp = await appFunctions.crud_contentTemplate("get", {
        content_template_id: contentTemplateId
      });

      var templateContent = resp.data.content_template_html;
      //var subjectLine = resp.data.content_template_subject_line;

      console.log(templateContent);

      dataFields.forEach(field => {
        templateContent = GlobalMethods.replaceAll(
          templateContent,
          "##" + field.key + "##",
          field.value
        );
      });

      //console.log(dataFields);

      //var guid = dataFields.find(k => k.key=="policy.customer-policy-guid").value;

      // var acceptQuoteLink = "<a href='" +
      // GlobalStore.siteURL +
      // "/acceptOffer/" + guid
      // +"'>Click here to accept quote</a>";

      //console.log(acceptQuoteLink);

      // templateContent = templateContent
      //   .toString()
      //   .replace(
      //     "##Click here to accept quote##",
      //     acceptQuoteLink
      //   );

      // templateContent = GlobalMethods.replaceAll(
      //   templateContent,
      //   "##Click here to set your password##",
      //   "<a href='#'>Click here to set your password</a>"
      // );

      return { templateContent };
    },
    convertToHashFields(dataObject, keyName) {
      const fields = Object.keys(dataObject);
      var retArray = [];

      fields.forEach((key, index) => {
        // console.log(`${key}: ${dataObject[key]}`);
        if (key !== "_id") {
          var tFieldName = GlobalMethods.replaceAll(
            key,
            "_",
            "-"
          ).toLowerCase();
          retArray.push({
            key: keyName + "." + tFieldName,
            value: dataObject[key]
          });
        }
      });
      return retArray;
    },
    convertToHashFieldsNoLower(dataObject, keyName) {
      const fields = Object.keys(dataObject);
      var retArray = [];

      fields.forEach((key, index) => {
        // console.log(`${key}: ${dataObject[key]}`);
        if (key !== "_id") {
          var tFieldName = GlobalMethods.replaceAll(key, "_", "-");
          retArray.push({
            key: keyName + "." + tFieldName,
            value: dataObject[key]
          });
        }
      });
      return retArray;
    }
  }
});
