<template>
  <div>
    <quote-form :model="formModel" :rawModel="rawModel" mode="view" />
  </div>
</template>

<script>
import QuoteForm from "../components/QuoteForm";
import { GlobalStore } from "../globalStore";
import { appFunctions } from "../helpers/appFunctions";

export default {
  data() {
    return {
      rawModel: null,
      formModel: {
        policyStart: "",
        insuredName: "",
        insuredABN: "",
        businessDesc: "",
        contactFName: "",
        contactLName: "",
        contactEmail: "",
        contactPhone: "",
        insuredAddress: "",
        insuredStreet: "",
        insuredCity: "",
        insuredState: "NSW",
        insuredPostcode: "",
        equipmentDescription: "",
        equipmentValue: "",
        equipmentCondition: "",
        operatingArea: "",
        usedOnPublicRoad: "",
        insurancePeriod: "12 Months",
        financier: "",
        financierOther: ""
      }
    };
  },

  mounted() {
    console.log(this.$route.params.id);
    appFunctions
      .crud_customerPolicy("getCombined", {
        policy_id: parseInt(this.$route.params.id)
      })
      .then(resp => {
        console.log(resp);
        var d = resp.data;
        this.rawModel = d;
        this.formModel.policyStart = d.Customer_policy_start_date;
        this.formModel.insuredName = d.Customer_insured_name;
        this.formModel.insuredABN = d.Customer_abn;
        this.formModel.businessDesc = d.Customer_business_description;
        this.formModel.contactFName = d.Customer_contact_first_name;
        this.formModel.contactLName = d.Customer_contact_last_name;
        this.formModel.contactEmail = d.Customer_contact_email;
        this.formModel.contactPhone = d.Customer_contact_number;
        this.formModel.insuredAddress = "";
        this.formModel.insuredStreet = d.Customer_contact_street;
        this.formModel.insuredCity = d.Customer_contact_city;
        this.formModel.insuredState = d.Customer_state;
        this.formModel.insuredPostcode = d.Customer_postcode;
        this.formModel.equipmentDescription =
          d.Customer_policy_equipment_description;
        this.formModel.equipmentValue = d.Customer_policy_equipment_value;
        this.formModel.equipmentCondition =
          d.Customer_policy_equipment_condition;
        this.formModel.operatingArea = d.Customer_policy_operation_location;
        this.formModel.usedOnPublicRoad = d.Customer_policy_public_road_usage;
        this.formModel.insurancePeriod = d.Customer_policy_insurance_period;
        this.formModel.financier = d.Customer_policy_Broker_finance_financier;

        this.formModel.financierOther =
          d.Customer_policy_Broker_finance_financier_other;

        this.formModel.Customer_policy_insurance_total_premium =
          d.Customer_policy_insurance_total_premium;
        this.formModel.Customer_policy_insurance_premium =
          d.Customer_policy_insurance_premium;
        this.formModel.Customer_policy_insurance_premium_gst =
          d.Customer_policy_insurance_premium_gst;
        this.formModel.Customer_policy_insurance_premium_stamp_duty =
          d.Customer_policy_insurance_premium_stamp_duty;
        this.formModel.Customer_policy_fee = d.Customer_policy_fee;

        this.formModel.Customer_policy_status = d.Customer_policy_status;
      });
  },
  components: {
    QuoteForm
  }
};
</script>

<style></style>
