import Vue from "vue";
export const baseDataMethods = new Vue({
  methods: {
    empty_Customer: function() {
      return {
        Customer_id: "",
        Customer_Broker_referred_by: "",
        Customer_policy_insured_name: "",
        Customer_policy_abn: "",
        Customer_policy_contact_first_name: "",
        Customer_policy_contact_last_name: "",
        Customer_policy_contact_email: "",
        Customer_policy_contact_number: "",
        Customer_policy_contact_street: "",
        Customer_policy_contact_city: "",
        Customer_policy_state: "",
        Customer_policy_postcode: "",
        Customer_policy_country: ""
      };
    },
    empty_CustomerPolicy: function() {
      return {
        Customer_policy_id: "",
        Customer_policy_number: "",
        Customer_id: "",
        Customer_policy_status: "",
        Customer_policy_start_date: "",
        Customer_policy_business_description: "",
        Customer_policy_equipment_description: "",
        Customer_policy_equipment_serial: "",
        Customer_policy_equipment_value: "",
        Customer_policy_equipment_condition: "",
        Customer_policy_operation_location: "",
        Customer_policy_public_road_usage: "",
        Customer_policy_insurance_period: "",
        Customer_policy_insurance_total_premium: 0,
        Customer_policy_insurance_premium: 0,
        Customer_policy_insurance_premium_gst: 0,
        Customer_policy_insurance_premium_stamp_duty: 0,
        Customer_policy_fee: 0,
        Customer_policy_quote_generated_date: "",
        Customer_policy_quote_generated_pdf: "",
        Customer_policy_quote_signed_date: "",
        Customer_policy_quote_signed_pdf: "",
        Customer_policy_bound_date: "",
        Customer_policy_schedule_pdf: "",
        Customer_payment_status: "",
        Customer_payment_type: "",
        Customer_policy_missing_information: "",
        Customer_policy_offer_form_acceptance: ""
      };
    },
    empty_EmailTemplate: function() {
      return {
        email_template_id: "",
        email_template_name: "",
        email_template_subject_line: "",
        email_template_html: ""
      };
    },
    empty_ContentTemplate: function() {
      return {
        content_template_id: "",
        content_template_name: "",
        content_template_html: ""
      };
    },
    empty_Broker: function() {
      return {
        Broker_org_id: "",
        Broker_id: "",
        Broker_first_name: "",
        Broker_last_name: "",
        Broker_email: "",
        Broker_active: false,
        Broker_org_admin: false
      };
    },
    empty_BrokerOrg: function() {
      return {
        Broker_Org_Name: "",
        Broker_Org_Logo: "",
        Broker_org_accounts_payable_email: "",
        Broker_org_abn: ""
      };
    },
    createBaseData: async function(
      user,
      doCustomer,
      doCustomerPolicy,
      doEmailTemplate,
      doContentTemplate,
      doBroker,
      doBrokerOrg
    ) {
      if (doCustomer) {
        var newCustomer = baseDataMethods.empty_Customer();

        newCustomer.Customer_id = "cust-0001";
        newCustomer.ustomer_Broker_referred_by = "broker-0001";
        newCustomer.Customer_policy_insured_name = "ACME Services";
        newCustomer.Customer_policy_abn = "123456";
        newCustomer.ustomer_policy_contact_first_name = "John";
        newCustomer.Customer_policy_contact_last_name = "Smith";
        newCustomer.Customer_policy_contact_email = "john@email.com";
        newCustomer.Customer_policy_contact_number = "0412345678";
        newCustomer.Customer_policy_contact_street = "Test Street";
        newCustomer.Customer_policy_contact_city = "Test City";
        newCustomer.Customer_policy_state = "Test State";
        newCustomer.Customer_policy_postcode = "4556";
        newCustomer.Customer_policy_country = "Australia";

        var customerResp = await user.functions.crud_customer(
          "create",
          newCustomer
        );
      }

      if (doCustomerPolicy) {
        var newCustomerPolicy = baseDataMethods.empty_CustomerPolicy();

        newCustomerPolicy.Customer_policy_id = "policy-001";
        newCustomerPolicy.Customer_policy_number = "policyNumber-001";
        newCustomerPolicy.Customer_id = "cust-0001";
        newCustomerPolicy.Customer_policy_status = "In_progress";
        newCustomerPolicy.Customer_policy_start_date = "2021-01-01";
        newCustomerPolicy.Customer_policy_business_description =
          "test description";
        newCustomerPolicy.Customer_policy_equipment_description =
          "policy description";
        newCustomerPolicy.Customer_policy_equipment_serial = "123456";
        newCustomerPolicy.Customer_policy_equipment_value = "10000";
        newCustomerPolicy.Customer_policy_equipment_condition = "good";
        newCustomerPolicy.Customer_policy_operation_location = "garage";
        newCustomerPolicy.Customer_policy_public_road_usage = "na";
        newCustomerPolicy.Customer_policy_insurance_period = "12 months";
        newCustomerPolicy.Customer_policy_insurance_total_premium = 12000;
        newCustomerPolicy.Customer_policy_insurance_premium = 400;
        newCustomerPolicy.Customer_policy_insurance_premium_gst = 10.5;
        newCustomerPolicy.Customer_policy_insurance_premium_stamp_duty = 999;
        newCustomerPolicy.Customer_policy_fee = 1000;
        newCustomerPolicy.Customer_policy_quote_generated_date = "2021-01-01";
        newCustomerPolicy.Customer_policy_quote_generated_pdf = "";
        newCustomerPolicy.Customer_policy_quote_signed_date = "";
        newCustomerPolicy.Customer_policy_quote_signed_pdf = "";
        newCustomerPolicy.Customer_policy_bound_date = "";
        newCustomerPolicy.Customer_policy_schedule_pdf = "";
        newCustomerPolicy.Customer_payment_status = "";
        newCustomerPolicy.Customer_payment_type = "";
        newCustomerPolicy.Customer_policy_missing_information = "";
        newCustomerPolicy.Customer_policy_offer_form_acceptance = "";

        var customerPolicyResp = await user.functions.crud_customerPolicy(
          "create",
          newCustomerPolicy
        );
      }

      if (doEmailTemplate) {
        var newEmailTemplate1 = baseDataMethods.empty_EmailTemplate();
        newEmailTemplate1.email_template_id = "offer";
        newEmailTemplate1.email_template_subject_line = "";
        newEmailTemplate1.email_template_name = "";
        newEmailTemplate1.email_template_html = "<p>No Content as yet</p>";

        await user.functions.crud_emailTemplate("create", newEmailTemplate1);

        var newEmailTemplate2 = baseDataMethods.empty_EmailTemplate();
        newEmailTemplate2.email_template_id = "policy-bound";
        newEmailTemplate2.email_template_subject_line =
          "Your policy is now bound!";
        newEmailTemplate2.email_template_name = "Policy Bound";
        newEmailTemplate2.email_template_html = "<p>No Content as yet</p>";

        await user.functions.crud_emailTemplate("create", newEmailTemplate2);

        var newEmailTemplate3 = baseDataMethods.empty_EmailTemplate();
        newEmailTemplate3.email_template_id = "broker-finance-acceptance";
        newEmailTemplate3.email_template_subject_line =
          "Congratulations! Your finance was accepted!";
        newEmailTemplate3.email_template_name = "Broker Finance Acceptance";
        newEmailTemplate3.email_template_html = "<p>No Content as yet</p>";

        await user.functions.crud_emailTemplate("create", newEmailTemplate3);

        var newEmailTemplate4 = baseDataMethods.empty_EmailTemplate();
        newEmailTemplate4.email_template_id = "payment-request";
        newEmailTemplate4.email_template_subject_line = "Payment Requested";
        newEmailTemplate4.email_template_name = "Payment Request";
        newEmailTemplate4.email_template_html = "<p>No Content as yet</p>";

        await user.functions.crud_emailTemplate("create", newEmailTemplate4);

        var newEmailTemplate5 = baseDataMethods.empty_EmailTemplate();
        newEmailTemplate5.email_template_id = "policy-expiring";
        newEmailTemplate5.email_template_subject_line =
          "Your policy is about to expire...";
        newEmailTemplate5.email_template_name = "Policy Expiring";
        newEmailTemplate5.email_template_html = "<p>No Content as yet</p>";

        await user.functions.crud_emailTemplate("create", newEmailTemplate5);
      }

      if (doContentTemplate) {
        var newContentTemplate1 = baseDataMethods.empty_ContentTemplate();
        newContentTemplate1.content_template_id = "policy-offer-template";
        newContentTemplate1.content_template_name = "Policy Offer Template";
        newContentTemplate1.content_template_html = "<p>No Content as yet</p>";

        await user.functions.crud_contentTemplate(
          "create",
          newContentTemplate1
        );

        var newContentTemplate2 = baseDataMethods.empty_ContentTemplate();
        newContentTemplate2.content_template_id = "policy-schedule-template";
        newContentTemplate2.content_template_name = "Policy Schedule Template";
        newContentTemplate2.content_template_html = "<p>No Content as yet</p>";

        await user.functions.crud_contentTemplate(
          "create",
          newContentTemplate2
        );
      }

      if (doBroker) {
        var newBroker = baseDataMethods.empty_Broker();
        newBroker.Broker_org_id = "brokOrg-001";
        newBroker.Broker_first_name = "Gary";
        newBroker.Broker_last_name = "Barry";
        newBroker.Broker_id = "brk-0001";
        newBroker.Broker_email = "test@broker.com";
        newBroker.Broker_active = true;
        newBroker.Broker_org_admin = false;

        await user.functions.crud_broker("create", newBroker);
      }

      if (doBrokerOrg) {
        var newBrokerOrg = baseDataMethods.empty_BrokerOrg();
        newBrokerOrg.Broker_Org_Id = "brokOrg-001";
        newBrokerOrg.Broker_Org_Logo = "test-logo.jpg";
        newBrokerOrg.Broker_org_accounts_payable_email = "test@org.com";
        newBrokerOrg.Broker_org_abn = "1234567";

        await user.functions.crud_brokerOrg("create", newBrokerOrg);
      }
    }
  }
});
